import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ChangeDetectorRef,
  SimpleChanges,
  OnInit, Inject,
  OnDestroy
} from '@angular/core';
import {ConfirmationDialog} from 'projects/common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import { Note, NoteStatus, NotesUpdate, NoteUpdate } from 'projects/business-slick-estimates/src/app/models/note.model';
import {ModalBehavior, ModalsService} from 'projects/common/src/lib/services/modals.service';
import {NotesService} from 'projects/business-slick-estimates/src/app/services/notes.service';
import { UtilsService} from 'projects/common/src/public-api';
import {UsersService} from "../../../../services/users.service";
import {UserProfile} from "../../../../../../../common/src/lib/models/user-profile.model";
import { ComponentPortal } from '@angular/cdk/portal';
import { SafeUrl } from '@angular/platform-browser';
import { BusinessService } from 'projects/business-slick-estimates/src/app/services/business.service';
import { ImageViewerComponent } from 'projects/common/src/lib/components/image-viewer/image-viewer.component';
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { NoteComponent } from "../note/note.component";
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';

const NEW_NOTE_ID = -1;
const MaxImageSizeMb = 5;

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {

  NEW_NOTE_ID = NEW_NOTE_ID;

  @Input() notes: NotesUpdate = [];
  @Input() workflowId: number | null = null;
  @Input() businessId!: string;
  @Input() currentUser!: UserProfile;
  
  @Input() isModal = true;
  @Input() showJobType = false;
  @Input() showOverlayImageViewer = false;
  @Input() from = '';
  
  @Output() onImageClick = new EventEmitter<NoteUpdate>();
  @Output() close = new EventEmitter();
  @Output() notesInFocus = new EventEmitter<boolean>();
  @Output() beforeDeleteConfirmation = new EventEmitter();
  
  jobType?: string;
  jobTypeControl = new FormControl<string>('', { nonNullable: true });
  focusedNoteId?: number;

  newNote?: NoteUpdate;
  jobTypeSub?: Subscription;
  jobTypeUpdateSub?: Subscription;
  focusedNote: number | null = null;

  notesSub?: Subscription;

  constructor(
    public usersService: UsersService,
    private businessService: BusinessService,
    public notesService: NotesService,
    private modalsService: ModalsService,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef,
    private snackbar: MatLegacySnackBar,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    if(!this.workflowId) {
      this.notes = this.notesService.getLocalNotes();
      return;
    }

    this.jobTypeSub = this.jobTypeControl.valueChanges.pipe(
      debounceTime(1000)
    )
    .subscribe(value => {
      if (value.trim() === this.jobType)
        return;
      if(value.trim() === '') {
        this.jobTypeControl.setValue(this.jobType ?? '', { emitEvent: false });
      } else {
        this.notesService.updateJobType(this.workflowId!, value.trim());
        this.jobType = value;
      }
    });

    this.jobTypeUpdateSub = this.notesService.jobTypeObservable(this.workflowId)
      .subscribe(jobType => {
        if (jobType !== this.jobType?.trim()) {
          this.jobTypeControl.setValue(jobType!, { emitEvent: !this.jobType });
          this.jobType = jobType!;
        }
      }
    );

    this.notesSub = this.notesService.notesObservable(this.workflowId).subscribe(notes => {
      if(notes && this.focusedNote === null) {
        this.notes = notes.map(note => {
          if(note.inUseBy === this.currentUser.id) {
            const inUseNote = this.notes.find(n => n.inUseBy === this.currentUser.id);
            if(inUseNote)
              return inUseNote;
          }
          return note;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.notesSub?.unsubscribe();
    this.jobTypeSub?.unsubscribe();
    this.jobTypeUpdateSub?.unsubscribe();
  }

  createAndFocusNoteIfEmpty() {
    if(this.notes.length === 0) {
      this.addNote();
      setTimeout(() => {
        this.document.scrollingElement?.scrollTo({top: 0});
      }, 300);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.from && changes.from.currentValue !== changes.from.previousValue) {
      this.notesService.updateFromInLocalNotes(changes.from.currentValue);
    }
  }

  setNewNotePosition(event: CdkDragDrop<NotesUpdate>, notes: NotesUpdate) {
    moveItemInArray<NoteUpdate>(notes, event.previousIndex, event.currentIndex);
    this.notesService.updateNotes(this.businessId, this.workflowId!, notes as NotesUpdate);
  }

  showImageViewer(imageUrl: SafeUrl | string, noteId?: number) {
    const viewer = new ComponentPortal(ImageViewerComponent);
    this.notesService.showImageViewer(imageUrl, viewer, noteId ? (() => this.focusNote(noteId)).bind(this) : undefined);
  }

  async imageClick(note: Partial<Note> | NoteUpdate) {
    if (this.showOverlayImageViewer) {
      const src = (note as NoteUpdate).image
        ? URL.createObjectURL((note as NoteUpdate).image!)
        : await this.notesService.getImageUrl(note as Note);
      if (src) {
        this.showImageViewer(src);
      }
    }
    this.onImageClick.emit(note);
  }

  async addImageNote(event: Event, index: number, elementRef?: NoteComponent) {
    let image: File | null = null;
    if (event && event.target) {
      const input = event.target as HTMLInputElement;
      if (!input.files || !input.files[0])
        return;

      image = input.files[0];
      if (image.size > MaxImageSizeMb * (1024 ** 2)) {
        showSnackbar(this.snackbar, {
          message: `File exceeds ${MaxImageSizeMb}MB limit`,
          duration: 3000
        });
        return;
      }
      const file = this.notesService.generateImageFile(image, this.businessId);

      if (index !== -1) {
        const note = this.notes[index];
        note.image = file;
        if(elementRef)
          elementRef.updateImage();
      } else {
        this.notesService.shouldOpenImageViewer = false;
        this.notes.unshift({
          image: file,
          author: this.currentUser.id,
          from: this.from,
          status: 'Unmark',
          text: ''
        });
      }

      if(this.workflowId) {
        await this.notesService.updateNotes(this.businessId, this.workflowId!, this.notes);
      } else {
        this.notesService.setLocalNotes(this.notes);
      }
      this.notesService.shouldOpenImageViewer = true;
    }
  }

  deleteNoteImage(index: number) {
    const note = this.notes[index];
    if(!note.text || note.text === '') {
      this.deleteNote(index);
      return;
    }
    const workflowId = this.workflowId;
    this.beforeDeleteConfirmation.emit();
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Delete Photo',
        message: 'Are you sure you want to delete this photo?',
        actionTitle: 'Delete',
        action: async () => {
          if(!workflowId) {
            delete note.image;
          } else {
            note.image = null;
            await this.notesService.updateNotes(this.businessId, workflowId, this.notes);
          }
        },
        actionColor: 'warn'
      },
      prevComponentMetadata: this.modalsService.dataToRestore(),
    })
  }

  async addNote() {
    this.newNote = {
      text: '',
      from: this.from,
      author: this.currentUser.id,
      status: 'Unmark',
    };
  }

  onNoteTextChange(text: string, index: number) {
    this.notes[index].text = text;
    if(this.workflowId) {
      if(text.length > 0) {
        this.notesService.updateNotes(this.businessId, this.workflowId, this.notes as NotesUpdate);
      }
      return;
    }
    this.notesService.setLocalNotes(this.notes);
    this.changeDetector.detectChanges();
  }

  async deleteNote(index: number) {
    let title;
    let message;
    this.changeDetector.detectChanges();
    const note = this.notes[index];
    if ((note.imageName || note.image) && note.text) {
      title = 'Delete Photo & Note';
      message = 'Are you sure you want to delete the photo and its note?';
    } else if ((note.imageName || note.image) && (note.text?.length === 0)) {
      title = 'Delete Photo';
      message = 'Are you sure you want to delete this photo?';
    } else {
      title = 'Delete Note';
      message = 'Are you sure you want to delete the note?';
    }
    const workflowId = this.workflowId;
    this.beforeDeleteConfirmation.emit();
    await this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title,
        message,
        actionTitle: 'Delete',
        action: async () => {
          const deleted = this.notes.splice(index, 1);
          if(!workflowId) {
            this.notesService.setLocalNotes(this.notes);
          } else {
            await this.notesService.updateNotes(this.businessId, workflowId, this.notes, deleted);
          }
        },
      },
      prevComponentMetadata: this.modalsService.dataToRestore()
    });
  }

  async noteFocused(index: number) {
    this.focusedNote = index;
    if(index !== NEW_NOTE_ID && this.notes[index].inUseBy === this.currentUser.id)
      return;

    this.notesInFocus.emit(true);
    if(this.workflowId && index !== NEW_NOTE_ID) {
      this.notes[index].inUseBy = this.currentUser.id;
      await this.notesService.updateNotes(this.businessId, this.workflowId, this.notes);
    }
  }

  async noteUnfocused(index: number) {
    this.focusedNote = null;
    this.notesInFocus.emit(false);
    let hasChanges = false;
    if(index === NEW_NOTE_ID) {
      if(this.newNote!.text!.length > 0 || !!this.newNote!.imageName) {
        this.notes.unshift(this.newNote!);
        hasChanges = true;
      }
      this.newNote = undefined;
    } else {
      hasChanges = true;
      delete this.notes[index].inUseBy;
      this.notes = this.notes.filter(note => {
        return note.text!.length > 0 || !!note.imageName || !!note.image;
      });
    }

    if(!hasChanges)
      return;

    if(this.workflowId) {
      await this.notesService.updateNotes(this.businessId, this.workflowId!, this.notes);
    } else {
      this.notesService.setLocalNotes(this.notes);
    }
  }

  focusNote(id: number | undefined) {
    this.focusedNoteId = id;
  }

  get isMobile(): boolean {
    return this.utilsService.isMobile();
  }

  getFullUserName(id: number, users: UserProfile[]) {
    const user = users.find((user) => user.id === id);
    return user ? user.firstName + ' ' + user.lastName : '';
  }

  async onStatusChange(index: number, status: NoteStatus) {
    this.notes[index].status = status;
    await this.notesService.updateNotes(this.businessId, this.workflowId!, this.notes);
  }

  onMenuClick(event: Event) {
    event.stopImmediatePropagation();
  }
  
}
