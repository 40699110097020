import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { ProposalsService } from "../../services/proposals.service";
import { FormControl } from "@angular/forms";
import { Subject, Subscription} from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { isEmpty, decimalOnly, onDecimalPaste } from "../../helpers/forms";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";

type ProposalSummaryValues = {
  subtotal: number,
  discountDisplay: string,
  salesTax: number,
  total: number
};

@Component({
  selector: 'app-proposal-summary',
  templateUrl: './proposal-summary.component.html',
  styleUrls: ['./proposal-summary.component.scss']
})
export class ProposalSummaryComponent implements OnInit, OnChanges, OnDestroy {

  protected readonly isNaN = isNaN;
  protected readonly decimalOnly = decimalOnly;
  protected readonly onPaste = onDecimalPaste;

  @Output() depositFocus = new EventEmitter<boolean>();

  showingDepositSnackbar = false;

  proposalSummaryValues: ProposalSummaryValues = {
    subtotal: 0,
    discountDisplay: '',
    salesTax: 0,
    total: 0
  };

  displayDeposit: number = 0;

  depositFocus$ = new Subject<boolean>();

  depositPercentFormControl = new FormControl();
  depositAmountFormControl = new FormControl();

  depositPercentFormControlSubscription!: Subscription;
  depositAmountFormControlSubscription!: Subscription;
  depositFocusSubscription!: Subscription;
  depositRecalculateSubscription!: Subscription;

  depositAbove: boolean = false;

  showDisplayDeposit = true;

  currentDepositAmount: number | null | undefined;
  currentDepositPercent: number | null | undefined;
  currentDiscountType?: 'amount' | 'percent';
  typeChange = false;

  @Input() proposal!: Proposal;
  @Input() proposalVersion!: ProposalVersion;

  constructor(
    private proposalsService: ProposalsService,
    private snackbar: MatSnackBar
  ) {
    this.depositRecalculateSubscription = this.proposalsService.recalculate$.pipe(
      tap(_ => {
        this.setDeposit(false, true).then()
      })
    ).subscribe();

    this.depositPercentFormControlSubscription = this.depositPercentFormControl.valueChanges.subscribe(
      percent => this.reCalcDisplayDeposit(percent, 'percent')
    );

    this.depositAmountFormControlSubscription = this.depositAmountFormControl.valueChanges.subscribe(
      amount => this.reCalcDisplayDeposit(amount, 'amount')
    );

    this.depositFocusSubscription = this.depositFocus$.pipe(
      debounceTime(150),
      tap(async focus => {
        await this.setDeposit(focus)
      })
    ).subscribe();
  }

  async setDeposit(focus: boolean, noSnackBar?: boolean) {
    if (!focus) {
      if (this.proposalVersion.depositType === 'percent') {
        this.proposalVersion.depositPercent = +this.depositPercentFormControl.value;
        this.proposalVersion.depositAmount = parseFloat((+this.displayDeposit).toFixed(2));
      } else if (this.proposalVersion.depositType === 'amount') {
        this.proposalVersion.depositAmount = +this.depositAmountFormControl.value;
        this.proposalVersion.depositPercent = parseFloat((+this.displayDeposit).toFixed(2));
      }

      if (
        this.displayDeposit === 0
        && this.proposalVersion.depositAmount === 0
        && this.proposalVersion.depositPercent === 0
      ) {
        await this.saveDeposit(noSnackBar);
      } else if (
        this.proposalVersion.depositAmount
        && !isNaN(this.proposalVersion.depositAmount)
        && this.proposalVersion.depositPercent
        && !isNaN(this.proposalVersion.depositPercent)
      ) {
        this.showDisplayDeposit = !noSnackBar ? false : true;
        await this.saveDeposit(noSnackBar);
        setTimeout((() => {
          this.showDisplayDeposit = true;
        }).bind(this), 250);
      }
    } else {
      this.currentDepositAmount = this.proposalVersion.depositAmount;
      this.currentDepositPercent = this.proposalVersion.depositPercent;
    }
  }

  private async saveDeposit(noSnackBar?: boolean) {
    if (
      this.currentDepositAmount !== this.proposalVersion.depositAmount
      || this.currentDepositPercent !== this.proposalVersion.depositPercent
      || this.typeChange
    ) {
      this.typeChange = false;
      this.depositAmountFormControl.disable();
      this.depositPercentFormControl.disable();
      await this.saveProposalVersion();
      if (!this.showingDepositSnackbar && !noSnackBar) {
        showSnackbar(this.snackbar, {
          message: 'Deposit set',
          duration: 2000,
        });
        this.showingDepositSnackbar = true;
        setTimeout(() => {
          this.showingDepositSnackbar = false;
        }, 1000);
      }
      this.depositAmountFormControl.enable();
      this.depositPercentFormControl.enable();
    }
  }

  ngOnInit() {
    if (this.proposalVersion.depositType === 'percent' && this.proposalVersion.depositPercent) {
      const fraction = this.proposalVersion.depositPercent
        ?.toFixed(2).toString().split('.')[1]?.substring(0, 2);
      this.depositPercentFormControl.patchValue(
        fraction === '00'
          ? this.proposalVersion.depositPercent?.toFixed(0)
          : this.proposalVersion.depositPercent?.toFixed(2)
      );
    } else if (this.proposalVersion.depositType === 'amount' && this.proposalVersion.depositAmount) {
      this.depositAmountFormControl.patchValue(this.proposalVersion.depositAmount?.toFixed(2));
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ('proposalVersion' in changes) {
      this.currentDiscountType = this.proposalVersion.discountType;
      this.calculateValues();
      if (this.proposalVersion.depositType === 'amount')
        this.reCalcDisplayDeposit(this.depositAmountFormControl.value, 'amount');
      else if (this.proposalVersion.depositType === 'percent')
        this.reCalcDisplayDeposit(this.depositPercentFormControl.value, 'percent');
    }
  }

  ngOnDestroy() {
    this.depositPercentFormControlSubscription?.unsubscribe();
    this.depositAmountFormControlSubscription?.unsubscribe();
    this.depositFocusSubscription?.unsubscribe();
    this.depositRecalculateSubscription?.unsubscribe();
  }

  calculateValues() {
    this.proposalSummaryValues.subtotal = 0;
    this.proposalSummaryValues.salesTax = 0;
    this.proposalSummaryValues.total = 0;
    let totalDiscountAmount = 0;

    this.proposalVersion.items?.forEach(item => {
      let discounted = 0;
      let taxed = 0;

      this.proposalSummaryValues.subtotal += (item.quantity ?? 0) * item.price;
      totalDiscountAmount += (item.discountAmount ?? 0) * (item.quantity ?? 0);

      discounted = item.price - (item.discountAmount ?? 0);

      taxed = !item.taxable
        ? discounted
        : discounted + (discounted * (this.proposalVersion.clientSalesTaxPercentage ?? 0) / 100);

      if (item.quantity && item.taxable && item.price)
        this.proposalSummaryValues.salesTax += parseFloat(((taxed - discounted) * item.quantity).toFixed(2));

      if (item.quantity)
        this.proposalSummaryValues.total += parseFloat((taxed * item.quantity).toFixed(2));
    });

    if (this.currentDiscountType === 'amount')
      this.proposalSummaryValues.discountDisplay = totalDiscountAmount.toFixed(2);
    else {
      this.proposalSummaryValues.discountDisplay
        = ((totalDiscountAmount/this.proposalSummaryValues.subtotal)*100).toFixed(2);
      if (this.proposalSummaryValues.discountDisplay === 'Infinity')
        this.proposalSummaryValues.discountDisplay = '0';
      if (this.proposalSummaryValues.discountDisplay.split('.')[1]?.substring(0, 2) === '00')
        this.proposalSummaryValues.discountDisplay = this.proposalSummaryValues.discountDisplay.slice(0, -3);
    }

    this.proposalSummaryValues.total =
      this.proposalSummaryValues.subtotal
      - totalDiscountAmount
      + this.proposalSummaryValues.salesTax;

  }

  async toggleSignature(status: boolean) {
    this.proposalVersion.signature = status;
    await this.saveProposalVersion();
  }

  async toggleDiscountType(type: 'amount' | 'percent') {
    if (
        !this.depositAmountFormControl.disabled
        && !this.depositPercentFormControl.disabled
    ) {
      if (type !== this.proposalVersion.discountType) {
        this.currentDiscountType = type;
        this.calculateValues();
        this.proposalVersion.discountType = type;
        await this.saveProposalVersion();
      }
    }
  }

  async toggleDepositType() {
    this.typeChange = true;
    if (!this.displayDeposit || this.displayDeposit === 0) {
      this.depositAmountFormControl.patchValue(null);
      this.depositPercentFormControl.patchValue(null);
    }
    if (this.proposalVersion.depositType === 'percent') {
      if (+this.displayDeposit === Infinity || isNaN(+this.displayDeposit))
        this.depositAmountFormControl.patchValue(0);
      else if (this.displayDeposit !== 0)
        this.depositAmountFormControl.patchValue(+this.displayDeposit.toFixed(2));
      this.proposalVersion.depositType = 'amount';
    } else if (this.proposalVersion.depositType === 'amount') {
      if (+this.displayDeposit === Infinity || isNaN(+this.displayDeposit))
        this.depositPercentFormControl.patchValue(0);
      else if (this.displayDeposit !== 0)
        this.depositPercentFormControl.patchValue(+this.displayDeposit.toFixed(2));
      this.proposalVersion.depositType = 'percent';
    }
  }

  private reCalcDisplayDeposit(value: any, which: 'percent' | 'amount') {
    if (isEmpty(value)) {
      this.displayDeposit = 0;
      this.depositAbove = false;
    } else {
      if (value && !isNaN(value)) {
        this.depositAbove = false;

        if (which === 'percent') {
          if (value > 100)
            this.depositAbove = true;
          this.displayDeposit = (value * this.proposalSummaryValues.total)/100;
        } else if (which === 'amount') {
          if (value > this.proposalSummaryValues.total)
            this.depositAbove = true;
          this.displayDeposit = (value/this.proposalSummaryValues.total)*100;
        }
      }
    }
  }

  formatDeposit(rawDeposit: number) {
    if (rawDeposit === Infinity || isNaN(rawDeposit)) {
      return '0';
    } else {
      if (this.proposalVersion.depositType === 'percent') {
        return rawDeposit.toFixed(2);
      } else if (this.proposalVersion.depositType === 'amount') {
        if (rawDeposit?.toString().split('.')[1]?.substring(0, 2) === '00')
          return this.displayDeposit.toFixed(2).slice(0, -3);
        else
          return rawDeposit.toFixed(2);
      } else return 0;
    }
  }

  private async saveProposalVersion() {
    await this.proposalsService.updateProposalVersion(this.proposalVersion.id, {
      signature: this.proposalVersion.signature,
      depositType: this.proposalVersion.depositType,
      depositAmount: this.proposalVersion.depositAmount,
      depositPercent: this.proposalVersion.depositPercent,
      discountType: this.proposalVersion.discountType
    });
  }
}
