import { AddressComponents } from "./address-comments.model";
import { TimeRange } from "./time-range.model";

export interface LeadTimeRange {
    id: number;
    leadId: number;
    startTime: Date;
    endTime: Date;
}

export interface Lead {
    docType: 'Lead';
    id: number;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    address: AddressComponents;
    businessName: string | null;
    createdAt: Date;
    email: string;
    type: 'personal' | 'business';
    firstName: string;
    lastName: string;
    clientId: number;
    clientCreatedAt: Date;
    salesTaxPercentage: number;
    jobType: string;
    phoneNumber: string;
    extNumber: string;
    unit: string;
    workflowId: number;
    ranges: TimeRange[];
}

export interface LeadTileData {
    docType: 'Lead';
    id: number;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    businessName: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    clientId: number;
    clientCreatedAt: Date;
    salesTaxPercentage: number;
    jobType: string;
    phoneNumber: string;
    workflowId: number;
    rangeId: number;
    startTime: Date;
    endTime: Date;
}

export function leadTileData(from: Lead, rangeId: number): LeadTileData {
    const range = from.ranges.find(r => (r as any).rangeId === rangeId)!;
    return {
        docType: 'Lead',
        id: from.id,
        createdAt: from.createdAt,
        ownerId: from.ownerId,
        ownerFirstName: from.ownerFirstName,
        ownerLastName: from.ownerLastName,
        businessName: from.businessName,
        firstName: from.firstName,
        lastName: from.lastName,
        jobType: from.jobType,
        phoneNumber: from.phoneNumber,
        startTime: range.startTime,
        endTime: range.endTime,
        rangeId,
        workflowId: from.workflowId,
        clientId: from.clientId,
        clientCreatedAt: from.clientCreatedAt,
        salesTaxPercentage: from.salesTaxPercentage,
    };
}