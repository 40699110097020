<div class="notifications-button" [class.open]="isMenuOpen"
     [matMenuTriggerFor]="menu"
     [hideMenuOnScreenSizeChange]="menuTrigger"
     #menuTrigger="matMenuTrigger"
     (menuOpened)="menuOpenChange(true)"
     (menuClosed)="menuOpenChange(false)"

>
    <app-icon class="se-icon-button" width="40px" height="40px" src="notif"></app-icon>
    <small class="counter" *ngIf="(unseenChatsCount$ | async) let count">{{ count }}</small>
</div>
<input type="file" id="chat-file-input" accept="audio/mp3, audio/mpeg, image/*, audio/*" style="display: none;">

<mat-menu #menu xPosition="before" class="no-margin notifications-menu-wrapper">
    <div (click)="onMenuClick($event);" [class]="'notifications-menu ' + selectedTab">
        <app-tabs
            [tabs]="['Notifications', 'Chat']"
            alignment="center"
            [activeTab]="selectedTab"
            (onTabSelect)="selectedTab = $event"
            [redDots]="[false, !!(unseenChatsCount$ | async) && selectedTab !== 'Chat' ]"
        ></app-tabs>
        <ng-container [ngSwitch]="selectedTab">
            <ng-container  *ngSwitchCase="'Notifications'">
                <app-notifications-list #notificationsList *ngIf="notificationsCount$ | async else empty"></app-notifications-list>
            </ng-container>
            <ng-container *ngSwitchCase="'Chat'">

                <div class="notifications-content" [class.chat-list]="selectedChatTab === 'Chats'" [ngSwitch]="selectedChatTab">
                    <ng-container *ngSwitchCase="'Chats'">
                        <app-chats-list #chatsList (openChat)="openChat($event.id)" *ngIf="!chatList.chats || chatList.chats.length > 0; else empty" [class.d-none]="!chatList.chats"></app-chats-list>
                    </ng-container>
                    <div *ngSwitchDefault class="chat-wrapper">
                        <app-chat #chat [chat]="chat$ | async" (chatCreated)="openChat($event)" (leave)="onChatTabSelected('Chats')"></app-chat>
                    </div>
                </div>

                <div class="pb--12 w-100 flex-shrink-0" style="min-height: 60px">
                    <app-tabs
                            [tabs]="chatTabs"
                            alignment="center"
                            [activeTab]="selectedChatTab"
                            (onTabSelect)="onChatTabSelected($event)"
                    ></app-tabs>
                </div>
            </ng-container>
        </ng-container>
    </div>
</mat-menu>

<ng-template #empty>
    <div class="empty-text flex-fill">
        <h4>{{ selectedTab === 'Notifications' ? 'No Notifications' : 'No chats' }}</h4>
    </div>
</ng-template>