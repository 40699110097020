import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { MatMenuTrigger } from "@angular/material/menu";
import { InvoiceVersion } from "../../models/invoice.model";

@Component({
  selector: 'app-version-picker',
  templateUrl: './version-picker.component.html',
  styleUrls: ['./version-picker.component.scss']
})
export class VersionPickerComponent<T extends string> implements OnChanges, AfterViewInit {

    @ViewChild('versionPicker') private versionPicker!: ElementRef;
    @ViewChild('menuTrigger') private menuTrigger!: MatMenuTrigger;

    @Input() versions!: ProposalVersion[] | InvoiceVersion[];
    @Input() selectedVersionId!: number;
    @Input() actions?: T[] = [];
    @Input() allowArrowScrolling?: boolean;
    @Input() type!: string;
    @Input() proposal?: Proposal;
    @Input() disableActions = false;

    @Output() versionSelected = new EventEmitter<ProposalVersion | InvoiceVersion>();
    @Output() actionClick = new EventEmitter<T>();

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedVersionId' in changes || 'versions' in changes)
            if (((this.versions as any[]).find((version: any) => version.id === this.selectedVersionId))?.items)
                this.actions = ['New', 'Duplicate'] as T[];
            else
                this.actions = ['New'] as T[];
    }

    ngAfterViewInit(): void {
        this.versionPicker.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
            event.preventDefault();
            this.versionPicker.nativeElement.scrollLeft += event.deltaY;
        });
    }

    selectVersion(version: ProposalVersion | InvoiceVersion) {
        this.selectedVersionId = version.id;
        this.versionSelected.emit(version);
    }

    actionsClick() {
        if(!this.actions || this.actions.length === 0)
            return;

        if(this.actions.length === 1)
            this.actionClick.emit(this.actions[0]);
        else
            this.menuTrigger.openMenu();
    }

    @HostListener('window:keydown', ['$event'])
    handleArrowKeys(event: KeyboardEvent): void {
        const focusedElement = document.activeElement?.tagName.toLowerCase();
        if (focusedElement === 'input' || focusedElement === 'textarea')
            return;
        
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
            if (event.key === 'ArrowLeft')
                this.versionPicker.nativeElement.scrollLeft -= 30;
            else
                this.versionPicker.nativeElement.scrollLeft += 30;
        }
    }
}
