<div class="content">
  <div class="w-100 d-flex align-items-center gap--8">

    <input #attachmentInput style="display: none;" type="file" accept="image/*, video/*" (change)="addFile(); attachmentInput.value = ''">
    <app-icon 
      class="control-button" 
      src="clip" 
      (click)="attachmentInput.click()" 
      [class.disabled]="disabled"
    ></app-icon>
    <form class="form">
      <textarea cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                [formControl]="messageInput"
                #textArea
                #autosize="cdkTextareaAutosize"
                placeholder="Type a question..."
                class="message-input hide-scrollbar"
                (keydown)="onKeyDown($event)"
      ></textarea>
    </form>
    <div class="d-flex gap--8 mb--2">
      <app-icon 
        class="control-button" 
        src="send_message" 
        [class.disabled]="disabled || textArea.value.trim().length === 0" 
        (click)="sendMessage()"
      ></app-icon>
    </div>
  </div>

  <div class="divider-line"></div>

  <ng-content></ng-content>
</div>
