import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { OnboardingService } from '../../services/onboarding.service';
import { UtilsService } from 'projects/common/src/public-api';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-onboarding-banner',
  templateUrl: './onboarding-banner.component.html',
  styleUrls: ['./onboarding-banner.component.scss']
})
export class OnboardingBannerComponent {
  onboardingData$ = this.onboardingService.onboardingData$;

  smallScreen$ = this.utilsService.onScreenBreakpointChange('md').pipe(
    map(large => !large)
  );

  text$ = this.onboardingData$.pipe(
    map(data => {
      if (!data)
        return null;
      const status = data.status;
      if (status === 'testing')
        return `Book your onboarding session with ${data.salesFirstName} ${data.salesLastName}`;
      if (status === 'onboarding')
        return `You're In! Onboarding scheduled for ${moment(data.callStart).format('MMMM Do')} at ${moment(data.callStart).format('h:mm A')}`;
      if (status === 'pending_approval')
        return 'Awaiting credit card processing company\'s approval';
      return null;
    })
  );

  link$ = this.onboardingData$.pipe(
    map(data => data?.url)
  );

  status$ = this.onboardingData$.pipe(
    map(data => data?.status)
  );
  
  linkText$ = combineLatest([this.status$, this.smallScreen$]).pipe(
    map(([status, smallSreen]) => {
      if (smallSreen)
        return 'Onboarding';
      return status === 'onboarding' ? 'Go to Onboarding' : 'Book Onboarding';
    })
  )

  constructor(
    private onboardingService: OnboardingService,
    private utilsService: UtilsService
  ) {}
}
