import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalsService } from '../../services/modals.service';

export interface ActionResponse {
  close: boolean,
  reopen: boolean,
}

interface ConfirmationDialogData {
  title: string;
  message: string;
  actionTitle: string;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  cancelTitle?: string;
  action: () => Promise<ActionResponse> | ActionResponse | Promise<void> | void;
  actionColor?: 'primary' | 'warn',
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialog implements OnInit {

  loading = false;

  get data() {
    return this.dialogData ?? this.modalsService.data;
  }

  constructor(
    private modalsService: ModalsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationDialogData
  ) {}

  ngOnInit(): void {
    if (this.data.showCancelButton === undefined)
      this.data.showCancelButton = true;
    if (this.data.showCloseButton === undefined)
      this.data.showCloseButton = true;
  }

  async action() {
    this.loading = true;
    try {
      // void will close, boolean will be provided as 'reopenPrevious' to the close function
      const res = await this.data.action();
      if(res?.close ?? true) {
        await this.modalsService.close(res?.reopen ?? true);
      }
    } catch (e) {
      console.log(`confirmation-dialog.component.ts > ConfirmationDialog > action()`, e);
    }
    this.loading = false;
  }

  cancel() {
    this.modalsService.close(true);
  }
}
