import { Injectable, NgZone } from '@angular/core';
import { RealtimeChannel, RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { serverToFrontTranslation } from 'projects/common/src/lib/services/supabase.service';
import { finalize, shareReplay, switchMap, take } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { SupabaseService } from './supabase.service';
import { UsersService } from './users.service';
import { combineLatest, Observable, of } from 'rxjs';

type OnboardingStatus = 'pending' | 'testing' | 'onboarding' | 'pending_approval' | 'approved';

interface OnboardingData {
  url?: string | null;
  callStart?: Date | null;
  salesFirstName?: string | null;
  salesLastName?: string | null;
  status: OnboardingStatus
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  onboardingData$ = combineLatest([this.businessService.selectedBusiness$, this.usersSerivce.currentUser$]).pipe(
    switchMap(([business, user]) => {
      if (user.role !== 'owner')
        return of(null);
      return this.supabaseService.supabase
        .schema('admin_panel')
        .from('businesses')
        .select('is_lead')
        .eq('business_uuid', business!.businessId);
      }
    ),
    switchMap(res => {
      if (!res?.data?.length || !serverToFrontTranslation(res.data[0]).isLead)
        return of(null);
      return this.onboardingObservable();
    }),
    shareReplay(1)
  );

  private onboardingObservable() {
    let channel: RealtimeChannel | null = null;
    let onboardingData: OnboardingData | null = null;
    const unsubscribe = () => channel ? this.supabaseService.supabase.removeChannel(channel) : null;

    return new Observable<OnboardingData | null>(observer => {
      const setObserver = () => this.ngZone.run(() => observer.next(onboardingData));

      const getData = async () => {
        const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
        const res = await this.supabaseService.supabase
          .schema('admin_panel')
          .from('system_leads')
          .select('calendly_onboarding_url, call_url, call_start, status, system_users (first_name, last_name)')
          .eq('business_uuid', businessId)
          .then(serverToFrontTranslation);
 
        if (res.data?.length) {
          const data = res.data[0];
          const status = data.status as OnboardingStatus;
          onboardingData = {
            status,
            callStart: status === 'onboarding' ? data.callStart : null,
            salesFirstName: data.systemUsers.firstName,
            salesLastName: data.systemUsers.lastName,
            url: status === 'onboarding' ?  data.callUrl : data.calendlyOnboardingUrl
          };
        } else {
          const res = await this.supabaseService.supabase
            .schema('admin_panel')
            .from('sales_users_stats')
            .select('approval_status')
            .eq('business_uuid', businessId)
            .then(serverToFrontTranslation);

          if (res.data?.length) {
            const data = res.data[0];
            const status = data.approvalStatus as OnboardingStatus;
            onboardingData = status === 'approved' ? null : { status: 'pending_approval' };
          }
        }
        setObserver();
      };

      const subscribeToChanges = async () => {
        if (!onboardingData || onboardingData.status === 'approved')
          return;
        const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
        const status = onboardingData.status;

        const statsCallback = async (payload: RealtimePostgresChangesPayload<{ [key: string]: any }>) => {
          if (payload.eventType === 'DELETE')
            return;
          const data = serverToFrontTranslation(payload.new);
          const status = data.approvalStatus;
          if (status === 'approved') {
            onboardingData = null;
            setObserver();
            unsubscribe();
            return;
          }
          onboardingData = { status: 'pending_approval' };
          setObserver();
        }

        if (['pending', 'testing', 'onboarding'].includes(status)) {
          channel = this.supabaseService.supabase
            .channel('onboarding')
            .on(
              'postgres_changes',
              { event: 'UPDATE', schema: 'admin_panel', table: 'system_leads', filter: `business_uuid=eq.${businessId}` },
              async (payload) => {
                if (!onboardingData)
                  return;
                const data = serverToFrontTranslation(payload.new);
                const status = data.status;
                onboardingData = {
                  ...onboardingData,
                  status: status,
                  callStart: data.callStart,
                  url: status === 'onboarding' ? data.callUrl : data.calendlyOnboardingUrl
                };
                setObserver();
              }
            )
            .on(
              'postgres_changes',
              { event: '*', schema: 'admin_panel', table: 'sales_users_stats', filter: `business_uuid=eq.${businessId}` },
              statsCallback
            );
        } else {
          channel = this.supabaseService.supabase
            .channel('onboarding')
            .on(
              'postgres_changes',
              { event: 'UPDATE', schema: 'admin_panel', table: 'sales_users_stats', filter: `business_uuid=eq.${businessId}` },
              statsCallback
            );
        }

        channel = channel?.subscribe();
      };

      getData().then(subscribeToChanges);
    }).pipe(
      finalize(() => unsubscribe()),
      shareReplay(1)
    );
  }

  constructor(
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
    private usersSerivce: UsersService,
    private ngZone: NgZone
  ) { }
}
