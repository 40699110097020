<div *ngIf="item" class="c-item clickable" (click)="openPayment(item.wId, item.pId)">
  <div class="d-flex align-items-center w-100 overflow-hidden">
    <mat-checkbox class="checkbox"
                  *ngIf="kind === 'Pending' && !(readonly$ | async)" [color]="'primary'"
                  (change)="check($event.checked, item.id)" (click)="$event.stopPropagation()"
                  [checked]="checked"
    ></mat-checkbox>

    <div class="c-item-left">
      <div class="name">{{ item.name }}</div>
      <div class="jt">{{ item.jobType ?? 'N/A' }}</div>
    </div>
  </div>

  <div class="c-item-right"
    [class.overpay]="item.overpay && item.overpay < 0"
    [class.sales-amount]="item.kind === 'sales'"
    [class.job-amount]="item.kind === 'job'"
    [shortNumber]="item.amount | currency"
    #tooltip="matTooltip"
    [matTooltip]="''"
    [shortNumberTooltip]="tooltip"
    [hideTooltipOnScroll]="tooltip"
  ></div>
</div>

<div *ngIf="dividerNeed" class="c-item-divider"></div>
