<div class="h-100 image-viewer d-flex flex-column align-items-center">
    <div class="panel top">
        <div class="h-100 d-flex justify-content-end align-items-center">
            <div class="close-btn">
                <image-viewer-button
                        style="scale :1.2"
                        (click)="onClose.emit()"
                        src="close"
                ></image-viewer-button>
            </div>
        </div>
    </div>

    <div #imageContainer class="image d-flex justify-content-center align-items-center">
        <img #image
             [src]="imageUrl" alt="Unknown error"
             [class.moveable]="zoom > 1"
             [style.transform]="imageTransform"
             (mousemove)="onMouseMove($event)"
             (mousedown)="onMouseDown($event)"
             (mouseup)="onMouseUp($event)"
             (mouseleave)="onMouseUp($event)">
    </div>

    <div class="panel controls">
        <div class="h-100 container d-flex justify-content-center align-items-center">
            <image-viewer-button
                    *ngIf="!isMobile"
                    src="zoom-out"
                    matTooltip="Zoom out"
                    (click)="zoomOut()"
                    [matTooltipShowDelay]="800"
            ></image-viewer-button>
            <image-viewer-button
                    *ngIf="!isMobile"
                    src="zoom-in"
                    matTooltip="Zoom in"
                    (click)="zoomIn()"
                    [matTooltipShowDelay]="800"
            ></image-viewer-button>
            <image-viewer-button
                    src="rotate"
                    matTooltip="Rotate"
                    (click)="rotate()"
                    [matTooltipShowDelay]="800"
            ></image-viewer-button>
            <a [href]="imageUrl" download="note-image.png">
                <image-viewer-button
                        *ngIf="showDownload"
                        src="download"
                        matTooltip="Download"
                        [matTooltipShowDelay]="800"
                ></image-viewer-button>
            </a>
        </div>
    </div>
</div>