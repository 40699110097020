import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BasicEvent, dateString, separateItemsByDate, UtilsService } from 'projects/common/src/public-api';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UserProfile } from '../../../../../common/src/lib/models/user-profile.model';
import { DateRangeComponent } from '../../../../../common/src/lib/components/date-range/date-range.component';
import { UsersService } from '../../services/users.service';
import { TimetableService } from '../../services/timetable.service';
import { AvailabilityService } from '../../services/availability.service';
import moment from 'moment';
import { Availability } from "../../models/availability.model";
import { UserSearchBarComponent } from "../../components/user-search-bar/user-search-bar.component";
import { DateFilter } from "../../models/date-filter.model";
import { EstimateTileData } from "../../models/estimate.model";
import { JobTileData } from "../../models/jobs.model";
import { ScheduleItem } from "../../models/schedule.model";
import { scheduleToList } from "../../services/schedule.service";

@Component({
  selector: 'app-availability-modal',
  templateUrl: './availability-modal.component.html',
  styleUrls: ['./availability-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvailabilityModalComponent implements AfterViewInit {

  dialogTitle = 'Assign to';
  tabs = ['List', 'Calendar view'];
  currentTab = this.tabs[0];
  showAssignButton!: boolean;
  @Input() singleAssigneeInList = false;
  @Input() hideAssignees: number[] = [];
  // selectedUserName!: string;

  assigneesList$ = this.usersService.assigneesList$.pipe(
      map(users => users!.filter(user => !this.hideAssignees.includes(user.id)))
  );
  assignedUsersSubject = new BehaviorSubject<UserProfile[]>([]);

  selectedDateRange$ = new BehaviorSubject<[Date, Date] | null>(null);
  selectedUserEvents$ = combineLatest([this.assignedUsersSubject, this.selectedDateRange$]).pipe(
    switchMap(async ([users, dateFilter]) => {
      if(users.length === 0)
        return null;
      const dateRanges = dateFilter ? new DateFilter(dateFilter[0], dateFilter[1]) : undefined;
      const usersIds = users.map(u => u.id);
      return this.availabilityService.getScheduleForUsers(usersIds, dateRanges)
        .then(schedule => {
          const eventsToInsert: ScheduleItem[] = [];
          for (const event of schedule) {
            if (!event.users || event.users.length === 1)
              continue;
            const eventUsers = event.users;
            schedule.splice(schedule.indexOf(event), 1);
            for (const user of eventUsers) {
              if (!usersIds.includes(user.userId))
                continue;
              const eventCopy = {...event} as ScheduleItem;
              eventCopy.users = [user];
              eventsToInsert.push(eventCopy);
            }
          }
          return schedule.concat(eventsToInsert);
        });
    }),
    separateItemsByDate((item: ScheduleItem) => dateString(item.startTime, item.endTime)),
    map(separatedItems => {
      return Object.keys(separatedItems).map(date => [date, scheduleToList(separatedItems[date])]) as [string, (Partial<EstimateTileData> | Partial<JobTileData> | Availability)[]][];
    })
  );

  @Input() assignedUsers: UserProfile[] = []
  @Output() close = new EventEmitter();
  @Output() usersAssigned = new EventEmitter<UserProfile[]>();
  @ViewChild('userSearch') userSearch!: UserSearchBarComponent;
  @ViewChild('dateRange') dateRange!: DateRangeComponent;

  @HostListener('window:keydown', ['$event']) tabKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab' && !this.dateRange.picker.opened && !this.userSearch.focus) {
      if (!event.shiftKey)
        this.userSearch.focusIn();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  smallScreen$ = this.utilsService.onScreenBreakpointChange('md').pipe(
      map(large => !large)
  );

  dateFilterMinDate = (): Date => {
    return new Date();
  };

  dateFilterMaxDate = (): Date => {
    return moment().add(10, 'days').toDate();
  };

  constructor(
    private utilsService: UtilsService,
    public timetableService: TimetableService,
    public usersService: UsersService,
    private availabilityService: AvailabilityService,
    private cd: ChangeDetectorRef
  ) { }

  eventsSort = (a: BasicEvent, b: BasicEvent): number => {
    if (a.startTime.getTime() === b.startTime.getTime() && a.endTime.getTime() === b.endTime.getTime()) {
      if (a.docType && b.docType) {
        return b.docType.localeCompare(a.docType); // Estimates-Jobs, replace a with b to swap Jobs-Estimates
      } else return 0
    } else if (a.startTime.getTime() === b.startTime.getTime()) {
      return a.endTime.getTime() - b.endTime.getTime();
    } else {
      return a.startTime.getTime() - b.startTime.getTime();
    }
  };

  assignedUsersCheck(selectedUser: UserProfile ) {
      this.showAssignButton = !this.assignedUsers.find(user => user.id === selectedUser.id)
  }

  onAssigneesChange(users: UserProfile[]) {
    this.assignedUsers = users;
    this.assignedUsersSubject.next(users);
  }

  ngAfterViewInit(): void {
    this.assignedUsersSubject.next(this.assignedUsers);
    this.cd.detectChanges();
  }

  get isMobile(): boolean {
    return this.utilsService.isMobile();
  }

  get isIOS(): boolean {
    return this.utilsService.isIOS();
  }

  getTileUsername(event: Partial<EstimateTileData> | Partial<JobTileData> | Availability) {
    switch (event.docType) {
      case 'Personal': 
        return `${event.firstName} ${event.lastName?.[0]}.`;
      case 'Estimate': 
        return `${event.ownerFirstName} ${event.ownerLastName?.[0]}.`;
      case 'Job':
        return `${event.users?.[0].firstName} ${event.users?.[0].lastName?.[0]}.`;
    }
    return '';
  }

  tabSelected(tab: string): void {
    this.currentTab = tab;
  }

  async submit() {
    this.usersAssigned.emit(this.assignedUsersSubject.value);
    this.close.emit()
  }

  unAssignUser(assignedUser: UserProfile): void {
    this.assignedUsers = this.assignedUsers.filter(user => user.id !== assignedUser.id);
    this.assignedUsersSubject.next(this.assignedUsers);
  }
}
