<div *ngIf="items" class="wrap-external">
  <div class="wrap-internal" [class.disabled-semitransparent]="disabled">
    <div
      class="selected-block"
      [style.margin-left]="_selectedItemIndex === 0 ? 0 : 'calc(' + (100 / items.length) * _selectedItemIndex + '% - 1px)'"
      [style.width]="'calc(' + (100 / items.length) + '% + 1px)'"
    ></div>
    <div
      *ngFor="let item of items; let index = index; let last = last"
      class="item"
      [class.selected]="index === _selectedItemIndex"
      [class.highlight]="index === _selectedItemIndex && highlightSelected"
      [style.width]="100 / items.length + '%'"
      (click)="!disabled ? changeItem(index) : null"
    >
      <h4 [class.largest-fs]="items.length < 3">{{ item }}</h4>
      <div *ngIf="showDividers && !last" class="divider-line" [class.hidden]="index === _selectedItemIndex"></div>
    </div>
  </div>
</div>
