import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from "./snackbar.service";

export interface SnackbarData {
  message: string;
  duration?: number,
  actionText?: string;
  action?: () => void;
  closeAction?: () => void;
}

interface FullSnackbarData extends SnackbarData {
  duration: number;
  matSnackbar: MatSnackBar
}

export function showSnackbar(matSnackbar: MatSnackBar, data: SnackbarData) {
  if (!data.duration)
    data.duration = 750;
  if (data.duration === -1) data.duration = undefined;
  return matSnackbar.openFromComponent(SnackbarComponent, {
    duration: data.duration,
    data: {
      ...data,
      matSnackbar: matSnackbar
    }
  });
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {

  intervalId: any;
  timer = new BehaviorSubject(this.data.duration ?? 0);

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: FullSnackbarData,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    if(this.data.actionText) {
      this.intervalId = setInterval( (_: any) => {
        this.timer.next(this.timer.value - 1000);
      }, 1000);
    }

    if (this.data.action)
      this.snackbarService.isActionSnackbar$.next(true);
  }

  ngOnDestroy(): void {
      if(this.intervalId) {
        this.snackbarService.isActionSnackbar$.next(false);
        clearInterval(this.intervalId);
      }

      if (this.snackbarService.isDelayedLogout$.value) {
        this.snackbarService.doLogout.emit();
      }
  }

  close() {
    this.snackbarService.isActionSnackbar$.next(false);
    if(this.data.closeAction) {
      this.data.closeAction();
    }
    this.snackBarRef.dismiss();
  }

  undo() {
    if(this.data.action) {
      this.snackbarService.isActionSnackbar$.next(false);
      this.snackbarService.isDelayedLogout$.next(false);
      this.snackBarRef.dismiss();
      this.data.action();
      this.data.action = undefined;
      showSnackbar(this.data.matSnackbar, {
        message: "Action undone",
      });
    }
  }
}
