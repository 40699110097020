import { Component } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { PaymentService } from "../../services/payment.service";
import { decimalOnly, onDecimalPaste } from "../../helpers/forms";
import { capitalize } from "../../helpers/common";

@Component({
  selector: 'app-edit-worker-payment',
  templateUrl: './edit-worker-payment.component.html',
  styleUrls: ['./edit-worker-payment.component.scss']
})
export class EditWorkerPaymentComponent {

  protected readonly decimalOnly = decimalOnly;
  protected readonly onPaste = onDecimalPaste;

  loading = false;

  overAmount = false;
  inputAmount: string = this.data.item.paid > 0
    ? this.data.item.paid
    : this.data.item.amount;

  get data() {
    return this.modalsService.data;
  }

  constructor(
    private modalsService: ModalsService,
    private paymentService: PaymentService,
  ) { }

  async close() {
    await this.modalsService.close();
  }

  async save() {
    if (!this.loading) {
      this.loading = true;

      if (this.validForm()) {
        switch (this.data.type) {
          case 'commission':
            await this.paymentService.updateCommissionAmount(this.data.item.id, +this.inputAmount);
            break;
          case 'tip':
            await this.paymentService.updateCurrentTips(
              this.data.item.paymentId,
              this.data.item.tip,
              this.data.item.currentTips,
              +this.inputAmount
            );
            break;
        }

        await this.close();
      }

      this.loading = false;
    }
  }

  validForm(): boolean {
    let amountUnder = true;

    if (+this.inputAmount > this.data.item.amount)
      amountUnder = false;

    this.overAmount = !amountUnder;

    return amountUnder;
  }

  protected readonly capitalize = capitalize;
}
