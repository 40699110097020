import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { capitalizeFirstChar, snakeCaseToCamel } from 'projects/common/src/public-api';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalBehavior, ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { UsersService } from '../../services/users.service';
import {
  UserPermission,
  UserProfile,
  UserRole,
  userPermissions,
  userCommission
} from '../../../../../common/src/lib/models/user-profile.model';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ForgotPasswordModal } from '../forgot-password/forgot-password.component';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmationDialog } from 'projects/common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';

type EmailError = 'forbidden' | 'exists';

export type AddUserData = {
  userProfile?: UserProfile,
  authSuccess?: boolean,
  formData?: any,
  emailError?: EmailError,
  selectedRole?: Capitalize<UserRole>
};

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, AfterViewInit {

  @ViewChild('firstNameInput') firstNameInput!: ElementRef<HTMLElement>;
  @ViewChild('lastNameInput') lastNameInput!: ElementRef<HTMLElement>;
  @ViewChild('emailInput') emailInput!: ElementRef<HTMLElement>;
  @ViewChild('markCommissionsSwitch') markCommissionsSwitch!: ElementRef<HTMLElement>;
  @ViewChild('markTechPartsSwitch') markTechPartsSwitch!: ElementRef<HTMLElement>;
  @ViewChild('editItemsSwitch') editItemsSwitch!: ElementRef<HTMLElement>;
  @ViewChild('jobCommissionsInput') jobCommissionsInput!: ElementRef<HTMLElement>;
  @ViewChild('salesCommissionsInput') salesCommissionsInput!: ElementRef<HTMLElement>;
  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLElement>;

  discountAboveAmount = false;

  form = new FormGroup({
    firstName: new FormControl<string>(''),
    lastName: new FormControl<string>(''),
    email: new FormControl<string>(''),
    markCommissions: new FormControl<boolean>(false),
    markTechParts: new FormControl<boolean>(false),
    editItems: new FormControl<boolean>(false),
    accessCreditCard: new FormControl<boolean>(false),
    creditCardRefund: new FormControl<boolean>(false),
    markTips: new FormControl<boolean>(false),
    jobCommissionValue: new FormControl<number | null>(null),
    jobCommissionType: new FormControl<'$' | '%'>('%'),
    jobCommissionAfterMaterials: new FormControl<boolean>(false),
    salesCommissionValue: new FormControl<number | null>(null),
    salesCommissionType: new FormControl<'$' | '%'>('%'),
    salesCommissionAfterMaterials: new FormControl<boolean>(false),
  });

  passwordControl = new FormControl<string>('');

  get data() {
    return this.modalsService.data as AddUserData | undefined;
  }

  get isEdit() {
    return !!this.data?.userProfile;
  }

  roles = ['User', 'Admin'];
  selectedRoleSubject = new BehaviorSubject<'User' | 'Admin' | 'Owner'>('User');

  loading = false;

  showPassword = false;

  showMarkCommissionsError = false;
  showMarkTechPartsError = false;
  showEditItemsError = false;
  showAccessCCError = false;
  showCCRefundError = false;
  showMarkTipsError = false;

  subscriptions: Subscription[] = [];

  isCurrentUser$ = this.usersService.currentUser$.pipe(
    map(user => {
      if(!this.data?.userProfile) {
        return false;
      }
      return user.email === this.data.userProfile.email;
    })
  );

  userCredentialsEditMode$ = combineLatest([this.usersService.currentUser$, this.selectedRoleSubject]).pipe(
    map(([user, role]) => {
      if(!this.data?.userProfile) {
        return true;
      }
      return user.email !== this.data.userProfile.email && role !== 'Owner';
    })
  );

  get changes() {
    const profile = this.data?.userProfile
    if(!profile)
      return false;
      
    if(this.selectedRoleSubject.value !== capitalizeFirstChar(profile.role))
      return true;
    
    const permissions = this.getPermissionsArray();
    if(permissions.length !== profile.permissions.length)
      return true;
    for(const permission of permissions) {
      if(!profile.permissions.includes(permission))
        return true;
    }

    if(this.form.value.jobCommissionValue !== profile.jobCommissionValue
      || this.form.value.jobCommissionType !== profile.jobCommissionType
      || this.form.value.jobCommissionAfterMaterials !== profile.jobCommissionAfterMaterials
    )
      return true;

    if(this.form.value.salesCommissionValue !== profile.salesCommissionValue
      || this.form.value.salesCommissionType !== profile.salesCommissionType
      || this.form.value.salesCommissionAfterMaterials !== profile.salesCommissionAfterMaterials
    )
      return true;

    if(this.form.value.firstName !== profile.firstName
      || this.form.value.lastName !== profile.lastName
      || this.form.value.email !== profile.email
    )
      return true;

    return false;
  }

  currentUser!: UserProfile;

  providers$ = this.authService.user$.pipe(
    map(user => user!.providers)
  )

  constructor(
    private modalsService: ModalsService,
    private usersService: UsersService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
  ) {}

  async ngOnInit() {
    if (this.data?.authSuccess) {
      this.transferOwnership();
      return;
    }

    localStorage.setItem('Settings_Tab', 'Users');
    this.currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();

    if(this.data?.userProfile) {
      if(this.currentUser.role === 'owner') {
        this.roles.push('Owner');
      }

      if (this.data?.formData) {
        this.form.setValue(this.data.formData);
        if (this.data.selectedRole)
          this.selectedRoleSubject.next(capitalizeFirstChar(this.data.selectedRole) as any);
      } else {
        this.selectedRoleSubject.next(capitalizeFirstChar(this.data.userProfile.role) as any);
        this.form.controls.firstName.setValue(this.data.userProfile.firstName);
        this.form.controls.lastName.setValue(this.data.userProfile.lastName);
        this.form.controls.email.setValue(this.data.userProfile.email);
        for(const permission of this.data.userProfile.permissions) {
          (this.form.controls as any)[snakeCaseToCamel(permission)].setValue(true);
        }

        const jobsCommissions = userCommission(this.data.userProfile, 'job');
        if(jobsCommissions?.value) {
          this.form.controls.jobCommissionValue.setValue(jobsCommissions.value);
        }
        if(jobsCommissions?.type) {
          this.form.controls.jobCommissionType.setValue(jobsCommissions.type);
        }
        if(jobsCommissions?.afterMaterials) {
          this.form.controls.jobCommissionAfterMaterials.setValue(jobsCommissions.afterMaterials);
        }

        const salesCommissions = userCommission(this.data.userProfile, 'sales');
        if(salesCommissions?.value) {
          this.form.controls.salesCommissionValue.setValue(salesCommissions.value);
        }
        if(salesCommissions?.type) {
          this.form.controls.salesCommissionType.setValue(salesCommissions.type);
        }
        if(salesCommissions?.afterMaterials) {
          this.form.controls.salesCommissionAfterMaterials.setValue(salesCommissions.afterMaterials);
        }
      }
    }

    if(this.currentUser.role !== 'owner') {
      this.subscriptions.push(this.form.controls.markCommissions.valueChanges.subscribe(v => {
        if(v && (!this.data || !this.data.userProfile!.permissionsAllowedByOwner.includes('mark_commissions'))) {
          this.form.controls.markCommissions.setValue(false);
          this.showMarkCommissionsError = true;
        }
      }));
      this.subscriptions.push(this.form.controls.markTechParts.valueChanges.subscribe(v => {
        if(v && (!this.data || !this.data!.userProfile!.permissionsAllowedByOwner.includes('mark_tech_parts'))) {
          this.form.controls.markTechParts.setValue(false);
          this.showMarkTechPartsError = true;
        }
      }));
      this.subscriptions.push(this.form.controls.accessCreditCard.valueChanges.subscribe(v => {
        if(v && (!this.data || !this.data!.userProfile!.permissionsAllowedByOwner.includes('access_credit_card'))) {
          this.form.controls.accessCreditCard.setValue(false);
          this.showAccessCCError = true;
        }
      }));
      this.subscriptions.push(this.form.controls.creditCardRefund.valueChanges.subscribe(v => {
        if(v && (!this.data || !this.data!.userProfile!.permissionsAllowedByOwner.includes('credit_card_refund'))) {
          this.form.controls.creditCardRefund.setValue(false);
          this.showCCRefundError = true;
        }
      }));
      this.subscriptions.push(this.form.controls.markTips.valueChanges.subscribe(v => {
        if(v && (!this.data || !this.data!.userProfile!.permissionsAllowedByOwner.includes('mark_tips'))) {
          this.form.controls.markTips.setValue(false);
          this.showMarkTipsError = true;
        }
      }));
    }
  }

  ngAfterViewInit(): void {
    if (this.data?.emailError) {
      setTimeout(() => {
        this.form.controls.email.setErrors({
          emailExists: this.data?.emailError === 'exists' || null,
          emailForbidden: this.data?.emailError === 'forbidden' || null
        });
        this.form.controls.email.markAsTouched();
        this.scrollIntoError();
      });
    }
  }

  indexOfRole(role: string) {
    return ['User', 'Admin', 'Owner'].indexOf(role);
  }

  indexOfAfterMaterials(afterMaterials: boolean) {
    return afterMaterials ? 1 : 0;
  }

  async addUser() {
    this.loading = true;
    if(!this.validateUserDetails(false)) {
      this.loading = false;
      return;
    }
    
    const data = this.form.value;
    const firstName = data.firstName!;
    const lastName = data.lastName!;
    const email = data.email!;
    const role = this.selectedRoleSubject.value.toLowerCase() as UserRole;
    const permissions = this.getPermissionsArray();
    const permissionsAllowedByOwner = permissions;
    const jobCommissionsValue = data.jobCommissionValue ?? null;
    const jobCommissionsType = data.jobCommissionType!;
    const jobCommissionsAfterMaterials = data.jobCommissionAfterMaterials!;
    const salesCommissionsValue = data.salesCommissionValue ?? null;
    const salesCommissionsType = data.salesCommissionType!;
    const salesCommissionsAfterMaterials = data.salesCommissionAfterMaterials!;

    try {
      await this.usersService.addUser(
          email,
          firstName,
          lastName,
          role,
          permissions,
          permissionsAllowedByOwner,
          jobCommissionsValue,
          jobCommissionsType,
          jobCommissionsAfterMaterials,
          salesCommissionsValue,
          salesCommissionsType,
          salesCommissionsAfterMaterials
      );
      showSnackbar(this.snackbar, {
        message: `${capitalizeFirstChar(role)} Added`
      });
      this.loading = false;
      this.close();
    } catch (e: any) {
      this.form.controls.email.setErrors({
        emailExists: e.message === 'duplicate key value violates unique constraint "user_email_key"' || null,
        emailForbidden: e.message === 'Email forbidden' || null
      });
      this.scrollIntoError();
      this.loading = false;
    }
  }

  async updateUser() {
    this.loading = true;
    const profile = this.data?.userProfile;
    const skipProfile = !(await this.userCredentialsEditMode$.pipe(take(1)).toPromise());
    if(!profile || !this.validateUserDetails(skipProfile)) {
      this.loading = false;
      return;
    }

    const data = await this.getUpdateData(profile);
    if (this.selectedRoleSubject.value === 'Owner' && this.data?.userProfile?.role !== 'owner') {
      const success = await this.reauthenticateForOwnershipTransfer();
      if(!success) {
        this.loading = false;
        return;
      }
    }

    if (Object.keys(data).length > 0) {
      if (data.email && data.email !== profile.email) {
        const dialogData = {
          userProfile: this.data?.userProfile,
          formData: this.form.value,
          selectedRole: this.selectedRoleSubject.value,
          emailError: false as EmailError | false
        };
        this.modalsService.open(ConfirmationDialog, {
          behavior: ModalBehavior.Dialog,
          data: {
            title: 'Email change',
            message: `Are you sure you want to change the user\'s email to ${data.email}?`,
            actionTitle: 'Yes',
            cancelTitle: 'No',
            action: async () => {
              try {
                await this.usersService.updateUser(data, profile);
                showSnackbar(this.snackbar, {
                  message: `${this.selectedRoleSubject.value} Updated`
                });
              } catch (e: any) {
                if (e.message === 'duplicate key value violates unique constraint "user_email_key"')
                  dialogData.emailError = 'exists';
                else if (e.message === 'Email forbidden')
                  dialogData.emailError = 'forbidden';
                else
                  console.log(e);
              }
              this.modalsService.close(!!dialogData.emailError);
            },
          },
          prevComponentMetadata: {
            componentClass: AddUserComponent,
            modalBehavior: ModalBehavior.Auto,
            data: dialogData,
          },
        });
        return;
      }
      await this.usersService.updateUser(data, profile);
    }

    showSnackbar(this.snackbar, {
      message: `${this.selectedRoleSubject} Updated`
    });
    this.loading = false;
    this.close();
  }

  async getUpdateData(profile: UserProfile) {
    const updateData: Partial<UserProfile> = {};
    const testKeys: (keyof UserProfile)[] = ['role', 'jobCommissionValue', 'jobCommissionType', 'jobCommissionAfterMaterials', 'salesCommissionValue', 'salesCommissionType', 'salesCommissionAfterMaterials'];
    for(const key of testKeys) {
      this.setChangedData(key, updateData);
    }
    if(this.selectedRoleSubject.value.toLowerCase() !== this.data!.userProfile!.role) {
      updateData.role = this.selectedRoleSubject.value.toLowerCase() as UserRole;
    }

    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    const permissions = this.getPermissionsArray();
    if(this.shouldUpdatePermissions(permissions, profile.permissions)) {
      updateData.permissions = permissions;
      if(currentUser.role === 'owner') {
        updateData.permissionsAllowedByOwner = permissions;
      }
    }

    const userCredentialsEditMode = await this.userCredentialsEditMode$.pipe(take(1)).toPromise();
    if (userCredentialsEditMode) {
      const keys: (keyof UserProfile)[] = ['firstName', 'lastName', 'email'];
      for (const key of keys)
        if (profile[key] !== (this.form.value as any)[key])
          updateData[key] = (this.form.value as any)[key]!.trim();
    }
    return updateData;
  }

  setChangedData(key: keyof UserProfile, updates: Partial<UserProfile>) {
    const values = this.form.value as UserProfile;
    const currentProfile = this.data!.userProfile!;
    if(key === 'role' && values.role === 'owner')
      return;

    if(values[key] === undefined) {
      return;
    }

    if(!!values[key] && !currentProfile[key]) {
      updates[key] = (this.form.value as any)[key];
      return;
    }

    if(values[key] !== currentProfile[key])
      updates[key] = (this.form.value as any)[key];
  }

  shouldUpdatePermissions(a: UserPermission[], b: UserPermission[]) {
    if(a.length !== b.length) {
      return true;
    }
    for(const permission of a) {
      if (!b.includes(permission))
        return true;
    }
    return false;
  }

  getPermissionsArray() {
    const permissions: UserPermission[] = [];
    for(const permission of userPermissions) {
      if((this.form.value as any)[snakeCaseToCamel(permission)]) {
        permissions.push(permission);
      }
    }
    if(this.currentUser?.role !== 'owner') {
      return permissions.filter(p => {
        if(p === 'edit_items')
          return true;
        return this.data?.userProfile?.permissionsAllowedByOwner?.includes(p) ?? false;
      });
    }
    return permissions;
  }

  async reauthenticateForOwnershipTransfer() {
    // const user = await this.authService.user$.pipe(take(1)).toPromise();
    // const fiveMinutes = 5 * 60 * 1000;
    // if(Date.now() - user!.lastSignIn.getTime() < fiveMinutes) {
    //   return this.transferOwnership();
    // }
    const password = this.passwordControl.value;
    if(!password || password.length === 0) {
      this.passwordControl.setErrors({
        required: true
      });
      this.passwordControl.markAsTouched();
      this.scrollIntoError();
      return false;
    }
    try {
      await this.authService.reauthenticateWithPassword(password);
    } catch (e) {
      console.log(e)
      this.passwordControl.setErrors({
        password: true
      });
      this.passwordControl.markAsTouched();
      this.scrollIntoError();
      return false;
    }
    this.transferOwnership();
    return true;
  }

  onGoogleReauthStart() {
    this.authService.savedModal = {
      componentClass: AddUserComponent,
      modalBehavior: ModalBehavior.Auto,
      data: {
        userProfile: this.data?.userProfile,
        formData: this.form.value,
        selectedRole: 'Owner'
      },
    };
  }


  transferOwnership() {
    const profile = this.data!.userProfile!;
    this.modalsService.open(ConfirmationDialog, {
      data: {
        title: 'Transfer Ownership',
        message: `Are you sure you want to transfer the ownership to ${profile.firstName} ${profile.lastName}?`,
        actionTitle: 'Yes',
        actionColor: 'warn',
        action: async () => {
          const res = await this.usersService.transferOwnership(profile.id);
          return { close: res, reopen: false };
        },
      }
    });
  }

  validateUserDetails(skipProfile: boolean) {
    this.form.controls.firstName.setValue(this.form.controls.firstName.value?.trim() ?? '');
    this.form.controls.lastName.setValue(this.form.controls.lastName.value?.trim() ?? '');
    this.form.controls.email.setValue(this.form.controls.email.value?.trim() ?? '');
    const data = this.form.value;
    let valid = true;
    if(!skipProfile) {
      if(!data.firstName || data.firstName === '') {
        this.form.controls.firstName.setErrors({
          required: true
        });
        valid = false;
      }
      if(!data.lastName || data.lastName === '') {
        this.form.controls.lastName.setErrors({
          required: true
        });
        valid = false;
      }
      const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (data.email!.match(validEmailRegex) === null) {
        if (!data.email || data.email! === '') {
          this.form.controls.email.setErrors({ required: true });
          valid = false;
        } else {
          this.form.controls.email.setErrors({ invalidFormat: true });
          valid = false;
        }
      }
    }
    if(data.jobCommissionType === '%' && (data.jobCommissionValue ?? 0) > 100) {
      this.form.controls.jobCommissionValue.setErrors({
        max: true
      });
      valid = false;
    }
    if(data.salesCommissionType === '%' && (data.salesCommissionValue ?? 0) > 100) {
      this.form.controls.salesCommissionValue.setErrors({
        max: true
      });
      valid = false;
    }
    this.form.markAllAsTouched();
    this.scrollIntoError();
    return valid;
  }

  onRoleChange(tab: string) {
    this.selectedRoleSubject.next(tab as any);
  }

  onAfterMaterialsChange(type: 'jobCommissionAfterMaterials' | 'salesCommissionAfterMaterials', value: string) {
    const afterMaterials = value === 'After';
    const control = this.form.controls[type];
    control.setValue(afterMaterials);
  }

  toggleDiscountType(commission: 'jobCommissionType' | 'salesCommissionType') {
    this.form.controls[commission].setValue(
      this.form.controls[commission].value === '%'
        ? '$'
        : '%'
    );
  }

  scrollIntoError() {
    const controls = this.form.controls;
    if(controls.firstName.errors) {
      this.firstNameInput.nativeElement.scrollIntoView({behavior: 'smooth'});
      return;
    }
    
    if(controls.lastName.errors) {
      this.lastNameInput.nativeElement.scrollIntoView({behavior: 'smooth'});
      return;
    }
    
    if(controls.email.errors) {
      this.emailInput.nativeElement.scrollIntoView({behavior: 'smooth'});
      return;
    }
    
    if(controls.jobCommissionValue.errors) {
      this.jobCommissionsInput.nativeElement.scrollIntoView({behavior: 'smooth'});
      return;
    }

    if(controls.salesCommissionValue.errors) {
      this.salesCommissionsInput.nativeElement.scrollIntoView({behavior: 'smooth'});
      return;
    }
    
    if(this.passwordControl.errors) {
      this.passwordInput.nativeElement.scrollIntoView({behavior: 'smooth'});
      return;
    }
  }

  forgotPassword() {
    this.modalsService.open(ForgotPasswordModal, {
      data: {
        backComponent: AddUserComponent,
        backComponentData: {
          userProfile: this.data?.userProfile,
          formData: this.form.value,
          selectedRole: 'Owner'
        },
      }
    });
  }

  close() {
    this.modalsService.close();
  }

  validateKey(event: KeyboardEvent, controlName: 'jobCommissionValue' | 'salesCommissionValue') {
    if(event.key === '.') {
      return !this.form.controls[controlName].value!.toString().includes('.');
    }
    return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Enter'].includes(event.key);
  }

  onPasteToCommissions(event: ClipboardEvent) {
    const data = event.clipboardData?.getData('text/plain');
    return data && data === +data + '';
  }
}
