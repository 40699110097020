import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Proposal, ProposalStatus, ProposalVersion } from '../models/proposal.model';
import { Client, MakeOptional } from 'projects/common/src/public-api';
import { ProposalTab } from '../models/navigation.model';
import { BusinessService } from "./business.service";
import { UsersService } from "./users.service";
import { ViewAsService } from './view-as.service';
import { LobbyService } from './lobby.service';
import { DocType } from '../models/doc-type.model';
import { rpcFilter, SupabaseService } from "./supabase.service";
import { Note } from "../models/note.model";
import { Item } from "../models/item.model";
import { CommonPoIItem } from "../models/poi.model";
import { MatLegacySnackBar } from "@angular/material/legacy-snack-bar";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

export type TiledProposal = {
  id: number,
  type: ProposalTab,
  createdAt: Date,
  name: string,
  opened: boolean,
  docType: DocType
};

export interface ProposalCreate {
  inCreatedBy: number;
  inClient: MakeOptional<Client, 'salesTaxPercentage'>;
  inWorkflowId?: number;
  inJobType?: string;
  inNotes?: Note[];
}

export interface ProposalRestore {
  inId: number;
  inVersionId: number;
  inVersionCreatedAt: Date;
  inCreatedBy: number;
  inClient: Client;
  inWorkflowId: number;
  inSignature?: boolean;
  inNote?: string;
  inDepositAmount?: number;
  inDepositPercent?: number;
  inDepositType?: 'percent' | 'amount';
  inDiscountType?: 'percent' | 'amount';
  inItems?: CommonPoIItem[];
}

export interface ProposalVersionCreate {
  inCreatedBy: number;
  inClient: MakeOptional<Client, 'salesTaxPercentage'> & { salesTaxPercentage?: number };
  inProposalId: number;
  inWorkflowId?: number;
  inSignature?: boolean;
  inNote?: string;
  inDepositAmount?: number;
  inDepositPercent?: number;
  inDepositType?: 'percent' | 'amount';
  inDiscountType?: 'percent' | 'amount';
  inItems?: Item[];
  inId?: number;
  inCreatedAt?: Date;
}

export interface ProposalVersionRestore {
  inId: number;
  inVersionId: number;
  inVersionCreatedAt: Date;
  inCreatedBy: number;
  inClient: MakeOptional<Client, 'salesTaxPercentage'> & { salesTaxPercentage?: number };
  inWorkflowId: number;
  inSignature?: boolean;
  inNote?: string;
  inDepositAmount?: number;
  inDepositPercent?: number;
  inDepositType?: 'percent' | 'amount';
  inDiscountType?: 'percent' | 'amount';
  inItems?: CommonPoIItem[];
}

interface ProposalUpdate {
  deletedAt: Date | null;
  activeDate: Date | null;
  activeUserId: number;
  activeOpened: boolean;
  activeStatus: ProposalStatus;
  activeVersionId: number;
  type: 'personal' | 'business';
  accepted: boolean;
  canceledFromPayment: boolean;
}

export function tiledProposalsForType(type: ProposalTab) {
  return function filterSent(source$: Observable<Proposal[]>): Observable<TiledProposal[]> {
    return source$.pipe(
      map((proposal) => {
        const category: TiledProposal[] = [];

        // proposal.forEach(async proposal => {
        //   category.push({
        //     id: proposal.id,
        //     type: type,
        //     createdAt: proposal.activeCreatedAt!,
        //     name: proposal.versions[proposal.activeVersionId!].name,
        //     opened: proposal.versions[proposal.activeVersionId!].opened ?? false,
        //     docType: 'Proposal'
        //   });
        // });

        return category;
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProposalsService {

  _recalculate$ = new Subject<0>();
  recalculate$ = this._recalculate$.asObservable().pipe(debounceTime(1000));


  constructor(
    private usersServices: UsersService,
    private supabaseService: SupabaseService,
    private lobbyService: LobbyService,
    protected businessService: BusinessService,
    protected viewAsService: ViewAsService,
    protected snackbar: MatLegacySnackBar,
  ) {}

  proposalObservable(proposalId: number, workflowId: number) {
    return this.businessService.selectedBusiness$.pipe(
      switchMap(business => this.supabaseService.rpc<Proposal[]>({
          cName: 'proposal_' + proposalId,
          schema: business!.businessId,
          fn: 'get_proposal_by_id',
          tables: [
            { table: 'proposal', filter: rpcFilter('id', 'eq', proposalId) },
            { table: 'proposal_version', filter: rpcFilter('proposal_id', 'eq', proposalId) },
            { table: 'proposal_version_client', filter: rpcFilter('proposal_id', 'eq', proposalId) },
            { table: 'client_proposal', filter: rpcFilter('proposal_id', 'eq', proposalId) },
            { table: 'workflow', filter: rpcFilter('id', 'eq', workflowId) },
            { table: 'payment', filter: rpcFilter('workflow_id', 'eq', workflowId) },
            { table: 'paid', filter: rpcFilter('workflow_id', 'eq', workflowId) },
            { table: 'refunded', filter: rpcFilter('workflow_id', 'eq', workflowId) },
            { table: 'user' },
          ],
          options: { in_id: proposalId }
        },
        (changes, items) => {
          if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
            return null;
          items[0].jobType = (changes.new as any).jobType;
          return items;
        }
      )),
      map(proposals => {
        if(!proposals || !proposals[0])
          return null;
        const proposal = proposals[0];
        proposal.docType = 'Proposal';
        proposal.versions.sort((a, b) => a.id - b.id)
        return proposal;
      }),
      shareReplay(1)
    );
  }

  async sendProposal(proposalVersionId: number) {
    const currentUser = await this.usersServices.currentUser$.pipe(take(1)).toPromise();
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    const res: string = await this.supabaseService.rpcFunc(business!.businessId, 'send_proposal', {
      in_proposal_version_id: proposalVersionId,
      in_sending_user_id: currentUser.id
    });

    this.sendProposalEmail(res, currentUser.id).then();

    console.log('client proposal id: ', res);

    return res;
  }

  async sendProposalEmail(clientProposalId: string, senderId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    this.supabaseService.edgeFunc('send-proposal-email', {
      businessId: business!.businessId,
      clientProposalId: clientProposalId,
      senderId: senderId
    }).then();
  }

  async createProposal(data: ProposalCreate) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return await this.supabaseService.rpcFunc<{ workflowId: number, proposalId: number }>(business!.businessId, 'create_proposal', data);
  }

  async updateProposal(proposalId: number, data: ProposalUpdate) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.update(business!.businessId, 'proposal', proposalId, data);
  }

  async restoreProposal(data: ProposalRestore) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<number>(business!.businessId, 'restore_proposal', data);
  }

  async deleteProposalVersion(id: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<boolean>(business!.businessId, 'delete_proposal_version', {
      in_proposal_version_id: id
    });
  }

  async restoreProposalVersion(data: ProposalVersionRestore) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    return (await this.supabaseService.supabase
        .schema(business!.businessId)
        .rpc('restore_proposal', frontToServerTranslation(data))).data;
  }

  findHighestVersionId(versions: ProposalVersion[], notIn?: number[]): number {
    return versions?.reduce((max, version) => {
      return version.id > max && !(notIn?.find(x => x === version.id)) ? version.id : max;
    }, -1);
  }

  async createProposalVersion(data: ProposalVersionCreate) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<number>(business!.businessId, 'create_proposal_version', data);
  }

  async duplicateProposalVersion(proposal: Proposal, proposalVersion: ProposalVersion): Promise<number> {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const user = await this.usersServices.currentUser$.pipe(take(1)).toPromise();
    const data = {
      inClient: {
        address: proposalVersion.clientAddress,
        businessName: proposalVersion.clientBusinessName,
        email: proposalVersion.clientEmail,
        extNumber: proposalVersion.clientExtNumber,
        firstName: proposalVersion.clientFirstName,
        lastName: proposalVersion.clientLastName,
        phoneNumber: proposalVersion.clientPhoneNumber,
        salesTaxPercentage: proposalVersion.clientSalesTaxPercentage!,
        type: proposalVersion.clientType,
        unit: proposalVersion.clientUnit,
      },
      inCreatedBy: user.id,
      inProposalId: proposal.id,
      inWorkflowId: proposal.workflowId,
      inSignature: proposalVersion.signature,
      inNote: proposalVersion.note,
      inDepositAmount: proposalVersion.depositAmount,
      inDepositPercent: proposalVersion.depositPercent,
      inDepositType: proposalVersion.depositType,
      inDiscountType: proposalVersion.discountType,
      inItems: proposalVersion.items,
    };
    return this.supabaseService.rpcFunc<number>(business!.businessId, 'create_proposal_version', data);
  }

  async updateProposalVersionClient(
    proposalVersionId: number,
    client: Client
  ) {
    delete (client as any).jobType;
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.update(
      business!.businessId,
      'proposal_version_client',
      {
        key: 'proposal_version_id',
        value: proposalVersionId
      },
      client
    );
  }

  async updateProposalNote(
    proposalVersionId: number,
    proposalsNoteText: string
  ){
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.supabase
      .schema(business!.businessId)
      .from('proposal_version')
      .update({ note: proposalsNoteText })
      .eq('id', proposalVersionId);
  }

  async updateProposalVersion(
    id: number,
    proposalVersion: Partial<ProposalVersion>,
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.update(business!.businessId, 'proposal_version', id, proposalVersion);
  }

  async declineProposal(versionId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return await this.supabaseService.rpcFunc(business!.businessId, 'decline_proposal', {
      in_proposal_version_id: versionId
    });
  }

  async cleanUpProposal(proposalId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    this.supabaseService.rpcFunc<number>(business!.businessId, 'clean_up_proposal', {
      inProposalId: proposalId
    }).then();
  }

  async deleteProposal(id: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    await this.supabaseService.rpcFunc(business!.businessId, 'delete_proposal', { inId: id });
  }
  
  async getProposal(id: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const proposal = (await this.supabaseService.rpcFunc<Proposal[]>(business!.businessId, 'get_proposal_by_id', { inId: id }))[0];
    proposal.docType = 'Proposal';
    return proposal;
  }

}
