<div contenteditable #input
     (input)="onChange();"
     (focusin)="fieldFocus(true)"
     (focusout)="fieldFocus(false)"
     [innerHTML]="innerHTML"
     (paste)="onPaste($event)"
></div>
<h4 *ngIf="placeholder && normalText.length === 0" class="ph" (click)="input.focus()">&nbsp;{{ placeholder }}</h4>
<!--<input matInput #fake #trigger="matAutocompleteTrigger" *ngIf="useAutocomplete" [style.position]="'absolute'"-->
<!--   [matAutocomplete]="autocomplete"-->
<!--   [matAutocompleteConnectedTo]="matAutocompleteOrigin"-->
<!--   [matAutocompletePosition]="'above'"-->
<!--&gt;-->

<!--<mat-menu #menu>-->
<!--    <mat-option class="user-suggestion" *ngFor="let user of userSuggestions" [value]="messageControl.value" (click)="preventEvent($event); userMentionClick(user)">-->
<!--        <span class="user-name">{{ user.firstName + ' ' + user.lastName }}</span>-->
<!--        <span *ngIf="user.action !== 'None'" [class]="'user-action ' + user.action" (click)="preventEvent($event); userActionClick(user)">{{ user.action }}</span>-->
<!--    </mat-option>-->
<!--</mat-menu>-->
<!--<mat-autocomplete #autocomplete="matAutocomplete">-->
<!--    <mat-option class="user-suggestion" *ngFor="let user of userSuggestions" [value]="messageControl.value" (click)="preventEvent($event, trigger); userMentionClick(user)">-->
<!--        <span class="user-name">{{ user.firstName + ' ' + user.lastName }}</span>-->
<!--        <span *ngIf="user.action !== 'None'" [class]="'user-action ' + user.action" (click)="preventEvent($event, trigger); userActionClick(user)">{{ user.action }}</span>-->
<!--    </mat-option>-->
<!--</mat-autocomplete>-->
