<div class="container">
  <div *ngIf="status$ | async let status" class="banner-wrapper" [class.p--12]="smallScreen$ | async">
    <p class="banner-text" [class.text-center]="status === 'pending_approval'">{{ text$ | async }}</p>
    <ng-container *ngIf="status !== 'pending_approval'">
      <a *ngIf="link$ | async let link" [href]="link" target="_blank" class="onboarding-link">
        {{ linkText$ | async }}
        <app-icon class="onboarding-arrow se-icon-button no-bg no-hover" src="cheveron_down"></app-icon>
      </a>
    </ng-container>
  </div>
</div>