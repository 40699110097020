<div class="page main d-flex flex-column justify-content-start" *ngIf="user$ | async">

  <ng-container *ngIf="payment$ | async let setup">
    <app-toolbar type="menu" class="above-tab" [leftAction]="backRoute ? 'Back' : 'Close'" (backClick)="back(setup.payment.id!)">
      <h3>Payments</h3>
    </app-toolbar>

    <app-mobile-toolbar class="under-tab" [showMenu]="true" [leftAction]="backRoute ? 'Back' : 'Close'" (backClick)="back(setup.payment.id!)"></app-mobile-toolbar>

    <ng-container *ngIf="!(largeScreen$ | async)" [ngTemplateOutlet]="tabs"></ng-container>

    <div class="d-flex h-100 container main-layout">
      <div [class.hide-tab]="!(showInfo$ | async)"
           class="payment-main flex-fill"
           *ngIf="currentUser$ | async let currentUser"
      >
        <div class="payment-content">
          <div class="close-and-workflow-bar">
            <app-progress-bar [workflowId]="setup.workflowId" active="payment"></app-progress-bar>
          </div>
          <app-payment-summary [showSmall]="(showSmallScreenView$ | async)!"
                               [paymentSummary]="setup.payment.paymentSummary"
                               (openedSummary)="openedSummary = $event"
          ></app-payment-summary>
          <div class="payment-items" [class.opened-summary]="openedSummary" (scroll)="checkItemsScroll($event)" id="payment-items">
            <ng-container *ngFor="let chargeback of setup.payment.paymentItems?.chargebacks; let i = index">
              <app-payment-item [itemData]="chargeback"
                                [type]="'chargeback'"
                                [paymentId]="setup.payment.id!"
                                [index]="i"
              ></app-payment-item>
              <div class="item-divider"></div>
            </ng-container>
            <ng-container *ngFor="let commission of setup.payment.paymentItems.commissions; let i = index">
              <app-payment-item [itemData]="commission"
                                [type]="'commission'"
                                [paymentId]="setup.payment.id"
                                [index]="i"
                                [canceled]="setup.payment.paymentSummary.canceled"
              ></app-payment-item>
              <div class="item-divider"></div>
            </ng-container>
            <app-payment-item [itemData]="setup.payment.paymentItems.itemsCost"
                              [type]="'items-cost'"
                              [paymentId]="setup.payment.id!"
                              *ngIf="!setup.payment.paymentSummary.canceled"
            ></app-payment-item>
            <div class="item-divider" *ngIf="!setup.payment.paymentSummary.canceled"></div>
            <app-payment-item [itemData]="setup.payment.paymentItems.salesTax"
                              [type]="'sales-tax'"
                              [paymentId]="setup.payment.id!"
                              *ngIf="!setup.payment.paymentSummary.canceled"
            ></app-payment-item>
            <ng-container *ngIf="setup.payment.paymentItems.paymentItems?.length !== 0">
              <div class="item-divider" *ngIf="setup.payment.paymentItems?.paymentItems?.length !== 0"></div>
              <ng-container *ngFor="let paymentItem of setup.payment.paymentItems.paymentItems; let i = index">
                <app-payment-item [itemData]="paymentItem"
                                  [type]="paymentItem.type"
                                  [paymentId]="setup.payment.id!"
                                  [workflowId]="setup.workflowId"
                                  [index]="i"
                                  [currentUser]="currentUser"
                                  [workflowCreator]="setup.payment.paymentSummary.workflowCreator"
                                  [balanceDue]="setup.payment.paymentSummary.sBalanceDue"
                ></app-payment-item>
                <div class="item-divider" *ngIf="i < setup.payment.paymentItems.paymentItems.length - 1"></div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <app-payment-actions [cancelVisible]="itemsScrolledToBottom"
                             [payment]="setup.payment"
                             [workflowCreator]="setup.payment.paymentSummary.workflowCreator"
                             [currentUser]="currentUser"
                             [workflowId]="setup.workflowId"
        ></app-payment-actions>
      </div>

      <div *ngIf="(largeScreen$ | async) || !(showInfo$ | async)" class="side-panel-wrap" (click)="sidePanelClick($event)">
        <ng-container *ngIf="largeScreen$ | async" [ngTemplateOutlet]="tabs"></ng-container>
        
        <div class="notes-wrap" [class.d-none]="!(showNotes$ | async)">
          <app-notes
            class="notes"
            [workflowId]="(params$ | async)!.workflowId"
            [businessId]="(businessId$ | async)!"
            [currentUser]="(currentUser$ | async)!"
            [isModal]="false"
            [showJobType]="true"
            [showOverlayImageViewer]="true"
            from="Payment"
          ></app-notes>
        </div>
  
        <div class="chat-wrap h-100" *ngIf="showChat$ | async">
          <app-chat #chat
            [chat]="chat$ | async"
            [workflowId]="(params$ | async)!.workflowId"
            [subject]="(chatSubject$ | async)!"
          ></app-chat>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #tabs>
    <app-tabs
      [class.app-tabs]="!(largeScreen$ | async)"
      linePosition="bottom"
      alignment="center"
      [tabs]="(tabs$ | async)!"
      [activeTab]="(selectedTab$ | async)!"
      (onTabSelect)="setActiveTab($event)"
      [redDots]="(tabsRedDots$ | async)!"
    ></app-tabs>
  </ng-template>

</div>
