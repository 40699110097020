<div class="page main h-100 w-100 d-flex flex-column justify-content-start" fadeIn="500" [fadeInDelay]="200">
    <ng-container *ngIf="businessInfo$ | async let businessInfo">
        <app-toolbar type="menu" class="not-mobile" leftAction="Back" (backClick)="backClick()"></app-toolbar>
        <app-mobile-toolbar class="only-mobile" [showMenu]="true" leftAction="Back" (backClick)="backClick()"></app-mobile-toolbar>


        <div class="container flex-fill d-flex flex-column h-100">

            <div class="top-bar-container">
                <div class="top-bar">
                    <div class="search-input-wrap hide-mobile">
                        <input
                                #input
                                matInput
                                class="flex-fill"
                                placeholder="Search by name"
                                [formControl]="searchFormControl"
                        />
                        <img class="prefix" src="../../../assets/icons/search.svg">
                        <app-icon src="close" class="se-icon-button no-bg suffix"(click)="clear($event)"
                            *ngIf="(searchFormControl.value && searchFormControl.value.length > 0)"></app-icon>
                    </div>
                    <div class="show-mobile w-100">
                        <app-icon class="se-icon-button width--40 height--40"
                                  src="search" *ngIf="!openedMobileSearch"
                                  (click)="handleMobileSearch(true)"
                        ></app-icon>
                        <div class="search-input-wrap mobile" *ngIf="openedMobileSearch">
                            <input
                                    matInput
                                    class="flex-fill"
                                    placeholder="Search"
                                    [formControl]="searchFormControl"
                                    #mobileSearch
                                    (focusout)="handleMobileSearch(false)"
                            />
                            <img class="prefix" src="../../../assets/icons/search.svg">
                            <app-icon src="close" class="se-icon-button no-bg suffix"(click)="clear($event)"
                                      *ngIf="(searchFormControl.value && searchFormControl.value.length > 0)"></app-icon>
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-2">

<!--                        <button (click)="changeSort(sortBy === 'date' ? 'name' : 'date')">{{ sortBy }}</button>-->
                        <app-menu-button
                                class="not-mobile-sm"
                                [matMenuTriggerFor]="addMenu" #addMenuTrigger="matMenuTrigger"
                                [matMenuTriggerData]="{ salesTaxPercentage: businessInfo.salesTaxPercentage, businessId: businessInfo.businessId }"
                                [menuOpen]="addMenuTrigger.menuOpen"
                                [hideMenuOnScreenSizeChange]="addMenuTrigger"
                        ></app-menu-button>
                    </div>
                </div>
            </div>

          <div class="total-sort">
            <div class="sort-block"
                 [matMenuTriggerFor]="sortMenu" #sortMenuTrigger="matMenuTrigger"
                 [hideMenuOnScreenSizeChange]="sortMenuTrigger">
              <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 11H1M7 7H1M5 3H1M11 15H1M18 17V1M18 17L21 14M18 17L15 14M18 1L21 4M18 1L15 4" stroke="#65707C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="sort">Sort</div>
            </div>
            <div class="total" *ngIf="total$ | async let total">
              Total: {{ total }}
            </div>
          </div>

            <div class="flex-fill">
                <dynamic-list #list class="h-100"
                    [style.display]="(searchResults$ | async) ? 'none' : 'block'"
                    [canLoadMoreTop]="false"
                    (loadMoreBottom)="loadItems($event)"
                    (itemEvent)="handleItemEvent($event)"
                    [initialBottomItems]="initialItems"
                    [stayOnTop]="true"
                >
                    <app-empty-state page="Items" [style.display]="(loading$ | async) ? 'none' : 'block'"></app-empty-state>
                </dynamic-list>
                <dynamic-list #searchFilterList class="h-100"
                    *ngIf="(searchResults$ | async) let searchResults"
                    [canLoadMoreTop]="false"
                    [canLoadMoreBottom]="false"
                    [initialBottomItems]="searchResults"
                    (itemEvent)="handleItemEvent($event)"
                    [stayOnTop]="true"
                >
                    <app-empty-state filter="Search" page="Items" [style.display]="(loading$ | async) ? 'none' : 'block'"></app-empty-state>
                </dynamic-list>
<!--                <div *ngIf="(searchResults$ | async) let searchResults">-->
<!--                    <app-item-tile-->
<!--                        *ngFor="let item of searchResults"-->
<!--                        [item]="item"-->
<!--                        (itemClick)="handleItemEvent({-->
<!--                            eventEmitterName: 'itemClick',-->
<!--                            value: $event-->
<!--                        })"-->
<!--                        (deleteItem)="handleItemEvent({-->
<!--                            eventEmitterName: 'deleteItem',-->
<!--                            value: $event-->
<!--                        })"-->
<!--                    ></app-item-tile>-->
<!--                </div>-->
            </div>
        </div>
        <div class="bottom-button">
            <app-menu-button
                    class="only-mobile-sm menu-button"
                    [matMenuTriggerFor]="addMenu" #addMenuTrigger="matMenuTrigger"
                    [matMenuTriggerData]="{ salesTaxPercentage: businessInfo.salesTaxPercentage, businessId: businessInfo.businessId }"
                    [menuOpen]="addMenuTrigger.menuOpen"
                    [hideMenuOnScreenSizeChange]="addMenuTrigger"
                    [hideOnScroll]="true"
            ></app-menu-button>
        </div>
    </ng-container>
</div>

<mat-menu #addMenu xPosition="before" class="no-margin">
    <ng-template matMenuContent let-salesTaxPercentage="salesTaxPercentage" let-businessId="businessId">
        <mat-option (click)="openCreateOrEditItemModal({ mode: 'create', salesTaxPercentage, businessId })">Add Item
        </mat-option>
        <mat-option (click)="openItemUpload()">Upload Items</mat-option>
        <mat-option (click)="openItemExample()">See Example</mat-option>
    </ng-template>
</mat-menu>

<mat-menu #sortMenu xPosition="before" class="no-margin">
  <ng-template matMenuContent>
    <mat-option (click)="changeSort('name')">Sort from A-Z</mat-option>
    <mat-option (click)="changeSort('date')">Sort by time</mat-option>
  </ng-template>
</mat-menu>
