import { Component, Input, OnInit } from '@angular/core';
import { capitalize, currencify } from "../../helpers/common";
import { PaymentService } from "../../services/payment.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Chargeback, Commission, Other, OtherType, Paid, PaidType, Refunded } from "../../models/payment.model";
import { rounder } from "../../../../../common/src/lib/helpers/payments";
import { EditWorkerPaymentComponent } from "../../modals/edit-worker-payment/edit-worker-payment.component";
import { RefundPaymentComponent } from "../../modals/refund-payment/refund-payment.component";
import { AddEditPaymentComponent } from "../../modals/add-edit-payment/add-edit-payment.component";
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";

type ItemType = 'commission' | 'items-cost' | 'sales-tax' | PaidType | OtherType | 'refunded' | 'chargeback';

@Component({
  selector: 'app-payment-item',
  templateUrl: './payment-item.component.html',
  styleUrls: ['./payment-item.component.scss']
})
export class PaymentItemComponent {

  protected readonly currencify = currencify;
  protected readonly rounder = rounder;
  protected readonly capitalize = capitalize;

  @Input() itemData!: number | Chargeback | Commission | Paid | Other | Refunded;
  @Input() type!: ItemType;
  @Input() paymentId!: number;
  @Input() workflowId!: number;
  @Input() index!: number;
  @Input() canceled?: boolean;
  @Input() currentUser?: UserProfile;
  @Input() workflowCreator?: number;
  @Input() balanceDue?: number;

  get techPartAccess(): {
    noPermission: boolean;
    onlyMark: boolean;
    handle: boolean;
  } {
    const paid = (this.itemData as Other).paid ?? false;
    const mark = this.currentUser?.permissions.includes('mark_tech_parts') ?? false;
    const owner = this.currentUser?.id === (this.itemData as Other).createdBy;

    return {
      noPermission: paid && !mark,
      onlyMark: mark || !paid,
      handle: !paid && owner
    }
  }

  get expenseAccess(): boolean {
    return this.currentUser?.id === this.workflowCreator;
  }

  get itemType(): string {
    if (
      this.type !== 'expense'
      && this.type !== 'tech_part'
      && this.type !== 'items-cost'
      && this.type !== 'refunded'
      && this.type !== 'chargeback'
      && this.type !== 'commission'
      && this.type !== 'sales-tax'
    )
      return 'paid';

    return this.type;
  }

  constructor(
    private modalsService: ModalsService,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar
  ) { }

  getPaymentDate(date: Date): string {
    if (!date)
      return '';

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}/${month}/${year}`;
  }

  getBadgeCopy(type: string): string {
    if (type === 'cc') {
      return `CC ${(this.itemData as any).lastDigits}`;
    } else if (type === 'bank transfer (offline)') {
      return `Bank transfer`;
    } else if (type === 'cash_app') {
      return `Cash app`;
    } else {
      return capitalize(type);
    }
  }

  async refund() {
    await this.modalsService.open(RefundPaymentComponent, {
      disableClose: true,
      data: {
        transactionNumber: (this.itemData as any).transactionNumber,
        amount: (this.itemData as any).amount,
        workflowId: this.workflowId,
        paymentId: this.paymentId
      }
    });
  }

  async delete() {
    const typeCorrection =
      this.type !== 'expense' && this.type !== 'tech_part'
        ? 'payment'
        : this.type;

    await this.modalsService.open(ConfirmationDialog, {
      data: {
        title: `Delete ${capitalize(typeCorrection)}`,
        message: `Are you sure you want to delete the ${typeCorrection}?`,
        actionTitle: 'Yes',
        actionColor: 'warn',
        action: async () => {
          this.itemData = this.itemData as Paid | Other;

          const deletedItem = {
            createdAt: this.itemData.createdAt,
            amount: this.itemData.amount,
            workflowId: this.workflowId,
            paymentId: this.paymentId,
            createdBy: this.itemData.createdBy,
            type: this.itemData.type,
            ...((this.itemData.type === 'expense' || this.itemData.type === 'tech_part') && { name: this.itemData.name }),
            ...(this.itemData.type === 'tech_part' && { paidAt: this.itemData.paidAt }),
            ...(this.itemData.type !== 'expense' && this.itemData.type !== 'tech_part' && { lastDigits: (this.itemData as Paid).lastDigits }),
            ...(this.itemData.type !== 'expense' && this.itemData.type !== 'tech_part' && { transactionNumber: (this.itemData as Paid).transactionNumber }),
            ...(this.itemData.type !== 'expense' && this.itemData.type !== 'tech_part' && { confirmation: (this.itemData as Paid).confirmation }),
          };

          await this.paymentService.deleteInstance(
            (this.itemData as any).id,
            typeCorrection === 'payment' ? 'paid' : 'other'
          );

          showSnackbar(this.snackBar, {
            message: `${capitalize(typeCorrection === 'payment' ? 'Payment' : this.type)} deleted`,
            duration: 10000,
            actionText: 'Undo',
            action: (async () => {
              await this.paymentService.addInstance(
                deletedItem,
                typeCorrection,
                this.workflowId,
                this.paymentId
              );
            }).bind(this),
          });
        }
      }
    });
  }

  async edit() {
    await this.modalsService.open(AddEditPaymentComponent, {
      disableClose: true,
      data: {
        balanceDue: this.balanceDue!,
        type: this.type !== 'expense' && this.type !== 'tech_part' ? 'payment' : this.type,
        edit: {
          id: (this.itemData as Paid | Other).id,
          amount: (this.itemData as Paid | Other).amount,
          ...(this.type !== 'expense' && this.type !== 'tech_part' && { confirmation: (this.itemData as Paid).confirmation }),
          ...((this.type === 'expense' || this.type === 'tech_part') && { name: (this.itemData as Other).name }),
          createdAt: (this.itemData as Paid | Other).createdAt,
          type: (this.itemData as Paid | Other).type,
        }
      }
    });
  }

  getExpenseName(name: string): string {
    const itemRow = document.getElementById('itemRow' + this.index);
    const itemRight = document.getElementById('itemRight' + this.index);

    if (itemRow && itemRight) {
      const restSpace = itemRow.offsetWidth - itemRight.offsetWidth - 20;
      const avgCharWidth = 9.5;
      const nameLength = avgCharWidth*name.length;

      if (nameLength > restSpace) {
        const charFit = Math.round(restSpace/avgCharWidth);
        return name.substring(0, charFit) + ' ...';
      }
    }

    return name;
  }

  async markTechPart() {
    if ((this.itemData as Other).type === 'tech_part')
      await this.paymentService.markTechPart(
        (this.itemData as Other).id,
        !(this.itemData as Other).paid
      );
  }

  getPendingAmount(amount: number, paid: number): number {
    let pending = amount - paid;

    if (pending < 0)
      pending *= -1;

    return pending;
  }

  async editCommission() {
    await this.modalsService.open(EditWorkerPaymentComponent, {
      disableClose: true,
      data: {
        item: this.itemData,
        type: 'commission'
      }
    });
  }

  async markCommissionAsPaid() {
    const commission: Commission = this.itemData as Commission;
    await this.paymentService.updateCommissionAmount(commission.id, commission.amount);
  }
}
