<div class="main-proposal-summary" *ngIf="proposalSummaryValues">

  <div class="values">
    <div class="section">
      <div class="text">Subtotal</div>
      <div class="d-flex">
        <div class="text">$</div>
        <div class="text fw-600">{{proposalSummaryValues.subtotal.toFixed(2)}}</div>
      </div>
    </div>

    <div class="section"
         *ngIf="
         !isNaN(+proposalSummaryValues.discountDisplay)
         && proposalSummaryValues.discountDisplay !== '0'
         && proposalSummaryValues.discountDisplay !== '0.00'"
    >
      <div class="d-flex align-items-center gap--10">
        <div class="text">Discount</div>
        <div class="discount-switcher">
          <div class="option" (click)="toggleDiscountType('percent')"
               [class.active]="proposalVersion.discountType === 'percent'"
          >
            %
          </div>
          <div class="option" (click)="toggleDiscountType('amount')"
               [class.active]="proposalVersion.discountType === 'amount'"
          >
            $
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <div class="text">-</div>
          <div class="text">{{currentDiscountType === 'amount' ? '$' : '&nbsp;'}}</div>
        </div>
        <div class="text fw-600">{{proposalSummaryValues.discountDisplay}}</div>
        <div class="text" *ngIf="currentDiscountType === 'percent'">%</div>
      </div>
    </div>

    <div class="section">
      <div class="text">Sales Tax</div>
      <div class="d-flex">
        <div class="text">$</div>
        <div class="text fw-600">{{proposalSummaryValues.salesTax.toFixed(2)}}</div>
      </div>
    </div>
  </div>

  <div class="total-line"></div>

  <div class="d-flex justify-content-between align-items-center">
    <div class="text fw-700">Total</div>
    <div class="text fw-700" id="editable-total-amount">
      ${{proposalSummaryValues.total.toFixed(2)}}
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center mt--20" *ngIf="proposalSummaryValues.total">
    <div class="d-flex gap--22 align-items-center">
      <div class="d-flex gap--4 align-items-center">
        <div class="text fw-600">Deposit</div>
        <app-icon src="question-mark-tooltip"
                  matTooltip="Payment Splitting available on the sent proposal. Nuvei processed Payments updated automatically, update other payment methods in payments"
                  [matTooltipShowDelay]="300"
                  matTooltipClass="deposit-tooltip"
                  class="scale--85"
                  #tooltip="matTooltip"
                  (click)="tooltip.show()"
                  [hideTooltipOnScroll]="tooltip"
        ></app-icon>
      </div>
      <div class="deposit-input-block">
        <mat-form-field appearance="outline" appFormFieldFilled [class.error]="depositAbove">
          <div class="d-flex" *ngIf="proposalVersion.depositType === 'amount'">
            <div class="d-flex align-items-center mr--2">$</div>
            <input matInput [formControl]="depositAmountFormControl"
                   (focus)="depositFocus$.next(true); depositFocus.emit(true)"
                   (focusout)="depositFocus$.next(false); depositFocus.emit(false)"
                   id="editable-deposit-amount"
                   autocomplete="off"
                   inputmode="decimal"
                   mask="separator.2" thousandSeparator="" decimalMarker="."
                   [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                   (keydown)="decimalOnly($event)"
                   (paste)="onPaste($event)"
                   replaceCommaWithDot
            >
            <div class="deposit-error" *ngIf="depositAbove">Above proposal amount</div>
          </div>
          <div class="d-flex" *ngIf="proposalVersion.depositType === 'percent'">
            <input matInput class="tar"
                   [formControl]="depositPercentFormControl"
                   (focus)="depositFocus$.next(true); depositFocus.emit(true)"
                   (focusout)="depositFocus$.next(false); depositFocus.emit(false)"
                   id="editable-deposit-percent"
                   autocomplete="off"
                   inputmode="decimal"
                   mask="separator.2" thousandSeparator="" decimalMarker="."
                   [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                   (keydown)="decimalOnly($event)"
                   (paste)="onPaste($event)"
                   replaceCommaWithDot
            >
            <div class="d-flex align-items-center ml--2" style="color: #1B1B1B">%</div>
            <div class="deposit-error" *ngIf="depositAbove">Above proposal amount</div>
          </div>
          <div class="deposit-divider"></div>
          <app-icon src="change" class="ml--4 clickable" (click)="toggleDepositType()"></app-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex" *ngIf="displayDeposit !== 0 && showDisplayDeposit"
         [class.deposit-danger]="(proposalVersion.depositType === 'percent' && displayDeposit > proposalSummaryValues.total)
         || (proposalVersion.depositType === 'amount') && displayDeposit > 100"
    >
      <div class="text fw-600" *ngIf="proposalVersion.depositType === 'percent'">$</div>
      <div class="text fw-600">
        {{formatDeposit(displayDeposit)}}
      </div>
      <div class="text fw-600" *ngIf="proposalVersion.depositType === 'amount'">%</div>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-between mt--16">
    <div class="d-flex align-items-center gap--8">
      <div class="d-flex align-items-center gap--4">
        <div class="fw-600 text">Signature</div>
        <app-icon src="question-mark-tooltip"
                  matTooltip="Request a signature from your customer"
                  [matTooltipShowDelay]="300"
                  class="scale--85"
                  #tooltip="matTooltip"
                  (click)="tooltip.show()"
                  [hideTooltipOnScroll]="tooltip"
        ></app-icon>
      </div>
      <mat-slide-toggle [checked]="proposalVersion.signature!"
                        (change)="toggleSignature($event.checked)"
                        class="slide-toggle"
      ></mat-slide-toggle>
    </div>
    <div class="text required" *ngIf="proposalVersion.signature">Required</div>
  </div>

</div>
