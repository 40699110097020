import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { currencify, round } from "../../helpers/common";
import { UtilsService } from "../../../../../common/src/lib/services";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['./sales-summary.component.scss'],
})
export class SalesSummaryComponent implements AfterViewInit, OnChanges {

  protected readonly currencify = currencify;
  protected readonly round = round;

  @Input() selectedTab!: string;
  @Input() data!: any;

  loading$ = this.reportsService.loading$;

  infoToFill = {
    paymentsAll: [
      {
        id: 'p.all.paid',
        text: 'Paid',
        onlyExpand: false,
        property: 'paid'
      },
      {
        id: 'p.all.pending',
        text: 'Pending',
        onlyExpand: true,
        property: 'pending'
      },
      {
        id: 'p.all.total',
        text: 'Total Sales',
        onlyExpand: true,
        property: 'totalSales'
      }
    ],
    paymentsPaid: [
      {
        id: 'p.paid.paid',
        text: 'Total paid',
        onlyExpand: false,
        property: 'paid'
      },
      {
        id: 'p.paid.avg_s_d',
        text: 'Avg. sale / day',
        onlyExpand: true,
        property: 'avgSalePerDay'
      },
      {
        id: 'p.paid.avg_s_j',
        text: 'Avg. sale / jobs',
        onlyExpand: true,
        property: 'avgSalePerJob'
      },
      {
        id: 'p.paid.avg_j_d',
        text: 'Avg. jobs / day',
        onlyExpand: true,
        property: 'avgJobsPerDay'
      }
    ],
    paymentsPending: [
      {
        id: 'p.pending.pending',
        text: 'Pending',
        onlyExpand: false,
        property: 'pending'
      },
      {
        id: 'p.pending.nc',
        text: 'Jobs',
        onlyExpand: true,
        property: 'jobs'
      }
    ],
    commissionsPending: [
      {
        id: 'c.pending.pending',
        text: 'Commissions',
        onlyExpand: false,
        property: 'pending'
      },
      {
        id: 'c.pending.jobs',
        text: 'Jobs',
        onlyExpand: true,
        property: 'jobs'
      }
    ],
    commissionsPaid: [
      {
        id: 'c.paid.paid',
        text: 'Commissions',
        onlyExpand: false,
        property: 'paid'
      },
      {
        id: 'c.paid.jobs',
        text: 'Jobs',
        onlyExpand: true,
        property: 'jobs'
      }
    ],
    techPartsPending: [
      {
        id: 't.pending.total',
        text: 'Total Sales',
        onlyExpand: false,
        property: 'totalSales'
      },
      {
        id: 't.pending.pending',
        text: 'Pending Tech Parts',
        onlyExpand: true,
        property: 'pending'
      }
    ],
    techPartsPaid: [
      {
        id: 't.paid.total',
        text: 'Total Sales',
        onlyExpand: false,
        property: 'totalSales'
      },
      {
        id: 't.paid.paid',
        text: 'Tech parts paid',
        onlyExpand: true,
        property: 'paid'
      }
    ]
  }

  showAll = false;

  resizeObserver?: ResizeObserver;
  scales: { [key: string]: 'none' | 'K' | 'M' | 'B' } = {};

  constructor(
    private utilService: UtilsService,
    private reportsService: ReportsService,
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.initResizeObserver();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.resizeObserver)
      return;

    if (changes.data) {
      if (changes.data.previousValue.type === changes.data.currentValue.type)
        this.processWidthChanges();
      else
        this.initResizeObserver();
    }
  }

  processElementScale(element: HTMLElement) {
    const container = element.parentElement!;
    let elementWidth = parseFloat(getComputedStyle(element).width);
    const containerWidth = parseFloat(getComputedStyle(container).width);
    const id = element.dataset.id!;
    const newWidth = () => parseFloat(getComputedStyle(element).width);

    if (elementWidth > containerWidth) {
      const value = Math.abs(this.data[(this.infoToFill as any)[this.selectedTab].find((item: any) => item.id === id)!.property]);
      let finalScale = true;
      if (this.scales[id] === 'none') {
        if (value >= 100) {
          this.scales[id] = 'K';
          if (value > 10_000)
            finalScale = false;
        }
      } else if (this.scales[id] === 'K') {
        if (value > 10_000) {
          this.scales[id] = 'M';
          if (value > 10_000_000)
            finalScale = false;
        }
      } else if (value > 10_000_000) {
        this.scales[id] = 'B';
      }
      this.cd.detectChanges();
      if (newWidth() > containerWidth && !finalScale)
        this.processElementScale(element);
    } else if (this.scales[id] !== 'none') {
      if (this.scales[id] === 'B') {
        this.scales[id] = 'M';
        this.cd.detectChanges();
        if (newWidth() === containerWidth)
          return;
        if (newWidth() < containerWidth)
          this.processElementScale(element);
        else
          this.scales[id] = 'B';
      } else if (this.scales[id] === 'M') {
        this.scales[id] = 'K';
        this.cd.detectChanges();
        if (newWidth() === containerWidth)
          return;
        if (newWidth() < containerWidth)
          this.processElementScale(element);
        else
          this.scales[id] = 'M';
      } else {
        this.scales[id] = 'none';
        this.cd.detectChanges();
        if (newWidth() === containerWidth)
          return;
        if (newWidth() > containerWidth)
          this.scales[id] = 'K';
      }
    }
    this.cd.detectChanges();
  }

  processWidthChanges() {
    this.cd.detectChanges();
    document.querySelectorAll('.sales .total-sales h3.currency').forEach(element => {
      if ((element as any).checkVisibility())
        this.processElementScale(element as HTMLElement);
    });
  }

  initResizeObserver() {
    this.resizeObserver?.disconnect();
    this.cd.detectChanges();

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.borderBoxSize?.[0].inlineSize) {
          const element = entry.target.querySelector('.currency') as HTMLElement;
          this.processElementScale(element);
        }
      }
    });

    this.scales = {};
    document.querySelectorAll('.sales .total-sales h3.currency').forEach(element => {
      const id = (element as HTMLElement).dataset.id!;
      this.scales[id] = 'none';
      this.cd.detectChanges();
      this.resizeObserver!.observe(element.parentElement!);
    });
  }

  get isMobile() {
    return this.utilService.isMobile();
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
    this.initResizeObserver();
  }

  redAmount(fieldId: string, data: any): boolean {
    return (
      (fieldId === 'p.all.paid' || fieldId === 'p.paid.paid')
      && data.needsRefund
    ) || (
      fieldId === 'c.paid.paid' && data.paid > data.total
    );
  }
}
