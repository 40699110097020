export const environment = {
  production: true,
  surl: 'https://slickestimates.com:8080',
  domain: 'https://dev.app.slickestimates.com',
  firebase: {
    locationId: 'us-central',
    apiKey: "AIzaSyDt93IH5BLJNn2B6GaeGRx07XpX2eh2ybM",
    authDomain: "dev.app.slickestimates.com",
    projectId: "slick-estimates-dev",
    storageBucket: "slick-estimates-dev.appspot.com",
    messagingSenderId: "296663573450",
    appId: "1:296663573450:web:4aa93606c42a66e78e2533",
    measurementId: "G-Y7V3SMMTDJ"
  },
  pushy: {
    appId: '6798ca2e591705551bbc6559'
  },
  supabase: {
    supabaseUrl: 'https://ftpqsrllioysqtaxrati.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ0cHFzcmxsaW95c3F0YXhyYXRpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzU4MjIyNjYsImV4cCI6MjA1MTM5ODI2Nn0.9zAhZgQaStorx_iWgX4QzVuCDRI7QIcgEfZUhLHWh-k'
  }
};
