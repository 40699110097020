import { Component } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { isEmpty, decimalOnly, onDecimalPaste } from "../../helpers/forms";
import { PaymentService } from "../../services/payment.service";
import { capitalize } from "../../helpers/common";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { OtherType, PaidType } from "../../models/payment.model";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { rounder } from "../../../../../common/src/lib/helpers/payments";

interface AddEditPaymentConfig {
  edit?: {
    id: number;
    amount: number;
    confirmation?: string;
    name?: string;
    createdAt: Date;
    type: PaidType | OtherType;
  };
  type: 'expense' | 'tech_part' | 'payment';
  balanceDue: number;
  workflowId?: number;
  paymentId?: number;
}

@Component({
  selector: 'app-add-edit-payment',
  templateUrl: './add-edit-payment.component.html',
  styleUrls: ['./add-edit-payment.component.scss']
})
export class AddEditPaymentComponent {

  protected readonly capitalize = capitalize;
  protected readonly decimalOnly = decimalOnly;
  protected readonly onPaste = onDecimalPaste;

  headerTitle!: string;
  headerType!: string;
  actionButton!: string;

  selectedPaymentType: PaidType = 'cash';

  inputName!: string;
  inputAmount!: string;
  inputConfirmation!: string;

  loading = false;

  invalidName = false;
  invalidAmount = false;
  invalidConfirmation = false;
  overAmount = false;

  menuOpened = false;

  get data(): AddEditPaymentConfig {
    return this.modalsService.data;
  }

  constructor(
    private modalsService: ModalsService,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar
  ) {
    this.headerTitle = this.data.edit ? 'Edit' : 'Add';
    this.actionButton = this.data.edit ? 'Save' : 'Add';

    if (this.data.edit) {
      this.inputAmount = this.data.edit.amount.toString();
      if (this.data.type === 'expense' || this.data.type === 'tech_part') {
        this.inputName = this.data.edit.name!;
      } else {
        this.selectedPaymentType = this.data.edit.type as PaidType;
        this.inputConfirmation = this.data.edit.confirmation!;
      }
    }

    switch (this.data.type) {
      case 'expense':
        this.headerType = 'Expense';
        break;
      case 'tech_part':
        this.headerType = 'Tech Part';
        break;
      default:
        this.headerType = 'Payment';
        break;
    }
  }

  async close() {
    await this.modalsService.close();
  }

  async addEditPayment() {
    let maxAmount = this.data.balanceDue;
    if (this.data.edit)
      maxAmount += this.data.edit.amount;

    if (this.valid(rounder(maxAmount) >= 0 ? maxAmount : this.data.edit?.amount ?? -1)) {
      if (!this.loading) {
        this.loading = true;

        const now = new Date();
        let usingDate = this.data.edit?.createdAt ?? now;

        if (this.data.edit)
          if (this.data.type === 'payment') {
            if (
              (this.data.edit.amount).toString() !== this.inputAmount.toString()
              || (this.data.edit.type).toString() !== this.selectedPaymentType.toString()
            )
              usingDate = now;
          } else {
            if (
              (this.data.edit.amount).toString() !== this.inputAmount.toString()
            )
              usingDate = now;
          }

        if (this.data.edit)
          await this.paymentService.editInstance(
            {
              id: this.data.edit.id,
              createdAt: usingDate,
              amount: +this.inputAmount,
              ...(this.data.type === 'payment' && { type: this.selectedPaymentType }),
              ...(this.data.type !== 'payment' && { name: this.inputName }),
              ...(this.selectedPaymentType !== 'cc' && { confirmation: this.inputConfirmation })
            },
            this.data.type
          );
        else
          await this.paymentService.addInstance(
            {
              amount: +this.inputAmount,
              type: this.data.type === 'payment' ? this.selectedPaymentType : this.data.type,
              ...(this.selectedPaymentType !== 'cc' && { confirmation: this.inputConfirmation }),
              createdAt: usingDate,
              ...(this.data.type !== 'payment' && { name: this.inputName }),
              ...(this.data.type === 'payment' && { copied: true })
            },
            this.data.type,
            this.data.workflowId!,
            this.data.paymentId!
          );

        showSnackbar(this.snackBar, {
          message: `${this.data.type === 'tech_part'
            ? 'Tech Part'
            : capitalize(this.data.type)} ${this.data.edit ? 'edited' : 'added'}`
        });
        this.loading = false;
        await this.modalsService.close();
      }
    }
  }

  valid(maxAmount: number): boolean {
    let validName = false;
    let validAmount = false;
    let notOverAmount = false;
    let validConfirmation = false;

    if (this.data.type === 'payment') {
      validName = true;
      if (!isEmpty(this.inputAmount))
        validAmount = true;

      if (this.selectedPaymentType === 'cash')
        validConfirmation = true;
      else
        if (!isEmpty(this.inputConfirmation))
          validConfirmation = true;

      if (+this.inputAmount <= maxAmount || !validAmount)
        notOverAmount = true;
    } else {
      validConfirmation = true;
      notOverAmount = true;
      if (!isEmpty(this.inputAmount))
        validAmount = true;
      if (!isEmpty(this.inputName))
        validName = true;
    }

    this.invalidName = !validName;
    this.invalidAmount = !validAmount;
    this.overAmount = !notOverAmount;
    this.invalidConfirmation = !validConfirmation;

    return validName && validAmount && validConfirmation && notOverAmount;
  }

}
