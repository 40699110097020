import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { UsersService } from "../../services/users.service";

@Component({
  selector: 'app-user-search-bar',
  templateUrl: './user-search-bar.component.html',
  styleUrls: ['./user-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSearchBarComponent implements OnInit, OnChanges {

  @ViewChild('searchInput') assignInput!: ElementRef;
  @ViewChild('auto') auto: any;

  @Input() user: UserProfile | null = null;

  multipleAssigneeControl = new FormControl();
  focus = false;
  searchedOnce = false;
  isOptionClicked = false;
  focusOutInputValue: string = '';
  afterInit = false;
  filteredUsers!: UserProfile[];

  @Input() singleAssigneeInList!: boolean;
  @Input() assignedUsers!: UserProfile[];
  @Input() users!: UserProfile[];

  @Output() focusChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<UserProfile[]>();
  @Output() clearButtonClicked = new EventEmitter<void>();

  @Output() selectedUsers: UserProfile[] = [];
  @Output() userSelected = new EventEmitter<UserProfile>();

  selectedUserSubject = new BehaviorSubject<UserProfile | null>(null);
  assignedUsersSubject = new BehaviorSubject([] as UserProfile[]);

  constructor(
    private usersService: UsersService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.selectedUsers = this.singleAssigneeInList
      ? (this.assignedUsers[0] ? [this.assignedUsers[0]] : [])
      : Array.from(this.assignedUsers);

    this.isOptionClicked = false;
    this.assignedUsersSubject.next(this.assignedUsers);
    // TODO: Check if this causes issues
    // this.onChange.emit(this.selectedUsers);
    this.afterInit = true;
    this.filteredUsers = this.users.filter(user => !this.selectedUsers.find(selectedUser => selectedUser.id === user.id));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.singleAssigneeInList && this.afterInit) {
      this.selectedUsers = this.selectedUsers.length > 0 ? [this.selectedUsers[0]] : [];
      // TODO: Check if this causes issues
      // this.onChange.emit(this.selectedUsers);
    }
    if (changes.assignedUsers && this.afterInit) {
      this.selectedUsers = changes.assignedUsers.currentValue;
      // this.selectedUsers = this.selectedUsers.length > 0 ? [this.selectedUsers[0]] : [];
    }
    this.updateDropdownList();
  }

  get showClearButton(): boolean {
    return this.multipleAssigneeControl.value && this.multipleAssigneeControl.value !== '';
  }

  updateDropdownList() {
    const searchValue = this.multipleAssigneeControl.value?.toLowerCase();
    this.filteredUsers = this.users.filter(user => {
      const selected = this.selectedUsers.find(selectedUser => selectedUser.id === user.id);
      if(!searchValue || searchValue === '')
        return !selected;

      if(
          user.firstName.toLowerCase().includes(searchValue) ||
          user.lastName.toLowerCase().includes(searchValue) ||
          (user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()).includes(searchValue)
      )
        return !selected;

      return false;
    });
  }

  assignUser(user: UserProfile): void {
    this.isOptionClicked = true;
    this.multipleAssigneeControl.setValue('');
    if(this.singleAssigneeInList) {
      this.selectedUsers = [];
    }
    this.selectedUsers.push(user);
    this.selectedUserSubject.next(user);
    this.userSelected.emit(user);
    this.onChange.emit(this.selectedUsers);
    this.updateDropdownList();
    // this.multipleAssigneeControl.setValue(user.firstName + ' ' + user.lastName);
    this.cdr.detectChanges();
  }

  unassignedUser(user: UserProfile) {
    this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser.id !== user.id);
    this.onChange.emit(this.selectedUsers);
  }

  clear(event: Event): void {
    event.preventDefault();
    this.isOptionClicked = false;
    this.multipleAssigneeControl.setValue('');
    this.focusOutInputValue = '';
    this.searchedOnce = false;
    this.clearButtonClicked.emit();
    this.updateDropdownList();
    event.stopPropagation();
  }

  onClick(event: Event): void {
    this.searchedOnce = true;
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  handleChange(event: Event): void {
    this.updateDropdownList();
    this.searchedOnce = true;
    event.stopPropagation();
  }

  userFullName(user: UserProfile): string {
    return `${user.firstName} ${user.lastName}`;
  }

  onFocus(): void {
    this.focus = true;
    this.focusChange.emit(true);
  }

  onFocusOut(): void {
    this.focus = false;
    this.focusChange.emit(false)
  }

  focusIn() {
    this.assignInput.nativeElement.focus();
  }
}
