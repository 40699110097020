import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';
import { FormsService } from 'projects/common/src/lib/services/forms.service';
import { UtilsService } from 'projects/common/src/public-api';

const goToLobbyTimeout = 5 * 60 * 1000;
const lastSignInTimeout = 5 * 60 * 1000;

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit, AfterViewInit {

  @ViewChild('passwordInput') passwordInput!: ElementRef;
  @ViewChild('newPasswordInput') newPasswordInput!: ElementRef;

  form = this.formBuilder.group({
    password: new UntypedFormControl(''),
    newPassword: new UntypedFormControl('', [Validators.minLength(6)])
  });

  showPassword = false;
  showNewPassword = false;
  showReauthentication = false;
  passwordFocused = false;
  loading = false;

  constructor(
      private authService: AuthService,
      private router: Router,
      private formBuilder: UntypedFormBuilder,
      private formsService: FormsService,
      private utilsService: UtilsService,
      private cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    if (Date.now() - user!.lastSignIn.getTime() > lastSignInTimeout) {
      this.showReauthentication = true;
      this.cd.detectChanges();
      this.passwordInput?.nativeElement.focus();
    }

    setTimeout(() => this.goToLobby(), goToLobbyTimeout);
  }

  ngAfterViewInit(): void {
    if (!this.showReauthentication) {
      this.newPasswordInput.nativeElement.focus();
      this.cd.detectChanges();
    }
  }

  async changePassword() {
    if(this.utilsService.isMobile() && this.passwordFocused) {
      this.newPasswordInput.nativeElement.focus();
      return;
    }

    if(this.loading) {
      return;
    }

    const requiredValid = this.showReauthentication
      ? this.formsService.validateRequired(this.form.get('password')!, this.form.get('newPassword')!)
      : this.formsService.validateRequired(this.form.get('newPassword')!);

    if(requiredValid && this.form.valid) {
      this.loading = true;

      const { password, newPassword } = this.form.value;
      try {
        if(this.showReauthentication) {
          await this.authService.reauthenticateWithPassword(password);
        }
        await this.authService.changePassword(newPassword);
        this.goToLobby();
      } catch (e) {
        console.log(e);

        if(e === 'Invalid login credentials') {
          this.form.get('password')!.setErrors({
            password: true
          });
        }

        if(e === 'New password should be different from the old password.') {
          this.form.get('newPassword')!.setErrors({
            samePassword: true
          });
        }
      }

    } else {
      this.form.markAllAsTouched();
    }

    this.passwordInput?.nativeElement?.blur();
    this.newPasswordInput.nativeElement.blur();
    this.loading = false;
  }

  goToLobby() {
    this.router.navigate(['/']);
  }

}
