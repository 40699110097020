<div class="main w-100 d-flex flex-column justify-content-start" style="overflow: hidden !important;">

    <div class="content flex-fill h-100">
        <div class="container d-flex flex-column h-100" fadeIn="500" [fadeInDelay]="200">

            <div class="header">

                <div class="tabs d-flex justify-content-between align-items-center">
                    <app-tabs
                        linePosition="bottom"
                        class="not-mobile-sm"
                        alignment="start"
                        [tabs]="tabs"
                        [activeTab]="(tab$ | async)!"
                        (onTabSelect)="onTabSelected($event)"
                    ></app-tabs>

                    <app-menu-button
                            class="not-mobile-sm"
                            (click)="openCreateServiceRequestDialog('Lead')"
                    ></app-menu-button>
                </div>
                <div class="date-picker-container">
                    <div class="date-picker-and-button">
                        <div class="d-flex date-picker-wrapper">
                            <div class="date-picker_top" *ngIf="!(calendarView$ | async)">
                                <app-date-range class="date-range-picker"
                                                [selected]="(dateFilter$ | async)"
                                                (change)="selectedRangeChange($event)"
                                                #dateRange
                                >
                                </app-date-range>
                            </div>

                            <ng-container *ngIf="(tab$ | async) let tab">
                                <div *ngIf="tab === 'Estimates' || tab === 'Jobs'"
                                        class="filter-by-status-menu" [class.open]="isMenuOpen"
                                        #menuTrigger="matMenuTrigger" [hideMenuOnScreenSizeChange]="menuTrigger"
                                        [matMenuTriggerFor]="filterByStatusMenu" (menuOpened)="isMenuOpen = true"
                                        (menuClosed)="isMenuOpen = false"
                                    >
                                    <p>{{ currentStatusFilter$ | async }}</p>
                                    <app-icon class="se-icon-button no-bg no-hover" src="cheveron_down"></app-icon>
                                    <mat-menu #filterByStatusMenu class="no-margin">
                                        <div class="change-status-menu">
                                            <ng-container *ngFor="let status of (currentStatusesForTab$ | async); let last = last">
                                                <p class="change-status-menu__option"
                                                    (click)="statusFilterSelected(tab, status)"
                                                >{{ status }}</p>
                                                <mat-divider *ngIf="!last" class="divider"></mat-divider>
                                            </ng-container>
                                        </div>
                                    </mat-menu>
                                </div>
                            </ng-container>
                        </div>
                        <div class="buttons-container" [class.ml--0]="(calendarView$ | async)">
                            <div class="set-schedule"
                                 (click)="openSetScheduleDialog()"
                                 *ngIf="(tab$ | async) === 'Unavailable'">
                                <p>Set Schedule</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(tab$ | async) let tab" class="estimate-view">
                        <app-text-toggle class="w-100 text-toggle" style="cursor: pointer;"
                                         [class.mt--0]="(calendarView$ | async) && ['All', 'Leads'].includes(tab)"
                                         [items]="['List','Calendar view']"
                                         [selectedItemIndex]="!(calendarView$ | async) ? 0 : 1"
                                         (onSelected)="changeView($event)"
                        >
                        </app-text-toggle>
                    </div>
                </div>
<!--                <div class="date-picker-container_bottom" *ngIf="!calendarView">-->
<!--                    <app-date-range class="date-range-picker"-->
<!--                                    [selected]="(dateFilter$ | async)"-->
<!--                                    (change)="selectedRangeChange($event)"-->
<!--                                    #dateRange-->
<!--                    >-->
<!--                    </app-date-range>-->
<!--                </div>-->

            </div>

            <div class="h-100 content" *ngIf="!(calendarView$ | async)">
                <router-outlet></router-outlet>
            </div>
<!--            <app-items-list-->
<!--                *ngIf="!calendarView"-->
<!--                [items$]="items$"-->
<!--                [dateForItem]="dateForItem"-->
<!--                [dateStringForItem]="dateStringForItem"-->
<!--                [canLoadMore$]="canLoadMore$"-->
<!--                (loadMore)="loadMore()"-->
<!--                (onScroll)="itemsScrolling = $event"-->
<!--                [statusFilter]="(currentStatusFilter$ | async)!"-->
<!--                [filter]="(dateFilter$ | async) !== null ? 'Range' : undefined"-->
<!--            ></app-items-list>-->

            <app-calendar-view class="flex-fill" *ngIf="calendarView$ | async" [selectedTab]="(tab$ | async)!" (dayWithEventsClicked)="calendarDayWithEventsClicked($event)"></app-calendar-view>

        </div>

        <div class="fadeout-padding"></div>
    </div>

    <div class="bottom-button only-mobile-sm">
        <app-menu-button *ngIf="!(calendarView$ | async)"
                         class="menu-button"
                         (click)="openCreateServiceRequestDialog('Lead')"
                         [hideOnScroll]="true"
        ></app-menu-button>
    </div>
    <div class="only-mobile-sm bottom tabs primary-scrollbar">
        <app-tabs
            linePosition="top"
            alignment="around"
            [tabs]="tabs"
            [activeTab]="(tab$ | async)!"
            (onTabSelect)="onTabSelected($event)"
        ></app-tabs>
    </div>
</div>
