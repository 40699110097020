import { Injectable, inject } from '@angular/core';
import { Page, Tab, pagePathToName } from '../models/navigation.model';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter, map, startWith, distinctUntilChanged, shareReplay, take } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { ViewAsService } from "./view-as.service";
import { SupabaseService } from "./supabase.service";

type ProperType = 'leads' | 'estimates' | 'jobs' | 'proposals' | 'invoices';

function isProperType(type: string): type is ProperType {
  return ['leads', 'estimates', 'jobs', 'proposals', 'invoices', 'payments'].includes(type);
}

function findProperType(segments: string[]) {
    for(let i = 0; i < segments.length; i++) {
        if(isProperType(segments[i]))
            return i;
    }
    return -1;
}

export const canViewItemGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {

  const businessService = inject(BusinessService);
  const supabaseService = inject(SupabaseService);
  const viewAsService = inject(ViewAsService);
  const router = inject(Router);
  const selectedUsers = await viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
  if(!selectedUsers)
    return true;

  const segments = state.url.split('?')[0].split('/');

  const typeIndex = findProperType(segments);
  if(typeIndex === -1)
    return false;

  const itemType = segments[typeIndex];
  const workflowId = segments[typeIndex+1];
  const itemId = segments[typeIndex+2];

  const business = await businessService.selectedBusiness$.pipe(take(1)).toPromise();
  const type = itemType.endsWith('s') ? itemType.substring(0, itemType.length - 1) : itemType;

  const canView = await supabaseService.rpcFunc<boolean>(business!.businessId, 'can_open_item', {
    inItemType: type,
    inItemId: +itemId,
    inUserIds: selectedUsers
  });

  if(!canView)
    return router.createUrlTree(['/lobby']);

  return true;
};

@Injectable({
  providedIn: 'root'
})
export class NavigationService<P = Page, T = Tab> {

  url$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(_ => this.router.url),
    startWith(this.router.url),
    distinctUntilChanged(),
    map(url => url.split('?')[0].split('/')),
    shareReplay(1)
  );

  scope$ = this.url$.pipe(
      map(([_, scope, page, tab]) => {
        if(!scope) {
          console.warn('scope is undefined', scope, page, tab);
        }
        return scope;
      }),
      distinctUntilChanged(),
      shareReplay(1)
  );

  page$ = this.url$.pipe(
    map(([_, scope, page, tab]) => {
      if(!page) {
        console.warn('page is undefined', scope, page, tab);
        return page as P;
      }
      return pagePathToName(page) as P;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  tab$ = this.url$.pipe(
    map(([scope, lobby, page, tab]) => {
      if(!tab) {
        if(scope === 'lobby' || scope === 'reports')
          console.warn('tab is undefined', lobby, page, tab);
        return '' as T;
      }
      return pagePathToName(tab) as T;
    }),
    distinctUntilChanged(),
  );

  isSingleUserPage$ = this.url$.pipe(
    map(([_, base, tab]) => {
      if(base === 'settings')
        return true;
      if(base === 'lobby' && (['assignments', 'assigned'].includes(tab)))
        return true;
      return false;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private router: Router,
  ) { 
    // Manual init of url$ is needed because router.events initializes and starts observing after first subscription
    this.url$.pipe(take(1)).toPromise();
  }

  refreshPage(state?: object) {
      // if(this.router.url.startsWith('/lobby')) {
          const url = this.router.url;
          this.router.navigateByUrl('/loading', {skipLocationChange: true}).then(() => {
              this.router.navigate([`/${url}`], { state });
          });
      // } else {
      //     this.router.navigate([`/lobby`], { state });
      // }
  }

}
