<div class="main-add-edit-payment-item">
  <div class="header">
    <h3>{{ headerTitle }} {{ headerType }}</h3>
    <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
  </div>

  <div class="content">
    <div class="payload" *ngIf="data?.type !== 'expense' && data?.type !== 'tech_part'">
      <div class="payload-row" [class.payment-error]="invalidName">
        <div class="payload-label">Payment Method</div>
        <div class="payment-selector"
             [matMenuTriggerFor]="paymentTypes"
             (menuOpened)="menuOpened = true"
             (menuClosed)="menuOpened = false"
        >
          <div class="selected-type">{{ selectedPaymentType !== 'cash_app' ? capitalize(selectedPaymentType) : 'Cash App' }}</div>
          <app-icon class="se-icon-button no-bg no-hover chevron" [class.opened]="menuOpened" src="cheveron_down"></app-icon>
        </div>
      </div>
      <div class="payload-row" [class.payment-error]="invalidAmount || overAmount">
        <div class="payload-label">Amount</div>
        <div class="amount-field">
          <mat-form-field appearance="outline">
            <div class="d-flex">
              <div class="d-flex align-items-center mr--4">$</div>
              <input matInput
                     [(ngModel)]="inputAmount"
                     inputmode="decimal"
                     mask="separator.2" thousandSeparator="" decimalMarker="."
                     [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                     (keydown)="decimalOnly($event)"
                     (paste)="onPaste($event)"
                     replaceCommaWithDot
              >
            </div>
            <div *ngIf="invalidAmount" class="payment-error">Amount is required</div>
            <div *ngIf="overAmount" class="payment-error">Amount exceeds proposal/invoice</div>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="selectedPaymentType !== 'cash'">
        <div class="payload-row" [class.payment-error]="invalidConfirmation">
          <div class="payload-label">Confirmation</div>
          <div>
            <mat-form-field appearance="outline">
              <div class="d-flex">
                <input matInput [(ngModel)]="inputConfirmation">
              </div>
              <div *ngIf="invalidConfirmation" class="payment-error">Confirmation is required</div>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="not-payment" *ngIf="data?.type === 'expense' || data?.type === 'tech_part'">
      <div class="w-100" [class.error-frame]="invalidName">
        <div class="label">Description</div>
        <mat-form-field appearance="outline" class="textarea w-100">
          <textarea matInput cdkTextareaAutosize tabindex="1"
                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="2"
                    [(ngModel)]="inputName"
          ></textarea>
          <div *ngIf="invalidName" class="error">Description is required</div>
        </mat-form-field>
      </div>
      <div class="payload-row mt--8" [class.payment-error]="invalidAmount || overAmount">
        <div class="payload-label">{{data.type === 'expense' ? 'Expense' : 'Tech-part'}} Amount</div>
        <div class="amount-field">
          <mat-form-field appearance="outline">
            <div class="d-flex">
              <div class="d-flex align-items-center mr--4">$</div>
              <input matInput
                     [(ngModel)]="inputAmount"
                     tabindex="2"
                     inputmode="decimal"
                     mask="separator.2" thousandSeparator="" decimalMarker="."
                     [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                     (keydown)="decimalOnly($event)"
                     (paste)="onPaste($event)"
                     replaceCommaWithDot
              >
            </div>
            <div *ngIf="invalidAmount" class="payment-error">Amount is required</div>
            <div *ngIf="overAmount" class="payment-error">Amount exceeds proposal/invoice</div>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="button-section">
      <div class="button close" (click)="close()">Close</div>
      <loader-button class="button add-edit" [loading]="loading"
                     [text]="actionButton" (click)="addEditPayment()"
      ></loader-button>
    </div>
  </div>
</div>

<mat-menu #paymentTypes xPosition="before" class="no-margin">
  <ng-template matMenuContent>
    <mat-option (click)="selectedPaymentType = 'cash'">Cash</mat-option>
    <mat-option (click)="selectedPaymentType = 'check'">Check</mat-option>
    <mat-option (click)="selectedPaymentType = 'cash_app'">Cash App</mat-option>
    <mat-option (click)="selectedPaymentType = 'venmo'">Venmo</mat-option>
    <mat-option (click)="selectedPaymentType = 'zelle'">Zelle</mat-option>
    <mat-option (click)="selectedPaymentType = 'transfer'">Bank transfer (offline)</mat-option>
  </ng-template>
</mat-menu>
