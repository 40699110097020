import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, ControlValueAccessor, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { UtilsService } from 'projects/common/src/public-api';
import { map, startWith, shareReplay } from 'rxjs/operators';
import { timeSuggestions} from '../../../../../common/src/lib/services/forms.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TimeInputComponent
    }
  ],
    animations: [
        trigger('showError', [
            transition(':enter', [
                style({top: '-16px', opacity: '0'}),
                animate('0ms 250ms ease', style({top: 0, opacity: '1'}))
            ]),
        ])
    ]
})
export class TimeInputComponent implements ControlValueAccessor, OnDestroy {
  @Input() layout: 'row' | 'column' = 'row';
  @Input() tabindex!: number;
  @Input() disabled = false;
  @Output() changed = new EventEmitter<void>();
  @Output() focusin = new EventEmitter<void>();
  @Output() focusout = new EventEmitter<void>();
  @ViewChild('timeInput') timeInput!: ElementRef;
  @ViewChild('timeTrigger') timeTrigger!: MatAutocompleteTrigger;
  control: AbstractControl | null = null;

  get value() {
    const formData = this.form.getRawValue();
    let date: Date | null = null;
    try {
      date = this.utilsService.parseDate({
        date: moment(0),
        time: formData.time,
        ampm: formData.ampm
      });
    } catch {
      return null;
    }
    if (date?.getHours() === 23 && date.getMinutes() === 59) {
      date = moment(date).endOf('day').toDate();
    }
    return date;
  }

  form = this.formBuilder.group({
    time: new UntypedFormControl(''),
    ampm: new UntypedFormControl({value: 'AM', disabled: true}),
  });
  timeControl = this.form.get('time')!;
  ampmControl = this.form.get('ampm')!;

  touched = false;
  onChange = (value: any) => {};
  onTouched = () => {};

  suggestionsSubscription: Subscription;

  timeSuggestions$ = this.timeControl.valueChanges.pipe(
    map(time => {
      if (!time || time.length === 0) {
        return timeSuggestions;
      }
      return timeSuggestions.filter(suggestion => suggestion.includes(time) && suggestion !== time);
    }),
    startWith(timeSuggestions),
    shareReplay(1)
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
  ) { 
    this.suggestionsSubscription = this.timeSuggestions$.subscribe();
    this.timeControl.markAsTouched();
  }

  ngOnDestroy(): void {
    this.suggestionsSubscription.unsubscribe();
  }

  timeValid() {
    const valid = this.utilsService.checkAndFixTime(this.timeControl, this.ampmControl);
    this.onChange(this.value);
    this.changed.emit();
    return valid;
  }

  onTimeSelected(event: any) {
    this.markAsTouched();
    setTimeout(() => this.timeInput.nativeElement.blur());
    if (event instanceof FocusEvent && (event?.relatedTarget as any)?.localName === 'mat-option') {
      return;
    }
    setTimeout(() => this.timeTrigger.closePanel());
    if (!this.timeValid()) {
      return;
    }
  }

  maskInput(event: Event, controlName: string, mask: string) {
    this.utilsService.maskInput(event.target as HTMLInputElement, this.form.get(controlName)!, mask);
  }

  writeValue(time: Date | moment.Moment | null | undefined) {
    if (!time) {
      this.timeControl.setValue('');
      this.ampmControl.setValue('AM');
      this.onChange(this.value);
      this.changed.emit();
      return;
    }
    const [timeStr, ampmStr] = moment(time).format('hh:mm A').split(' ');
    this.timeControl.setValue(timeStr);
    this.ampmControl.setValue(ampmStr);
    this.onChange(this.value);
    this.changed.emit();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.timeControl.markAsTouched();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.control) {
      this.control = control;
    }
    return null;
  }

  handleBackspace(event: KeyboardEvent): void {
    const inputElement = this.timeInput.nativeElement;
    if (event.key === 'Backspace' && inputElement.selectionStart === inputElement.selectionEnd) {
      const cursorPos = inputElement.selectionStart;
      if (cursorPos > 0 && inputElement.value[cursorPos - 1] === ':') {
        inputElement.setSelectionRange(cursorPos - 1, cursorPos - 1);
      }
    }
  }
}
