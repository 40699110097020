import {
    AfterViewInit, ChangeDetectorRef,
    Component, Inject, OnDestroy, OnInit, Optional, ViewChild,
} from '@angular/core';
import {
    FormBuilder,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { animate, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import {
    RequestInvitationFormComponent
} from "../../components/request-invitation-form/request-invitation-form.component";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { LeadRestore, LeadsService } from "../../services/leads.service";
import { UsersService } from "../../services/users.service";
import { UtilsService } from "../../../../../common/src/lib/services";
import { NotesService } from "../../services/notes.service";
import { map, take } from "rxjs/operators";
import { NoteUpdate } from "../../models/note.model";
import {
    ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { SummaryPreviewModal } from "../summary-preview/summary-preview.component";
import { sameRanges, TimeRange } from "../../../../../common/src/lib/models/time-range.model";
import { EstimateRestore, EstimatesService } from "../../services/estimates.service";
import { ProposalsService } from "../../services/proposals.service";
import { JobRestore, JobsService, JobUserRestore } from "../../services/jobs.service";
import { ActivatedRoute, Router } from "@angular/router";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { JobRequestInvitationFormData } from "../assigned-dialog/assigned-dialog.component";
import { BusinessService } from "../../services/business.service";
import { clientRestoreFromDocument } from "../../../../../common/src/lib/models/client-transform";
import { removePhoneNumberMask } from "../../../../../common/src/lib/utils/phone-number-mask";
import { NotesComponent } from "../../modules/shared/components/notes/notes.component";
import { RequestInvitationFormData, RequestInvitationModalData } from "./request-invitation.model";
import { NavigationService } from '../../services/navigation.service';

export const DATE_FORMATS = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        monthLabel: 'MMMM',
    },
};

@Component({
    selector: 'app-request-invitation',
    templateUrl: './request-invitation.component.html',
    styleUrls: ['./request-invitation.component.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    ],
    animations: [
        trigger('sidePanelShowHide', [
            transition(':enter', [
                style({right: '-100%'}),
                animate(500, style({right: 0}))
            ]),
            transition(':leave', [
                animate(500, style({right: '-100%'}))
            ])
        ])
    ]
})
export class RequestInvitationModal implements OnInit, AfterViewInit, OnDestroy {

    static readonly BREAKPOINT = 'sm';

    readonly = false;

    DIALOG_TYPES = ['Lead', 'Estimate', 'Proposal', 'Job'];
    selectedDialogTypeIndex?: number;

    user$ = this.usersService.currentUser$;
    selectedBusiness$ = this.businessService.selectedBusiness$;
    currentUser$ = this.usersService.currentUser$;
    tabletScreen$ = this.utilsService.onScreenBreakpointChange('sm');
    middleScreen$ = this.utilsService.onScreenBreakpointChange('md');

    selectedTabSubject = new BehaviorSubject<string>('Details');
    availabilityModalVisibleSubject = new BehaviorSubject(false);

    showDetails$ = combineLatest([this.selectedTabSubject, this.availabilityModalVisibleSubject, this.middleScreen$, this.tabletScreen$]).pipe(
      map(([tab, availabilityModalVisible, middleScreen, tabletScreen]) => {
          if (middleScreen)
              return true;
          return tab === 'Details' && (!availabilityModalVisible || tabletScreen);
      })
    );

    showNotes$ = this.selectedTabSubject.pipe(
      map((tab) => {
          return tab === 'Notes & Tasks';
      })
    );

    @ViewChild('formComponent') formComponent!: RequestInvitationFormComponent;
    @ViewChild('notesComponent') notesComponent!: NotesComponent;

    dialogTypeSubject = new BehaviorSubject<'Lead' | 'Estimate' | 'Job' | 'Proposal'>('Lead');
    isLeadDialog = true;
    isEstimateDialog!: boolean;
    isJobDialog!: boolean;
    isProposalDialog!: boolean;

    get showDialogTypeChoice() {
        return this.data?.showDialogTypeChoice ?? false;
    }

    inviting = false;

    get data() {
        return (this.dialogData ?? this.modalsService.data) as RequestInvitationModalData;
    }

    initialNotes: NoteUpdate[] = [];

    formData!: RequestInvitationFormData;
    formAssigneesSubject = new BehaviorSubject<UserProfile[]>([])
    submitButtonText$ = combineLatest([this.tabletScreen$, this.dialogTypeSubject, this.formAssigneesSubject]).pipe(
        map(([largeScreen, dialogType, assignees]) => {
            let text = {
                'Lead': "Assign",
                'Estimate': "Schedule & Send",
                'Job': "Schedule & Send",
                'Proposal': "Create",
            }[dialogType];
            if(this.isEdit && dialogType === 'Lead') {
                text = 'Save';
                const initialAssignees = this.data.initialData!.assignees!;
                if(initialAssignees.length !== assignees.length) {
                    return 'Assign';
                } else {
                    for(const assignee of assignees) {
                        if(this.formComponent.unassignedUsers.has(assignee.id) || !initialAssignees.find(a => a.id === assignee.id)) {
                            return 'Assign';
                        }
                    }
                }
            } else if(text === 'Schedule & Send') {
                if(!largeScreen) {
                    text = 'Send';
                }
            }
            return text;
        })
    );

    assigneesSub?: Subscription;

    constructor(
        private fb: FormBuilder,
        private modalsService: ModalsService,
        private businessService: BusinessService,
        private leadsService: LeadsService,
        private estimatesService: EstimatesService,
        private proposalsService: ProposalsService,
        private jobsService: JobsService,
        private usersService: UsersService,
        private utilsService: UtilsService,
        private notesService: NotesService,
        private navigationService: NavigationService,
        private snackbar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: RequestInvitationModalData,
    ) {}

    ngOnInit() {
        this.readonly = this.data?.readonly ?? false;
        const initialData = this.data.initialData ? {
            ...this.data.initialData,
            assignees: Array.from(this.data.initialData.assignees ?? []),
            ranges: Array.from(this.data.initialData.ranges ?? []),
        } : {};
        if(this.data.changes)
            this.formData = {...initialData, ...this.data.changes};
        else if(this.data.initialData)
            this.formData = initialData;

        (this.data as any).beforeResize = this.saveForm.bind(this);

        if(this.data.openNotes) {
            this.selectedTabSubject.next('Notes & Tasks')
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        if(this.formData?.dialogType) {
            this.setDialogType(this.formData.dialogType);
        }

        if(this.isLeadDialog && !this.isEdit) {
            this.initialNotes = this.notesService.getLocalNotes();
            this.cdr.detectChanges();
        }

        this.assigneesSub = this.formComponent.form.controls.assignees.valueChanges.subscribe(assignees => {
            this.formAssigneesSubject.next(assignees);
        });
    }

    onTabSelected(tab: string) {
        this.selectedTabSubject.next(tab);
        this.data.openNotes = (tab === 'Notes & Tasks');
        if(tab === 'Notes & Tasks') {
            this.notesComponent.createAndFocusNoteIfEmpty();
        }
    }

    setDialogType(type: string) {
        this.dialogTypeSubject.next(type as 'Lead' | 'Estimate' | 'Job' | 'Proposal');
        this.isJobDialog = type === 'Job';
        this.isLeadDialog = type === 'Lead';
        this.isEstimateDialog = type === 'Estimate';
        this.isProposalDialog = type === 'Proposal';
        this.selectedDialogTypeIndex = this.DIALOG_TYPES.indexOf(type);
        if (!this.isJobDialog && this.formComponent.form.controls.assignees.value.length > 1)
            this.formComponent.form.controls.assignees.setValue([this.formComponent.form.controls.assignees.value[0]]);
        this.formComponent.updateDialogType(type);
        this.cdr.detectChanges();
    }

    get headerLeftButtonCaption(): string {
        if (this.isProposalDialog && this.checkFormFilled()) {
            return 'Delete';
        } else if (!this.isEdit) {
            return !this.checkFormFilled() ? 'Close' : 'Delete';
        }
        return 'Close';
    }

    handleUsersAssigned(users: UserProfile[]) {
        let assignees = this.formComponent.form.controls.assignees.value;
        if (this.isJobDialog) {
            for(const user of users) {
                if (!assignees.find(u => u.id === user.id))
                    assignees.push(user)
            }
        } else {
            assignees = [users[0]];
        }
        this.formComponent.form.controls.assignees.setValue(assignees);
    }

    headerLeftButtonClick() {
        this.isEdit || !this.checkFormFilled() ? this.close() : this.deleteRequestConfirm();
    }

    get isEdit() {
        if(!this.data)
            return false;
        if(!this.data.initialData)
            return false;
        if(!this.data.changes)
            return true;
        return this.data.changes.dialogType === this.data.initialData.dialogType;
    }

    get isReschedule() {
        if(!this.data)
            return false;
        if(!this.data.initialData || !this.data.changes || !this.data.changes.ranges)
            return false;
        if(['Lead', 'Proposal'].includes(this.data.initialData.dialogType!))
            return false;
        const initialRanges = this.data.initialData.ranges!;
        const changesRanges = this.data.changes.ranges;
        return !sameRanges(initialRanges, changesRanges);
    }

    get isIOS() {
        return this.utilsService.isIOS();
    }

    async close() {
        this.notesService.clearLocalNotes();
        // if (this.data?.afterClose && eventId) {
        //     this.data.afterClose(eventId);
        // }
        // if (!(this.data.isEdit || this.data.isReschedule) || (this.data.initialRequest?.docType === 'Lead' && !this.isLeadDialog)) {
        //     if (this.isProposalDialog && eventId) {
        //         this.router.navigate(['/proposals', eventId]);
        //     } else if (this.isJobDialog || this.isEstimateDialog) {
        //         const path = `/${this.dialogType.toLocaleLowerCase()}s`;
        //         if (!this.isReschedule) {
        //             if (eventId) {
        //                 await this.router.navigate([path, eventId]);
        //             } else if (this.isEdit && this.data?.initialRequest) {
        //                 const id = this.data.initialRequest!.id;
        //                 await this.router.navigate([path, id]);
        //             }
        //         }
        //     }
        // }
        return this.modalsService.close();
    }

    showEditHeader$ = this.dialogTypeSubject.pipe(
        map(dialogType => {
            if(!this.data)
                return false;
            if(!this.data.initialData)
                return false;
            return dialogType === this.data.initialData.dialogType;
        })
    );

    checkFormFilled() {
        if (!this.formComponent) {
            return false;
        }
        const notes = this.notesService.localNotes;
        const note = notes[0]!;
        const hasNotes = notes.length > 1 || (notes.length === 1 && ( note.text && note.text.length > 0));

        let isAnyFieldFilled =
          !!(this.formComponent.jobTypeControl.value ||
            this.formComponent.clientControl.get('firstName')!.value ||
            this.formComponent.clientControl.get('lastName')!.value ||
            this.formComponent.clientControl.get('phoneNumber')!.value ||
            this.formComponent.clientControl.get('email')!.value ||
            this.formComponent.clientControl.get('address')!.value ||
            this.formComponent.clientControl.get('unit')!.value ||
            this.formComponent.clientControl.get('extNumber')!.value ||
            this.formComponent.form.controls.assignees.value.length > 0 ||
            hasNotes);

        if (!this.isProposalDialog) {
            isAnyFieldFilled = isAnyFieldFilled ||
              !!(this.formComponent.rangesArray.length > 1 ||
                this.formComponent.rangesArray.at(0).get('date')!.value ||
                this.formComponent.rangesArray.at(0).get('timeStart')!.value ||
                this.formComponent.rangesArray.at(0).get('timeEnd')!.value);
        }

        if (this.formComponent.isClientBusiness) {
            isAnyFieldFilled = isAnyFieldFilled || !!this.formComponent.clientControl.get('businessName')!.value;
        }

        return isAnyFieldFilled;
    }

    getFixedFormData() {
        const formValue = this.formComponent.form.value;
        formValue.client.phoneNumber = removePhoneNumberMask(formValue.client.phoneNumber);
        this.dialogTypeSubject.next(formValue.dialogType!);
        const data = {
            ...formValue,
            assignees: formValue.assignees,
            ranges: this.getRanges() ?? undefined
        };
        data.client.type = data.client.type?.toLowerCase() ?? 'personal';
        if (data.client.type !== 'business') {
            delete data.client.businessName;
        }
        if(data.dialogType === 'Job') {
            (data as JobRequestInvitationFormData).unassignedUserIds = Array.from(this.formComponent.unassignedUsers);
        }
        return data;
    }

    saveForm() {
        this.data.changes = this.getFixedFormData();
    }

    async openPreviewModal() {
        const valid = this.formComponent.isFormValid();
        if (!valid) {
            return;
        }
        this.saveForm();
        const data = {...this.data};
        this.modalsService.open(SummaryPreviewModal, {
            behavior: ModalBehavior.Auto,
            breakpoint: SummaryPreviewModal.BREAKPOINT,
            disableClose: true,
            data: {
                formData: {...this.data.changes, ranges: this.getFutureRanges()},
                mobileHeaderTitle: this.formComponent.form.get('dialogType')?.value,
                mobileHeaderLeftButtonCaption: 'Close',
                mobileHeaderRightButtonCaption: await this.submitButtonText$.pipe(take(1)).toPromise(),
            },
            panelClass: 'dialog-full-height',
            prevComponentMetadata: {
                componentClass: RequestInvitationModal,
                modalBehavior: ModalBehavior.Auto,
                data: { ...data, openNotes: this.selectedTabSubject.value === 'Notes & Tasks' },
                breakpoint: RequestInvitationModal.BREAKPOINT
            }
        });
    }

    getRanges() {
        return [...this.formComponent.pastRanges, ...this.getFutureRanges()];
    }

    getFutureRanges() {
        const ranges: TimeRange[] = [];
        for (let i = 0; i < this.formComponent.rangesArray.controls.length; i += 1) {
            const range = this.formComponent.getRange(i);
            if (!range)
                break;

            ranges.push({
                startTime: range.startTime,
                endTime: range.endTime
            });
            if (this.isLeadDialog)
                return ranges;
        }
        return ranges;
    }

    deleteRequestConfirm() {
        this.saveForm();
        const data = {
            ...this.data,
            deleteRequest: this.isEdit
        };
        const url = 
        this.modalsService.open(ConfirmationDialog, {
            behavior: ModalBehavior.Dialog,
            disableClose: true,
            data: {
                title: 'Delete',
                message: this.isEdit && !this.isLeadDialog
                    ? `Are you sure you want to delete this ${this.dialogTypeSubject.value.toLowerCase()}?`
                    : this.isEdit && this.isLeadDialog
                    ? `Deleting this lead will delete the ${this.dialogTypeSubject.value.toLowerCase()} of the assignee as well.`
                    : 'Are you sure you want to delete all the information in the form and notes?',
                actionTitle: 'Delete',
                action: async () => {
                    if(this.data?.onDelete) {
                        this.data.onDelete();
                    }

                    let restoreData: any;

                    if (data.deleteRequest && data.initialData) {
                        const type = data.initialData.dialogType;
                        const id = data.initialData.id;
                        const workflowId = data.initialData.workflowId;
                        restoreData = await this.generateRestoreData(id, workflowId, type as 'Lead' | 'Estimate' | 'Job');

                        if (type === 'Estimate')
                            await this.estimatesService.deleteEstimate(id);
                        else if (type === 'Job')
                            await this.jobsService.deleteJob(id);
                        else if (type === 'Proposal')
                            await this.proposalsService.deleteProposal(id);
                        else
                            await this.leadsService.deleteLead(id);
                        this.navigationService.goBack();
                    }
                    showSnackbar(this.snackbar, {
                        message: data.initialData ? `${data.initialData.dialogType!} deleted` : 'Form discarded',
                        duration: 10000,
                        actionText: 'Undo',
                        action: async () => {

                            if(data.initialData) {
                                await this.restoreDocument(restoreData, data.initialData.dialogType as 'Lead' | 'Estimate' | 'Job');
                                if (['Estimate', 'Job'].includes(data.initialData.dialogType as 'Lead' | 'Estimate' | 'Job'))
                                    await this.router.navigate([`/${data.initialData.dialogType?.toLowerCase()}s/${data.initialData.workflowId}/${data.initialData.id}`]);
                            }

                            this.modalsService.open(RequestInvitationModal, {
                                behavior: ModalBehavior.Auto,
                                breakpoint: RequestInvitationModal.BREAKPOINT,
                                disableClose: true,
                                data
                            });
                        }
                    });
                    this.modalsService.close();
                },
                actionColor: 'warn',
            },
            prevComponentMetadata: {
                componentClass: RequestInvitationModal,
                modalBehavior: ModalBehavior.Auto,
                data: { ...data, openNotes: this.selectedTabSubject.value === 'Notes & Tasks' },
                breakpoint: RequestInvitationModal.BREAKPOINT
            }
        });
    }

    async restoreDocument(restoreData: any, docType: 'Lead' | 'Estimate' | 'Job') {

        switch (docType) {
            case 'Lead':
                return this.leadsService.restoreLead(restoreData);
            case "Estimate":
                return this.estimatesService.restoreEstimate(restoreData);
            case "Job":
                return this.jobsService.restoreJob(restoreData);

        }
    }

    async generateRestoreData(id: number, workflowId: number, docType: 'Lead' | 'Estimate' | 'Job') {
        const notes = await this.notesService.notesObservable(workflowId).pipe(take(1)).toPromise();
        switch (docType) {
            case "Lead":
                const lead = await this.leadsService.getLead(id);
                return {
                    inId: lead.id,
                    inClient: await clientRestoreFromDocument(lead),
                    inCreatedAt: lead.createdAt,
                    inJobType: lead.jobType,
                    inNotes: notes!,
                    inOwnerId: lead.ownerId,
                    inWorkflowId: lead.workflowId
                } as LeadRestore;
            case "Estimate":
                const estimate = await this.estimatesService.getEstimate(id);
                return {
                    inId: estimate.id,
                    inCreatedAt: estimate.createdAt,
                    inClient: await clientRestoreFromDocument(estimate),
                    inOwnerId: estimate.ownerId,
                    inRanges: estimate.ranges,
                    inStatus: estimate.status,
                    inDenied: estimate.denied,
                    inAssignee: estimate.assigneeId,
                    inWorkflowId: estimate.workflowId,
                    inJobType: estimate.jobType,
                    inNotes: notes!
                } as EstimateRestore;
            case "Job":
                const job = await this.jobsService.getJob(id);
                return {
                    inId: job.id,
                    inCreatedAt: job.createdAt,
                    inCreatedBy: job.createdBy,
                    inClient: await clientRestoreFromDocument(job),
                    inRanges: job.ranges,
                    inStatus: job.status,
                    inJobUsers: job.users.map(user => ({
                        id: user.id,
                        assigneeUserId: user.userId,
                        assignedByUserId: user.assignedBy,
                        acceptanceStatus: user.acceptanceStatus
                    }) as JobUserRestore),
                    inStatusStoreForCancel: job.statusStoreForCancel,
                    inWorkflowId: job.workflowId,
                    inJobType: job.jobType,
                    inNotes: notes!
                } as JobRestore;

        }
    }

    async submit() {
        this.inviting = true;
        if (this.formComponent.isFormValid()) {
            const formValue = this.getFixedFormData();
            formValue.ranges = this.isLeadDialog ? this.getFutureRanges() : this.getRanges()!;
            const assigneesChanged = this.assigneesChanged(formValue);

            if(assigneesChanged) {
                if (formValue.assignees!.length === 0) {
                    formValue.assignees!.push(await this.currentUser$.pipe(take(1)).toPromise())
                }
            } else {
                delete formValue.assignees;
            }
            const submitResult = await this.data.onSubmit(formValue as Required<RequestInvitationFormData>);
            if(submitResult === null) {
                this.close();
            }
        } else {
            if (this.selectedTabSubject.value !== 'Details')
                this.selectedTabSubject.next('Details');
        }
        this.inviting = false;
    }

    assigneesChanged(formData: ReturnType<RequestInvitationModal['getFixedFormData']>) {
        if(this.data?.initialData?.assignees && formData.assignees) {
            if(this.data.initialData.dialogType !== formData.dialogType)
                return true;
            const newAssignees = formData.assignees.map(user => user.id);
            const oldAssignees = this.data.initialData.assignees;
            if(newAssignees.length !== oldAssignees.length)
                return true;
            for(const assignee of oldAssignees) {
                if(!newAssignees.includes(assignee.id))
                    return true;
            }
            return false;
        }
        return true;
    }

    clickInForm(event: Event) {
        if(this.availabilityModalVisibleSubject.value) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    ngOnDestroy() {
        this.assigneesSub?.unsubscribe();
    }

}