import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UsersService } from "../../services/users.service";
import { map, startWith, take, tap } from "rxjs/operators";
import { EstimatesService } from "../../services/estimates.service";
import { JobsService } from "../../services/jobs.service";
import { combineLatest, Observable } from "rxjs";
import { Estimate, estimateAcceptanceStatus } from "../../models/estimate.model";
import { Job, jobAcceptanceStatus, JobUser } from "../../models/jobs.model";
import { AcceptanceStatus } from "../../../../../common/src/lib/models";
import { ViewAsService } from "../../services/view-as.service";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import {
  ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ModalBehavior, ModalsService } from 'projects/common/src/lib/services/modals.service';
import { animate, style, transition, trigger } from "@angular/animations";
import { RequestInvitationFormData } from "../request-invitation/request-invitation.model";

interface AssignmentInfoModalData<T extends (Estimate | Job)> {
  id: number;
  docType: T['docType'];
  tileType: 'Assignment';
  initialData?: T
}

export type JobRequestInvitationFormData = RequestInvitationFormData & {
  unassignedUserIds: number[];
}

@Component({
  selector: 'app-assigned-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss'],
  animations: [
    trigger('sidePanelShowHide', [
      transition(':enter', [
        style({right: '-100%'}),
        animate(500, style({right: 0}))
      ]),
      transition(':leave', [
        animate(500, style({right: '-100%'}))
      ])
    ])
  ]
})
export class AssignmentDialogComponent<T extends Estimate | Job> implements OnInit{

  readonly timeFormat = 'MM/dd/YYYY hh:mm a';

  get data(): AssignmentInfoModalData<T> {
    return this.dialogData ?? this.modalsService.data;
  }

  isEstimate = this.data.docType === 'Estimate';
  isJob = this.data.docType === 'Job';

  docType = this.data.docType;
  currentUser$ = this.usersService.currentUser$;

  assignedBy$!: Observable<UserProfile | null>;
  jobUsers$!: Observable<JobUser[]>;

  users$ = this.usersService.users$;
  document$!: Observable<Estimate | Job>;

  assignedUsersChanges$!: Observable<(UserProfile & { acceptanceStatus: AcceptanceStatus })[]>;

  documentAssignees$!: Observable<number[]>;
  unassignedUserIds = new Set<number>();


  constructor(
    private usersService: UsersService,
    private modalsService: ModalsService,
    private estimatesService: EstimatesService,
    private jobsService: JobsService,
    private viewAsService: ViewAsService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: AssignmentInfoModalData<T>
  ) {}

  async ngOnInit() {
     const obs = (this.data.docType === 'Estimate'
        ? this.estimatesService.estimateObservable(this.dialogData.id)
        : this.jobsService.jobObservable(this.dialogData.id)) as Observable<Estimate | Job>;

    this.document$ = (this.data.initialData ? obs.pipe(
        startWith(this.data.initialData)
    ) : obs).pipe(
      tap(data => {
        if (!data)
          this.close();
      })
    );

    this.assignedBy$ = combineLatest([this.viewAsService.selectedUsers$, this.document$, this.users$]).pipe(
        map(([viewAsUsers, document, users]) => {
          let assignedBy: number;
          if(document.docType === 'Estimate') {
            assignedBy = document.ownerId;
          } else if(viewAsUsers === 'All' || viewAsUsers.length > 1) {
            assignedBy = document.users.reduce((acc, curr) => {
              if (acc.assignedAt.getTime() > curr.assignedAt.getTime())
                return acc;
              return curr;
            }, document.users[0]).userId;

          } else { 
            assignedBy = document.users.find(user => user.userId === viewAsUsers[0].id)!.assignedBy;
          }
          return users!.find(user => user.id === assignedBy)!;
        })
    );

    this.assignedUsersChanges$ = combineLatest([this.users$, this.document$, this.assignedBy$]).pipe(
        map(([users, document, assignedBy]) => {
          if(document.docType === 'Estimate') {
            const assignee = users!.find(user => user.id === document.assigneeId);
            if(!assignee)
              return [];
            const acceptanceStatus = this.unassignedUserIds.has(assignee?.id) ? 'pending' : estimateAcceptanceStatus(document);
            return [{ ...assignee, acceptanceStatus }];
          }
          const jobUsers = document.users.map(jobUser => users!.find(user => user.id === jobUser.userId)!);
          const res = jobUsers!.map(user => {
            const acceptanceStatus = this.unassignedUserIds.has(user.id) ? 'pending' : jobAcceptanceStatus(document, user.id);
            return { ...user, acceptanceStatus }
          });
          const removeIndex = res.findIndex(user => user.acceptanceStatus === 'looped_in' && user.id === assignedBy!.id);
          if(removeIndex !== -1) {
            res.splice(removeIndex, 1);
          }
          return res;
        }),
        map(users => {
          const index = users.findIndex(user => user.acceptanceStatus === 'looped_in');
          console.log(index)
          if(index === -1)
            return users;
          const user = users.splice(index, 1)[0];
          return [user, ...users];
        })
    );

    this.documentAssignees$ = this.document$.pipe(
        map(document => {
          if(document.docType === 'Estimate')
            return [document.assigneeId as number];
          return document.users.map(user => user.userId);
        })
    );

    if(this.isJob) {
      this.jobUsers$ = this.document$.pipe(
          map(document => {
            return (document as Job).users;
          })
      );
    }
  }

  close() {
    this.modalsService.close();
  }

  async decline(event: Event) {
    event.stopImmediatePropagation();
    const document = await this.document$.pipe(take(1)).toPromise();
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Deny',
        message: `Are you sure you want to deny this ${document.docType.toLowerCase()}?`,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        actionColor: 'warn',
        action: async () => {
          if (document.docType === 'Estimate') {
            await this.estimatesService.updateEstimate({
              inId: document.id,
              inAssignee: currentUser.id,
              inDenied: true
            });

          } else {
            const jobUser = document.users.find(user => user.userId === currentUser.id)!;
            await this.jobsService.updateJobUserStatus(jobUser.id, 'denied');
          }
        }
      }
    });
  }

  async accept(event: Event) {
    event.stopImmediatePropagation();
    const document = await this.document$.pipe(take(1)).toPromise();
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Accept',
        message: `Are you sure you want to accept this ${document.docType.toLowerCase()}?`,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        actionColor: 'primary',
        action: async () => {
          if (document.docType === 'Estimate') {
            await this.estimatesService.updateEstimate({
              inId: document.id,
              inDenied: false,
              inOwnerId: document.assigneeId!,
              inAssignee: null,
            });

          } else {
            const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
            const jobUser = document.users.find(user => user.userId === currentUser.id)!;
            await this.jobsService.updateJobUserStatus(jobUser.id, 'accepted');
          }
        }
      }
    });
  }

}
