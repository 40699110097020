import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseTileData } from "../base-tile/base-tile.component";
import { NgxMaskService } from "ngx-mask";
import { timer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {  LeadTileData } from "../../../../../common/src/lib/models/lead.model";
import { ViewAsService } from '../../services/view-as.service';

@Component({
  selector: 'app-lead-tile',
  templateUrl: './lead-tile.component.html',
  styleUrls: ['./lead-tile.component.scss']
})
export class LeadTileComponent implements OnInit {

  @Input() document!: LeadTileData;
  @Input() showDocType = false;

  @Output() tileClick = new EventEmitter<LeadTileData>();
  @Output() deleteLead = new EventEmitter<LeadTileData>();
  data!: BaseTileData;

  reverseTimeOrder$ = timer(1000).pipe(
    startWith(0),
    map(_ => {
      return this.document.endTime.getTime() < Date.now();
    })
  );
  startTitle$ = timer(1000).pipe(
    startWith(0),
    map(_ => {
      return this.document.startTime.getTime() < Date.now() ? 'Started' : 'Starts';
    })
  );
  endTitle$ = timer(1000).pipe(
    startWith(0),
    map(_ => {
      return this.document.endTime.getTime() < Date.now() ? 'Ended' : 'Ends';
    })
  );

  showOwner$ = this.viewAsService.selectedUsers$.pipe(map(users => users === 'All' || users.length > 1));

  readonly timeFormat = 'hh:mm a';

  constructor(
      private maskService: NgxMaskService,
      private viewAsService: ViewAsService,
  ) {}

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    const phoneNumber = this.maskService.applyMask(this.document.phoneNumber, '(000) 000-0000');
    this.data = {
      title: this.document.firstName + ' ' + this.document.lastName,
      subtitle: phoneNumber,
      description: this.document.jobType
    };
  }
  async onDeleteClick(event: Event) {
    event.stopPropagation();
    this.deleteLead.emit(this.document);
  }

}
