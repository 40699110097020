import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AcceptanceStatus, Client, dateString, deepReplace, MakeOptional,
  separateItemsByDate,
  UtilsService,
  VisitType
} from 'projects/common/src/public-api';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, filter, map, mergeMap, shareReplay, startWith, take } from 'rxjs/operators';
import { EstimateRestore, EstimatesService, EstimateUpdate } from '../../services/estimates.service';
import { ModalBehavior, ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { ConfirmationDialog } from '../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import {
  RequestInvitationModal
} from '../../modals/request-invitation/request-invitation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { NotesService } from '../../services/notes.service';
import { BusinessService } from '../../services/business.service';
import { TimetableService } from '../../services/timetable.service';
import { sameRanges, TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { ProposalsService } from '../../services/proposals.service';
import { ProposalVersion } from "../../models/proposal.model";
import { InvoicesService } from "../../services/invoices.service";
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { SCREEN_BREAKPOINTS } from 'projects/common/src/lib/directives/SCREEN_BREAKPOINTS';
import { PaymentService } from "../../services/payment.service";
import { NavigationService } from '../../services/navigation.service';
import { JobRestore, JobsService, JobUpdate, JobUserRestore } from '../../services/jobs.service';
import { Estimate } from "../../models/estimate.model";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { clientFromDocument, clientRestoreFromDocument } from "../../../../../common/src/lib/models/client-transform";
import { Job } from '../../models/jobs.model';
import { WorkflowService } from "../../services/workflow.service";
import { ViewAsService } from "../../services/view-as.service";
import { JobRequestInvitationFormData } from "../../modals/assigned-dialog/assigned-dialog.component";
import { ProgressBarStage } from "../../models/progress-bar.model";
import { ProgressBarService } from "../../services/progress-bar.service";
import { ScheduleService } from "../../services/schedule.service";
import { ChatService, unseenIndicatorDebounceMs } from '../../services/chat.service';
import {
  RequestInvitationFormData,
  RequestInvitationModalData
} from "../../modals/request-invitation/request-invitation.model";
import { ChatComponent } from "../../components/chat/chat.component";

const tabs = ['Info', 'Notes', 'Chat'] as const;
const sideTabs = tabs.slice(1);
type MainTab = typeof tabs[number];
type SideTab = Exclude<MainTab, 'Info'>;

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly timeFormat = 'hh:mm a';

  @ViewChild('jobTypeInput') jobTypeInput!: ElementRef;
  @ViewChild('contentDetails') contentDetails!: ElementRef;
  @ViewChild('chat') chat!: ChatComponent;

  showBottomButtons = true;
  contentScrollThreshold = 0;
  jobType?: string;

  requestId = +this.route.snapshot.params['requestId'];
  workflowId = +this.route.snapshot.params['workflowId'];
  requestType = this.getRequestType() as any as VisitType;
  dialogTitle = `${this.requestType} info`;
  submitStatesJob = ['Submitted', 'Pending', 'Canceled'];
  submitStatesEstimate = ['Submitted', 'Canceled'];

  resizeObserver: ResizeObserver | null = null;

  progressBar$ = this.progressBarService.progressBarObservable(this.workflowId);

  get properObservable(): Observable<Estimate | Job | null> {
    return this.requestType === 'Estimate'
        ? this.estimatesService.estimateObservable(this.requestId, 'event_info')
        : this.jobsService.jobObservable(this.requestId, 'event_info');
  }

  currentUser$ = this.usersService.currentUser$;
  selectedBusiness$ = this.businessService.selectedBusiness$;

  request$ = this.properObservable.pipe(
    map(request => {
      if (!request) {
        this.modalsService.close();
        this.router.navigate(['/lobby']);
        return;
      }
      if(this.jobTypeControl.value !== request.jobType) {
        if(this.jobTypeFocused) {
          this.lastIgnoredJobType = request.jobType;
        } else {
          this.jobTypeControl.setValue(request.jobType ?? '', {emitEvent: false});
        }
      }
      setTimeout(_ => {
        this.zone.run(_ => {});
      }, 20);
      return request;
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  assignedBy$ = combineLatest([this.usersService.users$, this.request$, this.viewAsService.selectedUsersIds$]).pipe(
    map(([users, req, selectedUsers]) => {
      const request = req!;
      if(request && request.docType === 'Estimate') {
        return [users!.find(user => user.id === request.ownerId)!];
      } else {
        const assignedByIds = (selectedUsers === null
            ? request.users
            : request.users.filter(user => selectedUsers.includes(user.userId)))
            .map(user => user.userId);
        return users!.filter(user => assignedByIds.includes(user.id));
      }
    })
  );

  ownerOrLastAssignedBy$ = combineLatest([this.request$, this.usersService.users$]).pipe(
      map(([request, users]) => {
        if(!request)
          throw new Error('Shouldn\'t be able to reach this since we check for nullability in this.request$');
        if(request.docType === 'Estimate') {
          return users!.find(user => user.id === request.ownerId)!;
        }
        let lastUser = request.users[0];
        for(const user of request.users) {
          if(user.assignedAt.getTime() > lastUser.assignedAt.getTime())
            lastUser = user;
        }
        return users!.find(user => user.id === lastUser.assignedBy)!;
      })
  );

  assignees$: Observable<{user: UserProfile, acceptanceStatus: AcceptanceStatus}[]> = combineLatest([this.currentUser$, this.usersService.users$, this.request$, this.ownerOrLastAssignedBy$]).pipe(
      filter(([currentUser, users, request, ownerOrLastAssignedBy]) => !!users && !!request),
      map(([currentUser, users, request, ownerOrLastAssignedBy]) => {
        if (!users || !request)
          return [];
        if (request.docType === 'Estimate') {
          const user = users.find((user) => user.id === (request.assigneeId ?? request.ownerId))!;
          const status: AcceptanceStatus = request.assigneeId === null 
            ? 'accepted' 
            : (request.denied ? 'denied' : 'pending');
          return [{user, acceptanceStatus: status}];
        } else {
          const assignees = request.users
              .map(jobUser => {
                const user = users.find((user) => user.id === jobUser.userId)!;
                return {user, acceptanceStatus: jobUser.acceptanceStatus}
              })
              .sort((a, b) => a.user.id === currentUser.id ? -1 : 1);
          const assignedByIndex = assignees.findIndex(a => a.user.id === ownerOrLastAssignedBy.id);
          if(assignedByIndex !== -1 && assignees[assignedByIndex].acceptanceStatus === 'looped_in')
            assignees.splice(assignedByIndex, 1);
          assignees.sort((a, b) => {
            if(a.acceptanceStatus === 'looped_in')
              return -1;
            if(b.acceptanceStatus === 'looped_in')
              return 1;
            return 0;
          });
          return assignees;
        }
      })
  );

  acceptanceStatus$ = combineLatest([this.currentUser$, this.request$]).pipe(
    map(([user, request]) => {
      if (this.requestType === 'Job') {
        return (request as Job).users.find(jobUser => jobUser.userId === user.id);
      }

      // TODO: Check if this needs to be fixed to use all users properly
      else
        return (request as Estimate).status;

    })
  );

  canEdit$ = combineLatest([this.request$, this.viewAsService.selectedUsersIds$]).pipe(
    map(([request, selectedUsers]) => {
      if (!request) {
        return false;
      }
      if (selectedUsers === null)
        return true;

      const creator = request.docType === 'Estimate'
          ? request.ownerId
          : request.createdBy;
      return selectedUsers.includes(creator);
    }),
  );

  tabletScreen$ = this.utilsService.onScreenBreakpointChange('sm');
  largeScreen$ = this.utilsService.onScreenBreakpointChange('lg');
  selectedTabSubject = new BehaviorSubject<MainTab>('Info');
  selectedSideTabSubject = new BehaviorSubject<SideTab>('Notes');
  selectedTab$ = combineLatest([this.largeScreen$, this.selectedTabSubject, this.selectedSideTabSubject]).pipe(
    map(([largeScreen, mainTab, sideTab]) => largeScreen ? sideTab : mainTab)
  );

  showInfo$ = combineLatest([this.largeScreen$, this.selectedTab$]).pipe(
    map(([largeScreen, selectedTab]) => largeScreen || selectedTab === 'Info')
  );

  showNotes$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Notes')
  );

  showChat$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Chat')
  );

  tabs$ = this.largeScreen$.pipe(
    map(largeScreen => (largeScreen ? sideTabs : tabs) as string[])
  );

  requestByDate$ = this.request$.pipe(
    map((estimate) => estimate?.ranges ?? null),
    filter(ranges => !!ranges),
    mergeMap((ranges) => separateItemsByDate<TimeRange>((range) => dateString(range.startTime, range.endTime))(of(ranges!))),
    map((items) => {
      const itemsArray: [string, TimeRange[]][] = [];
      for (const date in items) {
        if (items[date].length !== 0) {
          itemsArray.push([date, items[date].sort((a, b) => a.startTime > b.startTime ? 1 : -1)]);
        }
      }
      return itemsArray.sort((a, b) => a[1][0].startTime > b[1][0].startTime ? 1 : -1);
    })
  );

  chat$ = this.chatService.chatObservable(this.workflowId, true).pipe(shareReplay(1));

  tabsRedDots$ = combineLatest([
    this.chat$.pipe(
      map(chat => !!chat?.hasUnseenMessages),
      startWith(false),
      debounce(hasUnseen => timer(hasUnseen ? unseenIndicatorDebounceMs : 0))
    ),
    this.tabs$
  ]).pipe(map(([chatHasUnseenMessages, tabs]) => tabs.map(tab => tab === 'Chat' && chatHasUnseenMessages)));
  
  chatSubject$ = combineLatest([this.chat$, this.request$]).pipe(
    map(([chat, request]) => chat ? chat.subject : `CN: ${request?.firstName} ${request?.lastName}`)
  );

  jobTypeFocused = false;
  jobTypeControl = new FormControl<string>('', { nonNullable: true });
  jobTypeUpdateSub?: Subscription;
  jobTypeSub?: Subscription;
  lastIgnoredJobType?: string;

  getStatusToggleClass(request: Estimate | Job) {
    let className = 'event-status';
    className += ' ' + (request.docType === 'Estimate' ? 'width--240' : 'width--248');
    className += ' event-status-' + request.status.toLowerCase();
    return className;
  }

  getSelectedStatusIndex(request: Estimate | Job) {
    const statuses = request.docType === 'Estimate' ? this.submitStatesEstimate : this.submitStatesJob;
    return statuses.findIndex(status => status.toLowerCase() === request.status);
  }
  
  async setActiveTab(tab: string) {
    const largeScreen = await this.largeScreen$.pipe(take(1)).toPromise();
    largeScreen ? this.selectedSideTabSubject.next(tab as SideTab) : this.selectedTabSubject.next(tab as MainTab);
  }

  blockContentScrollHandle = false;
  scrollSubscription?: Subscription;

  bottomScrollOffset = this.utilsService.isIOS() ? 100 : 1;

  constructor(
    private modalsService: ModalsService,
    private estimatesService: EstimatesService,
    private jobsService: JobsService,
    public businessService: BusinessService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private snackbar: MatLegacySnackBar,
    public notesService: NotesService,
    private timetableService: TimetableService,
    private progressBarService: ProgressBarService,
    private proposalsService: ProposalsService,
    private invoicesService: InvoicesService,
    public scrollDispatcher: ScrollDispatcher,
    private paymentService: PaymentService,
    private workflowService: WorkflowService,
    private viewAsService: ViewAsService,
    private scheduleService: ScheduleService,
    private navigationService: NavigationService,
    private zone: NgZone,
    private window: Window,
    private chatService: ChatService,
  ) {}

  isLoopedIn(userId: number) {
    return this.request$.pipe(
        map(request => {
          if(request && request.docType === 'Estimate')
            return request.assigneeId;
          else if(request && request.docType === 'Job')
            return !!request.users.find(user => user.userId === userId && user.acceptanceStatus === 'looped_in');
          return false;
        })
    );
  }

  async ngOnInit() {
    this.initJobType();
    this.chat$.pipe(take(1)).toPromise(); // preload chat
  }

  ngAfterViewInit() {
    this.initResizeObserver();
    this.jobTypeInput?.nativeElement?.blur();

    this.scrollSubscription = this.scrollDispatcher.scrolled(100).subscribe(
      (scrollSource) => {
        const contentElement = this.contentDetails.nativeElement;
        if (!scrollSource
          || scrollSource.getElementRef().nativeElement !== contentElement
          || contentElement.scrollHeight - contentElement.clientHeight <= this.contentScrollThreshold
        ) {
          return;
        }

        if (this.blockContentScrollHandle) {
          this.blockContentScrollHandle = false;
          return;
        }
        this.zone.run(() => {
          this.showBottomButtons = scrollSource.measureScrollOffset('bottom') <= this.bottomScrollOffset;
        });
      }
    );
  }

  async initJobType() {
    this.jobTypeSub = this.jobTypeControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000)
    )
    .subscribe(value => {
        if(value.trim() === '') {
          this.jobTypeControl.setValue(this.jobType ?? '', { emitEvent: false });
        } else {
          this.notesService.updateJobType(this.workflowId!, value.trim());
          this.jobType = value;
        }
      }
    );

    this.jobTypeUpdateSub = this.notesService.jobTypeObservable(this.workflowId)
      .subscribe(jobType => {
        if (jobType !== this.jobType?.trim())
          this.jobTypeControl.setValue(jobType!, { emitEvent: !this.jobType });
      }
    );
  }

  onJobTypeUnfocus() {
    this.jobTypeFocused = false;
    if(this.lastIgnoredJobType) {
      this.jobTypeControl.setValue(this.lastIgnoredJobType, { emitEvent: false });
      this.lastIgnoredJobType = undefined;
    }
  }

  ngOnDestroy() {
    this.scrollSubscription?.unsubscribe();
    this.jobTypeUpdateSub?.unsubscribe();
    this.jobTypeSub?.unsubscribe;
    this.resizeObserver?.disconnect();
    this.disconnectResizeObserver();
  }

  async initResizeObserver(){
    const showSummary = await this.showInfo$.pipe(take(1)).toPromise();
    if(!showSummary || !this.contentDetails)
      return;
    const contentElement = this.contentDetails.nativeElement;
    this.resizeObserver = new ResizeObserver(() => {
      if (this.window.innerWidth >= SCREEN_BREAKPOINTS['sm']) {
        this.showBottomButtons = false;
        return;
      }

      const footer = document.querySelector('.footer');
      const modifyButtons = footer?.querySelector('.event-modify-buttons');
      if (footer && modifyButtons) {
        this.contentScrollThreshold = parseInt(getComputedStyle(footer).gap) + modifyButtons.getBoundingClientRect().height;
      }

      const bottomScrollOffset = contentElement.scrollHeight - contentElement.clientHeight - contentElement.scrollTop;
      if (contentElement.scrollHeight - contentElement.clientHeight <= this.contentScrollThreshold) {
        this.showBottomButtons = true;
        return;
      }

      if (bottomScrollOffset > this.contentScrollThreshold * 2) {
        this.showBottomButtons = false;
      }
    });

    this.resizeObserver.observe(contentElement);
  }

  disconnectResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  getRequestType() {
    let requestType = this.route.snapshot.url[0].path.slice(0, -1);
    return requestType.charAt(0).toUpperCase() + requestType.slice(1);
  }

  async close() {
    const from = this.route.snapshot.queryParamMap.get('from');
    if (from === 'customer-list')
      return this.router.navigate([from]);
    return this.router.navigate(['/lobby/schedule', (from === 'estimates' || from === 'jobs') ? from : 'all']);
  }

  async editRequest(request: Estimate | Job) {
    const users = await this.usersService.users$.pipe(take(1)).toPromise();
    const client = clientFromDocument(request);
    const replacedClient = deepReplace(client, null, undefined);
    const assignees = request.docType === 'Estimate'
        ? [request.assigneeId ?? request.ownerId]
        : (request.users.length > 1 ? request.users.filter(u => u.acceptanceStatus !== 'looped_in') : request.users).map(u => u.userId);
    const data: RequestInvitationModalData = {
      initialData: {
        id: request.id,
        workflowId: request.workflowId,
        dialogType: request.docType,
        jobType: request.jobType,
        client: replacedClient,
        assignees: assignees.map(assignee => users!.find(user => user.id === assignee)!),
        ranges: request.ranges,
      },
      onSubmit: ((data: Required<RequestInvitationFormData>) => this.onRequestInvitationSubmit(request, data, false)).bind(this),
    };
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: data
    });
  }

  async onRequestInvitationSubmit(original: Estimate | Job, data: Required<RequestInvitationFormData>, create: boolean) {
    if(create) {
      await this.scheduleService.createDocument(data, original.workflowId);
      return null;
    }
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    if(original.docType === 'Estimate') {
      const updateData: EstimateUpdate = {
        inId: original.id,
        inClient: data.client as Client,
        inJobType: data.jobType,
        inRanges: data.ranges,
        inAssignee: data.assignees ? data.assignees[0].id : original.assigneeId
      };
      if(data.assignees) {
        updateData.inDenied = false;
        if(updateData.inAssignee === currentUser.id)
          updateData.inAssignee = null;
      }
      await this.estimatesService.updateEstimate(updateData);
      const clientEmailChange = original.email !== data.client.email;
      const rangesChanged = !sameRanges(original.ranges, data.ranges);
      if(clientEmailChange || rangesChanged) {
        this.estimatesService.sendEmailForFormData(data, original, !clientEmailChange);
      }
    } else {
      const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
      const reassigned = (data as JobRequestInvitationFormData).unassignedUserIds.filter(id => !!data.assignees.find(u => u.id === id));
      const updateData: JobUpdate = {
        inId: original.id,
        inClient: data.client as Client,
        inJobType: data.jobType,
        inRanges: data.ranges,
        inAssignedBy: currentUser.id,
        inReassignedUserIds: reassigned
      };
      if(data.assignees) {
        updateData.inJobUserIds = data.assignees
            .filter(user => !(data as JobRequestInvitationFormData).unassignedUserIds.includes(user.id))
            .map(user => user.id);
      }
      await this.jobsService.updateJob(updateData);
      const clientEmailChange = original.email !== data.client.email;
      const rangesChanged = !sameRanges(original.ranges, data.ranges);
      if(clientEmailChange || rangesChanged) {
        this.jobsService.sendEmailForFormData(data, original, !clientEmailChange);
      }
    }
    return null;
  }

  async createOrOpenEstimate(request: Estimate | Job, estimateStage: ProgressBarStage) {
    let estimateId = estimateStage.id;
    const workflowId = request.workflowId;
    if(estimateStage.canOpen) {
      await this.router.navigate(['/estimates', workflowId, estimateId]);
      return;
    }

    const data: RequestInvitationModalData = {
      initialData: {
        id: request.id,
        workflowId: request.workflowId,
        dialogType: request.docType,
        jobType: request.jobType,
        client: deepReplace(clientFromDocument(request), null, ''),
        assignees: [],
        ranges: [],
      },
      changes: {
        dialogType: 'Estimate',
      },
      onSubmit: ((data: Required<RequestInvitationFormData>) => this.onRequestInvitationSubmit(request, data, true)).bind(this),
    };
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: data
    });

  }

  async createOrOpenJob(request: Estimate | Job, jobStage: ProgressBarStage) {
    let jobId = jobStage.id;
    const workflowId = request.workflowId;
    if(jobStage.canOpen) {
      await this.router.navigate(['/jobs', workflowId, jobId]);
      return;
    }

    const data: RequestInvitationModalData = {
      initialData: {
        id: request.id,
        workflowId: request.workflowId,
        dialogType: request.docType,
        assignees: [],
        ranges: [],
      },
      changes: {
        jobType: request.jobType,
        dialogType: 'Job',
        client: deepReplace(clientFromDocument(request), null, ''),
        assignees: [],
        ranges: [],
      },
      onSubmit: ((data: Required<RequestInvitationFormData>) => this.onRequestInvitationSubmit(request, data, true)).bind(this),
    };
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: data
    });

  }

  async createOrOpenProposal(request: Estimate | Job, proposalStage: ProgressBarStage) {
    let proposalId = proposalStage.id;
    const workflowId = request.workflowId;
    if(!proposalStage.canOpen) {
      const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
      const client = clientFromDocument(request);

      const res = await this.proposalsService.createProposal({
        inClient: client,
        inWorkflowId: request.workflowId,
        inCreatedBy: user.id,
      });
      proposalId = res.proposalId;
    }
    await this.router.navigate(['/proposals', workflowId, proposalId]);
  }

  async createOrOpenInvoice(request: Estimate | Job, invoiceStage: ProgressBarStage, proposalStage?: ProgressBarStage) {
    let invoiceId = invoiceStage.id;
    const workflowId = request.workflowId;
    if (!invoiceStage.canOpen) {
      const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
      const client = clientFromDocument(request);

      let activeProposalVersion: ProposalVersion | undefined = undefined;
      if (proposalStage && proposalStage.id) {
        const proposal = await this.proposalsService.getProposal(proposalStage.id);
        activeProposalVersion = proposal.versions.find(version => version.id === proposal.activeVersionId);
      }

      const res = await this.invoicesService.createInvoice({
        inClient: client,
        inWorkflowId: request.workflowId,
        inCreatedBy: user.id,
        ...(activeProposalVersion && { inItems: activeProposalVersion.items ?? undefined }),
        ...(activeProposalVersion && { inNote: activeProposalVersion.note ?? undefined }),
        ...(activeProposalVersion && { inDiscountType: activeProposalVersion.discountType ?? undefined }),
      });
      invoiceId = res.invoiceId;
    }
    await this.router.navigate(['/invoices', workflowId, invoiceId]);
  }

  private findNecessaryProposalVersion(versions: ProposalVersion[]): ProposalVersion {
    let proposalVersion!: ProposalVersion;

    versions.forEach(version => {
      if (version.status === 'accepted' || version.status == 'sent') {
        proposalVersion = version;
        return;
      }
    });

    return proposalVersion;
  }

  async deleteClientVisit(type: VisitType, id: number, workflowId: number) {
    switch (type) {
      case "Estimate":
        return this.deleteEstimate(id, workflowId);
      case "Job":
        return this.deleteJob(id, workflowId);
    }
  }

  async deleteRequest() {
    switch (this.requestType) {
      case "Estimate":
        return this.deleteEstimate(this.requestId, this.workflowId);
      case "Job":
        return this.deleteJob(this.requestId, this.workflowId);
    }
  }

  async deleteEstimate(estimateId: number, workflowId: number) {
    const url = this.router.url;
    const router = this.router;
    const notes = await this.notesService.notesObservable(workflowId).pipe(take(1)).toPromise();
    const estimate = await this.estimatesService.getEstimate(estimateId);
    const restoreData: EstimateRestore = {
      inId: estimate.id,
      inCreatedAt: estimate.createdAt,
      inClient: await clientRestoreFromDocument(estimate),
      inOwnerId: estimate.ownerId,
      inRanges: estimate.ranges,
      inStatus: estimate.status,
      inDenied: estimate.denied,
      inWorkflowId: estimate.workflowId,
      inJobType: estimate.jobType,
      inNotes: notes!
    };
    if(estimate.assigneeId)
      restoreData.inAssignee = estimate.assigneeId;

    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      data: {
        title: "Delete",
        message: "Are you sure you want to delete this estimate?",
        actionTitle: "Delete",
        actionColor: 'warn',
        action: async () => {
          await this.estimatesService.deleteEstimate(estimateId);
          await this.close();
          showSnackbar(this.snackbar, {
            message: 'Estimate deleted',
            duration: 10000,
            actionText: 'Undo',
            action: async () => {
              await this.estimatesService.restoreEstimate(restoreData);
              router.navigateByUrl(url);
            }
          });
        }
      }
    });
  }

  async deleteJob(jobId: number, workflowId: number) {
    const url = this.router.url;
    const router = this.router;
    const notes = await this.notesService.notesObservable(workflowId).pipe(take(1)).toPromise();
    const job = await this.jobsService.getJob(jobId);
    const restoreData: JobRestore = {
      inId: job.id,
      inCreatedAt: job.createdAt,
      inCreatedBy: job.createdBy,
      inClient: await clientRestoreFromDocument(job),
      inRanges: job.ranges,
      inStatus: job.status,
      inJobUsers: job.users.map(user => ({
        id: user.id,
        assigneeUserId: user.userId,
        assignedByUserId: user.assignedBy,
        acceptanceStatus: user.acceptanceStatus
      }) as JobUserRestore),
      inWorkflowId: job.workflowId,
      inJobType: job.jobType,
      inNotes: notes!
    };

    if(job.statusStoreForCancel)
      restoreData.inStatusStoreForCancel = job.statusStoreForCancel;

    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      data: {
        title: "Delete",
        message: "Are you sure you want to delete this job?",
        actionTitle: "Delete",
        actionColor: 'warn',
        action: async () => {
          await this.jobsService.deleteJob(jobId);
          await this.close();
          showSnackbar(this.snackbar, {
            message: 'Job deleted',
            duration: 10000,
            actionText: 'Undo',
            action: async () => {
              await this.jobsService.restoreJob(restoreData);
              router.navigateByUrl(url);
            }
          });
        }
      }
    });
  }

  async submitStateChanged(value: string, request: Estimate | Job) {
    if(this.requestType === 'Estimate') {
      await this.estimatesService.updateEstimate({
        inId: request.id,
        inStatus: value.toLowerCase() as Estimate['status']
      });
    } else {

      await this.jobsService.updateJob({
        inId: request.id,
        inStatus: value.toLowerCase() as Job['status']
      });
    }
  }

  // async acceptAssign(request: Estimate | Job, assignee: number) {
  //   this.modalsService.open(ConfirmationDialog, {
  //     data: {
  //       title: `Accept ${this.requestType}`,
  //       message: `After accepting this ${this.requestType.toLocaleLowerCase()} an email will be sent to the customer with the ${this.requestType.toLocaleLowerCase()} detail/timetable.\n\nAre you sure you want to accept this ${this.requestType.toLocaleLowerCase()}?`,
  //       actionTitle: 'YES',
  //       cancelTitle: 'NO',
  //       actionColor: 'primary',
  //       action: async () => {
  //         if (request.docType === 'Estimate') {
  //           await this.estimatesService.updateEstimate({
  //             inId: request.id,
  //             inDenied: false,
  //             inOwnerId: request.assigneeId!,
  //             inAssignee: null,
  //           });
  //         } else {
  //           // TODO: Fix
  //           // const assignees = request.assignees;
  //           // assignees[assignee] = 'Accepted';
  //           // await this.updateClientVisit(this.requestType, this.requestId, {assignees});
  //         }
  //         const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
  //
  //         await this.timetableService.fixEventActivities(request.ranges, [currentUser.id]);
  //       },
  //     }
  //   });
  // }

  // async denyAssign() {
  //   const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
  //   this.modalsService.open(ConfirmationDialog, {
  //     data: {
  //       title: `Deny ${this.requestType}`,
  //       message: `Are you sure you want to deny this ${this.requestType.toLocaleLowerCase()}?`,
  //       actionTitle: 'YES',
  //       cancelTitle: 'NO',
  //       actionColor: 'warn',
  //       action: async () => {
  //         if(this.requestType === 'Estimate') {
  //           await this.estimatesService.updateEstimate({
  //             inId: this.requestId,
  //             inAssignee: currentUser.id,
  //             inDenied: true
  //           });
  //         } else {
  //           // TODO: Fix
  //         }
  //         showSnackbar(this.snackbar, {
  //           message: "Assignment denied",
  //           duration: 10000,
  //           actionText: 'Undo',
  //           action: async () => {
  //             if(this.requestType === 'Estimate') {
  //               await this.estimatesService.updateEstimate({
  //                 inId: this.requestId,
  //                 inAssignee: currentUser.id,
  //                 inDenied: false
  //               });
  //             } else {
  //               // TODO: Fix
  //             }
  //             this.router.navigate([`/${this.requestType.toLocaleLowerCase()}s`, this.requestId]);
  //           },
  //         });
  //         await this.close();
  //       },
  //     }
  //   });
  // }

  async resendClick() {
    const document = (await this.request$.pipe(take(1)).toPromise())!;
    if(document.docType === 'Estimate')
      this.estimatesService.sendEmailForEstimate(document, false);
    else
      this.jobsService.sendEmailForJob(document, false)
    showSnackbar(this.snackbar, {
      message: 'Sent by email'
    });
  }

  get isMobile() {
    return this.utilsService.isMobile();
  }

  sidePanelClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.chat?.onMenuClick(event);
  }

  formatLastName(lastName: string): string {
    if (lastName && lastName.length > 0) {
      return lastName[0].toUpperCase() + '.';
    }
    return '';
  }
}