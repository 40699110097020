import { Injectable } from '@angular/core';
import { AcceptanceStatus, Client, MakeOptional, dateToISOStringWithTimezone } from 'projects/common/src/public-api';
import { BusinessService } from './business.service';
import { ViewAsService } from './view-as.service';
import { LobbyService } from './lobby.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PaymentService } from './payment.service';
import { UsersService } from './users.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { rpcFilter, SupabaseService } from "./supabase.service";
import { Job, JobTileData, JobUser } from "../models/jobs.model";
import { Note } from "../models/note.model";
import { PaginationOptions } from "./pagination.service";
import { JobClientRestore } from "../../../../common/src/lib/models/client-transform";
import { RequestInvitationFormData } from "../modals/request-invitation/request-invitation.model";
import { NgxMaskService } from 'ngx-mask';

export interface JobTimeRangeUpdate {
    id: number;
    startTime?: Date;
    endTime?: Date;
}

export interface JobCreate {
    inCreatedBy: number;
    inClient: MakeOptional<Client, 'salesTaxPercentage'>;
    inRanges: TimeRange[];
    inJobUserIds: number[] | null;
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}
export interface JobUserRestore {
    id: number;
    assigneeUserId: number;
    assignedByUserId: number;
    acceptanceStatus: AcceptanceStatus
}

export interface JobRestore {
    inId: number;
    inCreatedAt: Date;
    inCreatedBy: number;
    inClient: JobClientRestore;
    inRanges: TimeRange[];
    inStatus: Job['status'];
    inJobUsers: JobUserRestore[];
    inStatusStoreForCancel?: Job['status'];
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

interface BaseJobUpdate {
    inId: number;
    inStatus?: Job['status'];
    inJobType?: string;
    inClient?: Client;
    inRanges?: TimeRange[];
}

interface JobUpdateWithUsers {
    inAssignedBy: number;
    inJobUserIds?: number[];
    inReassignedUserIds?: number[];
}

export interface JobEmail {
    schemaName: string,
    itemId: number,
    timeRanges: { start: string, end: string }[],
    organizerName: string,
    businessEmail: string,
    businessName: string,
    clientName: string,
    businessPhoneNumber: string,
    clientEmailAddress: string,
    reschedule: boolean
}

export type JobUpdate = BaseJobUpdate | (BaseJobUpdate & JobUpdateWithUsers);


export function sortJobUsers(a: JobUser, b: JobUser) {
    if(a.acceptanceStatus === 'looped_in')
        return -1;
    if(b.acceptanceStatus === 'looped_in')
        return 1;
    return a.assignedAt.getTime() - b.assignedAt.getTime();
}


export interface JobUserUpdate {
    assigneeUserId: number;
    assignedByUserId: number;
    acceptanceStatus: AcceptanceStatus;
}


export interface JobUserAdd {
    jobId: number;
    workflowId: number;
    assigneeUserId: number;
    assignedByUserId: number;
    acceptanceStatus: AcceptanceStatus;
}

@Injectable({
  providedIn: 'root'
})
export class JobsService {

    constructor(
        protected businessService: BusinessService,
        protected viewAsService: ViewAsService,
        protected supabaseService: SupabaseService,
        protected paymentService: PaymentService,
        protected usersService: UsersService,
        protected lobbyService: LobbyService,
        protected snackbar: MatSnackBar,
        private maskService: NgxMaskService,
    ) {}

    jobObservable(id: number, pref = '') {
        return this.businessService.selectedBusiness$.pipe(
            switchMap(business => {
                return this.supabaseService.rpc<Job[]>({
                    cName: pref + '_job_' + id,
                    schema: business!.businessId,
                    fn: 'get_job_by_id',
                    tables: [
                        { table: 'job', filter: rpcFilter('id', 'eq', id) },
                        { table: 'job_client', filter: rpcFilter('job_id', 'eq', id) },
                        { table: 'job_range', filter: rpcFilter('job_id', 'eq', id) },
                        { table: 'job_user', filter: rpcFilter('job_id', 'eq', id) },
                        { table: 'workflow', filter: rpcFilter('job_id', 'eq', id) }
                    ],
                    options: { inId: id }
                },
                (changes, items) => {
                    if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
                        return null;
                    items[0].jobType = (changes.new as any).jobType;
                    return items;
                }).pipe(
                    map(jobs => {
                        if(!jobs || jobs.length === 0)
                            return null;
                        const job = jobs[0];
                        job.users.sort(sortJobUsers);
                        job.docType = 'Job';
                        return job;
                    })
                );
            }),
            switchMap(async job => {
                if (!job)
                    return null;
                const userId = (await this.usersService.currentUser$.pipe(take(1)).toPromise()).id;
                const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
                if (job.createdBy === userId || job.users.find(user => user.userId === userId))
                    return job;
                const canOpen = await this.supabaseService.rpcFunc<boolean>(businessId, 'can_open_item', {
                    inItemType: 'job',
                    inItemId: id,
                    inUserIds: [userId]
                });
                return canOpen ? job : null;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    jobsObservable(options: PaginationOptions, pref = '') {
        return this.businessService.selectedBusiness$.pipe(
            // TODO: This can be improved by adding the changes handler to handle each change separately and not to pull the list again.
            switchMap(business => {
                return this.supabaseService.rpc<JobTileData[]>({
                    cName: pref + '_jobs',
                    schema: business!.businessId,
                    fn: 'get_jobs',
                    tables: [
                        { table: 'job' },
                        { table: 'job_client' },
                        { table: 'job_range' },
                        { table: 'job_user' },
                        { table: 'workflow' }
                    ],
                    options
                },
                (changes, items) => {
                    if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
                        return null;
                    const data = changes.new as any;
                    const item = items.find(item => data.jobId === item.id);
                    if (item)
                        item.jobType = data.jobType;
                    return items;
                });
            }),
            map(jobs => {
                if(!jobs)
                    return [];
                for(const job of jobs) {
                    if((job as any).users) {
                        (job as any).users.sort(sortJobUsers);
                    }
                    job.docType = 'Job';
                }
                return jobs;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    async getJob(id: number) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const job = (await this.supabaseService.rpcFunc<Job[]>(business!.businessId, 'get_job_by_id', { inId: id }))[0];
        job.users.sort(sortJobUsers);
        job.docType = 'Job';
        return job;
    }

    public async createJob(data: JobCreate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const jobId = await this.supabaseService.rpcFunc<number>(business!.businessId, 'create_job', data);
        const businessOwner = users!.find(user => user.role === 'owner')!;
        const organizerId = data.inCreatedBy;
        const organizer = users!.find(user => user.id === organizerId)!;
        this.sendEmail({
            schemaName: business!.businessId,
            itemId: jobId,
            timeRanges: data.inRanges.map(range => ({
                start: dateToISOStringWithTimezone(range.startTime),
                end: dateToISOStringWithTimezone(range.endTime),
            })),
            organizerName: organizer.firstName,
            businessEmail: businessOwner.email,
            businessName: business!.businessName,
            clientName: data.inClient.firstName,
            businessPhoneNumber: this.applyPhoneNumberMask(business!.phoneNumber),
            clientEmailAddress: data.inClient.email,
            reschedule: false
        });
        return jobId;
    }

    public async updateJob(data: JobUpdate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'update_job', data);
    }

    public async restoreJob(data: JobRestore) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'restore_job', data);
    }

    async updateJobUserStatus(jobUserId: number, acceptanceStatus: AcceptanceStatus) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.update(business!.businessId, 'job_user', jobUserId, { acceptanceStatus });
    }

    public async deleteJob(id: number, showUndoSnackbar?: boolean) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.delete(business!.businessId, 'job', id);
    }

    async sendEmail(data: MakeOptional<JobEmail, 'schemaName' | 'businessEmail' | 'businessName' | 'businessPhoneNumber'>) {
        if(!data.schemaName) {
            const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
            data.schemaName = business.businessId;
            data.businessName = business.businessName;
            data.businessPhoneNumber = this.applyPhoneNumberMask(business.phoneNumber);
        }
        if(!data.businessEmail) {
            const users = await this.usersService.users$.pipe(take(1)).toPromise();
            const owner = users!.find(user => user.role === 'owner')!;
            data.businessEmail = owner.email;
        }
        return this.supabaseService.edgeFunc('send-job-email', data);
    }

    async sendEmailForJob(job: Job, reschedule: boolean) {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const owner = users!.find(user => user.role === 'owner')!;
        const creator = users!.find(user => user.id === job.createdBy)!;
        const data = {
            schemaName: business.businessId,
            itemId: job.id,
            timeRanges: job.ranges.map(range => ({
                start: dateToISOStringWithTimezone(range.startTime),
                end: dateToISOStringWithTimezone(range.endTime),
            })),
            organizerName: creator.firstName,
            businessEmail: owner.email,
            businessName: business.businessName,
            clientName: job.firstName,
            businessPhoneNumber: this.applyPhoneNumberMask(business.phoneNumber),
            clientEmailAddress: job.email,
            reschedule
        }
        return this.sendEmail(data);
    }

    async sendEmailForFormData(formData: Required<RequestInvitationFormData>, original: Job, reschedule: boolean) {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const owner = users!.find(user => user.role === 'owner')!;
        const creator = users!.find(user => user.id === original.createdBy)!;
        const data = {
            schemaName: business.businessId,
            itemId: original.id,
            timeRanges: formData.ranges.map(range => ({
                start: dateToISOStringWithTimezone(range.startTime),
                end: dateToISOStringWithTimezone(range.endTime),
            })),
            organizerName: creator.firstName,
            businessEmail: owner.email,
            businessName: business.businessName,
            clientName: formData.client.firstName ?? original.firstName,
            businessPhoneNumber: this.applyPhoneNumberMask(business.phoneNumber),
            clientEmailAddress: formData.client.email ?? original.email,
            reschedule
        }
        return this.sendEmail(data);
    }

    applyPhoneNumberMask(phoneNumber: string) {
        return this.maskService.applyMask(phoneNumber, '(000) 000-0000');
    }
}
