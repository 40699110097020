import { Injectable } from '@angular/core';
import { combineLatest, Observable } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { BusinessService } from "./business.service";
import { rpcFilter, SupabaseService } from "./supabase.service";
import {
  CurrentTip,
  Payment,
  PaymentItems,
  PaymentSummary, Tip,
} from "../models/payment.model";
import { UsersService } from "./users.service";
import { BusinessProfile } from "../../../../common/src/lib/models/business-profile.model";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

    private selectedBusiness$ = this.businessService.selectedBusiness$.pipe(
        filter(businessProfile => !!businessProfile)
    ) as Observable<BusinessProfile>;

  constructor(
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
    private usersService: UsersService
  ) { }

  paymentSummaryObservable(workflowId: number, paymentId: number): Observable<PaymentSummary> {
    return this.selectedBusiness$.pipe(
      switchMap(
        business => this.supabaseService.rpc<any>({
            cName: `payment_summary_${workflowId}_${paymentId}`,
            schema: business.businessId,
            fn: 'get_payment_summary',
            tables: [
              { table: 'payment', filter: rpcFilter('id', 'eq', paymentId) },
              { table: 'paid', filter: rpcFilter('payment_id', 'eq', paymentId) },
              { table: 'refunded', filter: rpcFilter('payment_id', 'eq', paymentId) },
              { table: 'other', filter: rpcFilter('payment_id', 'eq', paymentId) },
              { table: 'cc_processing_fee', filter: rpcFilter('payment_id', 'eq', paymentId) },
              { table: 'commission', filter: rpcFilter('payment_id', 'eq', paymentId) },
              { table: 'proposal_version', filter: rpcFilter('workflow_id', 'eq', workflowId) },
              'proposal_version_client',
              { table: 'invoice_version', filter: rpcFilter('workflow_id', 'eq', workflowId) },
              'invoice_version_client',
              { table: 'workflow', filter: rpcFilter('id', 'eq', workflowId) },
            ],
            options: { in_payment_id: paymentId }
          },
          (changes, items) => {
            if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
              return null;
            items[0].jobType = (changes.new as any).jobType;
            return items;
          },
          'payment'
        )
      ),
      map(summary => summary[0] as PaymentSummary)
    );
  }

  paymentItemsObservable(workflowId: number, paymentId: number): Observable<PaymentItems> {
      return this.selectedBusiness$.pipe(
          switchMap(
              business => this.supabaseService.rpc<any>({
                      cName: `payment_items_${workflowId}_${paymentId}`,
                      schema: business.businessId,
                      fn: 'get_payment_items',
                      tables: [
                          {table: 'payment', filter: rpcFilter('id', 'eq', paymentId)},
                          {table: 'paid', filter: rpcFilter('payment_id', 'eq', paymentId)},
                          {table: 'refunded', filter: rpcFilter('payment_id', 'eq', paymentId)},
                          {table: 'other', filter: rpcFilter('payment_id', 'eq', paymentId)},
                          {table: 'cc_processing_fee', filter: rpcFilter('payment_id', 'eq', paymentId)},
                          {table: 'commission', filter: rpcFilter('payment_id', 'eq', paymentId)},
                          'user',
                          {table: 'proposal_version', filter: rpcFilter('workflow_id', 'eq', workflowId)},
                          'proposal_version_client',
                          {table: 'invoice_version', filter: rpcFilter('workflow_id', 'eq', workflowId)},
                          'invoice_version_client'
                      ],
                      options: {in_payment_id: paymentId}
                  },
                  _ => null,
                  'payment'
              )
          )
      );
  }

  async updatePayment(workflowId: number, data: Partial<Payment>) {
    // await updateDoc(doc(this.firestore, `payments/${workflowId}`), data);
  }

  getTips(items: PaymentItems): Tip[] {
    const tips: Tip[] = [];
    if (items.tipsSum) {
      let workerCount = 1;
      let tipEligibles: {
        id: number;
        firstName: string;
        lastName: string;
      }[];
      if (items.tipEligibles && items.tipEligibles.length > 0) {
        workerCount = items.tipEligibles.length;
        tipEligibles = items.tipEligibles;
      } else {
        tipEligibles = [{
          id: items.invoiceCreator!,
          firstName: items.invoiceCreatorFirstName!,
          lastName: items.invoiceCreatorLastName!
        }];
      }

      const tipPerWorker = items.tipsSum ? (Math.floor((items.tipsSum / workerCount) * 100) / 100) : 0;

      const stillEligibles: number[] = [];

      tipEligibles.forEach(worker => {
        const currentTip = items
          .currentTips?.find(tip => tip.userId === worker.id);

        if (currentTip)
          stillEligibles.push(currentTip.userId);

        tips.push({
          userId: worker.id,
          firstName: worker.firstName,
          lastName: worker.lastName,
          amount: tipPerWorker,
          paid: currentTip?.paid ?? 0
        });
      });

      items.currentTips?.forEach(tip => {
        const stillEligible = stillEligibles.find(se => se === tip.userId);
        if (!stillEligible)
          tips.push({
            userId: tip.userId,
            firstName: tip.firstName,
            lastName: tip.lastName,
            amount: 0,
            paid: tip.paid
          });
      });
    }

    return tips;
  }

  paymentObservable(workflowId: number, paymentId: number): Observable<Payment> {
    return combineLatest([
      this.paymentSummaryObservable(workflowId, paymentId),
      this.paymentItemsObservable(workflowId, paymentId)
    ]).pipe(
      map(([summary, items]) => {
        return {
          docType: 'Payment',
          id: paymentId,
          paymentSummary: summary,
          paymentItems: items,
          tips: this.getTips(items)
        }
      })
    );
  }

  async addInstance(
    instance: any,
    type: 'expense' | 'tech_part' | 'payment',
    workflowId: number,
    paymentId: number
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();

    (instance as any).workflowId = workflowId;
    (instance as any).paymentId = paymentId;
    (instance as any).createdBy = user.id;

    await this.supabaseService.insert(
      business!.businessId,
      type === 'expense' || type === 'tech_part' ? 'other' : 'paid',
      instance as any
    );
  }

  async editInstance(
    instance: any,
    type: 'expense' | 'tech_part' | 'payment',
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.update(
      business!.businessId,
      type === 'expense' || type === 'tech_part' ? 'other' : 'paid',
      instance.id,
      instance
    );
  }

  async deleteInstance(
    id: number,
    which: 'paid' | 'other'
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.delete(
      business!.businessId,
      which,
      id
    );
  }

  // chargebacksObservable(paymentObservable: Observable<Payment | null>): Observable<Refunded[]> {
  //   return paymentObservable.pipe(
  //     map(payment => {
  //       if (!payment)
  //         return [];
  //
  //       const mergedItems = [ ...payment.refundeds.filter(refunded => refunded.type === 'chargeback') ];
  //       return mergedItems.sort((a, b) => a.paidAt?.getTime() - b.paidAt?.getTime());
  //     })
  //   );
  // }

  async markTechPart(
    id: number,
    paid: boolean
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.update(
      business!.businessId,
      'other',
      id,
      {
        paid,
        paidAt: new Date()
      }
    );
  }

  async cancelOrRestore(id: number, canceled: boolean) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.update(
      business!.businessId,
      'payment',
      id,
      {
        canceled
      }
    );
  }

  async updateCommissionAmount(
    id: number,
    amount: number
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.update(
      business!.businessId,
      'commission',
      id,
      {
        paid: amount
      }
    );
  }

  async updateCurrentTips(
    id: number,
    tip: Tip,
    currentTips: CurrentTip[],
    edit?: number
  ) {
    let existing = false;
    currentTips.forEach(currentTip => {
      if (tip.userId === currentTip.userId) {
        currentTip.paid = edit ?? tip.amount;
        existing = true;
      }
    });

    if (!existing)
      currentTips.push({
        userId: tip.userId,
        paid: edit ?? tip.amount,
        firstName: tip.firstName,
        lastName: tip.lastName
      });

    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.update(
      business!.businessId,
      'payment',
      id,
      {
        tips: currentTips
      }
    );
  }

  // todo: delete temporary refund add function
  async addRefund(
    amount: number,
    transactionNumber: string,
    workflowId: number,
    paymentId: number,
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.insert(
      business!.businessId,
      'refunded',
      {
        createdAt: new Date(),
        amount,
        workflowId,
        paymentId,
        type: 'refunded',
        lastDigits: 4321,
        transactionNumber,
        refundedAt: new Date()
      }
    );
  }
  
  async getWorkflowLastDocumentClientName(workflowId: number) {
    const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
    const res = await this.supabaseService.supabase
        .schema(businessId)
        .rpc('get_workflow_last_document_client_name', frontToServerTranslation({ inWorkflowId: workflowId }));
    return res.error || !res.data ? '' : res.data as string;
}
}
