<div *ngIf="data" class="main-create-edit-workflow overflow-scroll" cdkScrollable>

  <div class="header">
    <div class="gap--8 d-flex align-items-center">
      <h3>{{title}}</h3>
      <div *ngIf="edited" class="edited" (click)="editedTooltip.show()">
        <div>Edited</div>
        <app-icon src="question-mark-tooltip"
                  matTooltip="This item was changed from its original state in the item list"
                  [matTooltipShowDelay]="300"
                  matTooltipClass="header-tooltip"
                  class="scale--85"
                  #editedTooltip="matTooltip"
                  [hideTooltipOnScroll]="editedTooltip"
        ></app-icon>
      </div>
      <div *ngIf="notExisting" class="edited not-existing" (click)="warningTooltip.show()">
        <div>Warning!</div>
        <app-icon src="question-mark-tooltip"
                  matTooltip="Deleted from item list"
                  [matTooltipShowDelay]="300"
                  matTooltipClass="warning-tooltip"
                  class="scale--85"
                  #warningTooltip="matTooltip"
                  [hideTooltipOnScroll]="warningTooltip"
        ></app-icon>
      </div>
    </div>
    <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
  </div>

  <div class="content">
    <form [formGroup]="form">
      <div class="d-flex flex-column" [class.error]="nameError">
        <h5>Name</h5>
        <mat-form-field appearance="outline" class="textarea" appFormFieldFilled *ngIf="!selectedItem && data.mode !== 'edit'">
          <textarea #nameField
                    matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
                    formControlName="name" tabindex="1"
                    (click)="scrolledToTop = true"
                    [matAutocomplete]="autocomplete"
                    (focus)="focus$.next('name')"
                    (focusout)="focus$.next('unfocused')"
          ></textarea>
          <div *ngIf="nameError" class="input-error textarea">Item not found</div>
          <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="nameSelected($event)">
            <mat-option *ngFor="let nameSuggestions of nameSuggestions$ | async" [value]="nameSuggestions.name">
              {{nameSuggestions.name + nameSuggestions.price}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="selectedItem || data.mode === 'edit'" class="restricted-copy">
          {{form.get('name')?.value}}
        </div>
      </div>
      <ng-container *ngIf="data.mode === 'edit' || selectedItem">
        <div class="d-flex flex-column mt--20">
          <h5>Description</h5>
          <div class="restricted-copy">{{form.get('description')?.value}}</div>
        </div>
        <div class="d-flex mt--20">
          <div class="d-flex flex-column w-50">
            <h5>Price</h5>
            <div class="restricted-copy">{{currencify(form.get('price')?.value)}}</div>
          </div>
          <div class="d-flex flex-column w-50">
            <h5>Cost</h5>
            <div class="restricted-copy">{{currencify(form.get('cost')?.value)}}</div>
          </div>
        </div>
      </ng-container>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex gap--8 align-items-center"
             *ngIf="form.get('price')?.value && form.get('price')?.value > 0"
        >
          <div style="color: #1B1B1B;">Set Discount</div>
          <div id="discountField" [class.error]="discountAboveAmount">
            <mat-form-field appearance="outline" appFormFieldFilled>
              <div class="d-flex" *ngIf="form.get('discountType')?.value === 'amount'">
                <div class="d-flex align-items-center mr--2">$</div>
                <input matInput
                       formControlName="discountAmount"
                       tabindex="5"
                       (focus)="focus$.next('discount')"
                       (focusout)="focus$.next('unfocused')"
                       inputmode="decimal"
                       mask="separator.2" thousandSeparator="" decimalMarker="."
                       [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                       (keydown)="decimalOnly($event)"
                       (paste)="onPaste($event)"
                       replaceCommaWithDot
                >
                <div class="inputError" *ngIf="discountAboveAmount">Above item amount</div>
              </div>
              <div class="d-flex" *ngIf="form.get('discountType')?.value === 'percent'">
                <input matInput class="tar"
                       formControlName="discountPercent"
                       tabindex="5"
                       (focus)="focus$.next('discount')"
                       (focusout)="focus$.next('unfocused')"
                       inputmode="decimal"
                       mask="separator.2" thousandSeparator="" decimalMarker="."
                       [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                       (keydown)="decimalOnly($event)"
                       (paste)="onPaste($event)"
                       replaceCommaWithDot
                >
                <div class="d-flex align-items-center ml--2" style="color: #1B1B1B;">%</div>
                <div class="inputError" *ngIf="discountAboveAmount">Above item amount</div>
              </div>
              <div class="discount-divider"></div>
              <app-icon src="change" class="ml--4 clickable" (click)="toggleDiscountType()"></app-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="other-discount-value" [class.danger]="temporaryDiscountValue > +form.get('price')?.value"
             *ngIf="temporaryDiscountValue > 0 && form.get('discountType')?.value === 'percent'"
        >
          ${{(+temporaryDiscountValue).toFixed(2)}}
        </div>
        <div class="other-discount-value" [class.danger]="temporaryDiscountValue > 100"
             *ngIf="temporaryDiscountValue > 0 && form.get('discountType')?.value === 'amount'"
        >
          {{(+temporaryDiscountValue).toFixed(2)}}%
        </div>
      </div>
      <div *ngIf="form.get('price')?.value && form.get('price')?.value > 0 && form.get('cost')?.value && form.get('cost')?.value > 0"
           class="markup" [class.negative]="+getMarkupValue() < 0"
      >
        Markup {{getMarkupValue()}}%
      </div>
      <div class="mt--20 d-flex align-items-center justify-content-between"
           *ngIf="form.get('price')?.value && form.get('price')?.value > 0"
      >
        <div class="gap--8 d-flex align-items-center">
          <div class="d-flex align-items-center gap--4" (click)="taxTooltip.show()">
            <div>Sales Tax</div>
            <app-icon src="question-mark-tooltip"
                      matTooltip="Proposals/Invoices are based on the customers zip code"
                      [matTooltipShowDelay]="300"
                      matTooltipClass="header-tooltip"
                      class="scale--85"
                      style="color: #65707C"
                      #taxTooltip="matTooltip"
                      [hideTooltipOnScroll]="taxTooltip"
            ></app-icon>
          </div>
          <div class="tax-select">
            <div
                (click)="form.get('taxable')?.setValue(false)"
                [class.not-selected]="form.get('taxable')?.value === false"
                class="tax-selector"
            >
              0%
            </div>
            <div
                (click)="form.get('taxable')?.setValue(true)"
                [class.selected]="form.get('taxable')?.value === true"
                class="tax-selector"
            >
              {{taxPercentage}}%
            </div>
          </div>
        </div>
        <div *ngIf="form.get('taxable')?.value === true" class="fw-600">${{getSalesTaxValue()}}</div>
      </div>
    </form>
    <div class="divider" *ngIf="form.get('price')?.value && form.get('price')?.value > 0"></div>
    <div class="d-flex justify-content-between fw-600"
         *ngIf="form.get('price')?.value && form.get('price')?.value > 0"
    >
      <div>Total</div>
      <div>${{getTotalValue()}}</div>
    </div>

    <div class="button-wrap" *ngIf="selectedItem && data.mode === 'add'">
      <ng-container *ngIf="checkDiscount()">
        <loader-button class="flex-fill" [loading]="loading" [text]="'Add'" (onClick)="add()"
                       [@fadeIn]="{ value: ':leave', params : { time: '250ms' } }"
        ></loader-button>
      </ng-container>
    </div>
  </div>

</div>
