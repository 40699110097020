import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { LobbyPage, pageNameToPath, ScheduleTab, scheduleTabs, Tab } from '../../models/navigation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepicker } from '@angular/material/datepicker';
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import {
  RequestInvitationModal,
} from "../../modals/request-invitation/request-invitation.component";
import { map, take, tap } from "rxjs/operators";
import { eventStatusFilters, ScheduleService } from "../../services/schedule.service";
import { TimetableDialogComponent } from '../../modals/timetable-dialog/timetable-dialog.component';
import { DateRangeComponent } from "../../../../../common/src/lib/components/date-range/date-range.component";
import { RequestType, UserPersonalEvent } from 'projects/common/src/public-api';
import { NavigationService } from '../../services/navigation.service';
import { LobbyService } from '../../services/lobby.service';
import { DateFilter } from '../../models/date-filter.model';
import { LeadsService } from '../../services/leads.service';
import {
  RequestInvitationFormData,
  RequestInvitationModalData
} from "../../modals/request-invitation/request-invitation.model";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dateRange') dateRange!: DateRangeComponent;

  tabs = Array.from(scheduleTabs);

  tab$: Observable<ScheduleTab> = this.navigationService.tab$;

  tabs$ = this.leadsService.hasLeads$.pipe(
    map((hasLeads) => {
      let pages = Array.from(scheduleTabs);
      if (!hasLeads)
        pages = pages.filter(p => p !== 'Leads');
      return pages as ScheduleTab[];
    })
  );

  filterJobStatusSubject = this.scheduleService.filterJobStatusSubject;
  filterEstimateStatusSubject = this.scheduleService.filterEstimateStatusSubject;

  currentStatusFilter$ = combineLatest([this.tab$, this.filterEstimateStatusSubject, this.filterJobStatusSubject]).pipe(
    map(([tab, estimatesFilter, jobsFilter]) => {
      switch (tab) {
        case 'Leads':
          return null;
        case 'Estimates':
          return estimatesFilter;
        case 'Jobs':
          return jobsFilter;
        case 'All':
        case 'Unavailable':
          return null;
      }
    })
  );

  currentStatusesForTab$ = combineLatest([this.tab$, this.currentStatusFilter$]).pipe(
    map(([tab, filter]) => {
      if (!(tab === 'Estimates' || tab === 'Jobs')) {
        return null;
      }
      const filters = tab === 'Jobs' ? eventStatusFilters : eventStatusFilters.filter(status => status !== 'Pending');
      return filters.filter(status => status !== filter);
    })
  );

  isMenuOpen = false;
  shouldShowXMark = false;
  dateFilter$ = this.lobbyService.selectedDateRangeForEvent$;

  calendarView$ = this.scheduleService.showCalendarSubject;

  set calendarView(value: boolean) {
    this.scheduleService.showCalendarSubject.next(value);
    this.lobbyService.setSelectedDateRange(value ? this.scheduleService.calendarViewFilter : this.scheduleService.itemsListFilter);
  }

  constructor(
    public navigationService: NavigationService<LobbyPage, ScheduleTab>,
    private leadsService: LeadsService,
    public router: Router,
    private route: ActivatedRoute,
    private modalsService: ModalsService,
    public scheduleService: ScheduleService,
    private cdr: ChangeDetectorRef,
    private lobbyService: LobbyService,
  ) {}

  async ngOnInit() {
    const hasLeads = await this.leadsService.hasLeads$.pipe(take(1)).toPromise();
    if(!hasLeads) {
      const split = this.router.url.split('/');
      const index = split.indexOf('schedule');
      if(split[index + 1] === 'leads') {
        this.router.navigate(['all'], {relativeTo: this.route});
      }
    }
  }

  async onTabSelected(tab: string) {
    if(tab === 'Leads')
      this.calendarView = false;
    const useTab = pageNameToPath(tab as Tab);
    this.scheduleService.setLeadsView(false);
    this.shouldShowXMark = false;
    this.router.navigate([useTab], {relativeTo: this.route});
  }

  openPicker(picker: MatDatepicker<any>) {
    picker.open();
  }

  changeEstimateTileView(event: string) {
    this.calendarView = event === 'Calendar view';
    this.cdr.detectChanges();
  }

  openCreateServiceRequestDialog(requestType: RequestType) {
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: {
        dialogType: requestType,
        showDialogTypeChoice: true,
        onSubmit: async (data) => {
          return this.scheduleService.createDocument(data as Required<RequestInvitationFormData>);
        }
      } as RequestInvitationModalData
    });
  }

  statusFilterSelected(tab: string, status: any) {
    if (tab === 'Estimates')
      this.scheduleService.filterEstimateStatusSubject.next(status);
    else if (tab === 'Jobs')
      this.scheduleService.filterJobStatusSubject.next(status);
    this.navigationService.refreshPage();

    // TODO: Probably needs to be deleted
    if (tab === 'Estimates') {
      this.filterEstimateStatusSubject.next(status);
    } else if (tab === 'Jobs') {
      this.filterJobStatusSubject.next(status);
    }
  }

  openSetScheduleDialog() {
    this.modalsService.open(TimetableDialogComponent, {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  xMarkClicked(event: Event) {
    this.scheduleService.setLeadsView(false);
    this.shouldShowXMark = false;
    event.stopPropagation();
  }

  get isLeadsView$() {
    return this.scheduleService.isLeadsView$;
  }

  selectedRangeChange(event: [Date, Date] | null) {
    const filter = event ? DateFilter.from(event) : null;
    this.setItemsListFilter(filter);
  }

  ngAfterViewInit() {
    // this.scheduleService.filterEstimateStatusSubject.next('All');
    // this.scheduleService.filterJobStatusSubject.next('All');
    this.scheduleService.setLeadsView(false);
    this.cdr.detectChanges();
  }

  getSelectedPage(selectedPage: string | null) {
    if (selectedPage) {
      if (selectedPage === 'Schedule') {
        return 'Schedule';
      }
    }
    return '';
  }

  setItemsListFilter(dateFilter: DateFilter | null) {
    this.scheduleService.itemsListFilter = dateFilter;
    this.lobbyService.setSelectedDateRange(dateFilter);
  }

  calendarDayWithEventsClicked(dateFilter: DateFilter) {
    this.setItemsListFilter(dateFilter);
    this.calendarView = false;
  }

  ngOnDestroy() {
    this.scheduleService.setLeadsView(false);
    this.scheduleService.showCalendarSubject.next(false);
    this.lobbyService.setSelectedDateRange(null);
  }
}
