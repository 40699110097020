import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Invoice, InvoiceVersion } from '../models/invoice.model';
import { InvoicesTab } from '../models/navigation.model';
import { BusinessService } from './business.service';
import { Client } from "../../../../common/src/lib/models";
import { UsersService } from "./users.service";
import { ViewAsService } from './view-as.service';
import { rpcFilter, SupabaseService } from "./supabase.service";
import { Note } from "../models/note.model";
import { PreparedBy } from "../../../../common/src/lib/models/prepared.model";
import { MakeOptional } from 'projects/common/src/public-api';
import { LobbyService } from './lobby.service';
import { DocType } from '../models/doc-type.model';
import { CommonPoIItem } from "../models/poi.model";
import { clientFromInvoiceVersion } from "../../../../common/src/lib/models/client-transform";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

export type TiledInvoice = {
  id: number,
  type: InvoicesTab,
  createdAt: Date,
  name: string,
  opened: boolean,
  docType: DocType
};

export interface InvoiceCreate {
  inCreatedBy: number;
  inClient: MakeOptional<Client, 'salesTaxPercentage'>;
  inWorkflowId?: number;
  inJobType?: string;
  inNotes?: Note[];
  inSignature?: boolean;
  inNote?: string;
  inDiscountType?: 'percent' | 'amount';
  inItems?: CommonPoIItem[];
}

export interface InvoiceRestore {
  inId: number;
  inCreatedAt: Date;
  inVersionId: number;
  inVersionCreatedAt: Date;
  inCreatedBy: number;
  inClient: Client;
  inWorkflowId: number;
  inSignature?: boolean;
  inNote?: string;
  inDiscountType?: 'percent' | 'amount';
  inItems?: CommonPoIItem[];
}

export interface InvoiceVersionCreate {
  inCreatedBy: number;
  inClient: Client;
  inInvoiceId: number;
  inWorkflowId?: number;
  inSignature?: boolean;
  inNote?: string;
  inDiscountType?: 'percent' | 'amount';
  inItems?: CommonPoIItem[];
  inId?: number;
  inCreatedAt?: Date;
}

export function tiledInvoicesForType(type: InvoicesTab) {
  return function filterSent(source$: Observable<Invoice[]>): Observable<TiledInvoice[]> {
    return source$.pipe(
      map((invoices) => {
        const category: TiledInvoice[] = [];

        // invoices.forEach(async invoice => {
        //   category.push({
        //     id: invoice.id,
        //     type: type,
        //     createdAt: invoice.activeCreatedAt!,
        //     name: invoice.activeVersionId!.name,
        //     opened: invoice.activeVersionId!.opened,
        //     docType: 'Invoice'
        //   });
        // });

        return category;
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(
    private usersServices: UsersService,
    private lobbyService: LobbyService,
    protected businessService: BusinessService,
    protected viewAsService: ViewAsService,
    private supabaseService: SupabaseService
  ) {}

  invoiceObservable(invoiceId: number, workflowId: number) {
    return this.businessService.selectedBusiness$.pipe(
      switchMap(business => this.supabaseService.rpc<Invoice[]>({
          cName: 'invoice_' + invoiceId,
          schema: business!.businessId,
          fn: 'get_invoice_by_id',
          tables: [
            { table: 'invoice', filter: rpcFilter('id', 'eq', invoiceId) },
            { table: 'invoice_version', filter: rpcFilter('invoice_id', 'eq', invoiceId) },
            { table: 'invoice_version_client', filter: rpcFilter('invoice_id', 'eq', invoiceId) },
            { table: 'client_invoice', filter: rpcFilter('invoice_id', 'eq', invoiceId) },
            { table: 'workflow', filter: rpcFilter('id', 'eq', workflowId) },
            { table: 'payment', filter: rpcFilter('workflow_id', 'eq', workflowId) },
            { table: 'paid', filter: rpcFilter('workflow_id', 'eq', workflowId) },
            { table: 'refunded', filter: rpcFilter('workflow_id', 'eq', workflowId) },
            { table: 'user' },
          ],
          options: { in_id: invoiceId }
        },
        (changes, items) => {
          if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
            return null;
          items[0].jobType = (changes.new as any).jobType;
          return items;
        }
      )),
      map(invoices => {
        if(!invoices || !invoices[0])
          return null;
        invoices[0].docType = 'Invoice';
        invoices[0].versions.sort((a, b) => a.id - b.id)
        return invoices[0];
      }),
      shareReplay(1)
    );
  }

  async sendInvoice(invoiceVersionId: number) {
    const currentUser = await this.usersServices.currentUser$.pipe(take(1)).toPromise();
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    const res: string = await this.supabaseService.rpcFunc(business!.businessId, 'send_invoice', {
      in_invoice_version_id: invoiceVersionId,
      in_sending_user_id: currentUser.id
    });

    this.sendInvoiceEmail(res, currentUser.id).then();

    console.log('client invoice id: ', res);

    return res;
  }

  async sendInvoiceEmail(clientInvoiceId: string, senderId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    const res = await this.supabaseService.edgeFunc('send-invoice-email', {
      businessId: business!.businessId,
      clientInvoiceId: clientInvoiceId,
      senderId: senderId
    }).then();
  }

  async createInvoice(data: InvoiceCreate) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<{ workflowId: number, invoiceId: number }>(business!.businessId, 'create_invoice', data);
  }

  async updateInvoiceNote(
    invoiceVersionId: number,
    invoicesNoteText: string
  ){
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    await this.supabaseService.supabase
      .schema(business!.businessId)
      .from('invoice_version')
      .update({ note: invoicesNoteText })
      .eq('id', invoiceVersionId);
  }

  async updateInvoiceVersion(
    id: number,
    invoiceVersion: Partial<InvoiceVersion>,
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.update(business!.businessId, 'invoice_version', id, invoiceVersion);
  }

  findHighestVersionId(versions: InvoiceVersion[], notIn?: number[]): number {
    return versions?.reduce((max, version) => {
      return version.id > max && !(notIn?.find(x => x === version.id)) ? version.id : max;
    }, -1);
  }

  async updateInvoiceVersionClient(
    invoiceVersionId: number,
    client: Client
  ) {
    delete (client as any).jobType;
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.update(
      business!.businessId,
      'invoice_version_client',
      {
        key: 'invoice_version_id',
        value: invoiceVersionId
      },
      client
    );
  }

  async createInvoiceVersion(data: InvoiceVersionCreate) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<number>(business!.businessId, 'create_invoice_version', data);
  }

  async duplicateInvoiceVersion(invoice: Invoice, invoiceVersion: InvoiceVersion): Promise<number> {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const user = await this.usersServices.currentUser$.pipe(take(1)).toPromise();
    const data = {
      inClient: {
        address: invoiceVersion.clientAddress,
        businessName: invoiceVersion.clientBusinessName,
        email: invoiceVersion.clientEmail,
        extNumber: invoiceVersion.clientExtNumber,
        firstName: invoiceVersion.clientFirstName,
        lastName: invoiceVersion.clientLastName,
        phoneNumber: invoiceVersion.clientPhoneNumber,
        salesTaxPercentage: invoiceVersion.clientSalesTaxPercentage!,
        type: invoiceVersion.clientType,
        unit: invoiceVersion.clientUnit,
      },
      inCreatedBy: user.id,
      inInvoiceId: invoice.id,
      inWorkflowId: invoice.workflowId,
      inSignature: invoiceVersion.signature,
      inNote: invoiceVersion.note,
      inDiscountType: invoiceVersion.discountType,
      inItems: invoiceVersion.items,
    };
    return this.supabaseService.rpcFunc<number>(business!.businessId, 'create_invoice_version', data);
  }

  async getMaxUid(): Promise<number> {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return (await this.supabaseService.rpcFunc(business!.businessId, 'get_max_invoice_uid')) as number;
  }

  getMaxSubUid(invoice: Invoice): number {
    let maxSubUid = 0;
    invoice.versions.forEach(invoiceVersion => {
      if (invoiceVersion.subUid)
        if (invoiceVersion.subUid > maxSubUid)
          maxSubUid = invoiceVersion.subUid
    })
    return maxSubUid;
  }

  async deleteInvoiceVersion(id: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<boolean>(business!.businessId, 'delete_invoice_version', {
      in_invoice_version_id: id
    });
  }

  async restoreInvoiceVersion(invoiceVersion: InvoiceVersion, invoice: Invoice, lastVersion: boolean) {
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
    const currentUser = await this.usersServices.currentUser$.pipe(take(1)).toPromise();

    if (!lastVersion) {
      const data: InvoiceVersionCreate = {
        inCreatedBy: invoiceVersion.creatorId,
        inClient: clientFromInvoiceVersion(invoiceVersion),
        inInvoiceId: invoice.id,
        inWorkflowId: invoice.workflowId,
        inSignature: invoiceVersion.signature,
        inDiscountType: invoiceVersion.discountType,
        inId: invoiceVersion.id,
        inCreatedAt: invoiceVersion.createdAt
      };
      if(invoiceVersion.note)
        data.inNote = invoiceVersion.note;
      if(invoiceVersion.items)
        data.inItems = invoiceVersion.items;
      return this.createInvoiceVersion(data);

    } else {
      const data: InvoiceRestore = {
        inId: invoice.id,
        inVersionId: invoiceVersion.id,
        inVersionCreatedAt: invoiceVersion.createdAt,
        inCreatedBy: currentUser.id,
        inCreatedAt: invoiceVersion.createdAt,
        inClient: {
          address: invoiceVersion.clientAddress,
          businessName: invoiceVersion.clientBusinessName,
          email: invoiceVersion.clientEmail,
          extNumber: invoiceVersion.clientExtNumber,
          firstName: invoiceVersion.clientFirstName,
          lastName: invoiceVersion.clientLastName,
          phoneNumber: invoiceVersion.clientPhoneNumber,
          salesTaxPercentage: invoiceVersion.clientSalesTaxPercentage!,
          type: invoiceVersion.clientType,
          unit: invoiceVersion.clientUnit,
        },
        inWorkflowId: invoice.workflowId,
        inSignature: invoiceVersion.signature,
        inDiscountType: invoiceVersion.discountType,
      };
      if(invoiceVersion.note)
        data.inNote = invoiceVersion.note;
      if(invoiceVersion.items)
        data.inItems = invoiceVersion.items;

      return this.supabaseService.rpcFunc(business.businessId, 'restore_invoice', frontToServerTranslation(data));
    }
  }

  async cleanUpInvoice(invoiceId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    this.supabaseService.rpcFunc<number>(business!.businessId, 'clean_up_invoice', {
      inInvoiceId: invoiceId
    }).then();
  }

  // private async handleProposal(workflowId: number): Promise<void> {
  //   const proposal = await this.proposalsService.proposalObservable(workflowId, -1).pipe(take(1)).toPromise();
  //
  //   if (proposal) {
  //     if (proposal.activeStatus === null) {
  //       //await this.proposalsService.deleteProposal(proposal);
  //     } else {
  //       for (const version of proposal.versions) {
  //         if (version.status === 'sent' || version.status === 'accepted') {
  //           version.status = 'accepted';
  //           version.accepted = true;
  //           version.signature = false;
  //           await this.proposalsService.updateProposalVersion(version.id, version);
  //
  //           const clientProposal = await this.proposalsService
  //             .relatedClientProposal(version.clientProposalId ?? undefined).pipe(take(1)).toPromise();
  //           if (clientProposal) {
  //             const wasPayment = clientProposal.paidProposalPayment.length > 0;
  //
  //             // await this.proposalsService.updateRelatedClientProposal(clientProposal.id!, {
  //             //   paymentAllowed: false,
  //             //   status: clientProposal.status === 'paid' || wasPayment
  //             //     ? 'paid'
  //             //     : 'accepted',
  //             //   opened: true,
  //             //   signature: null
  //             // });
  //           }
  //         }
  //
  //         if (version.status === 'created') {
  //           version.status = 'deleted';
  //           await this.proposalsService.updateProposalVersion(version.id, version);
  //         }
  //       }
  //     }
  //   }
  // }

  async updateInvoice(invoiceId: number, data: Partial<Invoice>): Promise<void> {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    await this.supabaseService.update(business!.businessId, 'invoice', invoiceId, data);
  }

  async deleteInvoice(invoice: Invoice, versionId?: number | null): Promise<number> {
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
    if (versionId) {
      await this.supabaseService.rpcFunc(business.businessId, 'delete_invoice_version', {
        inInvoiceVersionId: versionId
      });
      return this.findHighestVersionId(invoice.versions);
    } else {
      await this.supabaseService.rpcFunc(business.businessId, 'delete_invoice', {
        inId: invoice.id
      });
      return -1;
    }
  }

  async restoreInvoice(data: InvoiceRestore): Promise<void> {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc(business!.businessId, 'restore_invoice', data);
  }

  invoicePreparedByObservable(invoiceId: number, workflowId: number): Observable<PreparedBy> {
    const userProfile$ = this.invoiceObservable(invoiceId, workflowId).pipe(
      switchMap(invoice => {
        return this.usersServices.userObservable(invoice!.createdBy)
      })
    )
    return combineLatest([this.businessService.selectedBusiness$, userProfile$]).pipe(
      map(([businessProfile, userProfile]) => {
        const preparedBy: PreparedBy = {
          businessId: businessProfile?.businessId,
          businessName: businessProfile?.businessName,
          salesmanName: (userProfile?.firstName + ' ' + userProfile?.lastName).trim(),
          phoneNumber: businessProfile?.phoneNumber!,
          address: businessProfile?.address,
          email: userProfile?.email,
          logo: businessProfile?.logo ?? null,
          salesmanPhoneNumber: userProfile?.directPhoneNumber ?? null
        }
        return preparedBy;
      })
    );
  }

  async getInvoice(id: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const invoice = (await this.supabaseService.rpcFunc<Invoice[]>(business!.businessId, 'get_invoice_by_id', { inId: id }))[0];
    invoice.docType = 'Invoice';
    return invoice;
  }

}
