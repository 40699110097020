<div class="sales-container">
    <div class="sales big-screen" *ngIf="data" [class.no-border]="loading$ | async">
        <div class="skeleton" *ngIf="loading$ | async"></div>
        <ng-container *ngFor="let tabLabel of infoToFill[selectedTab]; let last = last">
            <div class="total-sales" [class.p-paid-paid]="tabLabel.id === 'p.paid.paid'">
                <ng-container *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !==  'c.pending.jobs' && tabLabel.id !==  'c.paid.jobs'"
                        [class.overpay]="redAmount(tabLabel.id, data)"
                        class="currency"
                        [attr.data-id]="tabLabel.id"
                        #tooltip="matTooltip"
                        [matTooltip]="currencify(data[tabLabel.property], true)"
                        [matTooltipShowDelay]="isMobile ? 0 : 500"
                        [matTooltipDisabled]="scales[tabLabel.id] === 'none'"
                        [hideTooltipOnScroll]="tooltip"
                        (click)="tooltip.show()"
                    >
                        {{currencify(data[tabLabel.property], true, scales[tabLabel.id])}}
                    </h3>
                    <h3 *ngIf="(tabLabel.id === 'p.paid.avg_j_d' || tabLabel.id ===  'c.pending.jobs' || tabLabel.id ===  'c.paid.jobs' || tabLabel.id ===  'c.pending.jobs') && tabLabel.id !== 'p.pending.nc'">
                        {{round(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc'">
                        {{data[tabLabel.property]}}
                    </h3>
                </ng-container>
                <small>{{ tabLabel.text }}</small>
            </div>
            <div class="divider-line" *ngIf="!last"></div>
        </ng-container>
    </div>
    <div class="sales small-screen" [class.expand-animation]="showAll" *ngIf="data">
        <div class="skeleton" *ngIf="loading$ | async"></div>
        <ng-container *ngFor="let tabLabel of infoToFill[selectedTab]; let last = last; let i = index">
            <div class="total-sales" [class.p-paid-paid]="tabLabel.id === 'p.paid.paid'" *ngIf="!tabLabel.onlyExpand">
                <small>{{ tabLabel.text }}</small>
                <div class="w-100 overflow-hidden d-flex justify-content-end" *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !==  'c.pending.jobs' && tabLabel.id !==  'c.paid.jobs' && tabLabel.id !==  'c.pending.jobs'"
                        class="currency"
                        [attr.data-id]="tabLabel.id"
                        #tooltip="matTooltip"
                        [matTooltip]="currencify(data[tabLabel.property], true)"
                        [matTooltipShowDelay]="isMobile ? 0 : 500"
                        [matTooltipDisabled]="scales[tabLabel.id] === 'none'"
                        [hideTooltipOnScroll]="tooltip"
                        (click)="tooltip.show()"
                    >
                        {{currencify(data[tabLabel.property], true, scales[tabLabel.id])}}
                    </h3>
                    <h3 *ngIf="(tabLabel.id === 'p.paid.avg_j_d' || tabLabel.id ===  'c.pending.jobs' || tabLabel.id ===  'c.paid.jobs' || tabLabel.id ===  'c.pending.jobs') && tabLabel.id !== 'p.pending.nc'">
                        {{round(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc'">
                        {{data[tabLabel.property]}}
                    </h3>
                </div>
            </div>
            <div class="total-sales" [class.p-paid-paid]="tabLabel.id === 'p.paid.paid'" *ngIf="tabLabel.onlyExpand && showAll">
                <small>{{ tabLabel.text }}</small>
                <div class="w-100 overflow-hidden d-flex justify-content-end" *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !==  'c.pending.jobs' && tabLabel.id !==  'c.paid.jobs' && tabLabel.id !==  'c.pending.jobs'"
                        class="currency"
                        [attr.data-id]="tabLabel.id"
                        #tooltip="matTooltip"
                        [matTooltip]="currencify(data[tabLabel.property], true)"
                        [matTooltipShowDelay]="isMobile ? 0 : 500"
                        [matTooltipDisabled]="scales[tabLabel.id] === 'none'"
                        [hideTooltipOnScroll]="tooltip"
                        (click)="tooltip.show()"
                    >
                        {{currencify(data[tabLabel.property], true, scales[tabLabel.id])}}
                    </h3>
                    <h3 *ngIf="(tabLabel.id === 'p.paid.avg_j_d' || tabLabel.id ===  'c.pending.jobs' || tabLabel.id ===  'c.paid.jobs' || tabLabel.id ===  'c.pending.jobs') && tabLabel.id !== 'p.pending.nc'">
                        {{round(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc'">
                        {{data[tabLabel.property]}}
                    </h3>
                </div>
            </div>
        </ng-container>
        <app-icon class="se-icon-button no-bg no-hover" [class.transform]="showAll" src="cheveron_down" (click)="toggleShowAll()"></app-icon>
    </div>
</div>
