import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Pagination, PaginationOptions } from "../../services/pagination.service";
import { Client } from "../../../../../common/src/lib/models";
import { BehaviorSubject } from "rxjs";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import {
    DynamicListComponent,
    DynamicListEvent,
    PaginationListItem
} from "../../components/dynamic-list/dynamic-list.component";
import { limitStep } from "../../services/items.service";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { BusinessService } from "../../services/business.service";
import { LeadTileComponent } from "../../components/lead-tile/lead-tile.component";
import { SupabaseService } from "../../services/supabase.service";
import { LobbyService } from "../../services/lobby.service";
import { ViewAsService } from "../../services/view-as.service";
import { Lead, LeadTileData, LeadTimeRange } from "../../../../../common/src/lib/models/lead.model";
import { Workflow } from '../../models/workflow.model';

import { ScheduleService, ScheduleTileData } from "../../services/schedule.service";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { dateString } from 'projects/common/src/public-api';

function leadsToPaginationList(leads: LeadTileData[]) {
    return leads.map(lead => ({
        componentClass: LeadTileComponent,
        header: dateString(lead.startTime, lead.endTime),
        args: { document: {...lead, docType: 'Lead'} }
    }));
}

@Component({
    selector: 'app-leads',
    templateUrl: './leads.component.html',
    styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('list') dynamicList!: DynamicListComponent<LeadTileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<LeadTileComponent>;

    pagination!: Pagination<LeadTileData, Client | Workflow | LeadTimeRange>;
    dateFilterPagination!: Pagination<LeadTileData, Client | Workflow | LeadTimeRange>;
    loading$ = new BehaviorSubject<boolean>(true);
    initialItems!: PaginationListItem<LeadTileComponent>[];
    initialFilterItems!: PaginationListItem<LeadTileComponent>[];
    initialFilterCanLoadMoreBottom = true;

    firstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    firstHeader$ = this.firstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    filterFirstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    filterFirstHeader$ = this.filterFirstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));

    dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
        switchMap(async range => {
            if(!range) 
                return null;
            const inLimit = limitStep;
            const options: PaginationOptions = {
                inLimit, 
                inOffset: 0, 
                inStartDate: frontToServerTranslation(range.from), 
                inEndDate: frontToServerTranslation(range.to), 
            };
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if(userIds)
                options.inUserIds = userIds;

            const leads = await this.dateFilterPagination.get(options);
            this.filterFirstHeaderRangeSubject.next(leads.length ? { startTime: leads[0].startTime, endTime: leads[0].endTime } : null);
            this.initialFilterItems = leadsToPaginationList(leads);
            this.initialFilterCanLoadMoreBottom = leads.length >= inLimit;
            return range;
        }),
        shareReplay({ bufferSize: 1, refCount: true})
    );

    constructor(
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private lobbyService: LobbyService,
        private viewAsService: ViewAsService,
        private scheduleService: ScheduleService,
    ) {}

    async ngOnInit() {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<LeadTileData, Client | Workflow | LeadTimeRange>(this.supabaseService, 'get_leads', business.businessId, 'leads')
            .on<Lead, 'UPDATE'>('UPDATE', 'lead', this.handleLeadChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'lead_client', this.handleClientChanges.bind(this))
            .on<LeadTimeRange>('*', 'lead_range', this.handleRangeChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleWorkflowChanges.bind(this));

        this.dateFilterPagination = new Pagination<LeadTileData, Client | Workflow | LeadTimeRange>(this.supabaseService, 'get_leads', business.businessId, 'filtered_leads')
            .on<Lead, 'UPDATE'>('UPDATE', 'lead', this.handleDateFilterLeadChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'lead_client', this.handleDateFilterClientChanges.bind(this))
            .on<LeadTimeRange>('*', 'lead_range', this.handleDateFilterRangeChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleDateFilterWorkflowChanges.bind(this));

        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0 };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const leads = await this.pagination.get(options);
        this.firstHeaderRangeSubject.next(leads.length ? { startTime: leads[0].startTime, endTime: leads[0].endTime } : null);
        this.initialItems = leadsToPaginationList(leads);
        this.setInitialCanLoadMore();
        this.loading$.next(false);
    }

    ngAfterViewInit(): void {
        this.setInitialCanLoadMore();
    }

    setInitialCanLoadMore() {
        if (!this.initialItems || !this.dynamicList)
            return;

        if(this.initialItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(inOffset = 0) {
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const leads = await this.pagination.get(options);
        if(leads.length < inLimit)
            this.dynamicList.canLoadMoreBottom = false;

        this.dynamicList.addAtBottom(
            leadsToPaginationList(leads)
        );

    }

    async loadDateFilterItems(inOffset = 0) {
        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = { inLimit, inOffset, inStartDate: frontToServerTranslation(range!.from), inEndDate: frontToServerTranslation(range!.to) };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const leads = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;
        if(leads.length < inLimit)
            this.dateFilterList.canLoadMoreBottom = false;

        this.dateFilterList.addAtBottom(
            leadsToPaginationList(leads)
        );
    }

    handleLeadChanges(changes: RealtimePostgresChangesPayload<Lead>) {
        this.scheduleService.handleLeadChanges(this.dynamicList, changes, this.firstHeaderRangeSubject);
    }

    handleClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleLeadClientChanges(this.dynamicList, changes);
    }

    handleRangeChanges(changes: RealtimePostgresChangesPayload<LeadTimeRange>) {
        this.scheduleService.handleLeadRangeChanges(this.dynamicList, changes, this.firstHeaderRangeSubject);
    }
    
    handleWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleWorkflowChanges(this.dynamicList, changes);
    }

    handleDateFilterLeadChanges(changes: RealtimePostgresChangesPayload<Lead>) {
        this.scheduleService.handleDateFilterLeadChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject);
    }

    handleDateFilterClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleDateFilterLeadClientChanges(this.dateFilterList, changes);
    }

    handleDateFilterRangeChanges(changes: RealtimePostgresChangesPayload<LeadTimeRange>) {
        this.scheduleService.handleDateFilterLeadRangeChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject);
    }

    handleDateFilterWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleDateFilterWorkflowChanges(this.dateFilterList, changes);
    }

    onItemEvent(event: DynamicListEvent<LeadTileData>) {
        this.scheduleService.onItemEvent(event as DynamicListEvent<ScheduleTileData>);
    }

    ngOnDestroy() {
        this.pagination?.destroy();
        this.dateFilterPagination?.destroy();
    }
}
