import { Injectable } from '@angular/core';
import { of, BehaviorSubject, } from 'rxjs';
import { map, switchMap, take, shareReplay } from 'rxjs/operators';
import { Note, NotesUpdate, NoteUpdate } from '../models/note.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpClient } from '@angular/common/http';
import { UsersService } from "./users.service";
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BusinessService } from './business.service';
import { SupabaseService } from "./supabase.service";
import { ImageViewerComponent } from "../../../../common/src/lib/components/image-viewer/image-viewer.component";
import { TransformOptions } from "@supabase/storage-js/src/lib/types";
import { WorkflowService } from "./workflow.service";

function generateRandomString(): string {
    const length = 10;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

@Injectable({
    providedIn: 'root'
})
export class NotesService {

    currentUser$ = this.usersService.currentUser$;
    localNotes: NoteUpdate[] = [];
    localNotesSubject = new BehaviorSubject<NoteUpdate[]>([]);
    imageUploadingIds: number[] = [];
    localImages: { [name: string]: File } = {};

    constructor(
        private usersService: UsersService,
        private businessService: BusinessService,
        private domSanitizer: DomSanitizer,
        private snackbar: MatSnackBar,
        private http: HttpClient,
        private overlay: Overlay,
        private supabaseService: SupabaseService,
        private workflowService: WorkflowService,
    ) {}

    shouldOpenImageViewer = true;

    notesObservable(workflowId: number) {
        return this.workflowService.workflowObservable(workflowId, 'notes').pipe(
            map(workflow => {
                if(!workflow)
                    return null;
                return workflow.notes;
            })
        );
    }

    jobTypeObservable(workflowId: number) {
        return this.workflowService.workflowObservable(workflowId, 'jobtype').pipe(
            map(workflow => {
                if(!workflow)
                    return null;
                return workflow.jobType;
            })
        );
    }

    showImageViewer(source: SafeUrl | string, viewer: ComponentPortal<ImageViewerComponent>, onClose?: () => void) {
        if (!this.shouldOpenImageViewer) {
            return;
        }
        const overlayRef = this.overlay.create(
            {
                hasBackdrop: true,
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                panelClass: 'image-viewer-overlay'
            },
        );
        const viewerRef = overlayRef.attach(viewer);
        viewerRef.setInput('imageUrl', source);
        const subscription = viewerRef.instance.onClose.subscribe(() => {
            overlayRef.detach();
            viewer.viewContainerRef?.clear();
            subscription.unsubscribe();
            if (onClose) {
                onClose();
            }
        });
    }

    updateFromInLocalNotes(value: string) {
        for (let note of this.localNotes) {
            note.from = value;
        }
    }

    generateImageFile(file: File, businessId: string) {
        const splitName = file.name.split('.');
        const imageName = generateRandomString() + '.' + splitName[splitName.length-1];
        return new File([file], `${businessId}/${imageName}`, {type: file.type});
    }

    async uploadImage(file: File) {
        const res = await this.supabaseService.uploadFile('note_photos', file.name, file);
        return res.data.publicUrl;
    }

    async deleteImages(fileNames: string[]) {
        const res = await this.supabaseService.deleteFile('note_photos', ...fileNames);
        return res.data;
    }

    async updateNotes(businessId: string, workflowId: number, notes: NotesUpdate, deletedNotes: (Note | NoteUpdate)[] = []) {
        for(const note of deletedNotes) {
            (note as NoteUpdate).image = null;
        }
        const uploadPromises: Promise<any>[] = this.prepareImagesForUpload([...notes, ...deletedNotes]);
        uploadPromises.push(this.supabaseService.update(
            businessId,
            'workflow',
            workflowId,
            {notes: notes}
        ));
        await Promise.all(uploadPromises);
    }

    async getImageUrl(note: Note, small?: boolean) {
        if(!note.imageName)
            return null;

        const options: { transform?: TransformOptions } = {};
        if(small) {
            options.transform = {
                width: 72,
                height: 72
            };
        }
        return this.supabaseService.fileUrl('note_photos', note.imageName, options);
    }

    async urlToBlob(url: string): Promise<Blob> {
        const response = await fetch(url);
        return await response.blob();
    }

    clearLocalNotes() {
        this.localNotes = [];
        this.localNotesSubject.next([]);
    }

    getLocalNotes() {
        return this.localNotes;
    }

    prepareImagesForUpload(updateNotes: NotesUpdate) {
        const toDelete: string[] = [];
        const promises: Promise<any>[] = [];
        for(const note of updateNotes) {
            if(note.image === null) {
                toDelete.push(note.imageName!);
                delete note.image;
                delete note.imageName;
            } else if(!!note.image) {
                this.localImages[note.image.name] = note.image;
                if(!!note.imageName) {
                    toDelete.push(note.imageName);
                }
                promises.push(this.uploadImage(note.image));
                note.imageName = note.image.name;
                delete note.image;
            }
        }

        if(toDelete.length > 0)
            promises.push(this.deleteImages(toDelete))
        return promises;
    }

    setLocalNotes(notes: NotesUpdate) {
        this.localNotes = notes;
        this.localNotesSubject.next(notes);
    }

    async imageBlobFromUrl(url: string) {
        return await this.http.get(url, {responseType: 'blob'}).toPromise();
    }

    async updateJobType(workflowId: number, jobType: string) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        await this.supabaseService.update(business!.businessId, 'workflow', workflowId, { job_type: jobType });
    }

}
