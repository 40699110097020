<div class="tile-wrapper" 
    (click)="tileClick.emit(document)"
    [class.disable-delete]="readonly$ | async"
>   
     <app-base-tile
            [data]="data"
    >
        <div class="time" [class.reverse-time]="reverseTimeOrder$ | async" leading>
            <p><span>{{ startTitle$ | async }}:</span><span class="start-value">{{ document.startTime | date: timeFormat }}</span></p>
            <p><span>{{ endTitle$ | async }}:</span><span class="start-value">{{ document.endTime | date: timeFormat }}</span></p>
        </div>
        <div class="info" trailing *ngIf="{ value: readonly$ | async } as readonly">
            <div class="se-button delete-button" (click)="readonly.value ? null : onDeleteClick($event)">Delete</div>
            <div class="status-user-info">
                <app-label class="label" [color]="labelColor">{{ label }}</app-label>
                <ng-container *ngIf="userLabel$ | async let userLabel">
                    <small *ngIf="document.users.length === 1 else withTooltip" class="user-name single-user px--12 py--4 fw-600">{{ userLabel }}</small>
                    <ng-template #withTooltip>
                        <small
                                class="user-name px--12 py--4 fw-600"
                                #tooltip="matTooltip"
                                [matTooltip]="userNames"
                                matTooltipPosition="above"
                                matTooltipClass="mat-tooltip-left-text"
                                [hideTooltipOnScroll]="tooltip"
                        >{{ userLabel }}</small>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </app-base-tile>
</div>