import { UserCommission } from "../../../../business-slick-estimates/src/app/models/user-commissions.model";

export const userPermissions = [
    'mark_commissions',
    'mark_tech_parts',
    'edit_items',
    'access_credit_card',
    'credit_card_refund',
    'mark_tips'
] as const;
export type UserPermission = typeof userPermissions[number];

export type UserRole = 'owner' | 'admin' | 'user';
export type UserStatus = 'inactive' | 'active' | 'suspended' | 'inactive_suspended' | 'inactive_changed';

export interface UserProfile {
    id: number;
    userUuid: string;
    role: UserRole;
    firstName: string;
    lastName: string;
    directPhoneNumber? : string;
    showDirectPhoneNumber: boolean;
    email: string;
    status: UserStatus;
    permissions: UserPermission[];
    permissionsAllowedByOwner: UserPermission[];
    createdAt: Date;
    notificationsSubscribed?: number[];
    jobCommissionValue: UserCommission['value'];
    jobCommissionType: UserCommission['type'];
    jobCommissionAfterMaterials: UserCommission['afterMaterials'];
    salesCommissionValue: UserCommission['value'];
    salesCommissionType: UserCommission['type'];
    salesCommissionAfterMaterials: UserCommission['afterMaterials'];
}

export function userCommission(user: UserProfile, type: 'job' | 'sales') {
    return {
        value: type === 'job' ? user.jobCommissionValue : user.salesCommissionValue,
        type: type === 'job' ? user.jobCommissionType : user.salesCommissionType,
        afterMaterials: type === 'job' ? user.jobCommissionAfterMaterials : user.salesCommissionAfterMaterials,
    };
}
