import { Injectable } from "@angular/core";
import { SnackbarComponent } from "./snackbar.component";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    snackbarInstance: SnackbarComponent | null = null;

    close() {
        this.snackbarInstance?.close();
    }
}