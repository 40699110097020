<form [formGroup]="form" class="content form-content" cdkScrollable
     [class.d-none]="!showDetails && showNotes && !tabletScreen"
     [class.show-notes]="showNotes && !tabletScreen"
     [class.isIOS]="isIOS"
     [style]="showNotes ? 'visibility: hidden' : 'visibility: visible'"
>
    <div>
        <div class="job-type section">
            <h4 class="field-title">Job Type</h4>
            <mat-form-field class="textarea" appearance="outline" appFormFieldFilled>
              <textarea #jobTypeInput
                        matInput
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="3"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        formControlName="jobType"
                        (focus)="jobTypeFocused = true"
                        (focusout)="jobTypeFocused = false"
                        tabindex="1"
                        (keydown.Shift.Tab)="jobTypeShiftTabClick($event)"
              ></textarea>
                <mat-error *ngIf="jobTypeControl.hasError('required') && jobTypeControl.touched">
                    Job Type is required
                </mat-error>
            </mat-form-field>
        </div>

        <form [formGroup]="form.controls.client" class="d-flex justify-content-between align-items-center mb--20">
            <p class="type-title">Type</p>
            <app-text-toggle [items]="['Personal', 'Business']" class="width--240" formControlName="type" (onSelected)="onClientTypeChange()"></app-text-toggle>
        </form>

        <app-section-divider
                class="divider" [text]="'Info'"
        ></app-section-divider>

        <div class="personal-info section" formGroupName="client">

            <div class="d-flex">
                <div *ngIf="isBusinessClientType$ | async" class="field">
                    <h4 class="field-title">Business name</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input #businessNameInput matInput
                               formControlName="businessName"
                               autocomplete="off"
                               name="avoidSearch"
                               tabindex="2"
                               enterkeyhint="next"
                               (focus)="businessNameFocused = true"
                               (focusout)="businessNameFocused = false"
                        >
                        <mat-error *ngIf="clientControl.get('businessName')!.hasError('required') && clientControl.get('businessName')!.touched">
                            Business name is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="fullname">
                <div class="field">
                    <h4 class="field-title">First name</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input #firstNameInput matInput
                               formControlName="firstName"
                               autocomplete="off"
                               name="avoidSearch"
                               tabindex="3"
                               enterkeyhint="next"
                               (focus)="firstNameFocused = true; clientSuggestionsService.focusedField = 'firstName'"
                               (focusout)="firstNameFocused = false; unfocusClientSuggestionField()"
                               [placeholder]="(clientSuggestionsService.displayOption$ | async)?.firstName ?? ''"
                               [matAutocomplete]="fnAutocomplete"
                               [appHideText]="!firstNameFocused && !!(clientSuggestionsService.displayOption$ | async)"
                               [anchorAutocompleteOnScroll]="fnTrigger"
                               #fnTrigger="matAutocompleteTrigger"
                        >
                        <mat-error
                                *ngIf="clientControl.get('firstName')!.hasError('required') && clientControl.get('firstName')!.touched">
                            First name is required
                        </mat-error>
                        <mat-autocomplete
                                (optionSelected)="onClientSuggestionSelected($event)"
                                (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null"
                                #fnAutocomplete="matAutocomplete"
                        >
                            <mat-option class="withSubtitle" *ngFor="let option of clientSuggestionsService.options$ | async"
                                        (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                        (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                        [value]="option"
                            >
                                {{ option.firstName }}
                                <p class="subtitle">{{ applyPhoneNumberMask(option.phoneNumber) }}</p>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="field">
                    <h4 class="field-title">Last name</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input #lastNameInput matInput
                               formControlName="lastName"
                               autocomplete="off"
                               name="avoidSearch"
                               tabindex="4"
                               enterkeyhint="next"
                               (focus)="lastNameFocused = true; clientSuggestionsService.focusedField = 'lastName'"
                               (focusout)="lastNameFocused = false; unfocusClientSuggestionField()"
                               [placeholder]="(clientSuggestionsService.displayOption$ | async)?.lastName ?? ''"
                               [matAutocomplete]="lnAutocomplete"
                               [appHideText]="!lastNameFocused && !!(clientSuggestionsService.displayOption$ | async)"
                               [anchorAutocompleteOnScroll]="lnTrigger"
                               #lnTrigger="matAutocompleteTrigger"
                        >
                        <mat-error
                                *ngIf="clientControl.get('lastName')!.hasError('required') && clientControl.get('lastName')!.touched">
                            Last name is required
                        </mat-error>
                        <mat-autocomplete
                                (optionSelected)="onClientSuggestionSelected($event)"
                                (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #lnAutocomplete="matAutocomplete">
                            <mat-option class="withSubtitle" *ngFor="let option of clientSuggestionsService.options$ | async"
                                        (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                        (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                        [value]="option"
                            >
                                {{ option.lastName }}
                                <p class="subtitle">{{ option.email }}</p>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="contact">
                <div class="field">
                    <h4 class="field-title">Phone number</h4>
                    <div class="position-relative w-100">
                        <div class="ext-number-block">
                            <div class="ext-number-caption">Ext</div>
                            <mat-form-field appearance="outline" class="ext-number" [class.non-border]="extNumberInput.value.length > 0" #extNumberField >
                                <input #extNumberInput matInput
                                       class="text-center"
                                       formControlName="extNumber"
                                       inputmode="tel"
                                       autocomplete="off"
                                       name="avoidSearch"
                                       maxlength="4"
                                       tabindex="6"
                                       mask="0000"
                                       (focus)="extNumberFocused = true"
                                       (focusout)="extNumberFocused = false"
                                >
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline" class="w-100 phone-number-field" [class.focused]="extNumberFocused" appFormFieldFilled>
                            <input #phoneNumberInput matInput
                                   formControlName="phoneNumber"
                                   inputmode="tel"
                                   autocomplete="off"
                                   name="avoidSearch"
                                   tabindex="5"
                                   enterkeyhint="next"
                                   (input)="onPhoneNumberInputChange($event)"
                                   (focus)="phoneNumberFocused = true; clientSuggestionsService.focusedField = 'phoneNumber'"
                                   (focusout)="phoneNumberFocused = false; unfocusClientSuggestionField()"
                                   [placeholder]="(clientSuggestionsService.displayOption$ | async)?.phoneNumber ?? ''"
                                   [matAutocomplete]="phoneAutocomplete"
                                   [appHideText]="!phoneNumberFocused && !!(clientSuggestionsService.displayOption$ | async)"
                                   [anchorAutocompleteOnScroll]="pnTrigger"
                                   #pnTrigger="matAutocompleteTrigger"
                            >
                            <mat-error
                                    *ngIf="clientControl.get('phoneNumber')!.hasError('required') && clientControl.get('phoneNumber')!.touched">
                                Phone number is required
                            </mat-error>
                            <mat-error
                                    *ngIf="!phoneNumberFocused && clientControl.get('phoneNumber')!.hasError('invalidNumber') && clientControl.get('phoneNumber')!.touched">
                                Invalid phone number
                            </mat-error>
                            <mat-error
                                    *ngIf="!phoneNumberFocused && clientControl.get('phoneNumber')!.hasError('couldNotVerify') && clientControl.get('phoneNumber')!.touched">
                                Could not verify the number
                            </mat-error>
                            <mat-autocomplete
                                    (optionSelected)="onClientSuggestionSelected($event)"
                                    (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #phoneAutocomplete="matAutocomplete">
                                <mat-option *ngFor="let option of clientSuggestionsService.options$ | async"
                                            (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                            (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                            [value]="option"
                                >
                                    {{ applyPhoneNumberMask(option.phoneNumber) }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div class="field">
                    <h4 class="field-title">Email</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input #emailInput matInput
                               formControlName="email"
                               inputmode="email"
                               placeholder="example@gmail.com"
                               autocomplete="off"
                               name="avoidSearch"
                               tabindex="7"
                               enterkeyhint="next"
                               (focus)="emailFocused = true; clientSuggestionsService.focusedField = 'email'"
                               (focusout)="emailFocused = false; unfocusClientSuggestionField()"
                               [placeholder]="(clientSuggestionsService.displayOption$ | async)?.email ?? 'example@gmail.com'"
                               [matAutocomplete]="emailAutocomplete"
                               [appHideText]="!emailFocused && !!(clientSuggestionsService.displayOption$ | async)"
                               [anchorAutocompleteOnScroll]="emailTrigger"
                               #emailTrigger="matAutocompleteTrigger"
                        >
                        <mat-error
                                *ngIf="clientControl.get('email')!.hasError('invalidEmail') && clientControl.get('email')!.touched">
                            Invalid email
                        </mat-error>
                        <mat-error
                                *ngIf="clientControl.get('email')!.hasError('required') && clientControl.get('email')!.touched">
                            Email is required
                        </mat-error>
                        <mat-autocomplete
                                (optionSelected)="onClientSuggestionSelected($event)"
                                (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #emailAutocomplete="matAutocomplete">
                            <mat-option *ngFor="let option of clientSuggestionsService.options$ | async"
                                        (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                        (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                        [value]="option"
                            >
                                {{ option.email }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="location">
                <div class="field">
                    <div class="d-flex justify-content-between">
                        <h4 class="field-title">{{ isProposalDialog ? 'Billing Address' : 'Address' }}</h4>
                        <h4 *ngIf="addressInput.value.length > 0" class="clear-button" (click)="clientControl.get('address')?.setValue(null); addressInput.focus()">Clear</h4>
                    </div>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input #addressInput matInput
                               formControlName="address"
                               autocomplete="off"
                               name="avoidSearch"
                               placeholder="Enter at least 7 characters to for autocomplete"
                               [matAutocomplete]="autocomplete"
                               (focus)="addressFocused = true"
                               (focusout)="addressFocused = false"
                               tabindex="8"
                               enterkeyhint="next"
                               (keydown.enter)="$event.preventDefault(); $event.stopImmediatePropagation()"
                               [anchorAutocompleteOnScroll]="addressTrigger"
                               #addressTrigger="matAutocompleteTrigger"
                        >
                        <mat-autocomplete #autocomplete="matAutocomplete">
                            <mat-option (placeOptionClick)="onPlacesOptionSelected($event)" *ngFor="let option of addressPredictions$ | async" [value]="option">
                                {{ option.description }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                                *ngIf="clientControl.get('address')!.hasError('required') && clientControl.get('address')!.touched">
                            Address is required
                        </mat-error>
                        <mat-error
                                *ngIf="clientControl.get('address')!.hasError('addressIsString') && clientControl.get('address')!.touched">
                            Select a full address option from the dropdown
                        </mat-error>
                        <mat-error *ngIf="clientControl.get('address')!.hasError('missingComponents') && clientControl.get('address')!.touched">
                            Select a full address option from the dropdown
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="field optional">
                    <h4 class="field-title">Unit</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input #unitInput matInput
                               formControlName="unit"
                               autocomplete="off"
                               name="avoidSearch"
                               (focus)="unitFocused = true"
                               (focusout)="unitFocused = false"
                               tabindex="9"
                               (keydown.Tab)="unitTabClick($event)"
                               enterkeyhint="next"
                        ></mat-form-field>
                </div>

            </div>
        </div>

        <ng-container *ngIf="!isProposalDialog">
            <div class="section section-assign">
                <ng-container *ngIf="!isLeadDialog">
                    <app-section-divider
                            class="divider" [text]="'Schedule'"
                    ></app-section-divider>
                    <div *ngIf="pastRanges.length" class="past-time-ranges">
                        <ng-container *ngFor="let range of pastRanges">
                            <p class="past-time-range">{{ range.startTime | date:'MM/dd/YYYY, hh:mm a' }}
                                - {{ range.endTime | date:'hh:mm a' }}</p>
                            <mat-divider class="time-range-divider my--18"></mat-divider>
                        </ng-container>
                    </div>
                    <div class="set-date-time-block">
                        <div formArrayName="ranges" class="set-date-time time-ranges">
                            <ng-container
                                    *ngFor="let range of rangesArray.controls; let index = index; let last = last; let count = count">
                                <div *ngIf="range.get('date')! let dateControl" [formGroup]="range"
                                        class="time-range-block" [attr.data-range-index]="index">
                                    <div class="date-and-time" #ranges>
                                        <mat-form-field appearance="outline"
                                                        class="date-input"
                                                        appFormFieldFilled
                                                        (click)="datePicker.open()"
                                        >
                                            <img class="img-calendar" matPrefix
                                                    src="../../../assets/icons/calendar.svg"
                                                    width="16" height="16">
                                            <input #dateInput
                                                    matInput
                                                    formControlName="date"
                                                    placeholder="MM/DD/YYYY"
                                                    inputmode="numeric"
                                                    [matDatepicker]="datePicker"
                                                    autocomplete="off"
                                                    name="avoidSearch"
                                                    enterkeyhint="next"
                                                    [tabindex]="10 + index * 3"
                                                    (focus)="resetDateAndTimeErrors(range); datePicker.open()"
                                                    (focusout)="datePicker.opened ? resetDateAndTimeErrors(range) : checkOverlapAndScrollToWarning(index, dateControl.touched)"
                                                    (dateChange)="checkOverlapAndScrollToWarning(index, dateControl.touched)"
                                                    [min]="today()"
                                                    (input)="maskInput($event, dateControl, '00/00/0000')"
                                            >
                                            <mat-datepicker #datePicker
                                                            [disabled]="false"
                                                            (opened)="datePickerChange(true)"
                                                            (closed)="checkOverlapAndScrollToWarning(index, dateControl.touched); datePickerChange(false)"
                                            ></mat-datepicker>
                                            <mat-error
                                                    *ngIf="(dateControl.hasError('mask') || dateControl.hasError('date')) && dateControl.touched">
                                                Invalid date
                                            </mat-error>
                                            <mat-error
                                                    *ngIf="dateControl.hasError('required') && dateControl.touched">
                                                Choose date
                                            </mat-error>
                                        </mat-form-field>

                                        <div class="time-range d-flex align-items-start">
                                            <app-time-input
                                                #timeStartInput
                                                class="time-input time-input-start"
                                                formControlName="timeStart"
                                                [tabindex]="10 + index * 3 + 1"
                                                (focusin)="resetDateAndTimeErrors(range)"
                                                (changed)="checkOverlapAndScrollToWarning(index, timeStartInput.touched)"
                                            ></app-time-input>
                                            <div class="line line-time-range flex-fill mt--20"></div>
                                            <app-time-input
                                                #timeEndInput
                                                class="time-input time-input-end"
                                                formControlName="timeEnd"
                                                [tabindex]="10 + index * 3 + 2"
                                                (keydown.Tab)="timeEndTabClick($event, index === count - 1)"
                                                (focusin)="resetDateAndTimeErrors(range)"
                                                (changed)="checkOverlapAndScrollToWarning(index, timeEndInput.touched)"
                                            ></app-time-input>
                                        </div>

                                        <div *ngIf="count > 1 || dateControl.value || range.get('timeStart')!.value || range.get('timeEnd')!.value"
                                                class="time-range-buttons d-flex align-items-center gap--12 mt--9"
                                        >
                                            <app-icon class="icon-button p-0" src="trash"
                                                        (click)="deleteRangeClick(index)"></app-icon>
                                            <app-icon class="icon-button p-0" src="plus2"
                                                        (click)="addRangeClick(index)"></app-icon>
                                        </div>

                                    </div>

                                    <mat-error *ngIf="dateControl.hasError('pastTime') && dateControl.touched"
                                                class="range-error">
                                        Enter start time for your {{ form.get('dialogType')!.value | lowercase }} so
                                        it's later than the current time
                                    </mat-error>
                                    <mat-error *ngIf="dateControl.hasError('pastDate') && dateControl.touched"
                                                class="range-error">
                                        Enter start date for your {{ form.get('dialogType')!.value | lowercase }} so
                                        it's later than the current date
                                    </mat-error>
                                    <mat-error *ngIf="dateControl.hasError('reversedDate') && dateControl.touched"
                                                class="range-error">
                                        Enter end time for your {{ form.get('dialogType')!.value | lowercase }} so
                                        it's later than the start time
                                    </mat-error>

                                    <div *ngIf="range.get('overlap')?.value.length > 0">
                                        <p class="schedule-warning" *ngIf="currentUser$ | async let currentUser">
                                            {{ getOverlapWarningMessage(currentUser, index) }}
                                        </p>
                                    </div>
                                </div>
                                <mat-divider *ngIf="!last" class="time-range-divider mb--20 mt--6"></mat-divider>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <app-section-divider
                        class="divider" [text]="'Assignment'"
                ></app-section-divider>
                <div class="assignment-container">
                    <div class="assign-to d-flex align-items-center mobile w-100"
                            [class.mb-unset]="!isProposalDialog && form.controls.assignees.value.length"
                    >
                        <button mat-stroked-button class="availability-button"
                                (click)="changeAvailabilityVisibility(true, $event)"
                        >
                            Availability
                        </button>
                    </div>
                    <div class="d-flex align-items-center">
                        <h4 class="assignee-wrapper__title">{{isJobDialog ? 'Assignees:' : 'Assignee:'}}</h4>
                        <div class="assignee-container">
                            <ng-container *ngFor="let assignedUser of form.controls.assignees.value">
                                <div class="assignee-wrapper">
                                    <h4>{{assignedUserFullName(assignedUser)}}</h4>
                                    <app-icon class="unassign-icon se-icon-button no-bg" (click)="unAssignUser(assignedUser)" src="close"></app-icon>
                                </div>
                            </ng-container>
                        </div>
                        <div class="assign-to d-flex align-items-center ml-auto mb-auto not-mobile"
                                [class.mb-unset]="!isProposalDialog && form.controls.assignees.value.length"
                        >
                            <button mat-stroked-button class="availability-button"
                                (click)="changeAvailabilityVisibility(true, $event)"
                            >
                                Availability
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</form>

