<div *ngIf="(currentUser$ | async) let user" [class.overflow-hidden]="inserting" [class.content-hidden]="initializing" #contentView class="chat-wrapper">
  <div class="chat-content">
    <ng-container *ngFor="let date of dateHeaders$ | async; let last = last">
      <lib-chat-date-header [style.z-index]="2" 
        [attr.date-value]="date" 
        [date]="last ? (lastDateHeader$ | async)! : date" 
        class="date-header"
      ></lib-chat-date-header>
      <ng-container *ngFor="let item of (contentByDate$ | async)![date]">
        <lib-message [style.z-index]="1"
          *ngIf="isMessage(item) || isSentMessage(item); else logItem"
          [message]="convertMessage(item, user)"
          [attr.data-id]="isMessage(item) ? item.id : ''"
          class="message"
          (imageClicked)="showImageViewer($event)"
        ></lib-message>
        <ng-template #logItem>
          <div *ngIf="isLog(item) || isUserToAddLog(item)" class="log">{{ getLogText(item, user.id) }}</div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <lib-messages-indicator 
    *ngIf="chat && (showIndicator$ | async)! && !initializing"
    [messageCounter]="(unseenCount$ | async)!"
    (click)="scrollToFirstOtherUnseen(true)"
  ></lib-messages-indicator>
</div>
