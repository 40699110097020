<div class="dialog wrapper">
    <mat-stepper class="content" #stepper>
        <mat-step>
            <ng-container [ngTemplateOutlet]="formStep"></ng-container>
        </mat-step>
        <mat-step>
            <ng-container [ngTemplateOutlet]="sentStep"></ng-container>
        </mat-step>
    </mat-stepper>
    <app-icon  class="close-button se-icon-button no-bg" (click)="close()" src="close"></app-icon>
    <div class="background">
        <h3 class="caption">{{ caption }}</h3>
    </div>
</div>

<ng-template #formStep>
    <form *ngIf="(email$ | async) let email" class="d-flex flex-column align-items-stratch" [formGroup]="form">
        <p class="subtitle text-center">The reset link will be sent to</p>
        <p class="email text-center">{{ email }}</p>
        <div class="buttons d-flex align-items-stretch">
            <div class="button-wrap"><loader-button class="w-100" [loading]="loading" text="Submit" (onClick)="send(email)"></loader-button></div>
            <div class="button-wrap"><button class="w-100" mat-stroked-button (click)="back()">Back</button></div>
        </div>
    </form>
</ng-template>

<ng-template #sentStep>
    <div class="d-flex flex-column align-items-stretch">
        <p class="subtitle">Go to your email to reset your password. You may need to check your spam</p>
        <div class="buttons d-flex align-items-stretch">
            <div class="button-wrap"><button mat-flat-button type="submit" color="primary" (click)="close()">Done</button></div>
        </div>
    </div>
</ng-template>