import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from "@angular/forms";
import { ChatService } from "../../services/chat.service";
import { UsersService } from "../../services/users.service";
import { debounceTime, map, pairwise, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from "rxjs";
import {
  MatLegacyAutocompleteTrigger,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from "@angular/material/legacy-autocomplete";
import moment from "moment";
import { Chat, ChatUser, SentMessage } from "../../models/chat.model";
import { AudioRecorderService } from "../../services/audio-recorder.service";
import { CHAT_MESSAGE_FORMAT } from "../../../../../common/src/lib/directives/chat-message.directive";
import { WorkflowService } from '../../services/workflow.service';
import { ModalBehavior, ModalsService } from 'projects/common/src/lib/services/modals.service';
import { ConfirmationDialog } from 'projects/common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import { ChatMessageInputComponent } from "../chat-message-input/chat-message-input.component";
import { DOCUMENT } from "@angular/common";
import { BusinessChatContentComponent } from "../business-chat-content/business-chat-content.component";
import { UtilsService } from "../../../../../common/src/lib/services";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar } from "@angular/material/legacy-snack-bar";

export type UserMention = UserProfile & { action: 'Add' | 'Remove' | 'None' | 'Suspended' };
export type UserSuggestion = 'All' | UserMention;

function filterUsersByName(text: string, users: UserProfile[]) {
  const name = text.toLowerCase();
  if(name.length === 0)
    return users;
  return users
    .filter(user => user.firstName.toLowerCase().includes(name) || user.lastName.toLowerCase().includes(name));
}

function sortUsers(users: UserMention[], addFirst = true) {
  const addUsers: UserMention[] = [];
  const removeUsers: UserMention[] = [];

  while(users.length > 0) {
    const user = users.pop()!;
    if(user.action === 'Add')
      addUsers.push(user);
    else
      removeUsers.push(user);
  }

  function sortByName (a:UserMention, b: UserMention) {
    return (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName);
  }

  addUsers.sort(sortByName);
  removeUsers.sort(sortByName);

  if(addFirst) {
    users.push(...addUsers);
    users.push(...removeUsers);
  } else {
    users.push(...removeUsers);
    users.push(...addUsers);
  }

  return users;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;
  @ViewChild('messageInput') messageInput!: ChatMessageInputComponent;
  @ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('chatContent') chatContent!: BusinessChatContentComponent;
  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;

  @ViewChild('topTrigger') topTrigger?: MatLegacyAutocompleteTrigger;

  @Input() chat!: Chat | null;
  @Input() workflowId?: number;
  @Input() subject?: string;
  @Output() chatCreated = new EventEmitter<number>();
  @Output() leave = new EventEmitter<void>();

  chatSubject = new BehaviorSubject<Chat | null>(null);
  messageInputFocusSubject = new BehaviorSubject(false);
  editingUsersSubject = new BehaviorSubject(false);
  recordingStartSubject = new BehaviorSubject<Date | null>(null);
  recordingTimeout?: NodeJS.Timeout;

  subjectControl = new FormControl<string>('', { nonNullable: true });
  usersControl = new FormControl<string>('', { nonNullable: true });
  messageControl = new FormControl<string>('', { nonNullable: true });
  attachmentMessageControl = new FormControl<string>('', { nonNullable: true });
  allowBottomSuggestions = false;

  subjectFocused = false;
  sentMessage: SentMessage | null = null;
  createdChatId: number | null = null;
  creatingChat = false;

  currentChatUser: ChatUser | null = null;
  usersToAddSubject = new BehaviorSubject(new Set<number>());
  draggingAttachment = false;
  selectedFile?: File;
  selectedFileImage?: string;
  selectedFileVideo?: string;
  selectedFileAudio?: string;
  visibilityChangeHandler: (() => void) | null = null;

  audioPlaying = false;
  seeking = false;
  startProgressPos: number | null = null;
  startProgressTime: Date | null = null;

  progress$ = timer(0, 100).pipe(
    map(_ => {
      const player = this.audioPlayer!.nativeElement as HTMLMediaElement;
      if (!player.duration)
        return 0;

      if (player.currentTime === player.duration)
        this.audioPlaying = false;

      if(!this.audioPlaying || this.startProgressPos === null || this.startProgressTime === null)
        return (player.currentTime ?? 0) / player.duration * 100;

      const time = this.startProgressPos += ((Date.now() - this.startProgressTime.getTime()) / 1000);
      this.startProgressTime = new Date();
      return time / player.duration * 100;
    })
  );

  currentUser$ = this.usersService.currentUser$;
  usersToAdd$ = this.usersToAddSubject.pipe(map(users => Array.from(users)));

  recordingTime$ = this.recordingStartSubject.pipe(
    switchMap(start => {
      if(!start)
        return of('');
      return timer(0, 1000).pipe(
          map(_ => moment(moment().diff(start)).format('mm:ss'))
      );
    })
  );

  workflowIdSubject = new BehaviorSubject<number | null>(null);

  workflowUsers$ = this.workflowIdSubject.pipe(
    switchMap(id => id === null ? of([]) : this.workflowService.workflowUsersIdsObservable(id)),
    shareReplay(1)
  );

  canExit$ = combineLatest([
    this.usersService.currentUser$,
    this.workflowUsers$
  ]).pipe(
    map(([user, workflowUsers]) => !workflowUsers.includes(user.id)),
    shareReplay(1)
  );

  isChatMember$ = combineLatest([this.chatSubject, this.usersService.currentUser$]).pipe(
    map(([chat, user]) => !!chat?.users.find(chatUser => chatUser.userId === user.id))
  );

  users$ = combineLatest([
    this.editingUsersSubject,
    this.usersControl.valueChanges.pipe(startWith('')),
    this.usersService.activeUsers$,
    this.chatSubject,
    this.workflowUsers$,
    this.usersService.currentUser$
  ]).pipe(
    map(([editing, text, users, chat, workflowUsers, currentUser]) => {
      if(!editing || !users)
        return [] as UserSuggestion[];
      if(!text)
        text = '';

      const enabledUsers = users.filter(user => !workflowUsers.includes(user.id));
      const filteredUsers = filterUsersByName(text, enabledUsers)
        .filter(user => currentUser.id !== user.id)
        .map(user => ({
          ...user,
          action: this._userAction(chat, user.id, users, (!chat || currentUser.id === chat.creatorId))
        }) as UserMention)
        .filter(user => {
          if(!chat || currentUser.id === chat.creatorId) {
            return true;
          }
          return user.action === 'Add' || user.action === 'Suspended';
        });

      const resultUsers = sortUsers(filteredUsers) as UserSuggestion[];

      if(text.length > 0) 
        return resultUsers;

      if (filteredUsers.filter(user => user.action === 'Add').length > 0)
        resultUsers.push('All');

      return resultUsers;
    }),
  );

  mentionUsers$ = combineLatest([
    this.messageControl.valueChanges,
    this.usersService.activeUsers$,
    this.chatSubject,
    this.workflowUsers$,
    this.usersService.currentUser$,
    this.usersToAddSubject
  ]).pipe(
    map(([text, users, chat, workflowUsers, currentUser]) => {
      if(!text)
        text = '';
      const index = text.lastIndexOf('@');
      if(index === -1 || !users)
        return [] as UserMention[];

      const resultUsers = filterUsersByName(text.substring(index+1), users)
        .filter(user => user.id !== currentUser.id)
        .map(user => ({
          ...user,
          action: this._userAction(chat, user.id, users, !chat || currentUser.id === chat.creatorId)
        }) as UserMention);
      return sortUsers(resultUsers);
    })
  );

  get fileInput() {
    return document.getElementById('chat-file-input')! as HTMLInputElement;
  }

  menuIsOpen = true;

  typingSub?: Subscription;
  showTyping = true;
  typingText?: string;

  subjectSub?: Subscription;

  scrollPos = 0;

  startingRecording = false;

  constructor(
     private chatService: ChatService,
     private usersService: UsersService,
     private workflowService: WorkflowService,
     private audioRecorderService: AudioRecorderService,
     private modalsService: ModalsService,
     private utilsService: UtilsService,
     private snackbar: MatLegacySnackBar,
     private cd: ChangeDetectorRef,
     @Inject(DOCUMENT) private document: Document,
  ) {}

  private _userAction(chat: Chat | null, userId: number, users: UserProfile[], canBeRemoved = true) {
    if(users.find(user => user.id === userId)?.status.includes('suspended')) {
      return 'Suspended';
    }
    const removeAction = canBeRemoved ? 'Remove' : 'None';
    if(chat) {
      return chat.users.find(u => u.userId === userId) ? removeAction : 'Add';
    } else {
      return this.usersToAddSubject.value.has(userId) ? removeAction : 'Add';
    }
  }

  private fileInputChange(event: Event) {
    const file = this.fileInput.files!.item(0);
    (event.target as HTMLInputElement).value = null as any;
    if(file)
      this.onFileChange(file);
  }

  async ngOnInit() {
    if(this.chat) {
      this.chatSubject.next(this.chat);
      this.subjectControl.setValue(this.chat.subject);
      const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
      this.currentChatUser = this.chat.users.find(u => u.userId === currentUser.id) ?? null;
      this.setTypingUsers(this.chat.users);
      this.initTypingSubscription();
      this.initSubjectSubscription();
    } else if (this.subject) {
      this.subjectControl.setValue(this.subject);
    }

    this.fileInputChange = this.fileInputChange.bind(this);
    this.fileInput.addEventListener('change', this.fileInputChange);
  }

  ngAfterViewInit() {
    this.chatContent.contentView.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll() {
    this.scrollPos = this.chatContent.contentView.nativeElement.scrollTop;
  }

  menuStateChange(opened: boolean, showingChat: boolean) {
    this.menuIsOpen = opened;
    if(showingChat && opened) {
      const scrollElement = this.chatContent.contentView.nativeElement!;
      scrollElement.scrollTo({top: this.scrollPos});
    }
    if(!opened) {
      this.cancelAudioMessage();
    }
    this.dialog?.nativeElement?.close();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.chat) {
      if(changes.chat.currentValue) {
        this.chatSubject.next(changes.chat.currentValue);
        if(!this.subjectFocused && changes.chat.currentValue.subject !== this.subjectControl.value)
          this.subjectControl.setValue(changes.chat.currentValue.subject);
        this.setTypingUsers(changes.chat.currentValue.users);
        if(!changes.chat.previousValue) {
          this.initTypingSubscription();
          this.initSubjectSubscription();
        }
      } else if (changes.chat.previousValue) {
        this.leave.emit();
      }
    }
    if(changes.chat || changes.workflowId) {
      this.initWorkflow();
    }
    if(changes.subject?.currentValue) {
      this.subjectControl.setValue(this.subject!);
    }
  }

  private initWorkflow() {
    const workflowId = this.workflowId ?? this.chat?.workflowId ?? null;
    if(workflowId && this.workflowIdSubject.value !== workflowId)
      this.workflowIdSubject.next(workflowId);
  }

  private initSubjectSubscription() {
    if(!this.subjectSub) {
      this.subjectSub = this.subjectControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(text => {
          if(text.trim().length > 0) {
            this.subjectControl.setErrors(null);
            if (this.chat)
              this.chatService.setSubject(this.chat.id, text.trim());
          } else {
            this.subjectControl.setErrors({required: true});
          }
        });
    }
  }

  private initTypingSubscription() {
    if(!this.typingSub) {
      this.typingSub = combineLatest([
        this.messageInputFocusSubject,
        this.messageControl.valueChanges,
      ]).pipe(
        map(([focus, text]) => {
          return focus && text.length > 0;
        }),
        pairwise()
      ).subscribe(typing => {
        if(typing[0] !== typing[1])
          this.chatService.setUserTyping(this.chat!.id, typing[1]);
      });
    }
  }

  private async setTypingUsers(users: ChatUser[]) {
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    const typingUsers = users.filter(user => {
      if(user.userId === currentUser.id)
        return false;
      if(user.typingAt === null)
        return false;

      return Date.now() - user.typingAt.getTime() <= 15000;
    });

    this.showTyping = typingUsers.length > 0;

    if(typingUsers.length === 1)
      this.typingText = `${typingUsers[0].firstName} is typing...`;
    else if(typingUsers.length === 2)
      this.typingText = `${typingUsers[0].firstName} and ${typingUsers[1].firstName} are typing...`;
    else if(typingUsers.length > 0)
      this.typingText = `${typingUsers.length} users are typing...`;
  }

  preventEvent(event: Event, reopenTrigger?: MatAutocompleteTrigger) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if(reopenTrigger)
      reopenTrigger.openPanel();
  }

  validateSubject() {
    const value = this.subjectControl.value.trim();
    if(value.length === 0) {
      this.subjectControl.setErrors({
        required: true
      });
      this.subjectControl.markAsTouched();
      return null;
    }
    return value;
  }
  
  get chatId() {
    return this.chat?.id ?? this.createdChatId;
  }

  async muteOrExitClick() {
    if (!this.chat)
      return;
    const canExit = await this.canExit$.pipe(take(1)).toPromise();
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    if (canExit)
      this.modalsService.open(
        ConfirmationDialog,
        {
          data: {
            title: 'Leave Chat',
            message: 'Are you sure you want to leave the chat?',
            actionTitle: 'Leave',
            actionColor: 'warn',
            action: async () => {
              if (!this.chat)
                return;
              await this.chatService.removeUserFromChat(this.chat.id, currentUser.id);
              this.leave.emit();
            }
          }
        }
      );
    else
      this.chatService.setMute(this.chat.id, !this.chat.users.find(user => user.userId === currentUser.id)?.mute);
  }

  isChatMuted(currentUserId: number) {
    return !!this.chat?.users.find(user => user.userId === currentUserId)?.mute;
  }

  chatCreatedEvent(id: number) {
    this.createdChatId = id;
    this.chatCreated.emit(id);
  }
  
  async setSentMessage(content: string | null, file?: File | null, attachment?: string | null): Promise<void>
  async setSentMessage(message: SentMessage): Promise<void>
  async setSentMessage(contentOrMessage: string | SentMessage | null, file?: File | null, attachment?: string | null): Promise<void> {
    if (contentOrMessage && typeof contentOrMessage !== 'string') {
      this.sentMessage = contentOrMessage;
      this.cd.detectChanges();
      return;
    }
    if (!contentOrMessage && !file)
      return;
    this.sentMessage = await this.createMessage(contentOrMessage, file, attachment);
    this.cd.detectChanges();
  }

  async createMessage(content: string | null, file?: File | null, attachment?: string | null): Promise<SentMessage> {
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    return { 
      id: null,
      createdAt: new Date(),
      senderId: currentUser.id,
      content,
      attachment: attachment ?? null,
      file,
      usersSeen: []
    };
  }

  async sendMessage() {
    const content = this.messageControl.value;
    if(content.length === 0 || this.creatingChat)
      return;

    if(this.chatId) {
      this.messageControl.setValue('');
      await this.setSentMessage(content);
      const sentMessage = this.sentMessage;
      const matches = sentMessage!.content!.match(CHAT_MESSAGE_FORMAT);
      let mentions: number[] | null = null;
      if(matches) {
        mentions = matches.map(m => +m.substring(2, m.length-1));
      }
      await this.chatService.sendMessage(this.chatId, sentMessage!, content, null, mentions);
    } else {
      const subjectValue = this.validateSubject();
      if(!subjectValue)
        return;
      await this.setSentMessage(content);
      const sentMessage = this.sentMessage;
      this.messageControl.setValue('');
      this.creatingChat = true;
      const id = await this.chatService.sendMessage(subjectValue, sentMessage!, content, null, Array.from(this.usersToAddSubject.value), this.workflowId!);
      this.creatingChat = false;
      this.chatCreatedEvent(id);
    }
    this.messageControl.setValue('');
  }

  async sendAudioMessage() {
    clearTimeout(this.recordingTimeout);
    this.recordingStartSubject.next(null);
    const file = await this.stopRecording();
    if(!file || this.creatingChat)
      return;

    if(this.chatId) {
      const sentMessage = await this.createMessage(null, file);
      await this.chatService.sendAttachmentMessage(this.chatId, sentMessage, null, file);
      await this.setSentMessage(sentMessage);
    } else {
      const subjectValue = this.validateSubject();
      if(!subjectValue)
        return;
      this.creatingChat = true;
      const sentMessage = await this.createMessage(null, file);
      const id = await this.chatService.sendAttachmentMessage(subjectValue, sentMessage, null, file, Array.from(this.usersToAddSubject.value), this.workflowId!);
      await this.setSentMessage(sentMessage);
      this.creatingChat = false;
      this.chatCreatedEvent(id);
    }
  }

  async sendAttachmentMessage() {
    if(!this.selectedFile || this.creatingChat)
      return;

    const clearControls = () => {
      this.messageControl.setValue('');
      this.attachmentMessageControl.setValue('');
    }

    const content = this.attachmentMessageControl.value || null;
    if (this.chatId) {
      clearControls();
      const sentMessage = await this.createMessage(content,  this.selectedFile);
      const matches = sentMessage!.content?.match(CHAT_MESSAGE_FORMAT);
      let mentions: number[] | null = null;
      if(matches) {
        mentions = matches.map(m => +m.substring(2, m.length-1));
      }
      await this.chatService.sendAttachmentMessage(this.chatId, sentMessage, content, this.selectedFile, mentions);
      await this.setSentMessage(sentMessage);
    } else {
      const subjectValue = this.validateSubject();
      if(!subjectValue)
        return;
      clearControls();
      this.creatingChat = true;
      const sentMessage = await this.createMessage(content,  this.selectedFile);
      const id = await this.chatService.sendAttachmentMessage(subjectValue, sentMessage, content, this.selectedFile, Array.from(this.usersToAddSubject.value), this.workflowId!);
      await this.setSentMessage(sentMessage);
      this.creatingChat = false;
      this.chatCreatedEvent(id);
    }
    this.closeAttachmentDialog();
  }

  cancelAudioMessage() {
    clearTimeout(this.recordingTimeout);
    this.recordingStartSubject.next(null);
    this.audioRecorderService.cancelRecording();
  }

  async recordMessage() {
    if (!this.chat) {
      const subject = this.validateSubject();
      if (!subject)
        return;
    }

    if (this.recordingStartSubject.value)
      return;

    this.startingRecording = true;
    const recordingStarted = await this.audioRecorderService.startRecording();
    this.startingRecording = false;
    if (!recordingStarted)
      return;

    const stopRecording = async () => {
      const file = await this.stopRecording();
      this.recordingTimeout = undefined;
      this.recordingStartSubject.next(null);
      if (file)
        this.onFileChange(file, true);
    };

    this.recordingStartSubject.next(new Date());
    this.recordingTimeout = setTimeout(stopRecording, 60000);

    if (this.utilsService.isMobile()) {
      this.visibilityChangeHandler = () => {
        if (document.hidden)
          stopRecording();
      };
      document.addEventListener('visibilitychange', this.visibilityChangeHandler.bind(this));
    }
  }

  stopRecording() {
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler.bind(this));
      this.visibilityChangeHandler = null;
    }
    return this.audioRecorderService.stopRecording();
  }

  clearUsersField() {
    this.editingUsersSubject.next(false);
    this.usersControl.setValue('');
  }

  onDrag(dragging: boolean, event: DragEvent) {
    if(event.dataTransfer?.items.length) {
      const type = event.dataTransfer?.items[event.dataTransfer?.items.length - 1].type
      if(type.startsWith('image') || type.startsWith('audio')) {
        this.draggingAttachment = dragging;
      }
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.items[0]?.getAsFile();
    if(file)
      this.onFileChange(file);
  }

  toggle(player: HTMLAudioElement) {
    this.audioPlaying = !this.audioPlaying;
    if(this.audioPlaying) {
      player.play();
      this.startProgressPos = player.currentTime ?? 0;
      this.startProgressTime = new Date();
    } else {
      player.pause();
      this.startProgressPos = null;
      this.startProgressTime = null;
    }
  }

  async onFileChange(file: File, allowLargeFile?: boolean) {
    this.draggingAttachment = false;
    this.selectedFile = file;
    const fileType = file.type;
    if(!allowLargeFile) {
      if (!fileType.startsWith('image/') && !fileType.startsWith('audio/')) {
        showSnackbar(this.snackbar, {
          message: 'File type unsupported',
          duration: 3000
        });
        return;
      }
      if (fileType.startsWith('image/') && file.size > 2097152) { // 2MB
        showSnackbar(this.snackbar, {
          message: 'File exceeds 2MB limit for images',
          duration: 3000
        });
        return;
      }
      if (fileType.startsWith('audio/') && file.size > 5242880) { // 5MB
        showSnackbar(this.snackbar, {
          message: 'File exceeds 5MB limit for audio',
          duration: 3000
        });
        return;
      }
    }


    if (fileType.startsWith('image/')) {
      this.selectedFileImage = await this.fileToString(this.selectedFile);
    }
    // else if (fileType.startsWith('video/')) {
    //   this.selectedFileVideo = await this.fileToString(this.selectedFile);
    // }
    else if (fileType.startsWith('audio/')) {
      this.selectedFileAudio = await this.fileToString(this.selectedFile);
    }
    this.attachmentMessageControl.setValue(this.messageControl.value);
    this.dialog.nativeElement.showModal();
  }

  attachmentClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.fileInput.click();
  }

  fileToString(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ((event: ProgressEvent<FileReader>) => {
        resolve(event.target!.result as string);
      }).bind(this);
      reader.readAsDataURL(file);
    })
  }

  onMessageKeyDown(event: KeyboardEvent) {
    if(this.utilsService.isMobile())
      return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  onDialogKeyDown(event: KeyboardEvent) {
    if(this.utilsService.isMobile())
      return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendAttachmentMessage();
    }
  }

  closeAttachmentDialog() {
    this.selectedFile = undefined;
    this.selectedFileImage = undefined;
    this.selectedFileVideo = undefined;
    this.selectedFileAudio = undefined;
    this.attachmentMessageControl.setValue('');
    this.dialog.nativeElement.close();
  }

  editUsers() {
    this.editingUsersSubject.next(true);
    this.subjectInput.nativeElement.focus();
  }

  async userSuggestionClick(user: UserSuggestion) {
    if(user === 'All') {
      const usersToAdd = await this.getAllUsersToAdd();
      this.modalsService.open(ConfirmationDialog, {
        behavior: ModalBehavior.Dialog,
        data: {
          title: 'Add user',
          message: 'Are you sure you want to add all accounts?',
          actionTitle: 'Yes',
          cancelTitle: 'No',
          action: () => {
            this.addUsersToChat(...usersToAdd);
          }
        }
      });
    } else {
      if(user.action === 'Suspended') {
        return;
      }
      if(user.action === 'Add') {
        this.addUsersToChat(user.id);
      } else {
        this.removeUsersFromChat(user.id);
      }
    }
    this.usersControl.setValue('');
  }

  addUsersToChat(...userIds: number[]) {
    for(const id of userIds) {
      if(this.chat) {
        this.chatService.addUserToChat(this.chat.id, id);
      } else {
        this.usersToAddSubject.value.add(id);
        this.usersToAddSubject.next(this.usersToAddSubject.value);
      }
    }
  }

  async removeUsersFromChat(...userIds: number[]) {
    for(const id of userIds) {
      if(this.chat) {
        const user = (await this.usersService.users$.pipe(take(1)).toPromise())!.find(user => user.id === id)!;
        this.modalsService.open(
          ConfirmationDialog,
          {
            data: {
              title: 'Remove User',
              message: `Are you sure you want to remove ${user.firstName} ${user.lastName} from the chat?`,
              actionTitle: 'Remove',
              actionColor: 'warn',
              action: async () => {
                if (!this.chat)
                  return;
                await this.chatService.removeUserFromChat(this.chat.id, id);
              }
            }
          }
        );
      } else {
        this.usersToAddSubject.value.delete(id);
        this.usersToAddSubject.next(this.usersToAddSubject.value);
      }
    }
  }

  async getAllUsersToAdd() {
    const users = await this.users$.pipe(take(1)).toPromise();
    return users.filter(u => {
      if(u === 'All')
        return false;
      return u.action === 'Add';
    }).map(u => (u as UserMention).id);
  }

  userMentionClick(user: UserMention) {
    if(user.action === 'Suspended')
      return;
    const index = this.messageControl.value.lastIndexOf('@');
    const text = this.messageControl.value.substring(0, index) + `@{${user.id}}`;
    this.messageControl.setValue(text);

    setTimeout(() => {
      this.messageInput.input.nativeElement.focus();
      const childNodes = this.messageInput.input.nativeElement.childNodes;
      if(childNodes.length > 0) {
        const lastNode = childNodes[childNodes.length - 1];
        const selection = this.document.getSelection()!;
        const range = this.document.createRange();
        range.setStartAfter(lastNode);
        range.setEndAfter(lastNode);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 2)
  }

  onMenuClick(event: Event) {
    setTimeout(() => {
      if(!this.subjectFocused) {
        this.topTrigger?.closePanel();
        this.editingUsersSubject.next(false);
      }
      if(!this.messageInputFocusSubject.value)
        this.allowBottomSuggestions = false;
    }, 100);
  }

  seek(event: MouseEvent, element: HTMLMediaElement) {
    if (!element.duration)
      return;
    event.preventDefault();
    event.stopImmediatePropagation();
    const duration = element.duration;
    const percentage = ( event.offsetX / (event.target as HTMLProgressElement).clientWidth );
    element.currentTime = duration * percentage;
    this.startProgressPos = element.currentTime ?? 0;
    this.startProgressTime = new Date();
  }

  mouseMove(event: MouseEvent, element: HTMLMediaElement) {
    if(this.seeking) {
      this.seek(event, element);
    }
  }

  onDialogClose() {
    this.selectedFile = undefined;
    this.selectedFileImage = undefined;
    this.selectedFileVideo = undefined;
    this.selectedFileAudio = undefined;
    this.seeking = false;
    this.startProgressPos = null;
    this.startProgressTime = null;
    this.audioPlaying = false;
  }

  ngOnDestroy() {
    this.subjectSub?.unsubscribe();
    this.typingSub?.unsubscribe();
    this.chatContent.contentView.nativeElement.removeEventListener('scroll', this.onScroll);
    this.fileInput.removeEventListener('change', this.fileInputChange);
    this.cancelAudioMessage();
  }
}
