import { ComponentType } from '@angular/cdk/overlay';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';

interface ForgorPasswordDialogData<T> {
  backComponent: ComponentType<T>
  backComponentData: any
}

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordModal<T> implements OnInit {

  email$ = this.authService.user$.pipe(
    map(user => user!.email)
  );

  form = this.formBuilder.group({
    email: new UntypedFormControl()
  });

  loading = false;
  caption = 'Reset password';

  @ViewChild('stepper') stepper!: MatStepper;

  get data() {
    return this.dialogData ?? this.modalsService.data;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private modalsService: ModalsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: ForgorPasswordDialogData<T>
  ) { }

  ngOnInit(): void {
  }

  async send(email: string) {
    this.loading = true;
    try {
      await this.authService.sendPasswordResetEmail(email);
      this.stepper.next();
      this.caption = 'Email sent';
    } catch(e) {
      console.log(`forgot-password.component.ts > ForgotPasswordModal > send()`, e);
    }

    this.loading = false;
  }

  back() {
    this.modalsService.open(this.data.backComponent, {
      data: this.data.backComponentData
    });
  }

  close() {
    this.modalsService.close();
  }

}
