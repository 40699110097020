import { Injectable } from '@angular/core';
import { Client, MakeOptional } from 'projects/common/src/public-api';
import { BusinessService } from './business.service';
import { ViewAsService } from './view-as.service';
import { LobbyService } from './lobby.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PaymentService } from './payment.service';
import { UsersService } from './users.service';
import { SupabaseService } from "./supabase.service";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { Note } from "../models/note.model";
import { DateFilter } from "../models/date-filter.model";
import { PaginationOptions } from "./pagination.service";
import { Lead, LeadTileData } from "../../../../common/src/lib/models/lead.model";
import { combineLatest } from "rxjs";
import { LeadClientRestore } from "../../../../common/src/lib/models/client-transform";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';

export interface LeadCreate {
    inOwnerId: number;
    inClient: MakeOptional<Client, 'salesTaxPercentage' | 'email'>;
    inRanges: TimeRange[];
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

export interface LeadUpdate {
    inId: number;
    inRanges?: TimeRange[];
    inOwnerId?: number;
    inClient?: MakeOptional<Client, 'salesTaxPercentage' | 'email'>;
    inJobType?: string;
}

export interface LeadRestore {
    inId: number;
    inCreatedAt: Date;
    inOwnerId: number;
    inClient: LeadClientRestore;
    inRanges: TimeRange[];
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
    constructor(
        protected businessService: BusinessService,
        protected supabaseService: SupabaseService,
        protected viewAsService: ViewAsService,
        protected paymentService: PaymentService,
        protected usersService: UsersService,
        protected lobbyService: LobbyService,
        protected snackbar: MatSnackBar,
    ) {}

    leadsObservable(options: PaginationOptions, pref = '') {
        return this.businessService.selectedBusiness$.pipe(
            // TODO: This can be improved by adding the changes handler to handle each change separately and not to pull the list again.
            switchMap(business => {
                return this.supabaseService.rpc<LeadTileData[]>({
                    cName: pref + '_leads',
                    schema: business!.businessId,
                    fn: 'get_leads',
                    tables: [
                        { table: 'lead' },
                        { table: 'lead_client' },
                        { table: 'lead_range' },
                        { table: 'workflow' }
                    ],
                    options
                },
                (changes, items) => {
                    if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
                        return null;
                    const data = changes.new as any;
                    const item = items.find(item => data.leadId === item.id);
                    if (item)
                        item.jobType = data.jobType;
                    return items;
                });
            }),
            map(leads => {
                if(!leads)
                    return [];
                for(const lead of leads)
                    lead.docType = 'Lead';
                return leads;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    async hasLeads() {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        const count = (await this.supabaseService.supabase
            .schema(business!.businessId)
            .rpc('get_leads', { in_user_ids: userIds }, { count: 'exact' })
            .limit(0)).count ?? 0;
        return count > 0;
    }

    async getLeadsForDateRange(businessId: string, range: DateFilter, users: number[] | null, direction: 'future' | 'past') {
        const options: PaginationOptions = {
            inDirection: direction,
            inStartDate: frontToServerTranslation(range.from),
            inEndDate: frontToServerTranslation(range.to)
        };
        if(users)
            options.inUserIds = users;
        return this.supabaseService.rpcFunc<Lead[]>(businessId, 'get_leads', options);
    }

    async getLead(id: number) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const lead = (await this.supabaseService.rpcFunc<Lead[]>(business!.businessId, 'get_lead_by_id', { inId: id }))[0];
        lead.docType = 'Lead';
        return lead;
    }
    
    async createLead(data: LeadCreate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'create_lead', frontToServerTranslation(data));
    }

    async updateLead(data: LeadUpdate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'update_lead', data);
    }

    async restoreLead(data: LeadRestore) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'restore_lead', data);
    }

    async deleteLead(id: number) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.delete(business!.businessId, 'lead', id);
    }

}
