<div class="page main d-flex flex-column justify-content-start" *ngIf="user$ | async" fadeIn="500" [fadeInDelay]="200">

  <app-toolbar type="menu" class="above-tab" leftAction="Close" (backClick)="back()">
    <h3>Proposal</h3>
  </app-toolbar>

  <app-mobile-toolbar class="under-tab" [showMenu]="true" leftAction="Close" (backClick)="back()"></app-mobile-toolbar>

  <ng-container *ngIf="!(largeScreen$ | async)" [ngTemplateOutlet]="tabs"></ng-container>

  <div class="d-flex h-100 container main-layout" *ngIf="proposal$ | async let proposal">

    <div class="proposal-main flex-fill" scroll (scrollPosition)="scrollPosition = $event"
         [class.hide-tab]="!(showInfo$ | async)"
    >
      <div style="min-height: unset">
        <div class="close-and-workflow-bar">
          <app-progress-bar [workflowId]="proposal.workflowId" active="proposal"></app-progress-bar>
        </div>

        <app-version-picker
            [versions]="(proposalVersions$ | async)!"
            [selectedVersionId]="(currentProposalVersionId$ | async)!"
            (versionSelected)="setCurrentVersion($any($event))"
            [actions]="actions"
            (actionClick)="handleVersionPickerAction($event)"
            type="Proposal"
            [proposal]="proposal"
        ></app-version-picker>

        <ng-container *ngIf="currentProposalVersion$ | async let currentProposalVersion">
          <app-workflow-prepared [workflow]="proposal"
                                 [currentWorkflowVersion]="currentProposalVersion"
                                 *ngIf="currentProposalVersion.status === 'created' && !preview"
                                 [updateSnackbarMessage]="'Proposal updated'"
                                 [updateFunction]="clientUpdateFunction"
          ></app-workflow-prepared>

          <app-proposal-editable [scrollPosition]="scrollPosition"
                                 [proposal]="proposal"
                                 (editableInFocus)="differentInFocus$.next($event)"
                                 [currentProposalVersion]="currentProposalVersion"
                                 *ngIf="currentProposalVersion.status === 'created' && !preview"
          ></app-proposal-editable>

          <app-proposal-preview-and-send *ngIf="preview" [proposal]="proposal"
                                         [currentProposalVersion]="currentProposalVersion"
          ></app-proposal-preview-and-send>

          <app-proposal-preview-and-send *ngIf="currentProposalVersion.status !== 'created' && !preview"
                                         [proposal]="proposal" [currentProposalVersion]="currentProposalVersion"
                                         [sentMode]="true"
          ></app-proposal-preview-and-send>
        </ng-container>
      </div>

      <ng-container *ngIf="currentProposalVersion$ | async let currentProposalVersion">
        <ng-container *ngIf="{ value: differentInFocus$ | async } as differentInFocus">
          <app-proposal-actions [proposal]="proposal"
                                [currentProposalVersion]="currentProposalVersion"
                                (setCurrentProposalVersionId)="currentProposalVersionId$.next($event);"
                                (previewEvent)="preview = $event"
                                [preview]="preview"
                                (sendEvent)="sendProposalVersion(proposal, currentProposalVersion)"
                                [sendLoading]="sendLoading"
                                *ngIf="currentProposalVersion?.items?.length! > 0"
                                [depositFocused]="differentInFocus.value ?? false"
          ></app-proposal-actions>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="(largeScreen$ | async) || !(showInfo$ | async)" class="side-panel-wrap" (click)="sidePanelClick($event)">
      <ng-container *ngIf="largeScreen$ | async" [ngTemplateOutlet]="tabs"></ng-container>
      
      <div class="notes-wrap" [class.d-none]="!(showNotes$ | async)">
        <app-notes
          class="notes"
          [workflowId]="(params$ | async)!.workflowId"
          [businessId]="(businessId$ | async)!"
          [currentUser]="(currentUser$ | async)!"
          [isModal]="false"
          [showJobType]="true"
          [showOverlayImageViewer]="true"
          from="Proposal"
        ></app-notes>
      </div>

      <div class="chat-wrap h-100" *ngIf="showChat$ | async">
        <app-chat #chat
          [chat]="chat$ | async"
          [workflowId]="(params$ | async)!.workflowId"
          [subject]="(chatSubject$ | async)!"
        ></app-chat>
      </div>
    </div>
    
  </div>

  <ng-template #tabs>
    <app-tabs
      [class.app-tabs]="!(largeScreen$ | async)"
      linePosition="bottom"
      alignment="center"
      [tabs]="(tabs$ | async)!"
      [activeTab]="(selectedTab$ | async)!"
      (onTabSelect)="setActiveTab($event)"
      [redDots]="(tabsRedDots$ | async)!"
    ></app-tabs>
  </ng-template>

</div>
