// @ts-ignore
import { saveAs } from 'file-saver';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { Router } from "@angular/router";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { InvoicesService } from "../../services/invoices.service";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { debounceTime, take } from "rxjs/operators";
import { PdfService } from "../../../../../common/src/lib/services/pdf.service";
import { Invoice, InvoiceVersion } from "../../models/invoice.model";
import { BusinessService } from "../../services/business.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-invoice-actions',
  templateUrl: './invoice-actions.component.html',
  styleUrls: ['./invoice-actions.component.scss']
})
export class InvoiceActionsComponent implements OnChanges {

  @Input() invoice!: Invoice;
  @Input() currentInvoiceVersion!: InvoiceVersion;
  @Input() preview?: boolean = false;
  @Input() sendLoading?: boolean = false;
  @Input() editableFocused: boolean = false;
  @Output() setCurrentInvoiceVersionId = new EventEmitter<number>();
  @Output() previewEvent = new EventEmitter<boolean>();
  @Output() sendEvent = new EventEmitter();

  _editableFocused$ = new BehaviorSubject<boolean>(false);
  editableFocused$ = this._editableFocused$.asObservable().pipe(
    debounceTime(100)
  );

  openedActions = false;

  downloading = false;

  constructor(
    private modalsService: ModalsService,
    private invoicesService: InvoicesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private pdfService: PdfService,
    private businessService: BusinessService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('editableFocused' in changes)
      this._editableFocused$.next(this.editableFocused);
  }

  async deleteInvoice() {
    await this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      data: {
        title: 'Delete Invoice',
        message: 'Are you sure you want to delete the invoice?',
        actionTitle: 'Delete',
        actionColor: 'warn',
        action: async () => {
          const currentVersion = this.currentInvoiceVersion;
          const currentInvoice = this.invoice;
          const invoiceDeleted = await this.invoicesService.deleteInvoiceVersion(currentVersion.id);

          invoiceDeleted
            ? await this.router.navigate(['/lobby/invoices/paid'])
            : this.setCurrentInvoiceVersionId.emit(this.invoicesService.findHighestVersionId(currentInvoice.versions, [currentVersion.id]));

          showSnackbar(this.snackBar, {
            message: 'Invoice deleted',
            duration: 10000,
            actionText: 'Undo',
            action: (async () => {
              const id = await this.invoicesService.restoreInvoiceVersion(currentVersion, currentInvoice, invoiceDeleted);
              !invoiceDeleted
                ? this.setCurrentInvoiceVersionId.emit(currentVersion.id)
                : this.router.navigate([`/invoices/${currentInvoice.workflowId}/${id}`]);
            }).bind(this),
          });
        }
      }
    });
  }

  async resendInvoiceVersion() {
    await this.invoicesService.sendInvoiceEmail(this.currentInvoiceVersion.clientInvoiceId!, this.currentInvoiceVersion.sentBy!);
    showSnackbar(this.snackBar, {
      message: 'Invoice resent',
      duration: 2000,
    });
  }

  async downloadPdf() {
    if (!this.downloading) {
      this.downloading = true;
      const filename = `invoice-${this.invoice.uid}-${this.currentInvoiceVersion.subUid}`;
      const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
      const pdfData = {
        filename,
        type: 'invoice',
        clientDomain: 'https://dev-client-slickestimates.web.app',
        businessId: business!.businessId,
        recordId: this.currentInvoiceVersion.clientInvoiceId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      const blob = await this.pdfService.generatePdf(pdfData).pipe(take(1)).toPromise();
      try {
        if (blob)
          saveAs(blob, filename);
      } catch (e) {
        console.log('Error during PDF download:', e);
        alert('PDF download failed!');
      } finally {
        this.downloading = false;
        showSnackbar(this.snackBar, {
          message: 'Invoice downloaded',
          duration: 2000,
        });
      }
    }
  }
}
