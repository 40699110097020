<div class="dialog wrapper">
    <div class="header d-flex justify-content-between align-items-center">
        <h3>{{ isEdit ? 'Edit permissions' : 'Add user' }}</h3>
        <app-icon class="se-icon-button no-bg" (click)="close()" src="close"></app-icon>
    </div>

    <form class="content" [formGroup]="form">

        <app-text-toggle *ngIf="!(isCurrentUser$ | async)" class="user-role" [showDividers]="true" [items]="roles" (onSelected)="onRoleChange($event)" [selectedItemIndex]="indexOfRole(selectedRole)"></app-text-toggle>
        
        <div>
            <div class="form-field" #firstNameInput>
                <h4>First Name</h4>
                <mat-form-field class="" appearance="outline" appFormFieldFilled *ngIf="!isEdit else firstNameValue">
                    <input matInput
                        formControlName="firstName"
                        autocomplete="off"
                        name="avoidSearch"
                        enterkeyhint="next"
                    />
                    <mat-error *ngIf="form.controls.firstName.hasError('required')">
                        First Name is required
                    </mat-error>
                </mat-form-field>
                <ng-template #firstNameValue>
                    <p class="value">{{ data!.userProfile!.firstName }}</p>
                </ng-template>
            </div>
    
            <div class="form-field" #lastNameInput>
                <h4>Last Name</h4>
                <mat-form-field class="" appearance="outline" appFormFieldFilled *ngIf="!isEdit else lastNameValue">
                    <input matInput
                        formControlName="lastName"
                        autocomplete="off"
                        name="avoidSearch"
                        enterkeyhint="next"
                    />
                    <mat-error *ngIf="form.controls.lastName.hasError('required')">
                        Last Name is required
                    </mat-error>
                </mat-form-field>
                <ng-template #lastNameValue>
                    <p class="value">{{ data!.userProfile!.lastName }}</p>
                </ng-template>
            </div>
            
            <div class="form-field" #emailInput>
                <h4>Email</h4>
                <mat-form-field class="" appearance="outline" appFormFieldFilled *ngIf="!isEdit else emailValue">
                    <input matInput
                        formControlName="email"
                        autocomplete="off"
                        name="avoidSearch"
                        enterkeyhint="next"
                    />
                    <mat-error *ngIf="form.controls.email.hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="form.controls.email.hasError('invalidFormat')">
                        Invalid format
                    </mat-error>
                    <mat-error *ngIf="form.controls.email.hasError('emailExists')">
                        This email already exists in your business
                    </mat-error>
                </mat-form-field>
                <ng-template #emailValue>
                    <p class="value">{{ data!.userProfile!.email }}</p>
                </ng-template>
            </div>
        </div>

        <app-section-divider *ngIf="selectedRole !== 'Owner'">
            <div class="info-icon-wrapper section-title-space" style="margin-bottom: 0 !important;">
                <p class="section-title">Permissions</p>
                <div class="icon" maskImageSrc="../../assets/icons/question-mark-tooltip.svg" matTooltip="Set permissions" [hideTooltipOnScroll]="permissionsTooltip" #permissionsTooltip="matTooltip" (click)="permissionsTooltip.show()"></div>
            </div>
        </app-section-divider>

        <div class="permissions" *ngIf="selectedRole !== 'Owner'">
            <div class="permission-wrapper" #markCommissionsSwitch>
                <div class="permission">
                    <p>Mark commissions as unpaid</p>
                    <div class="permission-toggle d-flex align-items-center gap--12">
                        <small class="not-allowed">{{ form.controls.markCommissions.value ? 'Allowed' : 'Not allowed' }}</small>
                        <mat-slide-toggle formControlName="markCommissions"></mat-slide-toggle>
                    </div>
                </div>
                <small *ngIf="showMarkCommissionsError" class="permission-error">Ask owner to allow this</small>
            </div>
            <div class="permission-wrapper" #markTechPartsSwitch>
                <div class="permission">
                    <p>Mark tech parts as unpaid</p>
                    <div class="permission-toggle d-flex align-items-center gap--12">
                        <small class="not-allowed">{{ form.controls.markTechParts.value ? 'Allowed' : 'Not allowed' }}</small>
                        <mat-slide-toggle formControlName="markTechParts"></mat-slide-toggle>
                    </div>
                </div>
                <small *ngIf="showMarkTechPartsError" class="permission-error">Ask owner to allow this</small>
            </div>
            <div class="permission" *ngIf="selectedRole === 'User'" #editItemsSwitch>
                <p>View or Edit Item list</p>
                <div class="permission-toggle d-flex align-items-center gap--12">
                    <small class="not-allowed">{{ form.controls.editItems.value ? 'Allowed' : 'Not allowed' }}</small>
                    <mat-slide-toggle formControlName="editItems"></mat-slide-toggle>
                </div>
            </div>
        </div>

        <app-section-divider>
            <div class="info-icon-wrapper section-title-space" style="margin-bottom: 0 !important;">
                <p class="section-title">Set Commission for sales</p>
                <div class="icon" maskImageSrc="../../assets/icons/question-mark-tooltip.svg" matTooltip="These commissions will be calculated from proposals (sales) this user created" [hideTooltipOnScroll]="salesCommissionsTooltip" #salesCommissionsTooltip="matTooltip" (click)="salesCommissionsTooltip.show()"></div>
            </div>
        </app-section-divider>

        <div class="sales-commissions" #salesCommissionsInput>

            <div class="commission-row">
                <div class="commission-value">
                    <p class="field-title">Enter value</p>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <div class="d-flex flex-fill" *ngIf="form.controls.salesCommissionType.value === '$'">
                            <div class="d-flex align-items-center mr--2">$</div>
                            <input autocomplete="off" name="avoidSearch" matInput formControlName="salesCommissionValue" type="number" (keydown)="validateKey($event, 'jobCommissionValue')">
                        </div>
                        <div class="d-flex flex-fill" *ngIf="form.controls.salesCommissionType.value === '%'">
                            <input autocomplete="off" name="avoidSearch" matInput class="tar" formControlName="salesCommissionValue" type="number" (keydown)="validateKey($event, 'salesCommissionValue')" (paste)="onPasteToCommissions($event)">
                            <div class="d-flex align-items-center ml--2" style="color: #1B1B1B;">%</div>
                        </div>
                        <div class="discount-divider"></div>
                        <app-icon src="change" class="ml--4 clickable" (click)="toggleDiscountType('salesCommissionType')"></app-icon>
                        <mat-error *ngIf="form.controls.salesCommissionValue.hasError('max')">
                            Limited up to 100%
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="form.controls.salesCommissionType.value === '%'" class="commission-value expenses">
                    <p>Expenses</p>
                    <app-text-toggle [items]="['Before', 'After']" [selectedItemIndex]="indexOfAfterMaterials(form.controls.salesCommissionAfterMaterials.value!)" (onSelected)="onAfterMaterialsChange('salesCommissionAfterMaterials', $event)"></app-text-toggle>
                </div>
            </div>
        </div>

        <app-section-divider>
            <div class="info-icon-wrapper section-title-space" style="margin-bottom: 0 !important;">
                <p class="section-title">Set Commission for jobs</p>
                <div class="icon" maskImageSrc="../../assets/icons/question-mark-tooltip.svg" matTooltip="These commissions will be calculated from jobs to which this user is assigned to" [hideTooltipOnScroll]="jobCommissionsTooltip" #jobCommissionsTooltip="matTooltip" (click)="jobCommissionsTooltip.show()"></div>
            </div>
        </app-section-divider>

        <div class="job-commissions" #jobCommissionsInput>

            <div class="commission-row">
              <div class="commission-value">
                  <p class="field-title">Enter value</p>
                  <mat-form-field appearance="outline" appFormFieldFilled>
                    <div class="d-flex flex-fill" *ngIf="form.controls.jobCommissionType.value === '$'">
                      <div class="d-flex align-items-center mr--2">$</div>
                      <input autocomplete="off" name="avoidSearch" matInput formControlName="jobCommissionValue" type="number" (keydown)="validateKey($event, 'jobCommissionValue')">
                    </div>
                    <div class="d-flex flex-fill" *ngIf="form.controls.jobCommissionType.value === '%'">
                      <input autocomplete="off" name="avoidSearch" matInput class="tar" formControlName="jobCommissionValue" type="number" (keydown)="validateKey($event, 'jobCommissionValue')" (paste)="onPasteToCommissions($event)">
                      <div class="d-flex align-items-center ml--2" style="color: #1B1B1B;">%</div>
                    </div>
                    <div class="discount-divider"></div>
                    <app-icon src="change" class="ml--4 clickable" (click)="toggleDiscountType('jobCommissionType')"></app-icon>
                    <mat-error *ngIf="form.controls.jobCommissionValue.hasError('max')">
                        Limited up to 100%
                    </mat-error>
                  </mat-form-field>
              </div>
  
              <div *ngIf="form.controls.jobCommissionType.value === '%'" class="commission-value expenses">
                  <p>Expenses</p>
                  <app-text-toggle [items]="['Before', 'After']" [selectedItemIndex]="indexOfAfterMaterials(form.controls.jobCommissionAfterMaterials.value!)" (onSelected)="onAfterMaterialsChange('jobCommissionAfterMaterials', $event)"></app-text-toggle>
              </div>
            </div>
        </div>

        <div class="divider last" *ngIf="selectedRole === 'Owner' && data?.userProfile?.role !== 'owner'"></div>

        <div *ngIf="selectedRole === 'Owner' && data?.userProfile?.role !== 'owner'" #passwordInput>
            <p class="section-title section-title-space-all-screens">Confirm your identity</p>
            <div class="commission-value password align-items-stretch" *ngIf="(providers$ | async)!.includes('Email')">
                <div class="d-flex justify-content-between align-items-center">
                    <p>Password</p>
                    <p class="simple-text-button" (click)="forgotPassword()">Forgot password?</p>
                </div>
                <mat-form-field appearance="outline" class="w-100" appFormFieldFilled>
                    <input matInput [formControl]="passwordControl" class="d-flex flex-fill"
                           [type]="showPassword ? 'text' : 'password'"
                           placeholder="Enter your password"
                           autocomplete="off"
                           name="avoidSearch"
                           enterkeyhint="go">
                    <img *ngIf="passwordControl.value!.length > 0" matSuffix [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                         (click)="showPassword = !showPassword"><mat-error *ngIf="passwordControl.hasError('required')">
                    Password is required
                </mat-error>
                    <mat-error *ngIf="passwordControl.hasError('password')">
                        Invalid password. Re-enter
                    </mat-error>
                </mat-form-field>
            </div>
            <app-providers-row *ngIf="(providers$ | async)!.includes('Google')" type="reauthenticate" (onSuccess)="transferOwnership()" (onStart)="onGoogleReauthStart()"></app-providers-row>
        </div>
    </form>

    <div *ngIf="!isEdit else editFooter" class="footer">
        <button mat-stroked-button (click)="close()">Close</button>
        <loader-button [loading]="loading" text="Add" (click)="addUser()"></loader-button>
    </div>
    <ng-template #editFooter>
        <div class="footer">
            <button mat-stroked-button (click)="close()" [style]="changes ? '' : 'width: 100%;'">Close</button>
            <loader-button [loading]="loading" *ngIf="changes" [text]="(isCurrentUser$ | async) ? 'Save' : 'Reassign'" (click)="updateUser()"></loader-button>
        </div>
    </ng-template>
</div>
