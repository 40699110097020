<div class="dialog wrapper d-flex">
    <form class="dialog wrapper d-flex flex-column w-100" *ngIf="(user$ | async) let user" (click)="clickInForm($event)" [class.block]="availabilityModalVisibleSubject | async">
        <app-mobile-modal-header
                [titleText]="isReschedule ? '' : (isEdit ? 'Edit' : (dialogTypeSubject | async)!)"
                hideOnBreakpoint="sm"
                [leftButton]="headerLeftButtonCaption"
                [rightButton]="(submitButtonText$ | async)!"
                [disableActions]="readonly"
                (onLeftButtonClick)="headerLeftButtonClick()"
                (onRightButtonClick)="submit()"
                class="flex-shrink-0"
        ></app-mobile-modal-header>

        <div *ngIf="(tabletScreen$ | async) && (showEditHeader$ | async)"
             class="header d-flex justify-content-between align-items-center">
            <h3>Edit</h3>
            <app-icon class="se-icon-button no-bg" *ngIf="!(showNotes$ | async)" (click)="close()" src="close"></app-icon>
        </div>

        <app-tabs *ngIf="data?.initialData?.dialogType === 'Lead' || !(isEdit || isReschedule)"
                class="app-tabs"
                linePosition="bottom"
                alignment="center"
                [tabs]="['Details', 'Notes & Tasks']"
                [activeTab]="(selectedTabSubject | async)!"
                (onTabSelect)="onTabSelected($event)"
        ></app-tabs>
        <app-icon *ngIf="(tabletScreen$ | async) && !(showEditHeader$ | async) && !(showNotes$ | async)" class="close-icon se-icon-button no-bg" (click)="close()"
                  src="close"></app-icon>
        <div class="position-relative flex-grow-1 h-100 d-flex flex-column" [class.overflow-auto]="!(showNotes$ | async)">
            <div class="d-flex justify-content-center my--20 mx--16 flex-shrink-0" [style.z-index]="9" *ngIf="showDialogTypeChoice && !(showNotes$ | async) && !readonly!">
                <app-text-toggle
                        [selectedItemIndex]="selectedDialogTypeIndex"
                        [items]="DIALOG_TYPES"
                        class="type-switch"
                        [highlightSelected]="true"
                        [showDividers]="true"
                        (onSelected)="setDialogType($event)"
                ></app-text-toggle>
            </div>

            <app-request-invitation-form
                #formComponent
                [readonly]="readonly!"
                [showDetails]="(showDetails$ | async)"
                [showNotes]="(showNotes$ | async)"
                [tabletScreen]="(tabletScreen$ | async)"
                [middleScreen]="(middleScreen$ | async)"
                [data]="formData"
                [isLeadDialog]="isLeadDialog"
                [isJobDialog]="isJobDialog"
                [isEstimateDialog]="isEstimateDialog"
                [isProposalDialog]="isProposalDialog"
                [emailOptional]="isLeadDialog"
                [isIOS]="isIOS"
                [isEdit]="isEdit"
                [initialData]="data?.initialData"
                (availabilityModalVisibilityChanged)="availabilityModalVisibleSubject.next(true);"
                (selectedTabSubjectValue)="selectedTabSubject.next('Details')"
                [availabilityModalVisible]="(availabilityModalVisibleSubject | async)!"
                class="flex-shrink-0"
            ></app-request-invitation-form>
            <ng-container *ngIf="!(tabletScreen$ | async) && !(showNotes$ | async)">
                <div class="mb--20 mx--16 flex-shrink-0">
                    <button
                            mat-stroked-button
                            class="w-100 mt--12"
                            *ngIf="(isEstimateDialog || isJobDialog) && checkFormFilled()"
                            (click)="openPreviewModal()"
                    >
                        Preview
                    </button>
                <div
                        *ngIf="isEdit && !readonly"
                        class="discard-estimate-button w-100 mt--16"
                        (click)="deleteRequestConfirm()"
                    >
                        <p>Delete</p>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="data?.initialData?.dialogType === 'Lead' || !(isEdit || isReschedule)"
                 class="content content-notes"
                 [class.d-none]="!(showNotes$ | async)"
            >
                <app-notes #notesComponent
                        *ngIf="(selectedBusiness$ | async) let business"
                        [isModal]="false"
                        [notes]="initialNotes"
                        (close)="selectedTabSubject.next('Details')"
                        [workflowId]="data?.initialData?.workflowId ?? null"
                        [businessId]="business.businessId"
                        [currentUser]="(currentUser$ | async)!"
                        [from]="data?.initialData?.dialogType ?? (dialogTypeSubject  | async)!"
                        [showOverlayImageViewer]="true"
                        (beforeDeleteConfirmation)="saveForm()"
                        [readonly]="readonly!"
                ></app-notes>
            </div>
        </div>

        <div *ngIf="(tabletScreen$ | async) && !(showNotes$ | async)" class="footer mt--32" #footer>
            <button type="submit" style="display: none;"></button>
            <div
                    *ngIf="(isEdit || checkFormFilled()) && !readonly"
                    class="discard-estimate-button"
                    (click)="deleteRequestConfirm()"
            >
                <p>Delete</p>
            </div>
            <button
                    mat-stroked-button
                    class="footer-button"
                    *ngIf="readonly || (isProposalDialog || isLeadDialog) && (tabletScreen$ | async) && !checkFormFilled()"
                    (click)="close()"
            >
                Close
            </button>
            <button
                    mat-stroked-button
                    class="footer-button"
                    *ngIf="!readonly && !isProposalDialog && !isLeadDialog && ((tabletScreen$ | async) || checkFormFilled())"
                    (click)="checkFormFilled() ? openPreviewModal() : close()"
            >
                {{ checkFormFilled() ? 'Preview' : 'Close' }}
            </button>
            <loader-button
                    id="dialog_button_invite"
                    *ngIf="!readonly && (tabletScreen$ | async)"
                    class="footer-button"
                    [loading]="inviting"
                    [text]="(submitButtonText$ | async)!"
                    (onClick)="submit()"
            ></loader-button>
        </div>
    </form>
    <app-availability-modal
            *ngIf="(availabilityModalVisibleSubject | async)"
            @sidePanelShowHide
            (usersAssigned)="handleUsersAssigned($event)"
            [singleAssigneeInList]="(dialogTypeSubject | async) !== 'Job'"
            (close)="availabilityModalVisibleSubject.next(false)"
            [assignedUsers]="formComponent.form.controls.assignees.value"
    >
    </app-availability-modal>
</div>

