import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { SupportChatMessage, Ticket } from "../../models/support-chat.model";
import { UserProfile } from "../../models/user-profile.model";
import { ChatMessage, MessageComponent } from "../message/message.component";
import { SupportChatService } from "../../services/support-chat.service";
import { BehaviorSubject } from "rxjs";
import { SystemUserProfile } from '../../../../../dashboard-slick-estimates/src/app/models/user-profile.model';
import { SafeUrl } from '@angular/platform-browser';
import { ComponentPortal } from '@angular/cdk/portal';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { Overlay } from '@angular/cdk/overlay';
import moment from 'moment';
import { ChatDateHeaderComponent } from '../chat-date-header/chat-date-header.component';
import { RealtimeChannel } from '@supabase/supabase-js';
import { UtilsService } from '../../services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'lib-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.scss']
})
export class SupportChatComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('tempView') tempView!: ElementRef<HTMLDivElement>;
  @ViewChild('tempView', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;
  @ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('attachmentInput') attachmentInput!: ElementRef<HTMLInputElement>;

  @Input() ticket!: Ticket | null;
  @Input() messages!: SupportChatMessage[];
  @Input() currentUser!: UserProfile | SystemUserProfile;
  @Input() messageClass?: string;
  @Input() businessId?: string;
  @Input() showMessages = true;
  @Input() ticketsHistoryMode = false;

  private messageComponents: ComponentRef<MessageComponent>[] = [];
  private selfMessages: SupportChatMessage[] = [];
  private otherMessages: SupportChatMessage[] = [];

  unseenMessagesIds: number[] = [];
  hideIndicator = true;
  initializing = true;
  isViewInitialized = false;
  messagesChannel!: RealtimeChannel;
  scrollObserver: IntersectionObserver | null = null;
  attachmentSubject = new BehaviorSubject<File | null>(null);
  draggingAttachment = false;
  selectedFile?: File;
  selectedFileImage?: string;
  selectedFileVideo?: string;
  dialogMessageInput = this.chatService.messageInput;

  constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private chatService: SupportChatService,
      private overlay: Overlay,
      private utilsService: UtilsService,
      @Inject(DOCUMENT) private document: Document,
  ) {}

  get messagesCount() {
    return this.selfMessages.length + this.otherMessages.length;
  }

  async ngAfterViewInit() {
    this.isViewInitialized = true;
    
    if (!this.ticketsHistoryMode) {
      const options = {
        root: this.scrollContainer.nativeElement,
        rootMargin: '0px',
        threshold: .8,
      };
      this.scrollObserver = new IntersectionObserver(this.onScrollObserverEvent.bind(this), options);
    }

    await this.init();
    
    this.onFocusIn = this.onFocusIn.bind(this);
    if (!this.ticketsHistoryMode)
      document.addEventListener('focusin', this.onFocusIn);

    this.updateHeadersVisibility = this.updateHeadersVisibility.bind(this);
    this.scrollContainer.nativeElement.addEventListener('scroll', this.updateHeadersVisibility);
  }

  ngOnDestroy() {
    if(this.messagesChannel)
      this.chatService.unsubscribe(this.messagesChannel);
    this.scrollContainer.nativeElement.removeEventListener('scroll', this.updateHeadersVisibility);
    if (!this.ticketsHistoryMode)
      document.removeEventListener('focusin', this.onFocusIn);
  }

  async onScrollObserverEvent(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    if (!document.hasFocus()) {
      for (const entry of entries) {
        observer.unobserve(entry.target);
        observer.observe(entry.target);
      }
      return;
    }
    for (const entry of entries) {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        const id = +entry.target.id;
        this.chatService.markAsSeen(id);
        const index = this.unseenMessagesIds.indexOf(id);
        if (index > -1)
          this.unseenMessagesIds.splice(index, 1);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('ticket' in changes && this.isViewInitialized && (!changes.ticket.currentValue || !changes.ticket.previousValue))
      this.init();
  }

  async init() {
    if (!this.ticket) {
      this.messages = [];
      this.selfMessages = [];
      this.otherMessages = [];
      this.unseenMessagesIds = [];
      this.messageComponents = [];
      this.viewContainerRef.clear();
      this.initializing = false;
      if(this.messagesChannel)
        this.chatService.unsubscribe(this.messagesChannel);
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.messages = await this.chatService.getInitialMessages(this.ticket.id);
    this.messagesChannel = await this.chatService.subscribeToMessages(
      this.ticket.id,
      (message) => this.addNewMessage(message),
      (message) => this.updateMessage(message),
    );
    
    this.unseenMessagesIds = this.messages.filter(message => message.senderUuid !== this.currentUser.userUuid && !message.seen).map(m => m.id);
    if (this.tempView)
      this.tempView.nativeElement.remove();

    for (const message of this.messages)
      await this.addMessage(message, true);
    
    if (this.scrollContainer.nativeElement.scrollHeight > this.scrollContainer.nativeElement.clientHeight) {
      this.scrollContainer.nativeElement.addEventListener('scrollend', _ => {
        setTimeout(() => this.hideIndicator = false);
        this.initializing = false;
      }, { once : true });
    } else {
      this.initializing = false;
    }

    this.scrollToFirstOtherUnseen();
  }

  onFocusIn() {
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;
    if (scrollHeight > clientHeight)
      return;
    
    for (const message of this.otherMessages) {
      if (!message.seen)
        this.chatService.markAsSeen(message.id);
    }
  }

  updateHeadersVisibility() {
    const elements = this.document.getElementsByTagNameNS('http://www.w3.org/1999/xhtml', 'lib-chat-date-header');
    const contentRect = this.scrollContainer.nativeElement.getBoundingClientRect();
    let firstSet = false;
    for (let i = elements.length - 1; i > -1; i--) {
      const stick = elements[i].getBoundingClientRect().top === contentRect.top + parseInt(getComputedStyle(elements[i]).top);
      if(!stick) {
        elements[i].style.opacity = '1';
      } else if(!firstSet) {
        firstSet = true;
        elements[i].style.opacity = '1';
      } else {
        elements[i].style.opacity = '0';
      }
    }
  }

  scrollToFirstOtherUnseen(delay?: number, smooth = false) {
    setTimeout((() => {
      if(this.unseenMessagesIds.length > 0) {
        const message = this.otherMessages.find(message => !message.seen);
        if(message)
          document.getElementById(message.id + '')?.scrollIntoView({ block: 'start', behavior: smooth ? 'smooth' : 'auto' });
      } else {
        this.scrollContainer.nativeElement.scrollTo({
          top: this.scrollContainer.nativeElement.scrollHeight,
        });
      }
      if (this.scrollContainer.nativeElement.scrollHeight <= this.scrollContainer.nativeElement.clientHeight && this.unseenMessagesIds.length === 0) {
        setTimeout(() => this.hideIndicator = false);
      }
    }).bind(this), delay ?? 0);
  }

  async addNewMessage(message: SupportChatMessage) {
    this.messages.push(message);
    const scrollFromBottom = this.scrollContainer.nativeElement.scrollTop + this.scrollContainer.nativeElement.clientHeight;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;
    const threshold = 60;

    await this.addMessage(message);
    if (message.senderUuid !== this.currentUser.userUuid && !message.seen) {
      this.unseenMessagesIds.push(message.id);
    }

    if(message.senderUuid === this.currentUser.userUuid || ((scrollFromBottom + threshold) >= scrollHeight && scrollHeight > clientHeight && document.hasFocus())) {
      if (message.senderUuid !== this.currentUser.userUuid)
        this.hideIndicator = true;

      setTimeout((() => {
        this.scrollContainer.nativeElement.scrollTo({
          top: this.scrollContainer.nativeElement.scrollHeight,
          behavior: 'smooth'
        });
      }).bind(this));
    }
  }

  async addMessage(message: SupportChatMessage, force = false) {
    let component;

    const previousMessageIndex = this.messages.findIndex(m => m.id === message.id) - 1;
    if (previousMessageIndex >= -1 && (previousMessageIndex === -1 || !moment(this.messages[previousMessageIndex].createdAt).isSame(message.createdAt, 'date'))) {
      component = this.viewContainerRef.createComponent(ChatDateHeaderComponent);
      component.instance.date = message.createdAt;
    }

    component = this.viewContainerRef.createComponent(MessageComponent);
    this.messageComponents.push(component as ComponentRef<MessageComponent>);
    if (message.attachment)
      component.instance.imageClicked.subscribe(path => this.showImageViewer(path));

    if (this.scrollObserver && message.senderUuid !== this.currentUser.userUuid && !message.seen)
      this.scrollObserver.observe(component.location.nativeElement);

    component.instance.message = this.convertMessage(message);
    component.instance.supportChat = true;

    const element = component.location.nativeElement as HTMLElement;
    element.id = message.id+'';
    if(this.messageClass)
      element.classList.add(this.messageClass);

    const arr = message.senderUuid === this.currentUser.userUuid ? this.selfMessages : this.otherMessages;
    arr.push(message);

    if(message.seen)
      this.updateSeen(message);
  }

  updateMessage(message: SupportChatMessage) {
    const prevSelfIndex = this.selfMessages.findIndex(m => m.id === message.id);
    if(prevSelfIndex !== -1) {
      const prevSelf = this.selfMessages.splice(prevSelfIndex, 1, message)[0];
      this.updateLastSeenMessage(message, prevSelf);
      return;
    }

    const prevOtherIndex = this.otherMessages.findIndex(m => m.id === message.id);
    if (prevOtherIndex === -1)
      return;

    const prevOther = this.otherMessages.splice(prevOtherIndex, 1, message)[0];
    if (message.senderUuid !== this.currentUser.userUuid && !prevOther.seen && message.seen) {
      const index = this.unseenMessagesIds.indexOf(message.id);
      if (index > -1)
        this.unseenMessagesIds.splice(index, 1);
      this.hideIndicator = false;
    }
  }

  updateLastSeenMessage(message: SupportChatMessage, prev: SupportChatMessage) {
    if(message.senderUuid !== this.currentUser.userUuid)
      return;

    if(prev.seen)
      return;

    this.updateSeen(message);
  }

  updateSeen(message: SupportChatMessage) {
    if (!message.seen || message.senderUuid !== this.currentUser.userUuid)
      return;

    const messageComponent = this.messageComponents.find(comp => comp.location.nativeElement.id === message.id + '');
    if (messageComponent) {
      messageComponent.instance.message.status = 'seen';
    }
    this.changeDetectorRef.detectChanges();
  }

  convertMessage(message: SupportChatMessage): ChatMessage {
    const baseMessage = {
      id: message.id,
      createdAt: message.createdAt,
      attachment: message.attachment ? {
        bucket: 'support',
        path: message.attachment,
      } : null,
      content: message.content,
    };
    let convertedMessage: ChatMessage;
    if (message.senderUuid !== this.currentUser.userUuid || this.ticketsHistoryMode && this.ticket?.businessUserUuid === message.senderUuid) {
      const firstName =  this.ticket?.businessUserUuid === message.senderUuid ? this.ticket?.businessUserFirstName : this.ticket?.systemUserFirstName;
      const lastName = this.ticket?.businessUserUuid === message.senderUuid ? this.ticket?.businessUserLastName! : this.ticket?.systemUserLastName;
      convertedMessage = {
        ...baseMessage,
        status: 'foreign',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      };
    } else {
      convertedMessage = {
        ...baseMessage,
        status: message.seen ? 'seen' : 'delivered',
      };
    }
    return convertedMessage;
  }

  onDrag(dragging: boolean, event: DragEvent) {
    if (this.ticket && this.currentUser.userUuid !== this.ticket.businessUserUuid && this.ticket.status !== 'opened')
      return;
    if(event.dataTransfer?.items.length) {
      const type = event.dataTransfer?.items[event.dataTransfer?.items.length - 1].type;
      if(type.startsWith('image') || type.startsWith('video')) {
        this.draggingAttachment = dragging;
      }
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.items[0]?.getAsFile();
    if(file)
      this.onFileChange(file);
  }

  async onFileChange(file: File) {
    this.draggingAttachment = false;
    this.selectedFile = file;
    const fileType = file.type;
    if (fileType.startsWith('image/')) {
      this.selectedFileImage = await this.fileToString(this.selectedFile);
    } else if (fileType.startsWith('video/')) {
      this.selectedFileVideo = await this.fileToString(this.selectedFile);
    } else {
      return;
    }

    this.dialog.nativeElement.showModal();
  }

  fileToString(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ((event: ProgressEvent<FileReader>) => {
        resolve(event.target!.result as string);
      }).bind(this);
      reader.readAsDataURL(file);
    })
  }

  closeImageDialog() {
    this.selectedFile = undefined;
    this.selectedFileImage = undefined;
    this.selectedFileVideo = undefined;
    this.chatService.messageInput.setValue('')
    this.dialog.nativeElement.close();
  }

  onDialogKeyDown(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter' && this.dialogMessageInput.value!.trim().length > 0) {
      event.preventDefault();
      this.sendAttachmentMessage();
    }
  }

  async sendAttachmentMessage() {
    const splitName = this.selectedFile!.name.split('.');
    const fileName = this.utilsService.randomString(16) + '.' + splitName[splitName.length - 1];
    const content = this.dialogMessageInput.value!.trim() ?? '';
    let ticket = this.ticket;
    if (!ticket) {
      if (!this.businessId)
        return;
      ticket = await this.chatService.createTicket(this.businessId, this.currentUser.userUuid);
    }
    const path = `${ticket!.id}/${fileName}`;
    this.chatService.uploadImage(this.selectedFile!, path).then(_ => {
      this.chatService.sendMessage(this.ticket!.businessUuid, this.ticket!.businessUserUuid, this.currentUser.userUuid, content, path);
    });

    this.closeImageDialog();
  }

  showImageViewer(imageUrl: SafeUrl | string) {
    const viewer = new ComponentPortal(ImageViewerComponent);
    const overlayRef = this.overlay.create(
      {
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        panelClass: 'image-viewer-overlay'
      },
    );
    const viewerRef = overlayRef.attach(viewer);
    viewerRef.setInput('imageUrl', imageUrl);
    viewerRef.setInput('showDownload', true);
    const subscription = viewerRef.instance.onClose.subscribe(() => {
      overlayRef.detach();
      viewer.viewContainerRef?.clear();
      subscription.unsubscribe();
    });
  }
}
