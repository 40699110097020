import { SupabaseService } from "../../services/supabase.service";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { OverlookReport } from "../../models/reports.model";

export function overlookPendingProposals(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_pending_proposals',
      schema: businessId,
      fn: 'overlook_pending_proposals',
      tables: [
        'proposal',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookPendingJobs(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_pending_jobs',
      schema: businessId,
      fn: 'overlook_pending_jobs',
      tables: [
        'job',
        'job_range',
        'job_user',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookPendingInvoices(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_pending_invoices',
      schema: businessId,
      fn: 'overlook_pending_invoices',
      tables: [
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookCompletedProposals(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_completed_proposals',
      schema: businessId,
      fn: 'overlook_completed_proposals',
      tables: [
        'proposal',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookCompletedJobs(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_completed_jobs',
      schema: businessId,
      fn: 'overlook_completed_jobs',
      tables: [
        'job',
        'job_range',
        'job_user',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookCompletedInvoices(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_completed_invoices',
      schema: businessId,
      fn: 'overlook_completed_invoices',
      tables: [
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookJobsCompleted(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_jobs_completed',
      schema: businessId,
      fn: 'overlook_jobs_completed',
      tables: [
        'job',
        'payment',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookJobsCanceled(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_jobs_cancelled',
      schema: businessId,
      fn: 'overlook_jobs_cancelled',
      tables: [
        'job',
        'payment',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookAvgSalesPerDay(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_average_sales_sales_per_day',
      schema: businessId,
      fn: 'overlook_average_sales_sales_per_day',
      tables: [
        'payment',
        'invoice',
        'proposal'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookAvgJobsPerDay(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_average_sales_jobs_per_day',
      schema: businessId,
      fn: 'overlook_average_sales_jobs_per_day',
      tables: [
        'payment',
        'invoice',
        'proposal'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookLeadsToComplete(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_conversions_leads_to_complete',
      schema: businessId,
      fn: 'overlook_conversions_leads_to_complete',
      tables: [
        'lead',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookEstimatesToComplete(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_conversions_estimates_to_complete',
      schema: businessId,
      fn: 'overlook_conversions_estimates_to_complete',
      tables: [
        'estimate',
        'estimate_ranges',
        'invoice'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookInvoicesToComplete(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_conversions_invoices_to_complete',
      schema: businessId,
      fn: 'overlook_conversions_invoices_to_complete',
      tables: [
        'invoice',
        'proposal'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookProposalsToJobs(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_average_time_proposals_to_jobs',
      schema: businessId,
      fn: 'overlook_average_time_proposals_to_jobs',
      tables: [
        'job',
        'proposal'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookTimeOfAJob(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_average_time_time_of_a_job',
      schema: businessId,
      fn: 'overlook_average_time_time_of_a_job',
      tables: [
        'job',
        'job_ranges'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function overlookInvoiceToCompleted(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'overlook_average_time_invoices_to_completed',
      schema: businessId,
      fn: 'overlook_average_time_invoices_to_completed',
      tables: [
        'invoice',
        'payment'
      ],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initOverlook(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    overlookPendingProposals(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookPendingJobs(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookPendingInvoices(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookCompletedProposals(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookCompletedJobs(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookCompletedInvoices(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookJobsCompleted(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookJobsCanceled(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookAvgSalesPerDay(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookAvgJobsPerDay(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookLeadsToComplete(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookEstimatesToComplete(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookInvoicesToComplete(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookProposalsToJobs(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookTimeOfAJob(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    overlookInvoiceToCompleted(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
  ]).pipe(
    map(([
      pendingProposals,
      pendingJobs,
      pendingInvoices,
      completedProposals,
      completedJobs,
      completedInvoices,
      jobsCompleted,
      jobsCanceled,
      avgSalesPerDay,
      avgJobsPerDay,
      leadsToComplete,
      estimatesToComplete,
      invoicesToComplete,
      proposalsToJobs,
      timeOfAJob,
      invoiceToCompleted
         ]) => {
      return {
        pendingProposals: pendingProposals as unknown as number,
        pendingJobs: pendingJobs as unknown as number,
        pendingInvoices: pendingInvoices as unknown as number,
        completedProposals: completedProposals as unknown as number,
        completedJobs: completedJobs as unknown as number,
        completedInvoices: completedInvoices as unknown as number,
        jobsCompleted: jobsCompleted as unknown as number,
        jobsCanceled: jobsCanceled as unknown as number,
        avgSalesPerDay: avgSalesPerDay as unknown as number,
        avgJobsPerDay: avgJobsPerDay as unknown as number,
        leadsToComplete: leadsToComplete as unknown as number,
        estimatesToComplete: estimatesToComplete as unknown as number,
        invoicesToComplete: invoicesToComplete as unknown as number,
        proposalsToJobs: proposalsToJobs as unknown as number,
        timeOfAJob: timeOfAJob as unknown as number,
        invoiceToCompleted: invoiceToCompleted as unknown as number
      } as OverlookReport;
    })
  );
}
