<form [formGroup]="form" class="wrap" [class.column]="layout === 'column'" [class.disabled]="disabled">
    <mat-form-field class="ampm" appearance="outline">
        <div class="d-flex justify-content-between align-items-center">
            <div class="trigger-area" (click)="ampmAutocomplete.isOpen ? ampmTrigger.closePanel() : (disabled ? null : ampmTrigger.openPanel())"></div>
            <input matInput #ampmInput
                formControlName="ampm"
                [matAutocomplete]="ampmAutocomplete"
                #ampmTrigger="matAutocompleteTrigger"
                class="input-ampm"
                [anchorAutocompleteOnScroll]="ampmTrigger"
                [readonly]="disabled"
            >
            <mat-icon class="suffix rotate" [class.do]="ampmAutocomplete.isOpen">expand_more</mat-icon>
        </div>
        <mat-autocomplete #ampmAutocomplete="matAutocomplete" (optionSelected)="onTimeSelected($event)">
            <mat-option value="AM" class="am-pm-option">
                AM
            </mat-option>
            <mat-option value="PM" class="am-pm-option">
                PM
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="time w-100" appearance="outline" appFormFieldFilled [class.mat-form-field-invalid]="control?.errors" [class.disabled]="disabled">
        <input #timeInput
            formControlName="time"
            matInput
            placeholder="-- : --"
            autocomplete="off"
            inputmode="numeric"
            [matAutocomplete]="timeAutocomplete"
            #timeTrigger="matAutocompleteTrigger"
            [tabindex]="tabindex"
            (input)="maskInput($event, 'time', '00:00')"
            (focusin)="focusin.emit()"
            (focusout)="onTimeSelected($event); focusout.emit()"
            (keydown)="handleBackspace($event)"
            [anchorAutocompleteOnScroll]="timeTrigger"
            [readonly]="disabled"
        >
        <mat-autocomplete #timeAutocomplete="matAutocomplete" (optionSelected)="onTimeSelected($event)">
            <mat-option class="time-suggestion" *ngFor="let option of (timeSuggestions$ | async)" [value]="option">
                {{ option }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div class="divider"></div>
    <mat-error *ngIf="control?.hasError('required') && timeControl.touched" class="position-relative" @showError>
        Choose time
    </mat-error>
    <mat-error *ngIf="control?.hasError('time') && timeControl.touched" class="position-relative" @showError>
        Invalid time
    </mat-error>
</form>
