<ng-container *ngIf="nav$ | async let nav">
    <div class="main h-100 w-100 d-flex flex-column justify-content-start" style="overflow: hidden !important;">

        <div class="content flex-fill h-100">
            <div class="container flex-fill d-flex flex-column h-100">

                <div class="header" fadeIn="500" [fadeInDelay]="200">
                    <div class="tabs d-flex align-items-center tabs-pos">
                        <app-tabs
                            linePosition="bottom"
                            alignment="start"
                            [tabs]="tabs"
                            [activeTab]="nav.tab!"
                            [redDots]="[false, false, false, false]"
                            (onTabSelect)="onTabSelected($event)"
                        ></app-tabs>

                    </div>

                    <div class="d-flex align-items-center justify-content-between mt--16 pl--16">
                        <app-date-range class="date-range-picker"
                                        [selected]="(dateFilter$ | async)"
                                        (change)="selectedRangeChange($event)"
                        >
                        </app-date-range>
                        <div class="commission-legend-wrapper">
                            <div class="commission-legend">
                                <div class="legend-dot sales-dot"></div>
                                <div class="legend-label">Sales</div>
                            </div>
                            <div class="commission-legend">
                                <div class="legend-dot jobs-dot"></div>
                                <div class="legend-label">Jobs</div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="report$ | async let report">
                    <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!report">
                        <mat-spinner class="m-auto" [diameter]="40"></mat-spinner>
                    </div>
                    <ng-container *ngIf="report">
                        <app-sales-summary [selectedTab]="getSelectedTab(nav.tab)" [data]="report" fadeIn="500" [fadeInDelay]="200"></app-sales-summary>
                        <div class="jobs-count-and-mark_as_paid-button" [class.no-checkbox]="getSelectedTab(nav.tab) !== 'commissionsPending' || report.count === 1">
                            <div class="d-flex align-items-center">
                                <div class="select-all" *ngIf="getSelectedTab(nav.tab) === 'commissionsPending'">
                                    <mat-checkbox class="checkbox" [color]="'primary'"
                                                  [checked]="isAllSelected($any(report).allIds)"
                                                  *ngIf="$any(report).allIds.length > 1"
                                                  (change)="checkAllSelected($event.checked, $any(report).allIds)"
                                    ></mat-checkbox>
                                </div>
                                <div class="jobs-count-container">
                                    <h4 class="jobs-count" *ngIf="report.count">{{ report.count }} Commission{{ report.count > 1 ? 's' : null }}</h4>
                                </div>
                            </div>
                            <div class="mark_as_paid-button" *ngIf="selectedCommissionIds.size > 0" (click)="markAsPaid()">
                                <h4>Mark as Paid</h4>
                            </div>
                        </div>
                        <app-commissions-report-items [current]="$any(nav.tab)" class="flex-fill" fadeIn="500" [fadeInDelay]="200"></app-commissions-report-items>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="only-mobile-sm show-before-sm bottom tabs primary-scrollbar">
            <app-tabs
                linePosition="top"
                [tabs]="pages"
                [activeTab]="nav.page"
                (onTabSelect)="onPageSelected($event)"
            ></app-tabs>
        </div>
    </div>
</ng-container>
