import { Component, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, NgControl, UntypedFormControl } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BusinessAddressComponents, PlacesService } from '../../../../../common/src/lib/services/places.service';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import { BusinessService } from '../../services/business.service';
import { AddressComponents, addressComponentsEmpty } from 'projects/common/src/lib/models/address-comments.model';
import { componentsToString } from "../../../../../common/src/lib/pipes/address.pipe";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.scss']
})
export class CreateBusinessComponent {

  @ViewChild("businessInput") businessInput!: ElementRef;
  @ViewChild("addressInput") addressInput!: ElementRef;
  @ViewChild("phoneNumberInput") phoneNumberInput!: ElementRef;

  @ViewChild("firstNameInput") firstNameInput!: ElementRef;
  @ViewChild("lastNameInput") lastNameInput!: ElementRef;
  @ViewChild("passwordInput") passwordInput!: ElementRef;

  @ViewChild('addressInput', {read: NgControl})
  addressInputDirective!: NgControl

  form = this.formBuilder.group({
    businessName: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    phoneNumber: new UntypedFormControl(''),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
  });

  businessNameFocused = false;
  addressFocused = false;
  phoneNumberFocused = false;

  firstNameFocused = false;
  lastNameFocused = false;

  hasBusinesses$ = this.businessService.availableBusinesses$.pipe(
    map(businesses => !businesses ? false : businesses.length > 0)
  );

  creating = false;

  businessNameAutocompleteOptions$!: Observable<google.maps.places.AutocompletePrediction[] | null>;
  addressAutocompleteOptions$!: Observable<google.maps.places.AutocompletePrediction[] | null>;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private placesService: PlacesService,
    private formsService: FormsService,
    private businessService: BusinessService,
    private authService: AuthService,
  ) {}

  async ngAfterViewInit() {
    this.businessNameAutocompleteOptions$ = await this.placesService.getPlaceAutocomplete(this.businessInput, 'establishment');
    this.addressAutocompleteOptions$ = await this.placesService.getPlaceAutocomplete(this.addressInput, 'address');
    this.businessInput.nativeElement.focus();
  }

  onBusinessNameOptionSelected(place: BusinessAddressComponents) {
    this.form.controls.businessName.setValue(place.name);
    // this.onAddressOptionSelected(place);
  }

  onAddressOptionSelected(place: AddressComponents) {
    this.addressInputDirective.valueAccessor?.writeValue(componentsToString(place));
    this.form.controls.address.setValue(place, { emitModelToViewChange: false });
    this.form.controls.address.setErrors(null);
    
    if(addressComponentsEmpty(place)) {
      this.form.controls.address.setErrors({ missingComponents: true });
      this.form.controls.address.markAsTouched();
    } else {
      this.form.controls.address.setErrors(null);
    }
  }
  
  formValid(): boolean {
    const valid = this.formsService.validateRequired(
      this.form.get('businessName')!, this.form.get('address')!,
      this.form.get('phoneNumber')!,
      this.form.get('firstName')!, this.form.get('lastName')!,
    );
    
    let stringValid = (typeof this.form.controls.address.value !== 'string');
    if(!stringValid) {
      this.form.controls.address.setErrors({
        addressIsString: true
      });
    } else if(addressComponentsEmpty(this.form.controls.address.value)) {
      
      stringValid = false;
      this.form.controls.address.setErrors({
        missingComponents: true
      });
    }

    if (!this.form.valid || !stringValid) {
      return false;
    }

    return valid;
  }

  private unfocusAll() {
    this.businessInput.nativeElement.blur();
    this.addressInput.nativeElement.blur();
    this.phoneNumberInput.nativeElement.blur();
    this.firstNameInput.nativeElement.blur();
    this.lastNameInput.nativeElement.blur();
  }

  async create() {
    this.creating = true;
    this.unfocusAll();
    if(this.formValid()) {
      const user = await this.authService.user$.pipe(take(1)).toPromise();
      const data = {
        userUuid: user!.uid,
        businessName: this.form.value.businessName,
        address: this.form.value.address,
        phoneNumber: this.form.value.phoneNumber,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        email: user!.email
      };
      await this.businessService.createBusiness(data);
    } else {
      this.form.markAllAsTouched();
    }
    this.creating = false;
  }

  back() {
    this.location.back();
  }
}
