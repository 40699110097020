import { CommonPoIItem } from "../models/poi.model";

export function currencify(value: number | null, thousandSeparation = false, scale: 'none' | 'K' | 'M' | 'B' = 'none'): string {
  if ((typeof value) !== 'number')
    return '$0.00';

  if (!value || value === 0)
    return '$0.00';

  const scales = {
    none: 1,
    K: 1000,
    M: 10 ** 6,
    B: 10 ** 9
  };
  let result = Math.abs(value / scales[scale]).toFixed(2);
  if (thousandSeparation)
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${value < 0 ? '-' : ''}$${result}${scale === 'none' ? '' : scale}`;
}

export function round(value: number | null): string {
  if (!value || value === 0)
    return '0';

  return `${value.toFixed(2)}`;
}

export function formatMinutes(minutes: number): string {
  if (isNaN(minutes) || minutes <= 0) {
    return '0';
  }

  const hours = +round(Math.floor(minutes / 60));
  const remainingMinutes = +round(minutes % 60);

  const hoursString = hours > 0 ? `${hours}h` : '';
  const minutesString = remainingMinutes > 0 ? `${remainingMinutes}m` : '';

  return `${hoursString} ${minutesString}`.trim();
}

export function capitalize(text: string): string {
  if (!text)
    return '';

  return text[0].toUpperCase() + text.substring(1, text.length);
}

export function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

export function getItemValues(items: CommonPoIItem[], taxPercentage: number) {
  let total = 0;
  let discount = 0;
  let tax = 0;
  let cost = 0;

  items.forEach(item => {
    const discounted = !item.discountAmount
      ? item.price
      : item.price - item.discountAmount;

    const taxed = !item.taxable
      ? discounted
      : discounted + (discounted*taxPercentage/100);

    if (item.cost && item.quantity)
      cost += item.cost*item.quantity;
    discount += item.quantity && item.discountAmount ? (item.discountAmount * item.quantity) : 0;
    tax += item.taxable && item.quantity && item.price ? (taxed - discounted)*item.quantity : 0;
    total += item.quantity ? taxed*item.quantity : 0;
  });

  return {
    total: total,
    discount: discount,
    tax: tax,
    cost: cost
  };
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2)
    return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null)
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length)
    return false;

  for (const key of keys1)
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
      return false;

  return true;
}
