import { Component, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { ItemsService, limitStep } from "../../services/items.service";
import { CreateOrEditItemComponent } from "../../modals/create-or-edit-item/create-or-edit-item.component";
import { CreateOrEditModalData, Item } from "../../models/item.model";
import {
    ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ItemUploadComponent } from "../../modals/item-upload/item-upload.component";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FormControl } from "@angular/forms";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { ItemExampleComponent } from "../../modals/item-example/item-example.component";
import { BehaviorSubject, Observable } from "rxjs";
import { isEmpty } from "../../helpers/forms";
import { BusinessService } from "../../services/business.service";
import { DynamicListComponent, DynamicListEvent, PaginationListItem } from "../../components/dynamic-list/dynamic-list.component";
import { Pagination } from "../../services/pagination.service";
import { ItemTileComponent } from "../../components/item/item-tile.component";
import { SupabaseService } from "../../services/supabase.service";
import { UsersService } from "../../services/users.service";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

export const canOpenItemsPage = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const usersService = inject(UsersService);
    const router = inject(Router);
    const currentUser = await usersService.currentUser$.pipe(take(1)).toPromise();
    if(currentUser.role !== 'user' || currentUser.permissions.includes('edit_items'))
        return true;

    return router.createUrlTree(['/lobby']);
}

function itemsToPaginationList(items: Item[]) {
    return items.map(item => ({
        componentClass: ItemTileComponent,
        header: 'Items',
        args: {item}
    }));
}

const SORT_BY_KEY = 'SE_SORT_BY';

@Component({
    selector: 'app-items',
    templateUrl: './items.component.html',
    styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit, OnDestroy {

    @ViewChild('mobileSearch') mobileSearch!: any;
    @ViewChild('input') input: any;
    @ViewChild('list') dynamicList!: DynamicListComponent<ItemTileComponent>;
    @ViewChild('searchFilterList') searchFilterList!: DynamicListComponent<ItemTileComponent>;

    loading$ = new BehaviorSubject<boolean>(true);

    searchOn: boolean = false;

    total$ = this.itemsService.total$;

    user$ = this.authService.user$;

    businessInfo$: Observable<{
        salesTaxPercentage: number,
        businessId: string
    }> = this.businessService.selectedBusiness$.pipe(
        filter(businessProfile => !!businessProfile),
        map(businessProfile => {
            return {
                salesTaxPercentage: businessProfile!.salesTax!,
                businessId: businessProfile!.businessId
            };
        })
    );

    searchFormControl = new FormControl();
    searchResults$ = this.searchFormControl.valueChanges.pipe(
      switchMap(async value => {
          if (!value || value === '')
              return null;
          return this.itemsService.itemsByName(value, this.sortBy);
      }),
      map(items => {
          if (!items)
              return null;
          return itemsToPaginationList(items);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    sortBy: 'date' | 'name' = 'name';

    openedMobileSearch = false;
    noItems$ = new BehaviorSubject<boolean>(true);
    pagination!: Pagination<Item>;
    initialItems!: PaginationListItem<ItemTileComponent>[];

    constructor(
        private authService: AuthService,
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private itemsService: ItemsService,
        private modalsService: ModalsService,
        private router: Router,
        private snackbar: MatSnackBar
    ) {
        const sortBy = localStorage.getItem(SORT_BY_KEY);
        if(sortBy)
            this.sortBy = sortBy as any;
    }

    backClick() {
        this.router.navigate(['lobby']);
    }

    async changeSort(sort: typeof this.sortBy) {
        if(sort === this.sortBy)
            return;
        this.sortBy = sort;
        this.searchFormControl.setValue(this.searchFormControl.value, {emitEvent: true})
        localStorage.setItem(SORT_BY_KEY, sort);
        this.loading$.next(true);
        this.dynamicList.clear();
        await this.loadItems(0);
        this.loading$.next(false);
    }

    async ngOnInit() {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<Item>(this.supabaseService, 'get_items', business.businessId)
            .on('INSERT', 'item', payload => this.insertItem(payload.new))
            .on('UPDATE', 'item', payload => this.updateItem(payload.new))
            .on('DELETE', 'item', payload => this.removeItem((payload.old as Item).id));
        
        const inLimit = limitStep;
        const items = await this.pagination.get({ inLimit, inOffset: 0, inOrdering: this.sortBy });
        if(items.length < inLimit)
            this.dynamicList.canLoadMoreBottom = false;
        this.initialItems = itemsToPaginationList(items);
        this.loading$.next(false);
    }

    async loadItems(inOffset = 0) {
        const inLimit = limitStep;
        const items = await this.pagination.get({ inLimit, inOffset, inOrdering: this.sortBy });
        if(items.length < inLimit)
            this.dynamicList.canLoadMoreBottom = false;

        await this.dynamicList.addAtBottom(
            itemsToPaginationList(items)
        );
    }

    async handleItemEvent(event: DynamicListEvent<Item>) {
        const businessInfo = await this.businessInfo$.pipe(take(1)).toPromise();
        switch (event.eventEmitterName) {
            case 'itemClick':
                this.openCreateOrEditItemModal({
                    mode: 'edit',
                    item: event.value,
                    salesTaxPercentage: businessInfo.salesTaxPercentage,
                    businessId: businessInfo.businessId
                })
                break;
            case 'deleteItem':
                this.deleteItem(event.value);
        }
    }

    async deleteItem(item: Item) {
        await this.modalsService.open(ConfirmationDialog, {
            behavior: ModalBehavior.Dialog,
            data: {
                title: 'Delete Item',
                message: 'Are you sure you want to delete this item?',
                actionTitle: 'Delete',
                cancelTitle: 'No',
                action: async () => {
                    await this.itemsService.deleteItem(item.id);
                    showSnackbar(this.snackbar, {
                        message: 'Item deleted',
                        duration: 10000,
                        actionText: 'Undo',
                        action: (async () => {
                            await this.restoreDeletedItem(item);
                        }).bind(this),
                    });
                },
                actionColor: 'warn',
            }
        });
    }

    async openCreateOrEditItemModal(data: CreateOrEditModalData) {
        await this.modalsService.open(CreateOrEditItemComponent, {
            behavior: ModalBehavior.Auto,
            disableClose: true,
            data: {
                mode: data.mode,
                item: data.item,
                salesTaxPercentage: data.salesTaxPercentage,
                businessId: data.businessId
            }
        });
    }

    private async restoreDeletedItem(item: Item) {
        const extractedItem: any = {...item};
        delete extractedItem.id;
        await this.itemsService.createItem(extractedItem, item.createdAt);
    }

    async openItemUpload() {
        const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())?.businessId
        await this.modalsService.open(ItemUploadComponent, {
            behavior: ModalBehavior.Dialog,
            disableClose: true,
            data: {
                businessId
            }
        });
    }

    async openItemExample() {
        await this.modalsService.open(ItemExampleComponent, {
            behavior: ModalBehavior.Dialog,
            disableClose: true
        });
    }

    handleMobileSearch(open: boolean) {
        if (open) {
            this.openedMobileSearch = true;
            setTimeout(() => {
                this.mobileSearch.nativeElement.focus();
            }, 0);
        } else {
            if (isEmpty(this.searchFormControl.value)) {
                this.openedMobileSearch = false;
            }
        }
    }

    canInsertOrUpdate(item: Item) {

        const smallerThan = ((a: Item, b: Item) => {
            if(this.sortBy === 'date')
                return a.createdAt > b.createdAt;
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase()) < 0;
        });

        if(this.dynamicList.canLoadMoreBottom) {
            const lastItem = this.dynamicList.getLastComponent<ItemTileComponent>()!.instance.item;
            if(smallerThan(lastItem, item))
                return false;
        }
        return true;
    }

    insertItem(item: Item) {
        if (!this.canInsertOrUpdate(item))
            return;
        const addItem = this.searchFormControl.value && item.name.toLowerCase().includes(this.searchFormControl.value.toLowerCase()) && !this.searchFilterList.canLoadMoreBottom;
        if (this.sortBy === 'date') {
            this.dynamicList.insertItem((prev, val, next) => {
                return !!val && item.createdAt.getTime() > val.instance.item.createdAt.getTime();
            }, {
                componentClass: ItemTileComponent,
                header: 'Items',
                args: {item}
            }, 'bottom',!this.dynamicList.canLoadMoreBottom);
            if (this.searchFormControl.value) {
                this.searchFilterList.insertItem((prev, val, next) => {
                    return (!!val && item.createdAt.getTime() > val.instance.item.createdAt.getTime() && item.name.toLowerCase().includes(this.searchFormControl.value.toLowerCase()));
                }, {
                    componentClass: ItemTileComponent,
                    header: 'Items',
                    args: {item}
                }, 'bottom', addItem);
            }
        } else {
            this.dynamicList.insertItem((prev, val, next) => {
                return val.instance.item.name.toLowerCase().localeCompare(item.name.toLowerCase()) > 0;
            }, {componentClass: ItemTileComponent, header: 'Items', args: {item}}, 'bottom', !this.dynamicList.canLoadMoreBottom);
            if (this.searchFormControl.value) {
                this.searchFilterList.insertItem((prev, val, next) => {
                    return (item.name.toLowerCase().includes(this.searchFormControl.value.toLowerCase())) && val.instance.item.name.toLowerCase().localeCompare(item.name.toLowerCase()) > 0;
                }, {
                    componentClass: ItemTileComponent,
                    header: 'Items',
                    args: {item}
                }, 'bottom', addItem);
            }
        }
    }

    updateItem(item: Item) {
        if (!this.canInsertOrUpdate(item))
            return;

        this.dynamicList.updateItems(c => {
            if (c.instance.item.id === item.id) {
                c.instance.item = item;
            }
            return c.instance.item.id === item.id;
        });
        if (this.searchFormControl.value) {
            this.searchFilterList.updateItems(c => {
                if (c.instance.item.id === item.id) {
                    c.instance.item = item;
                }
                return c.instance.item.id === item.id;
            });
        }
    }

    removeItem(id: number) {
        this.dynamicList.removeItem('bottom', (v) => v.instance.item.id === id);

        if (this.searchFormControl.value) {
            this.searchFilterList.removeItem('bottom', (v) => v.instance.item.id === id);
        }
    }

    clear(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.searchFormControl.setValue('', { emitEvent: true });
        this.input.nativeElement.blur();
    }

    ngOnDestroy() {
        this.pagination?.destroy();
    }

}
