import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyAutocomplete } from "@angular/material/legacy-autocomplete";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { Item } from "../../models/item.model";
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from "rxjs/operators";
import { ItemsService } from "../../services/items.service";
import { isEmpty, decimalOnly, onDecimalPaste } from "../../helpers/forms";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { fadeIn } from "../../helpers/animations";
import { currencify } from "../../helpers/common";
import { ModalsService } from 'projects/common/src/lib/services/modals.service';

type Focus = 'unfocused' | 'name' | 'discount';

@Component({
  selector: 'app-create-edit-workflow-item-restricted',
  templateUrl: './create-edit-workflow-item-restricted.component.html',
  styleUrls: ['./create-edit-workflow-item-restricted.component.scss'],
  animations: [ fadeIn ]
})
export class CreateEditWorkflowItemRestrictedComponent implements OnDestroy, AfterViewChecked, OnInit {

  protected readonly decimalOnly = decimalOnly;
  protected readonly onPaste = onDecimalPaste;

  @ViewChild('autocomplete') autocomplete!: MatLegacyAutocomplete;
  @ViewChild('nameField') nameField!: any;

  showingDiscountSnackbar = false;

  title = this.data?.mode === 'edit' ? 'Edit Item' : 'Add Item';

  edited: boolean = false;
  notExisting: boolean = false;

  loading = false;

  scrolledToTop = false;

  focus$ = new BehaviorSubject<Focus>('unfocused');

  form = this.formBuilder.group({
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    cost: new UntypedFormControl(''),
    discountPercent: new UntypedFormControl(''),
    discountAmount: new UntypedFormControl(''),
    discountType: new UntypedFormControl(''),
    taxable: new UntypedFormControl(false),
  });
  connectedItem!: Item | undefined;

  suggestionClicked = false;

  discountPercentValueChanges!: Subscription;
  discountAmountValueChanges!: Subscription;
  discountTypeValueChanges!: Subscription;
  temporaryDiscountValue!: number;

  justSavedDiscount = false;
  discountChanged = false;

  discountAboveAmount = false;

  nameError = false;
  nameFieldHeight = 0;

  items!: Item[] | null;
  itemsSub!: Subscription;
  selectedItem!: Item;

  formHandlerSubscription!: Subscription;

  taxHandlerSubscription!: Subscription;

  nameSuggestions$ = this.form.get('name')?.valueChanges.pipe(
    debounceTime(50),
    tap(() => {
      this.nameFieldHeight = this.nameField?.nativeElement?.offsetHeight;
    }),
    debounceTime(350),
    map(name => {
      if (name.length < 2 || this.nameError)
        return [];
      const nameSuggestions: { name: string, price: string }[] = [];
      this.items?.forEach(item => {
        item.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) && item.name !== ''
          ? nameSuggestions.push({ name: item.name, price: ' ($' + item.price.toFixed(2) +')' })
          : null;
      });

      return nameSuggestions;
    })
  );

  get data() {
    return this.modalsService.data;
  }

  get taxPercentage() {
    return this.modalsService.data.salesTaxPercentage;
  }

  constructor(
    private modalsService: ModalsService,
    private formBuilder: UntypedFormBuilder,
    private itemsService: ItemsService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data?.mode === 'edit') {
      if (this.data.item.discountAmount === 0)
        delete this.data.item.discountAmount;
      if (this.data.item.discountPercent === 0)
        delete this.data.item.discountPercent;

      this.form.patchValue(this.data.item);
    }

    this.itemsSub = this.itemsService.items$.pipe(
      filter(items => !!items)
    ).subscribe(items => {
      this.items = items;

      if (this.data.mode === 'edit') {
        this.connectedItem = this.items?.find(item => item.name === this.data.item.name);
        if (this.connectedItem)
          if (
            this.connectedItem.description !== this.data.item.description
            || this.connectedItem.price !== this.data.item.price
            || this.connectedItem.cost !== this.data.item.cost
            || this.connectedItem.taxable !== this.data.item.taxable
          )
            this.edited = true;
      }

      this.handleDiscount();
      this.handleForm();
      this.handleTax();
    });
  }

  ngOnDestroy() {
    this.unsubscribes();
  }

  private unsubscribes() {
    this.formHandlerSubscription?.unsubscribe();
    this.taxHandlerSubscription?.unsubscribe();
    this.discountPercentValueChanges?.unsubscribe();
    this.discountAmountValueChanges?.unsubscribe();
    this.discountTypeValueChanges?.unsubscribe();
    this.itemsSub?.unsubscribe();
  }

  async ngAfterViewChecked() {
    if (!this.scrolledToTop && this.nameField)
      this.nameField.nativeElement.scrollTop = 0;
  }

  nameSelected(event: any) {
    this.selectedItem = this.items?.find(item => item.name === event.option.value)!;
    this.nameError = false;
    this.form.patchValue(this.selectedItem);
  }

  private handleTax() {
    this.taxHandlerSubscription = this.form.get('taxable')!.valueChanges.subscribe(taxable => {
      if (
        this.data.mode === 'edit'
        && this.data.workflowVersion.items[this.data.item.index].taxable !== taxable
      ) {
        this.data.workflowVersion.items[this.data.item.index].taxable = taxable;
        this.saveWorkflowVersion(this.data.workflowVersion).then(_ => {
          showSnackbar(this.snackbar, {
            message: 'Tax set',
            duration: 2000,
          });
        });
      }
    });
  }

  private handleForm() {
    this.formHandlerSubscription = combineLatest([
      this.form.valueChanges.pipe(startWith(this.data.item ?? {})),
      this.focus$.asObservable()
    ]).subscribe(async ([form, focus]) => {
      if (this.data?.mode === 'edit') {
        await this.discountSave(form, focus);
        const sameName = this.data?.item.name === form.name;
        const sameContent =
          this.data?.item.description === form.description
          && this.data?.item.price === +form.price
          && (
            this.data?.item.cost
              ? this.data?.item.cost === +form.cost
              : (isEmpty(form.cost) && isEmpty(this.data?.item.cost))
          )
          && this.data?.item.taxable === form.taxable;
        if (!this.connectedItem) {
          if (sameName && sameContent) {
            this.edited = false;
            this.notExisting = true;
          }
        } else {
          this.notExisting = false;
          if (sameName && !sameContent) {
            this.edited = true;
          } else {
            this.edited = this.connectedItem.description !== form.description
              || this.connectedItem.price !== +form.price
              || (
                this.connectedItem.cost
                  ? this.connectedItem.cost !== +form.cost
                  : !isEmpty(form.cost)
              )
              || this.connectedItem.taxable !== form.taxable;
          }
        }
      } else {
        if (this.selectedItem) {
          this.edited = false;
          const sameName = this.selectedItem.name === form.name;
          const sameContent =
            this.selectedItem.description === form.description
            && this.selectedItem.price === +form.price
            && (
              this.selectedItem.cost
                ? this.selectedItem.cost === +form.cost
                : (isEmpty(form.cost) && isEmpty(this.selectedItem.cost))
            )
            && this.selectedItem.taxable === form.taxable;
          if (sameName && !sameContent)
            this.edited = true;
        } else {
          this.nameError = focus !== 'name' && !isEmpty(form.name);
        }
      }
    });
  }

  private handleDiscount() {
    if (this.data?.mode === 'edit') {
      this.temporaryDiscountValue = this.form.get('discountType')?.value === 'percent'
        ? this.form.get('discountAmount')?.value
        : this.form.get('discountPercent')?.value;
      if (this.form.get('price')?.value
        && (this.form.get('discountAmount')?.value || this.form.get('discountPercent')?.value)) {
        this.discountAboveAmount = (
          +(this.form.get('discountPercent')?.value) > 100
          || +(this.form.get('discountAmount')?.value) > +(this.form.get('price')?.value)
        );
      } else {
        this.discountAboveAmount = false;
      }
    } else {
      this.form.get('discountType')?.setValue('percent');
    }
    this.discountAmountValueChanges = this.form.controls['discountAmount'].valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(discountAmount => {
      if (this.form.value.discountType !== 'amount')
        return;
      this.discountChanged = true;
      const percent = (discountAmount/this.form.get('price')?.value)*100;
      this.temporaryDiscountValue = +(Math.round(percent * 100) / 100).toFixed(2);
      this.discountAboveAmount = +discountAmount > +(this.form.get('price')?.value);
    });
    this.discountPercentValueChanges = this.form.controls['discountPercent'].valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(discountPercent => {
      if (this.form.value.discountType !== 'percent')
        return;
      this.discountChanged = true;
      const amount = (this.form.get('price')?.value*discountPercent)/100;
      this.temporaryDiscountValue = +(Math.round(amount * 100) / 100).toFixed(2);
      this.discountAboveAmount = +discountPercent > 100;
    });
    this.discountTypeValueChanges = this.form.controls['discountType'].valueChanges
      .subscribe(discountType => {
        this.discountChanged = true;
        if (discountType === 'percent') {
          const tmp = this.form.get('discountAmount')?.value;
          if (this.temporaryDiscountValue !== 0)
            this.form.get('discountPercent')?.setValue(this.temporaryDiscountValue);
          this.temporaryDiscountValue = tmp;
        } else {
          const tmp = this.form.get('discountPercent')?.value;
          if (this.temporaryDiscountValue !== 0)
            this.form.get('discountAmount')?.setValue(this.temporaryDiscountValue);
          this.temporaryDiscountValue = tmp;
        }
      });
  }

  private async discountSave(form: any, focus: Focus) {
    if (focus !== 'discount' && !this.justSavedDiscount && this.discountChanged) {
      const noDifference =
        form.name === this.data?.item.name
        && form.description === this.data?.item.description
        && +form.price === this.data?.item.price
        && +form.cost === this.data?.item.cost
        && form.taxable === this.data?.item.taxable;
      if (noDifference) {
        if (form.discountType !== this.data?.item.discountType) {
          await this.saveDiscount('type');
          this.justSavedDiscount = true;
          this.discountChanged = false;
          setTimeout((() => {
            this.justSavedDiscount = false;
          }),300);
          return;
        }
        const zeroFormPercent = !form.discountPercent;
        const zeroDataPercent = !this.data?.item.discountPercent;
        const samePercent =
          (zeroDataPercent && zeroFormPercent)
          || +this.data?.item.discountPercent === +form.discountPercent;
        if (!samePercent) {
          await this.saveDiscount('percent');
          this.justSavedDiscount = true;
          this.discountChanged = false;
          setTimeout((() => {
            this.justSavedDiscount = false;
          }),300);
          return;
        }
        const zeroFormAmount = !form.discountAmount;
        const zeroDataAmount = !this.data?.item.discountAmount;
        const sameAmount = (zeroDataAmount && zeroFormAmount)
          || +this.data?.item.discountAmount === +form.discountAmount;
        if (!sameAmount) {
          await this.saveDiscount('amount');
          this.justSavedDiscount = true;
          this.discountChanged = false;
          setTimeout((() => {
            this.justSavedDiscount = false;
          }),300);
          return;
        }
      }
    }
  }

  async saveDiscount(which: 'amount' | 'percent' | 'type') {
    if (this.checkDiscount()) {
      if (which === 'type') {
        this.data.item.discountType = this.form.value.discountType;
      } else if (which === 'percent') {
        this.data.item.discountPercent = +this.form.value.discountPercent;
        this.data.item.discountAmount = this.form.get('price')?.value * this.form.value.discountPercent / 100;
        this.form.get('discountAmount')?.setValue(this.data.item.discountAmount);
      } else if (which === 'amount') {
        this.data.item.discountAmount = +this.form.value.discountAmount;
        this.data.item.discountPercent = this.form.value.discountAmount / this.form.get('price')?.value * 100;
        this.form.get('discountPercent')?.setValue(this.data.item.discountPercent);
      }
      this.data.workflowVersion.items[this.data.item.index] = this.data.item;
      await this.saveWorkflowVersion(this.data.workflowVersion);
      if (!this.showingDiscountSnackbar) {
        showSnackbar(this.snackbar, {
          message: 'Discount set',
          duration: 2000,
        });
        this.showingDiscountSnackbar = true;
        setTimeout(() => {
          this.showingDiscountSnackbar = false;
        }, 1000);
      }
    }
  }

  private async saveWorkflowVersion(workflowVersion: any) {
    await this.data.updateFunction(workflowVersion.id, { items: workflowVersion.items });
  }

  checkDiscount(): boolean {
    if (this.form.get('discountType')?.value === 'percent') {
      return this.form.get('discountPercent')?.value <= 100;
    } else {
      return +this.form.get('discountAmount')?.value <= +this.form.get('price')?.value;
    }
  }

  getMarkupValue(): string {
    const diff = this.getDiscountedPrice()-this.form.get('cost')?.value;
    const markup = (diff/this.form.get('cost')?.value)*100;
    return (Math.round(markup * 100) / 100).toFixed(2);
  }

  getSalesTaxValue(): string {
    const tax = (this.getDiscountedPrice()*this.taxPercentage)/100;
    return tax < 0 ? '0' : (Math.round(tax * 100) / 100).toFixed(2);
  }

  getTotalValue(): string {
    const taxed = this.form.get('taxable')?.value === false
      ? this.getDiscountedPrice()
      : ((this.getDiscountedPrice()*this.taxPercentage)/100) + this.getDiscountedPrice();
    return taxed < 0 ? '0' : (Math.round(taxed * 100) / 100).toFixed(2);
  }

  private getDiscountedPrice(): number {
    return this.form.get('discountType')?.value === 'amount'
      ? this.form.get('price')?.value - this.form.get('discountAmount')?.value
      : this.form.get('price')?.value - ((this.form.get('price')?.value * this.form.get('discountPercent')?.value) / 100);
  }

  async close() {
    await this.modalsService.close();
  }

  toggleDiscountType() {
    this.form.get('discountType')?.setValue(
      this.form.get('discountType')?.value === 'percent'
        ? 'amount'
        : 'percent'
    );
  }

  async add() {
    this.loading = true;
    if (this.selectedItem) {
      const workflowVersion = this.data.workflowVersion;
      const item = {
        ...this.form.value
      };
      item.name = item.name?.trim();
      item.quantity = 1;
      item.index = workflowVersion.items.length;
      if (!item.discountAmount && !item.discountPercent) {
        item.discountPercent = 0;
        item.discountAmount = 0;
      } else {
        if (this.form.get('discountType')?.value === 'percent') {
          const amount = (this.form.get('price')?.value*item.discountPercent)/100;
          item.discountAmount = +(Math.round(amount * 100) / 100).toFixed(2);
        } else {
          const percent = (item.discountAmount/this.form.get('price')?.value)*100;
          item.discountPercent = +(Math.round(percent * 100) / 100).toFixed(2);
        }
      }
      workflowVersion.items.push(item);
      await this.saveWorkflowVersion(workflowVersion);
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item added',
        duration: 2000,
      });
    }
    this.loading = false;
  }

  protected readonly currencify = currencify;
}
