import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { InvoicesService } from "../../services/invoices.service";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { Invoice, InvoiceVersion } from "../../models/invoice.model";

@Component({
  selector: 'app-invoice-editable',
  templateUrl: './invoice-editable.component.html',
  styleUrls: ['./invoice-editable.component.scss']
})
export class InvoiceEditableComponent {

  @Input() invoice: any;
  @Input() currentInvoiceVersion!: InvoiceVersion;
  @Input() scrollPosition: any;
  @Input() readonly = false;

  @Output() editableInFocus = new EventEmitter<boolean>();

  noteFocused: boolean = false;
  currentNote: string = '';

  constructor(
    private snackBar: MatSnackBar,
    private invoicesService: InvoicesService,
  ) { }

  async noteFocusout(currentInvoiceVersionId: number, note: string | null) {
    this.noteFocused = false;
    if (note !== this.currentNote) {
      try {
        await this.invoicesService.updateInvoiceNote(
          currentInvoiceVersionId,
          note ?? ''
        );
        showSnackbar(this.snackBar, {
          message: "Note updated",
          duration: 2000
        });
      } catch (e) {
        alert('Something went wrong')
      }
    }
  }
}
