export const lobbyPages = ['Assigned', 'Assignment', 'Schedule', 'Proposals', 'Invoices'] as const;
export type LobbyPage = typeof lobbyPages[number];

export const scheduleTabs = ['All', 'Leads', 'Estimates', 'Jobs', 'Unavailable'] as const;
export type ScheduleTab = typeof scheduleTabs[number];

export const reportsPages = ['Payments', 'Commissions', 'Tech Parts', 'Overlook', 'Compare'] as const;
export type ReportsPages = typeof reportsPages[number];

export const reportsPaymentsTabs = ['All', 'Paid', 'Pending'] as const;
export type ReportsPaymentsTabs = typeof reportsPaymentsTabs[number];

export const reportsCommissionsTab = ['Pending', 'Paid'] as const;
export type ReportsCommissionsTab = typeof reportsCommissionsTab[number];

export const reportsTechPartsTab = ['Pending', 'Paid'] as const;
export type ReportsTechPartsTab = typeof reportsTechPartsTab[number];

export const estimatesTabs = ['Upcoming', 'In Progress', 'Overdue', 'History'] as const;
export type EstimatesTab = typeof estimatesTabs[number];

export const proposalsTabs = ['Pending', 'Won', 'Declined', 'Canceled', 'Drafts'] as const;
export type ProposalTab = typeof proposalsTabs[number];

export const jobsTabs = ['Submitted', 'Pending', 'In Progress', 'Done', 'Issues', 'Canceled'] as const;
export type JobsTab = typeof jobsTabs[number];

export const invoicesTabs = ['Pending', 'Paid', 'Canceled', 'Drafts'] as const;
export type InvoicesTab = typeof invoicesTabs[number];

export type Page = 'Settings' | 'Items' | LobbyPage | ReportsPages;

export type Tab =
  ScheduleTab
  | EstimatesTab
  | ProposalTab
  | JobsTab
  | InvoicesTab
  | ReportsPaymentsTabs
  | ReportsCommissionsTab
  | ReportsTechPartsTab;

export type AnyPage = Page | Tab;

export function pagePathToName(page: string) {
  const nameArr = page.split('-');
  return nameArr
    .map(part => (part.charAt(0).toUpperCase() + part.slice(1)))
    .join(' ') as AnyPage;
}

export function pageNameToPath(page: string) {
  const nameArr = page.split(' ');
  return nameArr
    .map(part => part.toLowerCase())
    .join('-');
}
