import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UtilsService } from "../../../../../common/src/lib/services";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { debounce, map, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";
import { PaymentService } from "../../services/payment.service";
import { UsersService } from "../../services/users.service";
import { BusinessService } from '../../services/business.service';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { ChatService, unseenIndicatorDebounceMs } from '../../services/chat.service';
import { ChatComponent } from "../../components/chat/chat.component";
import { NavigationService } from '../../services/navigation.service';
import { ViewAsService } from '../../services/view-as.service';
import { rounder } from "../../../../../common/src/lib/helpers/payments";
import {
  ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { CurrentTip, Tip } from "../../models/payment.model";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { EditWorkerPaymentComponent } from "../../modals/edit-worker-payment/edit-worker-payment.component";

const tabs = ['Payment', 'Notes', 'Chat'] as const;
const sideTabs = tabs.slice(1);
type MainTab = typeof tabs[number];
type SideTab = Exclude<MainTab, 'Payment'>;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements AfterViewInit {

  @ViewChild('chat') chat!: ChatComponent;

  initInterval!: any;

  readonly$ = this.viewAsService.readonly$;

  user$ = this.authService.user$;

  activeTab: string = 'Payment';

  itemsScrolledToBottom = false;

  previousUrl: string[] = [];

  openedSummary = false;

  currentUser$ = this.usersService.currentUser$;

  businessId$ = this.businessService.selectedBusiness$.pipe(
    map(business => business!.businessId)
  );

  params$ = this.route.params.pipe(
    map(params => {
      return {
        paymentId: +params['paymentId'],
        workflowId: +params['workflowId']
      }
    })
  );

  payment$ = this.params$.pipe(
    switchMap(
      params =>
        this.paymentService.paymentObservable(params.workflowId, params.paymentId).pipe(
          map(payment => ({ payment, workflowId: params.workflowId }))
        )
    ),
    shareReplay(1)
  );

  showSmallScreenView$ = this.utilsService.onScreenBreakpointChange('md').pipe(
    map(above => !above)
  );

  ngAfterViewInit() {
    this.initInterval = setInterval(() => {
      const paymentItems = document.getElementById('payment-items');

      if (paymentItems)
        this.checkItemsScroll(paymentItems, true, true);
    }, 75);
  }

  largeScreen$ = this.utilsService.onScreenBreakpointChange('lg');
  selectedTabSubject = new BehaviorSubject<MainTab>('Payment');
  selectedSideTabSubject = new BehaviorSubject<SideTab>('Notes');
  selectedTab$ = combineLatest([this.largeScreen$, this.selectedTabSubject, this.selectedSideTabSubject]).pipe(
    map(([largeScreen, mainTab, sideTab]) => largeScreen ? sideTab : mainTab)
  );
  
  showInfo$ = combineLatest([this.largeScreen$, this.selectedTab$]).pipe(
    map(([largeScreen, selectedTab]) => {
      return largeScreen || selectedTab === 'Payment';
    })
  );

  showNotes$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Notes')
  );

  showChat$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Chat')
  );

  tabs$ = this.largeScreen$.pipe(
    map(largeScreen => (largeScreen ? sideTabs : tabs) as string[])
  );

  chat$ = this.params$.pipe(
    map(params => params.workflowId),
    switchMap(workflowId => this.chatService.chatObservable(workflowId, true)),
    shareReplay(1)
  );

  tabsRedDots$ = combineLatest([
    this.chat$.pipe(
      map(chat => !!chat?.hasUnseenMessages),
      startWith(false),
      debounce(hasUnseen => timer(hasUnseen ? unseenIndicatorDebounceMs : 0))
    ),
    this.tabs$
  ]).pipe(map(([chatHasUnseenMessages, tabs]) => tabs.map(tab => tab === 'Chat' && chatHasUnseenMessages)));

  chatSubject$ = combineLatest([this.chat$, this.params$.pipe(map(p => p.workflowId))]).pipe(
    switchMap(([chat, workflowId]) => {
      return chat ? of(chat.subject) : this.paymentService.getWorkflowLastDocumentClientName(workflowId).then(cn => `CN: ${cn}`);
    }),
    shareReplay(1)
  );

  async setActiveTab(tab: string) {
    const largeScreen = await this.largeScreen$.pipe(take(1)).toPromise();
    largeScreen ? this.selectedSideTabSubject.next(tab as SideTab) : this.selectedTabSubject.next(tab as MainTab);
  }

  constructor(
    private authService: AuthService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private paymentService: PaymentService,
    private usersService: UsersService,
    private businessService: BusinessService,
    private chatService: ChatService,
    private navigationService: NavigationService,
    private viewAsService: ViewAsService,
  ) {
    this.navigationService.url$.pipe(take(1)).toPromise()
      .then(url => this.previousUrl = url)
      .then(() => this.navigationService.setStateQueryParam());
  }

  back() {
    this.navigationService.goBack();
  }

  get backButtonCaption() {
    return this.navigationService.fromReports ? 'Back' : 'Close';
  }

  checkItemsScroll(event: any, inside?: boolean, interval?: boolean) {
    const scrollTop = !inside ? event.target.scrollTop : event.scrollTop;
    const scrollHeight = !inside ? event.target.scrollHeight : event.scrollHeight;
    const clientHeight = !inside ? event.target.clientHeight : event.clientHeight;

    this.itemsScrolledToBottom = clientHeight + scrollTop >= scrollHeight - 16;

    if (interval)
      clearInterval(this.initInterval);
  }

  sidePanelClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.chat?.onMenuClick(event);
  }

  async markTipAsPaid(
    paymentId: number,
    tip: Tip,
    currentTips: CurrentTip[]
  ) {
    await this.modalsService.open(ConfirmationDialog, {
      data: {
        title: `Mark as Paid`,
        message: `Are you sure you want to mark this as Paid?`,
        cancelTitle: 'No',
        actionTitle: 'Yes',
        action: async () => {
          await this.paymentService.updateCurrentTips(paymentId, tip, currentTips);
        },
        actionColor: 'primary',
      }
    });
  }

  async editTip(
    paymentId: number,
    tip: Tip,
    currentTips: CurrentTip[]
  ) {
    await this.modalsService.open(EditWorkerPaymentComponent, {
      disableClose: true,
      data: {
        item: {
          paymentId,
          amount: tip.amount,
          paid: tip.paid,
          currentTips,
          tip
        },
        type: 'tip'
      }
    });
  }
}
