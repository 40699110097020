import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { map, startWith, take } from 'rxjs/operators';
import { UsersService } from '../../services/users.service';
import { AvailabilityTileData } from "../../models/availability.model";
import { BaseTileData } from "../base-tile/base-tile.component";
import { timer } from "rxjs";
import { TimeData } from "../base-tile/base-tile-time/base-tile-time.component";
import { ViewAsService } from '../../services/view-as.service';

@Component({
  selector: 'app-availability-tile',
  templateUrl: './availability-tile.component.html',
  styleUrls: ['./availability-tile.component.scss']
})
export class AvailabilityTileComponent implements OnInit {

  @Input() document!: AvailabilityTileData;
  @Input() showUserName = true;
  @Input() showDocType = false;

  @Output() tileClick = new EventEmitter<AvailabilityTileData>();

  readonly timeFormat = 'hh:mm a';
  readonly dateFormat = 'MM/DD/YY';
  data!: BaseTileData;
  timeData!: TimeData;

  readonly$ = this.viewAsService.readonly$;

  reverseTimeOrder$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.endTime.getTime() < Date.now();
      })
  );
  startTitle$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.startTime.getTime() < Date.now() ? 'Started' : 'Starts';
      })
  );
  endTitle$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.endTime.getTime() < Date.now() ? 'Ended' : 'Ends';
      })
  );
  isPast$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.endTime.getTime() < Date.now();
      })
  );

  showUserName$ = this.viewAsService.selectedUsers$.pipe(map(users => users === 'All' || users.length > 1));

  constructor(
    public usersService: UsersService,
    public viewAsService: ViewAsService,
  ) { }

  refreshData() {
    this.data = {
      title: this.document.available ? String.fromCharCode(0xA0) : 'Absent',
      description: this.document.description
    };
    this.timeData = {
      startTime: this.document.startTime,
      endTime: this.document.endTime,
    };
  }

  async ngOnInit() {
    this.refreshData();
  }

  async onTileClick() {
    const readonly = await this.readonly$.pipe(take(1)).toPromise();
    if(this.document.endTime.getTime() > Date.now() && !readonly)
      this.tileClick.emit(this.document);
  }

  getEndDateText(startDate: Date, endDate: Date) {
    function toDate(date: Date, incDate: number = 0): number {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + incDate).getTime();
    }

    if (!endDate) {
      return 'Ends:';
    }
    const today = toDate(new Date());
    const tomorrow = toDate(new Date(), 1);
    const compareStartDate = toDate(startDate);
    const compareEndDate = toDate(endDate);
    if (compareStartDate === compareEndDate) {
      return 'Ends:';
    }
    if (compareStartDate !== today && compareEndDate === today) {
      return 'Today:'
    }
    return compareEndDate === tomorrow ? 'Tomorrow:' : `${moment(endDate).format(this.dateFormat)}:`;
  }
}
