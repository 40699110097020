import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UsersService } from "../../services/users.service";
import { map, take } from "rxjs/operators";
import { BusinessService } from '../../services/business.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import moment from 'moment';
import { RequestInvitationFormData, RequestInvitationModalData } from "../request-invitation/request-invitation.model";

interface SummaryPreviewModalData extends RequestInvitationModalData {
  isReschedule: boolean,
  isReassign: boolean,
  formData: Required<RequestInvitationFormData>,
  mobileHeaderTitle: string;
  mobileHeaderLeftButtonCaption: string;
  mobileHeaderRightButtonCaption: string;
}

@Component({
  selector: 'app-summary-preview',
  templateUrl: './summary-preview.component.html',
  styleUrls: ['./summary-preview.component.scss']
})
export class SummaryPreviewModal implements OnInit {
  static readonly BREAKPOINT = 'sm';
  readonly timeFormat = 'hh:mm a';
  readonly dateFormat = 'EEE, LLL dd, YYYY';

  get data() {
    return this.dialogData ?? this.modalsService.data;
  }

  name = this.data.formData.client.firstName;
  assignees = this.data.formData.assignees
  business$ = this.businessService.selectedBusiness$;
  address = this.data.formData.client.address;
  unit = this.data.formData.client.unit;
  isReschedule = this.data.isReschedule;
  isReassign = this.data.isReassign;
  isEstimate = this.data.formData.dialogType === 'Estimate';
  isJob = this.data.formData.dialogType === 'Job';
  rangesByDate: TimeRange[][] = [];
  assigneeFullName!: string
  currentUserProfile$ = this.usersService.currentUser$;

  directPhoneNumber$ = this.currentUserProfile$.pipe(
    map(user => user.directPhoneNumber)
  );
  users$ = this.usersService.users$;

  showDirectPhoneNumber$ = this.currentUserProfile$.pipe(
    map(user => user.showDirectPhoneNumber)
  );

  mobileHeaderTitle = this.data.mobileHeaderTitle;
  mobileHeaderLeftButtonCaption = this.data.mobileHeaderLeftButtonCaption;
  mobileHeaderRightButtonCaption = this.data.mobileHeaderRightButtonCaption;

  constructor(
    private modalsService: ModalsService,
    private businessService: BusinessService,
    private usersService: UsersService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: SummaryPreviewModalData
  ) { }

  async ngOnInit() {
    await this.getAssigneeFullName();
    for (let range of this.data.formData.ranges) {
      const date = moment(range.startTime).startOf('day');
      if (this.rangesByDate.length && moment(this.rangesByDate[this.rangesByDate.length - 1][0].startTime).startOf('day').isSame(date)) {
        this.rangesByDate[this.rangesByDate.length - 1].push(range);
      } else {
        this.rangesByDate.push([range]);
      }
    }
  }

  async getAssigneeFullName() {
    const users = await this.users$.pipe(take(1)).toPromise();

    let currentAssigneeId: number;
    if(this.assignees.length > 0)
      currentAssigneeId = this.assignees[0].id;
    else
      currentAssigneeId = (await this.usersService.currentUser$.pipe(take(1)).toPromise()).id;

    const assigneeProfile =  users!.find((user) => user.id === currentAssigneeId);
    if(assigneeProfile) {
      this.assigneeFullName = `${assigneeProfile.firstName}` + ' ' + `${assigneeProfile.lastName}`
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }

  close() {
    console.log((this.modalsService as any)._config)
    this.modalsService.close(true);
  }

  onMobileHeaderButtonClick(button: string) {
    // this.data.prevComponentData.action = button;
    // this.close();
  }
}
