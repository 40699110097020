import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { JobTileComponent } from "../../components/job-tile/job-tile.component";
import {
    DynamicListComponent,
    DynamicListEvent,
    PaginationListItem,
} from "../../components/dynamic-list/dynamic-list.component";
import { Pagination, PaginationOptions } from "../../services/pagination.service";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { BusinessService } from "../../services/business.service";
import { SupabaseService } from "../../services/supabase.service";
import { LobbyService } from "../../services/lobby.service";
import { ViewAsService } from "../../services/view-as.service";
import { limitStep } from "../../services/items.service";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { Job, JobTileData, JobTimeRange, JobUser } from "../../models/jobs.model";
import { ScheduleService, ScheduleTileData } from "../../services/schedule.service";
import { dateString } from "../../../../../common/src/lib/services";
import { Client } from 'projects/common/src/public-api';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { JobsService, sortJobUsers } from '../../services/jobs.service';
import { Workflow } from '../../models/workflow.model';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { Router } from "@angular/router";
import { MatLegacySnackBar } from "@angular/material/legacy-snack-bar";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

function jobsToPaginationList(jobs: JobTileData[]) {
    for(const job of jobs) {
        job.users.sort(sortJobUsers);
    }
    return jobs.map(job => ({
        componentClass: JobTileComponent,
        header: dateString(job.startTime, job.endTime),
        args: { document: {...job, docType: 'Job'} }
    }));
}
@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('list') dynamicList!: DynamicListComponent<JobTileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<JobTileComponent>;

    pagination!: Pagination<JobTileData>;
    dateFilterPagination!: Pagination<JobTileData>;
    loading$ = new BehaviorSubject<boolean>(true);

    firstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    firstHeader$ = this.firstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    filterFirstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    filterFirstHeader$ = this.filterFirstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    initialTopItems!: PaginationListItem<JobTileComponent>[];
    initialBottomItems!: PaginationListItem<JobTileComponent>[];
    initialFilterTopItems!: PaginationListItem<JobTileComponent>[];
    initialFilterBottomItems!: PaginationListItem<JobTileComponent>[];
    initialFilterCanLoadMoreTop = true;
    initialFilterCanLoadMoreBottom = true;

    dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
        switchMap(async range => {
            if(!range) 
                return null;
            const inLimit = limitStep;
            const options: PaginationOptions = {
                inLimit, 
                inOffset: 0, 
                inStartDate: frontToServerTranslation(range.from), 
                inEndDate: frontToServerTranslation(range.to), 
            };
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if(userIds)
                options.inUserIds = userIds;
            const status = this.scheduleService.filterJobStatusSubject.value;
            if(status !== 'All')
                (options as any).inStatus = status.toLowerCase();
            
            const [past, future] = await Promise.all([
                this.dateFilterPagination.get({...options, in_direction: 'past'}),
                this.dateFilterPagination.get({...options, in_direction: 'future'})
            ]);
            this.filterFirstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
            this.initialFilterTopItems = jobsToPaginationList(past);
            this.initialFilterBottomItems = jobsToPaginationList(future);
            this.initialFilterCanLoadMoreBottom = future.length >= inLimit;
            this.initialFilterCanLoadMoreTop = past.length >= inLimit;
            return range;
        }),
        shareReplay({ bufferSize: 1, refCount: true})
    );

    currentStatusFilter$ = this.scheduleService.filterJobStatus$;
    currentStatusFilterSubject = this.scheduleService.filterJobStatusSubject;

    constructor(
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private lobbyService: LobbyService,
        private viewAsService: ViewAsService,
        private scheduleService: ScheduleService,
        private jobsService: JobsService,
        private router: Router,
        private modalsService: ModalsService,
        private snackbar: MatLegacySnackBar,
    ) {}

    async ngOnInit() {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<JobTileData, Job | Client | JobTimeRange | Workflow | JobUser>(this.supabaseService, 'get_jobs', business.businessId, 'jobs')
            .on<Job, 'UPDATE'>('UPDATE', 'job', this.handleJobChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'job_client', this.handleClientChanges.bind(this))
            .on<JobTimeRange>('*', 'job_range', this.handleRangeChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleWorkflowChanges.bind(this))
            .on<JobUser>('*', 'job_user', this.handleJobUserChanges.bind(this));

        this.dateFilterPagination = new Pagination<JobTileData, Job | Client | JobTimeRange | Workflow | JobUser>(this.supabaseService, 'get_jobs', business.businessId, 'filtered_jobs')
            .on<Job, 'UPDATE'>('UPDATE', 'job', this.handleDateFilterJobChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'job_client', this.handleDateFilterClientChanges.bind(this))
            .on<JobTimeRange>('*', 'job_range', this.handleDateFilterRangeChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleDateFilterWorkflowChanges.bind(this))
            .on<JobUser>('*', 'job_user', this.handleDateFilterJobUserChanges.bind(this));

        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0 };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;
        const status = this.scheduleService.filterJobStatusSubject.value;
        if(status !== 'All')
            (options as any).inStatus = status.toLowerCase();

        const [past, future] = await Promise.all([
            this.pagination.get({...options, in_direction: 'past'}),
            this.pagination.get({...options, in_direction: 'future'})
        ]);

        this.firstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
        this.initialTopItems = jobsToPaginationList(past);
        this.initialBottomItems = jobsToPaginationList(future);
        this.setInitialCanLoadMore();
        this.loading$.next(false);
    }

    ngAfterViewInit(): void {
        this.setInitialCanLoadMore();
    }

    ngOnDestroy(): void {
        this.pagination.destroy();
        this.dateFilterPagination.destroy();
    }

    setInitialCanLoadMore() {
        if (!this.initialTopItems || !this.initialBottomItems || !this.dynamicList)
            return;

        if(this.initialTopItems.length < limitStep)
            this.dynamicList.canLoadMoreTop = false;
        if(this.initialBottomItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset, inDirection: at === 'top' ? 'past' : 'future' };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;
        const status = this.scheduleService.filterJobStatusSubject.value;
        if(status !== 'All')
            (options as any).inStatus = status.toLowerCase();

        const jobs = await this.pagination.get(options);
        if(at === 'top') {
            if(jobs.length < inLimit)
                this.dynamicList.canLoadMoreTop = false;
            this.dynamicList.addAtTop(
                jobsToPaginationList(jobs)
            );
        } else {
            if(jobs.length < inLimit)
                this.dynamicList.canLoadMoreBottom = false;
            this.dynamicList.addAtBottom(
                jobsToPaginationList(jobs)
            );
        }
    }

    async loadDateFilterItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = {
            inLimit,
            inOffset,
            inDirection: at === 'top' ? 'past' : 'future',
            inStartDate: frontToServerTranslation(range!.from),
            inEndDate: frontToServerTranslation(range!.to),
        };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;
        const status = this.scheduleService.filterJobStatusSubject.value;
        if(status !== 'All')
            (options as any).inStatus = status.toLowerCase();

        const jobs = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;

        if(at === 'top') {
            if(jobs.length < inLimit)
                this.dateFilterList.canLoadMoreTop = false;
            this.dateFilterList.addAtTop(
                jobsToPaginationList(jobs)
            );
        } else {
            if(jobs.length < inLimit)
                this.dateFilterList.canLoadMoreBottom = false;
            this.dateFilterList.addAtBottom(
                jobsToPaginationList(jobs)
            );
        }
    }

    onItemEvent(event: DynamicListEvent<JobTileData>) {
        this.scheduleService.onItemEvent(event as DynamicListEvent<ScheduleTileData>)
    }

    handleJobChanges(changes: RealtimePostgresChangesPayload<Job>) {
        this.scheduleService.handleJobChanges(this.dynamicList, changes, this.firstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleJobClientChanges(this.dynamicList, changes);
    }

    handleRangeChanges(changes: RealtimePostgresChangesPayload<JobTimeRange>) {
        this.scheduleService.handleJobRangeChanges(this.dynamicList, changes, this.firstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleWorkflowChanges(this.dynamicList, changes);
    }

    handleJobUserChanges(changes: RealtimePostgresChangesPayload<JobUser>) {
        this.scheduleService.handleJobUserChanges(this.dynamicList, changes, this.firstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleDateFilterJobChanges(changes: RealtimePostgresChangesPayload<Job>) {
        this.scheduleService.handleDateFilterJobChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleDateFilterClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleDateFilterJobClientChanges(this.dateFilterList, changes);
    }

    handleDateFilterRangeChanges(changes: RealtimePostgresChangesPayload<JobTimeRange>) {
        this.scheduleService.handleDateFilterJobRangeChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleDateFilterWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleDateFilterWorkflowChanges(this.dateFilterList, changes);
    }
    
    handleDateFilterJobUserChanges(changes: RealtimePostgresChangesPayload<JobUser>) {
        this.scheduleService.handleDateFilterJobUserChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }
}

