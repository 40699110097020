<div class="create-edit-item-modal overflow-scroll" *ngIf="data" cdkScrollable>

  <div class="header">
    <h4>{{title}}</h4>
    <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
  </div>

  <div class="content">
    <form [formGroup]="form">
      <div class="section">
        <h5>Name</h5>
        <mat-form-field appearance="outline" class="textarea" appFormFieldFilled>
          <textarea matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
                    formControlName="name" tabindex="1"
                    [matAutocomplete]="autocomplete"
                    #nameField
          ></textarea>
          <div *ngIf="form.get('name')?.hasError('emptyNameError')" class="input-error textarea">
            Name is required
          </div>
          <div *ngIf="form.get('name')?.hasError('alreadyUsedNameError')" class="input-error textarea">
            Item name already in use
          </div>
          <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="nameSelected($event)">
            <mat-option *ngFor="let nameSuggestions of nameSuggestions$ | async" [value]="nameSuggestions.name">
              {{nameSuggestions.name + nameSuggestions.price}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="section">
        <h5>Description</h5>
        <mat-form-field appearance="outline" class="textarea" appFormFieldFilled>
          <textarea matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
                    formControlName="description" tabindex="1"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-between gap--10">
        <div class="section divided">
          <h5>Price</h5>
          <mat-form-field appearance="outline" appFormFieldFilled class="divided-fields">
            <div class="d-flex">
              <div class="d-flex align-items-center mr--3">$</div>
              <input matInput
                     formControlName="price"
                     autocomplete="off"
                     tabindex="3"
                     class="fw-600"
                     inputmode="decimal"
                     mask="separator.2" thousandSeparator="" decimalMarker="."
                     [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                     (keydown)="decimalOnly($event)"
                     (paste)="onPaste($event)"
                     replaceCommaWithDot
              >
              <div *ngIf="form.get('price')?.hasError('emptyPriceError')" class="input-error">
                Price is required
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="section divided">
          <h5>Cost</h5>
          <mat-form-field appearance="outline" appFormFieldFilled class="divided-fields">
            <div class="d-flex">
              <div class="d-flex align-items-center mr--3">$</div>
              <input matInput
                     formControlName="cost"
                     autocomplete="off"
                     tabindex="4"
                     class="fw-600"
                     inputmode="decimal"
                     mask="separator.2" thousandSeparator="" decimalMarker="."
                     [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                     (keydown)="decimalOnly($event); tabHandle($event, nameField)"
                     (paste)="onPaste($event)"
                     replaceCommaWithDot
              >
            </div>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="markup" [class.negative]="+getMarkup() < 0 || getMarkup() === 'error'"
         *ngIf="!isEmpty(form.get('price')?.value) && !isEmpty(form.get('cost')?.value)"
    >
      <div *ngIf="getMarkup() !== 'error'">Markup {{getMarkup()}}%</div>
      <div *ngIf="getMarkup() == 'error'">Error</div>
    </div>
    <div class="markup-warning"
         *ngIf="+getMarkup() < 0 && !isEmpty(form.get('price')?.value) && !isEmpty(form.get('cost')?.value)"
    >
      <h4>Warning! Price is lower than the cost</h4>
    </div>

    <div class="mt-20 tax d-flex justify-content-between align-items-center"
         [class.tax-markup-together]="!isEmpty(form.get('price')?.value) && !isEmpty(form.get('cost')?.value)"
         *ngIf="form.get('price')?.value"
    >
      <div class="d-flex gap--8 align-items-center">
        <div class="d-flex gap--4 align-items-center">
          <div class="semi-bold">Sales Tax</div>
          <app-icon src="question-mark-tooltip"
                    matTooltip="The tax rate in items is based on your business zip code. Proposals/invoices are based on the customers zip code"
                    [matTooltipShowDelay]="300"
                    class="scale--90"
                    [matTooltipPosition]="'above'"
                    [hideTooltipOnScroll]="tooltip" #tooltip="matTooltip"
                    (click)="tooltip.show()"
          ></app-icon>
        </div>
        <div class="tax-block">
          <div class="tax-select" [class.not-selected]="form.get('taxable')?.value === false"
               (click)="setTaxable(false)"
          >
            0%
          </div>
          <div class="tax-select" [class.selected]="form.get('taxable')?.value === true"
               (click)="setTaxable(true)"
          >
            {{taxPercentage}}%
          </div>
        </div>
      </div>
      <div *ngIf="form.get('taxable')?.value === true && getTaxAmount() !== 'error'" class="fw-600">${{getTaxAmount()}}</div>
      <div *ngIf="form.get('taxable')?.value === true && getTaxAmount() === 'error'" class="fw-600 danger">Error</div>
    </div>

    <div class="divider" *ngIf="form.get('price')?.value"></div>

    <div *ngIf="form.get('price')?.value" class="d-flex justify-content-between">
      <div class="fw-600">Total</div>
      <div *ngIf="getTaxAmount() !== 'error'" class="fw-600">{{getTotal()}}</div>
      <div *ngIf="getTaxAmount() === 'error'" class="fw-600 danger">Error</div>
    </div>

    <div class="d-flex flex-column gap--12"
         *ngIf="modalState$ | async let modalState"
         [class.mt--18]="!(form.get('price')?.value)"
         [class.mt--32]="form.get('price')?.value"
    >
      <loader-button [loading]="loading" [text]="'Create'"
                     (onClick)="create()" *ngIf="modalState === 'create'"
                     [@fadeIn]="{ value: ':leave', params : { time: '500ms' } }"
      ></loader-button>
      <loader-button [loading]="loading" [text]="'Update'"
                     (onClick)="update()" *ngIf="modalState === 'update'"
                     [@fadeIn]="{ value: ':leave', params : { time: '500ms' } }"
      ></loader-button>
      <loader-button [loading]="loading" [text]="'Create New Item'"
                     (onClick)="create()" *ngIf="modalState === 'createNewItem'"
                     [@fadeIn]="{ value: ':leave', params : { time: '500ms' } }"
      ></loader-button>
      <button mat-stroked-button (click)="close()">Close</button>
      <button mat-flat-button (click)="deleteItem()"
              class="mobile-delete-button"
              *ngIf="allowDeletion"
      >
        Delete
      </button>
    </div>
  </div>

</div>
