<div class="tile-wrapper" (click)="tileClick.emit(document)">
    <app-base-tile
        [data]="data"
    >
        <div class="time" [class.reverse-time]="reverseTimeOrder$ | async" leading>
            <p><span>{{ startTitle$ | async }}:</span><span class="start-value">{{ document.startTime | date: timeFormat }}</span></p>
            <p><span>{{ endTitle$ | async }}:</span><span class="start-value">{{ document.endTime | date: timeFormat }}</span></p>
        </div>
        <div class="info" trailing>
            <div class="se-button delete-button" (click)="onDeleteClick($event)">Delete</div>
            <div class="owner-info">
                <app-label class="label" color="grey" *ngIf="showDocType">Lead</app-label>
                <small *ngIf="showOwner$ | async" class="owner-name">{{ document.ownerFirstName }}&nbsp;{{ document.ownerLastName[0].toUpperCase() }}.</small>
            </div>
        </div>
    </app-base-tile>
</div>