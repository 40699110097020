export function isEmpty(data: any): boolean {
  return data === null || data === undefined || data === '' || +data === 0;
}

const dotKeys = [110, 190, 191];
const extraChars = [35, 36, 37, 39, 46, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
const unidentified = 229; //dot and comma on Android

export function decimalOnly(event: any): boolean {
  if (event.ctrlKey)
    return true;

  const inputValue = event.target.value;
  const charCode = (event.which) ? event.which : event.keyCode;

  if (['.', ','].includes(event.key) || charCode === unidentified) {
    const [start, end] = [event.target.selectionStart, event.target.selectionEnd];
    if (!inputValue || end - start === inputValue.length) {
      event.target.value = '0';
      return true;
    }

    if (inputValue.includes('.') && !inputValue.slice(start, end).includes('.'))
      return false;
  }

  if (/\d|\.|\,/.test(event.key) || charCode === unidentified)
    return true;

  if (dotKeys.includes(charCode))
    return false;

  return !(
    charCode > 31
    && (charCode <= 46 || charCode > 57)
    && !extraChars.includes(charCode)
  );
}

export function digitsOnly(event: any): boolean {
  if (event.ctrlKey)
    return true;

  if (/\d/.test(event.key))
    return true;

  const charCode = (event.which) ? event.which : event.keyCode;

  return !(
    charCode > 31
    && (charCode <= 46 || charCode > 57)
    && !extraChars.includes(charCode)
  );
}

export function onDecimalPaste(event: ClipboardEvent) {
  return !!event.clipboardData?.getData('text').match(/^\d+\.?\d*$/);
}

export function onIntegerPaste(event: ClipboardEvent) {
  return !!event.clipboardData?.getData('text').match(/^\d+$/);
}