<div class="flex-fill d-flex flex-column">
    <div class="row text-center calendar-header">
        <div class="calendar-header-elements">

            <div class="explanation-fields">
                <div *ngFor="let item of legendItems" class="explanation-field">
                    <div [class]="item.class"></div>
                    <p class="explanation-field__text">{{ item.title }}</p>
                </div>
            </div>

            <div class="month-changer">
                <div class="btn-group">
                    <div
                            mwlCalendarPreviousView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()"
                            (click)="previousMonth()"
                    >
                        <div class="arrow-left">
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.55743 0.944315C8.70542 0.801494 8.70542 0.569937 8.55743 0.427116C8.40944 0.284296 8.16951 0.284296 8.02152 0.427116L0.44257 7.7414C0.294582 7.88422 0.294582 8.11578 0.44257 8.2586L8.02152 15.5729C8.16951 15.7157 8.40944 15.7157 8.55743 15.5729C8.70542 15.4301 8.70542 15.1985 8.55743 15.0557L1.24286 7.99655L8.55743 0.944315Z"
                                      fill="#65707C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M0.208108 8.48487C-0.0693694 8.21708 -0.0693694 7.78291 0.208108 7.51513L7.78706 0.200841C8.06453 -0.066947 8.51441 -0.066947 8.79189 0.200841C9.06937 0.468629 9.06937 0.9028 8.79189 1.17059L1.7119 7.99666L8.79189 14.8294C9.06937 15.0972 9.06937 15.5314 8.79189 15.7992C8.51441 16.0669 8.06453 16.0669 7.78706 15.7992L0.208108 8.48487ZM0.677032 7.96767C0.658533 7.98553 0.658533 8.01447 0.677032 8.03232L8.25598 15.3466C8.27448 15.3645 8.30447 15.3645 8.32297 15.3466C8.34147 15.3288 8.34147 15.2998 8.32297 15.282L0.773822 7.99643L8.32297 0.718039C8.34147 0.700188 8.34147 0.671243 8.32297 0.65339C8.30447 0.635537 8.27448 0.635537 8.25598 0.65339L0.677032 7.96767Z"
                                      fill="#65707C"/>
                            </svg>

                        </div>
                    </div>
                </div>
                <h3>{{ viewDate | calendarDate:((view$ | async) + 'ViewTitle'):'en' }}</h3>
                <div class="btn-group">
                    <div
                            mwlCalendarNextView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()"
                            (click)="nextMonth()"
                    >
                        <div class="arrow-right">
                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M0.94257 0.944315C0.794581 0.801494 0.794581 0.569937 0.94257 0.427116C1.09056 0.284296 1.33049 0.284296 1.47848 0.427116L9.05743 7.7414C9.20542 7.88422 9.20542 8.11578 9.05743 8.2586L1.47848 15.5729C1.33049 15.7157 1.09056 15.7157 0.94257 15.5729C0.794581 15.4301 0.794581 15.1985 0.94257 15.0557L8.25714 7.99655L0.94257 0.944315Z"
                                      fill="#65707C"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M9.29189 8.48487C9.56937 8.21708 9.56937 7.78291 9.29189 7.51513L1.71294 0.200841C1.43547 -0.066947 0.985586 -0.066947 0.708109 0.200841C0.430631 0.468629 0.430631 0.9028 0.708109 1.17059L7.7881 7.99666L0.708109 14.8294C0.430631 15.0972 0.430631 15.5314 0.708109 15.7992C0.985586 16.0669 1.43547 16.0669 1.71294 15.7992L9.29189 8.48487ZM8.82297 7.96767C8.84147 7.98553 8.84147 8.01447 8.82297 8.03232L1.24402 15.3466C1.22552 15.3645 1.19553 15.3645 1.17703 15.3466C1.15853 15.3288 1.15853 15.2998 1.17703 15.282L8.72618 7.99643L1.17703 0.718039C1.15853 0.700188 1.15853 0.671243 1.17703 0.65339C1.19553 0.635537 1.22552 0.635537 1.24402 0.65339L8.82297 7.96767Z"
                                      fill="#65707C"/>
                            </svg>

                        </div>
                    </div>
                </div>

            </div>

            <div class="explanation-fields-small">
                <div *ngFor="let item of legendItems" class="explanation-field">
                    <div [class]="item.class"></div>
                    <p class="explanation-field__text">{{ item.title }}</p>
                </div>
            </div>

        </div>
        <div class="week-days">
            <div *ngFor="let day of daysOfTheWeek" class="day">{{ day }}</div>
        </div>
    </div>
    <br/>

    <div class="flex-fill">
        <mwl-calendar-month-view class="h-100"
                [viewDate]="viewDate"
                (dayClicked)="handleDayClick($event.day)"
                [cellTemplate]="customCellTemplate"
        >
        </mwl-calendar-month-view>
    </div>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
<!--    <ng-container class="unavailable-cell"-->
<!--                  *ngIf="(isUnavailableDay(day.date) | async) || (fullUnavailableDay(day.date) | async)">-->
<!--        <div class="cal-cell cal-day-cell cal-day-weekend">-->
<!--            <div class="cal-cell-top">-->
<!--                <span class="cal-day-number unavailable">-->
<!--                    {{ formatDatePart(day.date.getMonth()) }}/<strong [class.today-date]="isToday(day.date)">{{formatDatePart(day.date.getDate())}}</strong>-->
<!--                </span>-->
<!--            </div>-->
<!--        </div>-->

<!--    </ng-container>-->
    <ng-container *ngIf="calendarEvents$ | async let calendarEvents">
        <ng-container *ngIf="unavailableConfigSubject | async as unavailableConfig">
            <div *ngIf="day.date.getMonth() !== viewDate.getMonth() else dayCell"></div>
            <ng-template #dayCell>
                <div class="cell d-flex flex-column" [class.unavailable-cell]="isUnavailableDay(day.date, unavailableConfig) || fullUnavailableDay(day.date,unavailableConfig)"
                     [class.cal-day-cell__cursor-pointer]="hasEventsInAnyCategory(calendarEvents, day.date)"
                >
                    <div class="cal-cell-top">
                        <span class="cal-day-number" [class.past-date]="isPastDate(day.date)"
                              [class.available]="!isUnavailableDay(day.date, unavailableConfig)">
                            {{ formatDatePart(day.date.getMonth()+1) }}/<strong [class.today-date]="isToday(day.date)">{{formatDatePart(day.date.getDate())}}</strong>
                        </span>
                    </div>
                    <div class="flex-fill d-flex flex-column justify-content-end">
                        <div class="h-100 pb--10 d-flex align-items-center justify-content-center" *ngIf="fullUnavailableDay(day.date,unavailableConfig)">
                            <h4 class="full-unavailable-title">Un<span class="not-mobile-sm">available</span></h4>
                        </div>

                        <div class="calendar-cell-events" *ngIf="!fullUnavailableDay(day.date, unavailableConfig)">
                            <div class="calendar-event__leads"
                                *ngIf="countEventsInDay(calendarEvents.leads || [], day.date) > 0 &&
                                    (selectedTab === 'All' || selectedTab === 'Leads')"
                            >
                                <p class="calendar-event__count leads">
                                    {{ countEventsInDay(calendarEvents.leads || [], day.date) }}
                                </p>
                            </div>
                            <div class="calendar-event__estimates"
                                *ngIf="countEventsInDay(calendarEvents.estimates || [], day.date) > 0 &&
                                    (selectedTab === 'All')"
                            >
                                <p class="calendar-event__count estimates">
                                    {{ countEventsInDay(calendarEvents.estimates || [], day.date) }}
                                </p>
                            </div>
                            <div class="calendar-event__estimates"
                                *ngIf="countEventsInDay(calendarEvents.estimates || [], day.date, 'submitted') > 0 &&
                                    (selectedTab === 'Estimates')"
                            >
                                <p class="calendar-event__count estimates">
                                    {{ countEventsInDay(calendarEvents.estimates || [], day.date, 'submitted') }}
                                </p>
                            </div>
                            <div class="calendar-event__estimates-canceled"
                                 *ngIf="countEventsInDay(calendarEvents.estimates || [], day.date, 'canceled') > 0 && selectedTab === 'Estimates'">
                                <p class="calendar-event__count estimates">
                                    {{ countEventsInDay(calendarEvents.estimates || [], day.date, 'canceled') }}
                                </p>
                            </div>
                            <div class="calendar-event__jobs"
                                *ngIf="countEventsInDay(calendarEvents.jobs || [], day.date) > 0 &&
                                    (selectedTab === 'All')"
                            >
                                <p class="calendar-event__count jobs">
                                    {{ countEventsInDay(calendarEvents.jobs || [], day.date) }}
                                </p>
                            </div>
                            <div class="calendar-event__jobs"
                                *ngIf="countEventsInDay(calendarEvents.jobs || [], day.date, 'submitted') > 0 &&
                                    (selectedTab === 'Jobs')"
                            >
                                <p class="calendar-event__count jobs">
                                    {{ countEventsInDay(calendarEvents.jobs || [], day.date, 'submitted') }}
                                </p>
                            </div>
                            <div class="calendar-event__jobs-canceled"
                                 *ngIf="countEventsInDay(calendarEvents.jobs || [], day.date, 'canceled') > 0 && selectedTab === 'Jobs'">
                                <p class="calendar-event__count jobs">
                                    {{ countEventsInDay(calendarEvents.jobs || [], day.date, 'canceled') }}
                                </p>
                            </div>
                            <div class="calendar-event__jobs-pending"
                                 *ngIf="countEventsInDay(calendarEvents.jobs || [], day.date, 'pending') > 0 && selectedTab === 'Jobs'">
                                <p class="calendar-event__count jobs">
                                    {{ countEventsInDay(calendarEvents.jobs || [], day.date, 'pending') }}
                                </p>
                            </div>
                            <div class="calendar-event__unavailable"
                                *ngIf="countEventsInDay(calendarEvents.unavailable || [], day.date) > 0 &&
                                    (selectedTab === 'All' || selectedTab === 'Unavailable')">
                                <p class="calendar-event__count unavailable">
                                    {{ countEventsInDay(calendarEvents.unavailable, day.date) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>
