<div class="chat" (drop)="onDrop($event)" (dragover)="onDrag(true, $event)" (dragleave)="onDrag(false, $event)">
    <div class="top-section" [class.mb--10]="subjectControl.invalid" [class.hide-elements]="(editingUsersSubject | async)">
        <div class="top-autocomplete-anchor" matAutocompleteOrigin #topOrigin="matAutocompleteOrigin"></div>
        <mat-form-field class="subject-field flex-fill" appFormFieldFilled appearance="outline">
            <input matInput #subjectInput [formControl]="(editingUsersSubject | async) ? usersControl : subjectControl"
                   [placeholder]="(editingUsersSubject | async) ? '' : 'Subject'"
                   [matAutocomplete]="usersAutocomplete"
                   [matAutocompleteConnectedTo]="topOrigin"
                   (focusin)="subjectFocused = true"
                   (focusout)="subjectFocused = false"
                   #topTrigger="matAutocompleteTrigger"
                   [anchorAutocompleteOnScroll]="topTrigger"
            >
            <app-icon matSuffix src="close"
                  class="se-icon-button no-bg"
                  *ngIf="(editingUsersSubject | async)"
                  (click)="clearUsersField()"
            ></app-icon>
            <mat-error *ngIf="subjectControl.hasError('required')">Subject is required</mat-error>
            <mat-autocomplete #usersAutocomplete="matAutocomplete">
                <mat-option class="user-suggestion" [class.suspended]="user !== 'All' && user.action === 'Suspended'" *ngFor="let user of users$ | async" (click)="preventEvent($event, topTrigger)">
                    <span class="user-name">{{ user === 'All' ? 'All accounts' : user.firstName + ' ' + user.lastName }}</span>
                    <span [class]="'user-action ' + (user === 'All' ? 'Add' : user.action)" (click)="preventEvent($event, topTrigger); userSuggestionClick(user)">{{ user === 'All' ? 'Add' : user.action }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <ng-container *ngIf="!chat || (isChatMember$ | async)">
            <app-icon
                [class.hidden]="(editingUsersSubject | async)"
                class="se-icon-button no-bg" 
                borderRadius="3px" width="24px" height="24px" src="plus" 
                (click)="editUsers()"
            ></app-icon>
            <app-icon 
                *ngIf="!!chat && (workflowUsers$ | async)" [class.hidden]="(editingUsersSubject | async)"
                class="se-icon-button no-bg" borderRadius="3px" width="24px" height="24px"
                [src]="(canExit$ | async) ? 'exit' : (isChatMuted((currentUser$ | async)!.id) ? 'notif' : 'mute-notif')"
                [matTooltip]="(canExit$ | async) ? '' : isChatMuted((currentUser$ | async)!.id) ? 'Unmute' : 'Mute'"
                [matTooltipShowDelay]="1000"
                (click)="muteOrExitClick()"
            ></app-icon>
        </ng-container>
    </div>

    <div class="chat-content">
        <app-business-chat-content #chatContent 
            [menuIsOpen]="menuIsOpen" 
            [chat]="chat" 
            [sentMessage]="sentMessage" 
            [usersToAdd]="chat ? null : (usersToAdd$ | async)"
        ></app-business-chat-content>
        <lib-chat-overlay [class.dragging]="draggingAttachment"></lib-chat-overlay>
    </div>

    <div class="w-100" matAutocompleteOrigin #bottomOrigin="matAutocompleteOrigin"></div>

    <div class="bottom-section">
        <small class="typing" [class.show]="showTyping">{{ typingText }}</small>
        <div class="message-section">
            <app-icon class="se-icon-button no-bg mb--6" [style.border-radius]="'3px'" src="clip" (click)="attachmentClick($event)"></app-icon>
            <div class="suggestions" *ngIf="allowBottomSuggestions" [style.z-index]="4">
                <mat-option class="user-suggestion" [class.suspended]="user.action === 'Suspended'" *ngFor="let user of (mentionUsers$ | async)!" [value]="messageControl.value" (click)="preventEvent($event); userMentionClick(user)">
                    <span class="user-name">{{ user.firstName + ' ' + user.lastName }}</span>
                    <span *ngIf="user.action !== 'None'" [class]="'user-action ' + user.action" (click)="preventEvent($event); userSuggestionClick(user)">{{ user.action }}</span>
                </mat-option>
            </div>
            <div class="message-input flex-fill"
                 [class.focused]="messageInputFocusSubject | async"
                 [class.filled]="messageControl.value.length > 0"
            >
                <app-chat-message-input #messageInput
                    [messageControl]="messageControl"
                    (focusin)="messageInputFocusSubject.next(true); allowBottomSuggestions = true"
                    (focusout)="messageInputFocusSubject.next(false)"
                    (keydown)="onMessageKeyDown($event)"
                    placeholder="Message"
                ></app-chat-message-input>
                <app-icon class="send-button" src="send_message" (click)="sendMessage()"></app-icon>
            </div>
            <div class="audio-record-wrapper" [class.expand]="recordingStartSubject | async">
                <app-icon class="se-icon-button no-bg mic" borderRadius="3px" width="24px" height="24px" src="mic" (click)="recordMessage()"></app-icon>
                <small class="flex-fill recording-time">{{ recordingTime$ | async }} / 01:00</small>
                <app-icon class="se-icon-button no-bg" borderRadius="3px" width="24px" height="24px" src="trash" (click)="cancelAudioMessage()"></app-icon>
                <app-icon class="send-button" src="send_message" (click)="sendAudioMessage()"></app-icon>
            </div>
        </div>
    </div>
</div>

<dialog #dialog (close)="onDialogClose()">
    <div class="header">
        <h3>Attach file</h3>
        <app-icon class="se-icon-button" src="close" (click)="closeAttachmentDialog()"></app-icon>
    </div>
    <img *ngIf="selectedFileImage" [src]="selectedFileImage">
    <video *ngIf="selectedFileVideo" class="video" [src]="selectedFileVideo" controls></video>
    <div class="audio" *ngIf="selectedFileAudio">
        <audio #audioPlayer [style.display]="'none'" [src]="selectedFileAudio"></audio>
        <img [style.cursor]="'pointer'" [src]="'../../../assets/icons/' + (audioPlaying ? 'pause' : 'play') + '.svg'" (click)="toggle(audioPlayer)">
        <div class="progress"
             (click)="seek($event, audioPlayer)"
             (mousedown)="seeking = true"
             (mouseup)="seeking = false"
             (mouseleave)="seeking = false"
             (mousemove)="mouseMove($event, audioPlayer)"
        >
            <progress [value]="progress$ | async" max="100"></progress>
            <span class="current-time">{{ audioPlayer.currentTime * 1000 | date: 'mm:ss' }}</span>
            <span class="duration">{{ audioPlayer.duration * 1000 | date: 'mm:ss' }}</span>
        </div>
    </div>
    <div class="footer">
        <div class="w-100 d-flex align-items-center gap--8">
            <app-chat-message-input
                    class="message-input"
                    [messageControl]="attachmentMessageControl"
                    (keydown)="onDialogKeyDown($event)"
                    placeholder="Add a caption..."
            ></app-chat-message-input>
            <div class="d-flex gap--8 mb--2">
                <app-icon class="send-button" src="send_message" (click)="sendAttachmentMessage()"></app-icon>
            </div>
        </div>
    </div>
</dialog>