import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseTileData } from "../base-tile/base-tile.component";
import { JobTileData } from "../../models/jobs.model";
import { timer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { NgxMaskService } from "ngx-mask";
import { ViewAsService } from '../../services/view-as.service';

@Component({
    selector: 'app-job-tile',
    templateUrl: './job-tile.component.html',
    styleUrls: ['./job-tile.component.scss']
})
export class JobTileComponent implements OnInit {

    @Input() document!: JobTileData;
    @Input() showDocType = false;

    @Output() tileClick = new EventEmitter<JobTileData>();
    @Output() deleteJob = new EventEmitter<JobTileData>();
    data!: BaseTileData;

    reverseTimeOrder$ = timer(1000).pipe(
        startWith(0),
        map(_ => {
            return false;
        })
    );
    
    startTitle$ = timer(1000).pipe(
        startWith(0),
        map(_ => {
          return this.document.startTime.getTime() < Date.now() ? 'Started' : 'Starts';
        })
    );
    endTitle$ = timer(1000).pipe(
        startWith(0),
        map(_ => {
          return this.document.endTime.getTime() < Date.now() ? 'Ended' : 'Ends';
        })
    );

    userLabel$ = this.viewAsService.selectedUsers$.pipe(
        map(users => {
            if (users === 'All' || users.length > 1) {
                if (this.document.users.length === 1) {
                    const user = this.document.users[0];
                    return `${user.firstName} ${user.lastName[0]}.`;
                }
                return this.document.users.length + ' users';
            }
            return null;
        }),
    );
    
    readonly$ = this.viewAsService.readonly$;

    userNames!: string;
    label!: string;
    labelColor!: "red" | "yellow" | "grey";

    readonly timeFormat = 'hh:mm a';

    constructor(
        private maskService: NgxMaskService,
        private viewAsService: ViewAsService
    ) {}

    refreshData() {
        const phoneNumber = this.maskService.applyMask(this.document.phoneNumber, '(000) 000-0000');
        this.data = {
            title: this.document.firstName + ' ' + this.document.lastName,
            subtitle: phoneNumber,
            description: this.document.jobType
        };
        this.setUserNames();
        this.setLabel();
        this.setLabelColor();
    }

    setUserNames() {
        let names: string[] = [];
        for (const user of this.document.users) {
            let name = `${user.firstName} ${user.lastName[0]}.`;
            if(user.acceptanceStatus === 'looped_in')
                name += ' ↺';
            names.push(name);
        }
        this.userNames =  names.join('\n');
    }

    setLabel() {
        if(this.showDocType) {
            this.label = 'Job';
            return;
        }
        if(this.document.status === 'canceled' && this.document.outTotalAmount !== 0)
            this.label = '$' + this.document.outTotalAmount;
        else if(this.document.outTotalAmount === 0 || this.document.outTotalAmount === undefined)
            this.label = 'N/A';
        else
            this.label = '$' + this.document.outPaidAmount + '/$' + this.document.outTotalAmount;
    }

    setLabelColor() {
        this.labelColor = {
            canceled: 'red',
            pending: 'yellow',
            submitted: 'grey',
        }[this.document.status] as "red" | "yellow" | "grey";
    }

    ngOnInit() {
        this.refreshData();
    }

    async onDeleteClick(event: Event) {
        event.stopPropagation();
        this.deleteJob.emit(this.document);
    }
}
