import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ChatService, messagesLimit, unseenIndicatorDebounceMs } from '../../services/chat.service';
import { BusinessChatMessage, Chat, ChatContent, ChatLog, ChatUser, SentMessage, UserToAddChatLog } from '../../models/chat.model';
import { BehaviorSubject, combineLatest, Subject, timer } from 'rxjs';
import { debounce, debounceTime, filter, map, shareReplay, startWith, take } from 'rxjs/operators';
import { separateItemsByDate, UtilsService } from 'projects/common/src/public-api';
import moment from 'moment';
import { ChatMessage } from 'projects/common/src/lib/components/message/message.component';
import { UsersService } from '../../services/users.service';
import { UserProfile } from 'projects/common/src/lib/models/user-profile.model';
import { ImageViewerComponent } from 'projects/common/src/lib/components/image-viewer/image-viewer.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { SafeUrl } from '@angular/platform-browser';
import { RealtimeChannel } from '@supabase/supabase-js';
import { DOCUMENT } from "@angular/common";

const scrollThreshold = 500;
const updateLastSeenTimeoutMs = 200;

@Component({
  selector: 'app-business-chat-content',
  templateUrl: './business-chat-content.component.html',
  styleUrls: ['./business-chat-content.component.scss']
})
export class BusinessChatContentComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() chat!: Chat | null;
  @Input() sentMessage!: SentMessage | null;
  @Input() usersToAdd!: number[] | null;
  @Input() menuIsOpen!: boolean;

  @ViewChild('contentView') contentView!: ElementRef<HTMLElement>;

  private prevScroll = 0;
  private scrollDebouncer = new Subject<void>();
  private hideDateHeaderDebouncer = new Subject<HTMLElement>();

  loading = false;
  initializing = true;
  inserting = false;
  canLoadMore = true;
  contentSubject = new BehaviorSubject<ChatContent>([]);
  intersectionObserver: IntersectionObserver | null = null;
  lastMessageSeenIdSubject = new BehaviorSubject<number | null>(null);
  messagesChannel: RealtimeChannel | null = null;
  logsChannel: RealtimeChannel | null = null;
  updateLastSeenTimeoutId: NodeJS.Timeout | undefined = undefined;

  contentByDate$ = this.contentSubject.pipe(
    map(items => items),
    separateItemsByDate(item => moment(item.createdAt).format(moment(item.createdAt).isSame(moment(), 'year') ? 'DD MMMM' : 'DD MMMM, yyyy')),
    shareReplay(1),
  );

  dateHeaders$ = this.contentByDate$.pipe(
    map(items => Object.getOwnPropertyNames(items)),
    shareReplay(1),
  );

  lastDateHeader$ = combineLatest([this.dateHeaders$.pipe(map(headers => headers[headers.length - 1])), timer(30000, 30000).pipe(startWith(0))]).pipe(
    map(([header]) => header === moment().format('DD MMMM') ? 'Today' : header),
    shareReplay(1),
  );

  unseenCount$ = combineLatest([
    this.contentSubject,
    this.lastMessageSeenIdSubject,
    this.usersService.currentUser$
  ]).pipe(
    map(([messages, lastSeenId, currentUser]) => {
      return messages
        .filter(message => this.isMessage(message) && (lastSeenId && message.id > lastSeenId || !lastSeenId) && message.senderId !== currentUser.id)
        .length;
    }),
    shareReplay(1)
  );

  showIndicator$ = this.unseenCount$.pipe(
    map(count => count > 0),
    debounce(show => timer(show ? unseenIndicatorDebounceMs : 0)),
    shareReplay(1)
  );

  currentUser$ = this.usersService.currentUser$;

  constructor(
    private chatService: ChatService,
    private usersService: UsersService,
    private utilsService: UtilsService,
    private overlay: Overlay,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.scrollDebouncer
      .pipe(
        debounceTime(100),
        filter(_ => !this.loading)
      )
      .subscribe(() => this.loadMore());

    this.hideDateHeaderDebouncer
      .pipe(
        debounceTime(500),
      )
      .subscribe((header: HTMLElement) => {
        if (this.contentView.nativeElement.scrollTop === 0)
          return;
        header.style.transition = 'opacity 500ms';
        header.style.opacity = '0';
      });
  }

  ngAfterViewInit(): void {
    this.init();
  }

  async init() {
    if (!this.chat)
      return;

    const initObserver = () => {
      const options = {
        root: this.contentView.nativeElement,
        rootMargin: '0px',
        threshold: 0.8,
      };
      this.intersectionObserver = new IntersectionObserver(this.onIntersectionObserverEvent.bind(this), options);
      for (let i = this.contentSubject.value.length - 1; i >= 0; i--) {
        const message = this.contentSubject.value[i];
        if (this.isMessage(message) && (!this.lastMessageSeenIdSubject.value || message.id > this.lastMessageSeenIdSubject.value))
          this.observeMessage(message.id);
      }
    }

    const initFocusIn = () => {
      document.addEventListener('focusin', _ => {
        const hasScroll = this.contentView.nativeElement.scrollHeight > this.contentView.nativeElement.clientHeight;
        if (!hasScroll && this.chat)
          this.updateLastMessageSeen(this.chat.lastMessageId);
      });
    }

    const subscribeToMessages = async () => {
      this.chatService.subscribeToMessages(this.chat!.id, async messageRecord => {
        const senderIsCurrentUser = messageRecord.senderId === await this.currentUserId();
        const content = this.contentSubject.value;
        if (senderIsCurrentUser && content.length > 0) {
          this.updateLastMessageSeen(messageRecord.id);
          for (let index = content.length - 1; index >= 0; index--) {
            const item = content[index];
            if (
              this.isMessage(item) && item.id === messageRecord.id
              || this.isSentMessage(item) && item.content === messageRecord.content && item.attachment === messageRecord.attachment
            ) {
              item.createdAt = messageRecord.createdAt;
              const prev = index > 0 ? content[index - 1] : null;
              const next = index < content.length - 1 ? content[index + 1] : null;
              if (prev && item.createdAt < prev.createdAt || next && item.createdAt > next.createdAt) {
                content.splice(index, 1);
                for (index = content.length; index >= 0; index--) {
                  if (index === 0 || item.createdAt > content[index - 1].createdAt) {
                    content.splice(index, 0, item);
                    this.contentSubject.next(content);
                    return;
                  }
                }
              }
              return;
            }
          }
        }

        const senderProfile = await this.getUserById(messageRecord.senderId);
        const message: BusinessChatMessage = {
          ...messageRecord,
          firstName: senderProfile.firstName,
          lastName: senderProfile.lastName,
          usersSeen: senderIsCurrentUser ? [] : null
        };
        await this.addItem(message);
      })
      .then(channel => this.messagesChannel = channel);
    };

    const subscribeTologs = async () => {
      this.chatService.subscribeToLogs(this.chat!.id, async logRecord => {
        const actorProfile = await this.getUserById(logRecord.actorId);
        const subjectProfile = await this.getUserById(logRecord.subjectId);
        for (const item of this.contentSubject.value) {
          if (!this.isUserToAddLog(item))
            break;
          if (item.subjectId === logRecord.subjectId) {
            (item as any as ChatLog).id = logRecord.id;
            item.createdAt = logRecord.createdAt;
            return;
          }
        }
        const log: ChatLog = {
          ...logRecord,
          actorFirstName: actorProfile!.firstName,
          actorLastName: actorProfile!.lastName,
          subjectFirstName: subjectProfile!.firstName,
          subjectLastName: subjectProfile!.lastName,
        };
        await this.addItem(log);
      })
      .then(channel => this.logsChannel = channel);
    }

    this.loading = true;
    this.contentSubject.next(await this.chatService.getChatContent(this.chat.id));
    subscribeToMessages();
    subscribeTologs();
    if (this.contentSubject.value.filter(item => this.isMessage(item)).length < messagesLimit)
      this.canLoadMore = false;

    const chatUser = await this.getChatCurrentUser();
    if (chatUser) {
      const lastSeenId = chatUser.lastSeenId;
      if (this.lastMessageSeenIdSubject.value === null || this.lastMessageSeenIdSubject.value < lastSeenId)
        this.lastMessageSeenIdSubject.next(lastSeenId);
    } else {
      this.lastMessageSeenIdSubject.next(this.chat.lastMessageId);
    }

    this.cd.detectChanges();
    initObserver();
    initFocusIn();
    await this.scrollToFirstOtherUnseen();
    this.contentView.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    this.loading = false;
    this.initializing = false;
  }

  ngOnDestroy() {
    if (this.messagesChannel)
      this.chatService.unsubscribe(this.messagesChannel);
    if (this.logsChannel)
      this.chatService.unsubscribe(this.logsChannel);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.chat?.currentValue) {
      if (changes.chat.previousValue === null) {
        await this.init();
        return;
      }

      const updateUsersSeen = async () => {
        if (!changes.chat.previousValue)
          return;

        const curUsers = changes.chat.currentValue.users as ChatUser[];
        const prevUsers = changes.chat.previousValue.users as ChatUser[];
        const content = this.contentSubject.value;
        const currentUserId = await this.currentUserId();
        let contentChanged = false;
        for (const curUser of curUsers) {
          if (curUser.userId === currentUserId)
            continue;
          const prevUser = prevUsers.find(user => user.id === curUser.id);
          if (!prevUser || prevUser.lastSeenId !== curUser.lastSeenId)
            for (let i = content.length - 1; i >= 0; i--) {
              const item = content[i];
              if (!this.isMessage(item) || item.senderId !== currentUserId || item.id > curUser.lastSeenId)
                continue;
              if (prevUser && item.id <= prevUser.lastSeenId)
                break;
              item.usersSeen?.push(curUser.userId);
              contentChanged = true;
            }
        }
        if (contentChanged) {
          this.contentSubject.next(content);
        }
      }

      const chatUser = await this.getChatCurrentUser();
      if (chatUser && (this.lastMessageSeenIdSubject.value === null || this.lastMessageSeenIdSubject.value < chatUser.lastSeenId))
        this.lastMessageSeenIdSubject.next(chatUser.lastSeenId);
      updateUsersSeen();
    }

    if (changes.sentMessage?.currentValue) {
      if (!this.chat)
        this.initializing = false;
      if (this.sentMessage?.id === null)
        this.addItem(this.sentMessage);
    }

    if (changes.usersToAdd?.currentValue && !this.chat) {
      this.initializing = false;
      this.contentSubject.next([]);
      const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
      const users = await this.usersService.users$.pipe(take(1)).toPromise();
      for (const id of this.usersToAdd!) {
        const user = users?.find(u => u.id === id);
        if (!user)
          break;
        const item: UserToAddChatLog = {
          action: 'user_added',
          actorId: currentUser.id,
          subjectId: id,
          actorFirstName: currentUser.firstName,
          actorLastName: currentUser.lastName,
          subjectFirstName: user.firstName,
          subjectLastName: user.lastName,
          createdAt: new Date(),
          id: null
        }
        this.addItem(item);
      }
    }
  }

  observeMessage(id: number) {
    const element = this.contentView.nativeElement.querySelector(`[data-id='${id}']`);
    if (this.intersectionObserver && element)
      this.intersectionObserver.observe(element);
  }

  async addItem(item: BusinessChatMessage | SentMessage | ChatLog | UserToAddChatLog) {
    const content = this.contentSubject.value;
    const lastItem = content[content.length - 1];
    if (lastItem && this.isMessage(lastItem) && item.createdAt.getTime() === lastItem.createdAt.getTime())
      content.splice(-1, 0, item);
    else
      content.push(item);
    this.contentSubject.next(content);
    const scrollFromBottom = this.contentView.nativeElement.scrollTop + this.contentView.nativeElement.clientHeight;
    const scrollHeight = this.contentView.nativeElement.scrollHeight;
    const clientHeight = this.contentView.nativeElement.clientHeight;
    const threshold = 60;
    const hasScroll = scrollHeight > clientHeight;
    const hasFocus = document.hasFocus() && this.menuIsOpen;
    const scrollAtBottom = (scrollFromBottom + threshold) >= scrollHeight;
    const senderIsCurrentUser = !(this.isLog(item) || this.isUserToAddLog(item)) && item.senderId === await this.currentUserId();

    if (
      this.isMessage(item)
      && (senderIsCurrentUser || !hasScroll && hasFocus || hasScroll && scrollAtBottom && hasFocus)
    ) {
      this.updateLastMessageSeen(item.id);
    }
    this.cd.detectChanges();

    if (
      hasScroll && scrollAtBottom && (hasFocus || senderIsCurrentUser || this.isLog(item))
      || hasFocus && senderIsCurrentUser
    ) {
      this.contentView.nativeElement.scrollTo({
        top: this.contentView.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
      return;
    } else if (
      this.isMessage(item) && !senderIsCurrentUser 
      && (!hasFocus || (hasScroll && !scrollAtBottom || !hasScroll))
    ) {
      this.observeMessage(item.id);
    }
  }

  async currentUserId() {
    return (await this.currentUser$.pipe(take(1)).toPromise()).id;
  }

  async getChatCurrentUser() {
    const currentUserId = await this.currentUserId();
    return this.chat?.users.find(user => user.userId === currentUserId);
  }

  getUserById(id: number) {
    return this.usersService.users$.pipe(map(users => users?.find(user => user.id === id)!), take(1)).toPromise();
  }

  async lastSeenId() {
    const currentUserId = await this.currentUserId();
    return this.chat?.users.find(user => user.userId === currentUserId)?.lastSeenId ?? null;
  }

  async updateLastMessageSeen(id: number, useDebounce = false) {
    if (!this.lastMessageSeenIdSubject.value || this.lastMessageSeenIdSubject.value < id) {
      this.lastMessageSeenIdSubject.next(id);
      if (await this.getChatCurrentUser() && this.updateLastSeenTimeoutId === undefined) {
        if (useDebounce)
          this.updateLastSeenTimeoutId = setTimeout(() => {
            clearTimeout(this.updateLastSeenTimeoutId);
            this.updateLastSeenTimeoutId = undefined;
            this.chatService.setLastMessageSeen(this.lastMessageSeenIdSubject.value!, this.chat!.id);
          }, updateLastSeenTimeoutMs);
        else
          this.chatService.setLastMessageSeen(id, this.chat!.id);
      }
    }
  }

  async scrollToFirstOtherUnseen(smooth = false) {
    if (this.lastMessageSeenIdSubject.value === null || this.contentView.nativeElement.scrollHeight <= this.contentView.nativeElement.clientHeight)
      return;
    const currentUserId = await this.currentUserId();
    const message = this.contentSubject.value
      .find(message => this.isMessage(message) && message.senderId !== currentUserId && message.id > this.lastMessageSeenIdSubject.value!);

    if (message)
      this.contentView.nativeElement.querySelector(`[data-id='${message.id!}']`)?.scrollIntoView({ block: 'start', behavior: smooth ? 'smooth' : 'auto' });
    else
      this.contentView.nativeElement.scrollTo({
        top: this.contentView.nativeElement.scrollHeight
      });
  }

  isMessage(item: BusinessChatMessage | SentMessage | ChatLog | UserToAddChatLog): item is BusinessChatMessage {
    return typeof (item as any).senderId === 'number' && item.id !== null;
  }

  isSentMessage(item: BusinessChatMessage | SentMessage | ChatLog | UserToAddChatLog): item is SentMessage {
    return typeof (item as any).senderId === 'number' && item.id === null;
  }

  isLog(item: BusinessChatMessage | SentMessage | ChatLog | UserToAddChatLog): item is ChatLog {
    return typeof (item as any).actorId === 'number' && item.id !== null;
  }

  isUserToAddLog(item: BusinessChatMessage | SentMessage | ChatLog | UserToAddChatLog): item is UserToAddChatLog {
    return typeof (item as any).actorId === 'number' && item.id === null;
  }

  getLogText(log: ChatLog | UserToAddChatLog, currentUserId: number) {
    const userName = `${log.subjectFirstName} ${log.subjectLastName}`;
    if (log.actorId === log.subjectId)
      return log.action === 'user_added' ? `${userName} was added to chat` : `${userName} left the chat`;
    if (log.actorId === currentUserId)
      return log.action === 'user_added' ? `You added ${userName} to chat` : `${userName} was removed from the chat`;
    return log.action === 'user_added' ? `${userName} was added to chat` : `${userName} was removed from the chat`;
  }

  convertMessage(message: BusinessChatMessage | SentMessage, currentUser: UserProfile): ChatMessage {
    if (this.isSentMessage(message)) {
      return {
        id: null,
        createdAt: message.createdAt,
        status: 'sent',
        content: message.content!,
        attachment: message.file || message.attachment ? {
          file: message.file!,
          path: message.attachment!
        } : null,
      };
    }

    const baseMessage = {
      id: message.id,
      createdAt: message.createdAt,
      content: message.content!,
      attachment: message.attachment ? {
        bucket: 'chat',
        path: message.attachment,
      } : null,
    };

    let convertedMessage: ChatMessage;
    if (message.senderId !== currentUser.id) {
      convertedMessage = {
        ...baseMessage,
        status: 'foreign',
        firstName: message.firstName,
        lastName: message.lastName,
      };
    } else {
      convertedMessage = {
        ...baseMessage,
        status: (message.usersSeen?.length ?? 0) > 0 ? 'seen' : 'delivered',
      };
    }

    return convertedMessage;
  }

  async loadMore() {
    if (!this.canLoadMore || !this.chat)
      return;
    this.loading = true;
    const loadedContent = await this.chatService.getChatContent(this.chat.id, this.contentSubject.value.find(item => this.isMessage(item))?.id);
    if (loadedContent.filter(item => this.isMessage(item)).length < messagesLimit)
      this.canLoadMore = false;
    if (this.utilsService.isIOS()) {
      this.inserting = true;
      const scrollElement = this.contentView.nativeElement!;
      const scrollFromBottom = scrollElement.scrollHeight - scrollElement.scrollTop;
      this.contentSubject.value.unshift(...loadedContent);
      this.contentSubject.next(this.contentSubject.value);
      this.cd.detectChanges();
      this.contentView.nativeElement.scrollTo({ top: scrollElement.scrollHeight - scrollFromBottom });
      this.cd.detectChanges();
      this.inserting = false;
    } else {
      this.contentSubject.value.unshift(...loadedContent);
      this.contentSubject.next(this.contentSubject.value);
    }
    this.loading = false;
  }

  private onScroll() {
    this.updateHeadersVisibility();
    if (!this.canLoadMore) 
      return;
    const scrollElement = this.contentView.nativeElement!;
    const scroll = scrollElement.scrollTop;
    if (scroll < 10)
      scrollElement.scrollTo({ top: 10 });
    if (this.loading)
      return;
    if (this.canLoadMore && scroll < this.prevScroll && scroll < scrollThreshold)
      this.scrollDebouncer.next();
    this.prevScroll = scroll;
  }

  updateHeadersVisibility() {
    const elements = this.document.getElementsByTagNameNS('http://www.w3.org/1999/xhtml', 'lib-chat-date-header');
    const contentRect = this.contentView.nativeElement.getBoundingClientRect();
    let firstSet = false;
    for (let i = elements.length - 1; i > -1; i--) {
      const stick = elements[i].getBoundingClientRect().top === contentRect.top;
      if(!stick) {
        elements[i].style.opacity = '1';
      } else if(!firstSet) {
        firstSet = true;
        elements[i].style.opacity = '1';
        this.hideDateHeaderDebouncer.next(elements[i]) ;       
      } else {
        elements[i].style.transition = 'unset';
        elements[i].style.opacity = '0';
      }
    }
  }

  async onIntersectionObserverEvent(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    if (!document.hasFocus()) {
      for (const entry of entries) {
        observer.unobserve(entry.target);
        observer.observe(entry.target);
      }
      return;
    }
    let lastId: number | null = null;
    for (const entry of entries) {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        const curId = +((entry.target as HTMLElement).dataset.id!);
        if (!lastId || lastId < curId)
          lastId = curId;
      }
    }
    if (lastId) {
      this.updateLastMessageSeen(lastId, true);
    }
  }

  showImageViewer(imageUrl: SafeUrl | string) {
    const viewer = new ComponentPortal(ImageViewerComponent);
    const overlayRef = this.overlay.create(
      {
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        panelClass: 'image-viewer-overlay'
      },
    );
    const viewerRef = overlayRef.attach(viewer);
    viewerRef.setInput('imageUrl', imageUrl);
    viewerRef.setInput('showDownload', true);
    const subscription = viewerRef.instance.onClose.subscribe(() => {
      overlayRef.detach();
      viewer.viewContainerRef?.clear();
      subscription.unsubscribe();
    });
  }
}
