<div class="page bg" *ngIf="state$ | async let state">

    <app-toolbar></app-toolbar>

    <div class="container d-flex flex-fill justify-content-center w-100">
    
        <mat-card class="mat-elevation-z3">
            <div class="content" *ngIf="state === 'ready' else noData">
                <ng-container *ngIf="authUser$ | async else signup">
                    <mat-card-title><h2 class="text-center">Welcome onboard!</h2></mat-card-title>
                
                    <p class="text-center">You've been invited to join {{ (business$ | async)?.businessName }}</p>
                    <p class="text-center mb--16">Would you like to accept the invitation?</p>

                    <loader-button [loading]="loading" text="Accept" (onClick)="accept()"></loader-button>
                </ng-container>
                
            </div>
        </mat-card>
    </div>
</div>

<ng-template #noData>
    <mat-card-title><h2 class="text-center mb--40">Invitation not found</h2></mat-card-title>

    <p class="text-center" style="text-wrap: balance">Click the link in the invitation email to make sure you have the right invitation.<br>Contact the business owner if you still see this&nbsp;message</p>

    <button *ngIf="authUser$ | async" class="mt--24" mat-flat-button color="primary" (click)="close()">Close</button>
</ng-template>

<ng-template #signup>
    <div class="content">
        <mat-card-title>
            <h2 class="text-center second-title" *ngIf="!login">Create an account to accept the invitation</h2>
            <h2 class="text-center second-title" *ngIf="login">Login and add <span class="business-name">{{ (business$ | async)?.businessName }}</span> to your account</h2>
        </mat-card-title>

        <form class="d-flex flex-column align-items-stretch" [formGroup]="accountForm">

            <h4>Email</h4>
            <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #emailInput id="input_email"
                    formControlName="email"
                    type="email"
                    inputmode="email"
                    placeholder="Example@gmail.com"
                    autocomplete="email"
                    (focus)="emailFocused = true"
                    (focusout)="emailFocused = false"
                    enterkeyhint="next"
                    tabindex="3"
                />
                <mat-error *ngIf="accountForm.get('email')!.hasError('required') && accountForm.get('email')!.touched">
                    Email is required
                </mat-error>
                <mat-error *ngIf="accountForm.get('email')!.hasError('email') && accountForm.get('email')!.touched">
                    Invalid email. Re-enter
                </mat-error>
                <mat-error *ngIf="accountForm.get('email')!.hasError('emailInUse') && accountForm.get('email')!.touched">
                    Email already in use.
                </mat-error>
                <mat-error *ngIf="accountForm.get('email')!.hasError('emailPassword') && accountForm.get('email')!.touched">
                    Incorrect email or password
                </mat-error>
            </mat-form-field>

            <h4>{{login ? 'Password' : 'Create password'}}</h4>
            <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #passwordInput id="input_password"
                        formControlName="password"
                        type="password"
                        placeholder="At least 6 characters"
                        autocomplete="new-password"
                        [type]="showPassword ? 'text' : 'password'"
                        enterkeyhint="go"
                        tabindex="4"
                />
                <img *ngIf="accountForm.get('password')!.value.length > 0" matSuffix
                    [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                    (click)="showPassword = !showPassword">
                <mat-error *ngIf="accountForm.get('password')!.hasError('required') && accountForm.get('password')!.touched">
                    Password is required
                </mat-error>
                <mat-error *ngIf="accountForm.get('password')!.hasError('minlength') && accountForm.get('password')!.touched">
                    at least 6 characters
                </mat-error>
                <mat-error *ngIf="accountForm.get('password')!.hasError('emailPassword') && accountForm.get('password')!.touched">
                    Incorrect email or password
                </mat-error>
            </mat-form-field>

            <loader-button class="footer" [loading]="loading" [text]="login ? 'Login' : 'Create account'" id="button_signup" (onClick)="action()"></loader-button>

            <app-providers-row [type]="login ? 'login' : 'signup'" [redirectTo]="(redirectTo$ | async)!" (onStart)="saveInvitation()"></app-providers-row>
        </form>
    </div>

    <h4 class="text-center second-title change-button" [class.emphasize]="emphasize" (click)="changeView()">{{ login ? '\< Back' : 'Or link to an existing account >' }}</h4>
</ng-template>