import { Inject, Injectable, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { shareReplay, map, switchMap, distinctUntilChanged, take, pairwise, startWith, tap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UserPermission, UserRole } from '../../../../common/src/lib/models/user-profile.model';
import { SupabaseService } from "./supabase.service";
import { capitalizeFirstChar, UtilsService } from "../../../../common/src/lib/services";
import { showSnackbar } from "../../../../common/src/lib/components/snackbar/snackbar.component";
import { User } from "@supabase/supabase-js";
import { ModalsService } from "../../../../common/src/lib/services/modals.service";
import { PushyService } from "./pushy.service";
import { environment } from "../../environments/environment";
import { SnackbarService } from "../../../../common/src/lib/components/snackbar/snackbar.service";

export interface UserI {
  uid: string,
  email: string,
  providers: string[],
  lastSignIn: Date,
}

export interface Role {
  role: UserRole,
  businessId: string,
  permissions: UserPermission[]
}

const providersMap: { [provider: string]: string } = {
  'password': 'Email',
  'google.com': 'Google',
  'facebook.com': 'Facebook',
  'apple.com': 'Apple',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth = this.supabaseService.supabase.auth;
  private changedValuesSubject = new BehaviorSubject<any>({});
  private authChanges$ = new ReplaySubject<User | null>();

  user$ = this.authChanges$.pipe(
      startWith(null),
      pairwise(),
      map(([prev, curr]) => {
        if(environment.production) {
          if(prev === null && curr !== null) {
            this.pushyService.register();
          } else if(prev !== null && curr === null) {
            this.pushyService.unregister();
          }
        }
        return curr;
      }),
      switchMap(user => {
        return this.changedValuesSubject.pipe(
            map(changedValues => {
              return user == null ? null : {
                uid: user.id,
                email: changedValues.email ?? user.email,
                providers: user.identities?.map(identity => capitalizeFirstChar(identity.provider)),
                lastSignIn: user.last_sign_in_at ? new Date(user.last_sign_in_at) : new Date()
              } as UserI
            })
        );
      }),
      distinctUntilChanged((prev, curr) => {
        if(prev === null && curr === null)
          return true;
        if(prev === null || curr === null) {
          this.router.navigate([curr ? 'lobby' : 'login']);
          return false;
        }
        for(const key of Object.keys(prev)) {
          if((prev as any)[key] !== (curr as any)[key])
            return false;
        }
        if(prev.providers.length !== curr.providers.length)
          return false;
        return true;
      }),
      shareReplay(1)
  );

  unlinkedDoubleProviderAccount = false;
  popupReference: Window | null = null;

  constructor(
    @Inject('DOMAIN') private domain: string,
    private router: Router,
    private snackBar: MatSnackBar,
    private supabaseService: SupabaseService,
    private modalsService: ModalsService,
    private utilsService: UtilsService,
    private pushyService: PushyService,
    private snackbarService: SnackbarService,
    private zone: NgZone
  ) {
    this.auth.onAuthStateChange((event, session) => {
      this.zone.run(() => {
        if(event === 'INITIAL_SESSION')
          this.auth.refreshSession();

        if(event === 'PASSWORD_RECOVERY')
          this.router.navigate(['/change-password']);

        this.authChanges$.next(session?.user ?? null);
      });
    });
    snackbarService.doLogout.subscribe(this.logout.bind(this));
  }

  async signUpWithGoogle(redirectTo?: string) {
    return this.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: this.domain + '/' + (redirectTo ?? 'lobby'),
        queryParams: {
          prompt: 'select_account',
        },
      },
    });
  }
  
  async signInWithGoogle(redirectTo?: string) {
    const res = await this.signUpWithGoogle(redirectTo);
    return res;
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    const res = await this.auth.signInWithPassword({ email, password });
    if(res.error) {
      throw res.error.message;
    } else {
      this.router.navigate(['/lobby']);
    }
  }

  async signUpWithEmailAndPassword(email: string, password: string) {
    const res = await this.auth.signUp({ email, password });
    if(res.error) {
      throw res.error.message;
    } else {
      this.router.navigate(['/lobby']);
    }
  }

  async reauthenticateWithPassword(password: string) {
    const user = await this.user$.pipe(take(1)).toPromise();
    const res = await this.auth.signInWithPassword({
      email: user!.email,
      password
    });
    if(res.error) {
      return Promise.reject(res.error.message);
    }
    return res;
  }

  generateGoogleAuthLink() {
    return this.auth.signInWithOAuth({
      provider: 'google',
      options: {
        skipBrowserRedirect: true,
        redirectTo: this.domain + '/gauthres',
        queryParams: {
          prompt: 'select_account',
          ux_mode: 'popup',
        },
      },
    })
  }

  async reauthenticateGoogle() {

    const prevUser = (await this.user$.pipe(take(1)).toPromise())!.uid;
    const url = this.domain + '/gauth';
    // window.removeEventListener('message', this.receiveGoogleAuthPopupMessage);
    if (this.popupReference === null || this.popupReference.closed) {
      this.popupReference = window.open(url, 'googleAuthPopup', 'width=500,height=600');
    } else if (this.popupReference.location.href !== url) {
      this.popupReference = window.open(url, 'googleAuthPopup', 'width=500,height=600');
      this.popupReference!.focus();
    } else {
      this.popupReference.focus();
    }

    await new Promise<void>(async (resolve, reject) => {
      window.addEventListener('message', event => this.receiveGoogleAuthPopupMessage(resolve, reject, event), false);
      const timer = setInterval((() => {
        if(!this.popupReference || this.popupReference.closed) {
          clearInterval(timer);
          reject('manually-closed');
        }
      }).bind(this), 1000);
    });

    await this.utilsService.delay(500);
    const user = (await this.auth.getUser()).data.user?.id ?? null;

    if(!!user && prevUser !== user) {
      this.logout();
      this.modalsService.close();
      throw new Error('reject');
    }
  }

  async receiveGoogleAuthPopupMessage(resolve: (value: (void | PromiseLike<void>)) => void, reject: (reason?: any) => void, event: MessageEvent) {
    if(event.data === 'success')
      resolve();
    else
      reject('failed');
  }

  sendPasswordResetEmail(email: string) {
    return this.auth.resetPasswordForEmail(email, {
      redirectTo: this.domain + '/update-password',
    });
  }

  async changePassword(newPassword: string) {
    const res = await this.auth.updateUser({ password: newPassword });
    if(res.error)
      throw res.error.message;
    await this.auth.refreshSession();
  }

  async changeAccountViaGoogle() {
    this.auth.linkIdentity({
      provider: 'google',
      options: {
        redirectTo: this.domain + '/lobby',
        queryParams: {
          prompt: 'select_account',
        },
      },
    });
    showSnackbar(this.snackBar, {
      message: "Profile updated",
    });
  }

  async changeAccountViaEmail(email: string, password?: string) {
    const res = await this.auth.updateUser(password ? { email, password } : { email });
    if(res.error?.message === 'A user with this email address has already been registered') {
      throw 'emailInUse';
    }
    if(res.error?.message === 'Unable to validate email address: invalid format') {
      throw 'email';
    }
    if(res.error?.message === 'New password should be different from the old password.') {
      await this.auth.updateUser({ email });
    }
    this.auth.refreshSession();
    showSnackbar(this.snackBar, {
      message: "Profile updated",
    });
  }

  async logout() {
    if (!(this.snackbarService.isActionSnackbar$.value)) {
      await this.auth.signOut({ scope: 'local' });
    } else {
      this.snackbarService.isDelayedLogout$.next(true);
    }
  }

}
