<div class="dialog">
  <div class="header">
    <h3>Example</h3>
    <app-icon class="close se-icon-button" (click)="close()" src="close" ></app-icon>
  </div>
  <div class="dialog-content">
    <h3 class="text-center">Fill out the table with items</h3>
    <p class="first-p">Fields marked with an asterisk (*) are required</p>
    <div class="example-table">

        <div class="table-header">Name*</div>
        <div class="table-header">Description</div>
        <div class="table-header">Price*</div>
        <div class="table-header">Cost</div>
        <div class="last-columng table-header">Texable</div>

        <div>Black walnut slat door</div>
        <div class="fw-400">Size: 3/4"W x 3/4"D<br>Slat Material: 100% Solid Wood<br>BackingMaterial: Wood Veneer</div>
        <div class="text-right">$600.00</div>
        <div class="text-right">$300.00</div>
        <div class="last-columng text-center">Yes</div>

        <div class="last-row">Cleaning the floor in the kitchen</div>
        <div class="last-row fw-400">Size: 3/4"W x 3/4"D<br>Slat Material: 100% Solid Wood</div>
        <div class="last-row text-right">$200.00</div>
        <div class="last-row text-right">$80.00</div>
        <div class="last-row last-columng text-center">No</div>

    </div>
    <p class="second-p">Download a ready made table</p>
    <a class="d-none" #download href="assets/example.xlsx"></a>
    <button class="download-button" mat-flat-button color="primary" (click)="mobileDownload()">Download</button>
  </div>
</div>
