import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { ProposalsService } from "../../services/proposals.service";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import {
  CreateEditWorkflowItemComponent
} from "../../modals/create-edit-workflow-item/create-edit-workflow-item.component";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { debounce, filter, map, tap } from "rxjs/operators";
import { WorkflowItemInfoMobileComponent } from "../workflow-item-info-mobile/workflow-item-info-mobile.component";
import { CommonPoIItem } from "../../models/poi.model";
import { digitsOnly, onIntegerPaste } from "../../helpers/forms";
import { UsersService } from "../../services/users.service";
import {
  CreateEditWorkflowItemRestrictedComponent
} from "../../modals/create-edit-workflow-item-restricted/create-edit-workflow-item-restricted.component";
import { BusinessService } from "../../services/business.service";

@Component({
  selector: 'app-proposal-items-list',
  templateUrl: './proposal-items-list.component.html',
  styleUrls: ['./proposal-items-list.component.scss']
})
export class ProposalItemsListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  mobileView = false;
  @HostListener('window:resize')
  onResize() {
    this.mobileView = window.innerWidth < 576;
  }

  protected readonly digitsOnly = digitsOnly;
  protected readonly onPaste = onIntegerPaste;

  @Input() proposal!: Proposal;
  @Input() proposalVersion!: ProposalVersion;
  @Input() scrollPosition = 0;

  @Output() itemsListFocus = new EventEmitter<boolean>();

  dragging: boolean = false;

  hoveredItemIndex: number | null = null;
  infoIconDistancesFromTop: number[] = [];

  afterViewInit = false;

  qtyFocus$ = new Subject<boolean>();
  qtyFocusSub!: Subscription;

  fullItemAccess$: Observable<boolean> = this.usersService.currentUser$.pipe(
    filter(value => !!value),
    map(userProfile => userProfile.role !== 'user' || userProfile.permissions.includes('edit_items'))
  );

  businessId$ = this.businessService.selectedBusiness$.pipe(
    filter(businessProfile => !!businessProfile),
    map(businessProfile => businessProfile!.businessId)
  );

  constructor(
    private proposalsService: ProposalsService,
    private modalsService: ModalsService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private usersService: UsersService,
    private businessService: BusinessService
  ) {
    this.mobileView = window.innerWidth < 576;
  }

  ngOnInit(){
    this.qtyFocusSub = this.qtyFocus$.pipe(
      tap(focus => {
        this.itemsListFocus.emit(focus);
        if (!focus && this.proposalVersion.items)
          this.proposalVersion.items.forEach(item => {
            if (item.quantity === 0 || !item.quantity)
              item.quantity = 1;
          });
      }),
      debounce(focus => {
        return focus ? timer(20) : timer(0);
      })
    ).subscribe(async focus => {
      if (!focus)
        await this.saveProposalVersion();
    });
  }

  ngOnDestroy() {
    this.qtyFocusSub.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this.mobileView) {
      if (this.proposalVersion.items)
        for (let i = 0; i < this.proposalVersion.items.length; i++)
          this.infoIconDistancesFromTop.push(
            document.getElementById('info-icon-'+i)!.getBoundingClientRect().top
          );
      this.cdr.detectChanges();
      this.afterViewInit = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.proposalVersion.items)
      return;

    if ('proposalVersion' in changes) {
      for (let i = 0; i < this.proposalVersion.items.length; i++) {
        this.reCalcQtyPricePadding('qty-price-'+i);
      }
    }

    if (this.afterViewInit) {
      for (let i = 0; i < this.proposalVersion.items.length; i++) {
        const top = document.getElementById('info-icon-' + i)?.getBoundingClientRect().top;
        if(top)
          this.infoIconDistancesFromTop[i] = top;
      }
    }
  }

  reCalcQtyPricePadding(elementId: string){
    if (!this.mobileView) {
      setTimeout(() => {
        try {
          const textLength = document.getElementById(elementId)
            ?.getElementsByClassName('item-price')[0].textContent?.length!-4
          const qtyPriceBlock = document.getElementById(elementId);
          if (textLength > 8 && textLength < 10) {
            qtyPriceBlock!.classList.add('sp-s');
          } else if (textLength >= 10 && textLength < 12) {
            qtyPriceBlock!.classList.add('sp-m');
          } else if (textLength >= 12) {
            qtyPriceBlock!.classList.add('sp-l');
          } else {
            qtyPriceBlock!.classList.remove('sp-l');
            qtyPriceBlock!.classList.remove('sp-m');
            qtyPriceBlock!.classList.remove('sp-s');
          }
        } catch (e) {
          console.log('Error during calculation!')
        }
      }, 0);
    }
  }

  getItemPrice(proposalItem: CommonPoIItem): string {
    if (!proposalItem.quantity || proposalItem.quantity === 0) {
      return '0';
    } else {
      let total = proposalItem.price!;

      if (proposalItem.discountAmount || proposalItem.discountPercent) {
        if (proposalItem.discountType === 'percent') {
          total -= total*proposalItem.discountPercent!/100;
        } else {
          total -= proposalItem.discountAmount!;
        }
      }

      if (proposalItem.taxable) {
        total += total*this.proposalVersion.clientSalesTaxPercentage!/100;
      }

      total = proposalItem.quantity!*total;

      return (Math.round(total * 100) / 100).toFixed(2).toString();
    }
  }

  async qtyFieldInput(event: any, proposalItem: CommonPoIItem, mobile?: boolean, elementId?: string) {
    event.target.value = event.target.value?.replace(/\D/, ''); // Prevent input non-digit chars by codes using ALT key
    setTimeout(async () => {
      proposalItem.quantity = +event.target.value;
      if (!mobile)
        this.reCalcQtyPricePadding(elementId!);
    }, 10);
  }

  async dropAction(event: any) {
    try {
      moveItemInArray(this.proposalVersion.items!, event.previousIndex, event.currentIndex);
      this.fixItemsIndexes();
      await this.saveProposalVersion();
    } catch (e) {
      alert('Something went wrong during proposals update!');
    }
  }

  async openAddProposalItemModal(fullAccess: boolean) {
    await this.modalsService.open(
      (fullAccess ? CreateEditWorkflowItemComponent : CreateEditWorkflowItemRestrictedComponent) as ComponentType<any>,
      {
        behavior: ModalBehavior.Auto,
        disableClose: true,
        data: {
          salesTaxPercentage: this.proposalVersion.clientSalesTaxPercentage,
          workflow: this.proposal,
          workflowVersion: this.proposalVersion,
          mode: 'add',
          updateFunction: (async (proposalId: number, updateData: Partial<ProposalVersion>) =>
            this.proposalsService.updateProposalVersion(proposalId, updateData)).bind(this)
        }
      }
    );
  }

  async editItem(item: CommonPoIItem, fullAccess: boolean) {
    await this.modalsService.open(
      (fullAccess ? CreateEditWorkflowItemComponent : CreateEditWorkflowItemRestrictedComponent) as ComponentType<any>,
      {
        behavior: ModalBehavior.Auto,
        disableClose: true,
        data: {
          salesTaxPercentage: this.proposalVersion.clientSalesTaxPercentage,
          item: item,
          workflow: this.proposal,
          workflowVersion: this.proposalVersion,
          mode: 'edit',
          updateFunction: (async (proposalId: number, updateData: Partial<ProposalVersion>) =>
            this.proposalsService.updateProposalVersion(proposalId, updateData)).bind(this)
        }
      }
    );
  }

  fixItemsIndexes() {
    for (let i = 0; i < this.proposalVersion.items!.length; i++) {
      this.proposalVersion.items![i].index = i;
    }
  }

  async duplicateItem(item: CommonPoIItem, index: number) {
    const newItem = { ... item };
    this.proposalVersion.items?.splice(index + 1, 0, newItem);
    if (index !== this.proposalVersion.items!.length - 1)
      this.fixItemsIndexes();
    await this.saveProposalVersion();
    showSnackbar(this.snackBar, {
      message: 'Item Duplicated',
      duration: 2000
    });
  }

  async deleteItem(index: number) {
    const currentItemsState = JSON.parse(JSON.stringify(this.proposalVersion.items));
    this.proposalVersion.items!.splice(index, 1);
    if (index !== this.proposalVersion.items!.length)
      this.fixItemsIndexes();
    await this.saveProposalVersion();
    showSnackbar(this.snackBar, {
      message: 'Item deleted',
      duration: 10000,
      actionText: 'Undo',
      action: (async () => {
        this.proposalVersion.items = currentItemsState;
        await this.saveProposalVersion();
      }).bind(this),
    });
  }

  private async saveProposalVersion() {
    this.proposalsService._recalculate$.next(0);
    await this.proposalsService.updateProposalVersion(this.proposalVersion.id, { items: this.proposalVersion.items } );
  }

  async openItemInfoMobile(proposalItem: CommonPoIItem) {
    await this.modalsService.open(WorkflowItemInfoMobileComponent, {
      data: {
        salesTaxPercentage: this.proposalVersion.clientSalesTaxPercentage,
        proposalItem: proposalItem
      }
    });
  }
}
